import React from "react";
import { useMutation } from "@tanstack/react-query";

import { put } from "utils/DeApi";

import getExpensesHeaders from "./expensesHeaders";
import getLabourHeaders from "./labourHeaders";
import getEquipmentHeaders from "./equipmentHeaders";
import getDigitialEquipmentHeaders from "./digitalEquipmentHeaders";
import getLabTestsHeaders from "./labTestsHeaders";
import getSubContractorsHeaders from "./subContractorsHeaders";

import {
  OpportunityContext,
  GovernanceExceptionsContext,
  GovernanceExceptionsDispatchContext,
} from "contexts/OpportunityProvider";

export const useCostingHeaders = (costingType = "labour") => {
  const opportunity = React.useContext(OpportunityContext);
  const governanceExceptions = React.useContext(GovernanceExceptionsContext);
  const setGovernanceExceptions = React.useContext(
    GovernanceExceptionsDispatchContext,
  );

  const updateExceptionStatus = useMutation({
    mutationFn: ({ exceptionId, status, uniqueId }) =>
      put(
        `/opportunities/${opportunity.id}/governance-exceptions/${exceptionId}`,
        {
          status,
          uniqueId,
        },
      ).promise.then((res) => res.data),
    onSuccess: (data, variables, context) => {
      setGovernanceExceptions([
        ...governanceExceptions.filter((item) => item.id !== data.id),
        data,
      ]);
    },
    throwOnError: false,
  });

  const labourExceptions = governanceExceptions?.filter(
    (item) => item.resourceType === "activityLabor",
  );
  const subContractorExceptions = governanceExceptions?.filter(
    (item) => item.resourceType === "activitySubcontractor",
  );
  const expenseExceptions = governanceExceptions?.filter(
    (item) => item.resourceType === "activityExpense",
  );

  const labourHeaders = React.useMemo(
    () =>
      getLabourHeaders(
        opportunity.contractTerm?.title?.toUpperCase() === "MSA",
        labourExceptions,
        updateExceptionStatus,
      ),
    [opportunity.contractTerm, labourExceptions, updateExceptionStatus],
  );
  const expensesHeaders = React.useMemo(
    () =>
      getExpensesHeaders(opportunity, expenseExceptions, updateExceptionStatus),
    [opportunity, expenseExceptions, updateExceptionStatus],
  );
  const equipmentHeaders = React.useMemo(getEquipmentHeaders, []);
  const digitalEquipmentHeaders = React.useMemo(
    getDigitialEquipmentHeaders,
    [],
  );
  const labTestsHeaders = React.useMemo(
    () => getLabTestsHeaders(opportunity),
    [opportunity],
  );
  const subContractorsHeaders = React.useMemo(
    () =>
      getSubContractorsHeaders(
        opportunity,
        subContractorExceptions,
        updateExceptionStatus,
      ),
    [opportunity, subContractorExceptions, updateExceptionStatus],
  );

  switch (costingType) {
    case "labour":
      return labourHeaders;
    case "expenses":
      return expensesHeaders;
    case "equipmentAndSupplies":
      return equipmentHeaders;
    case "digitalEquipment":
      return digitalEquipmentHeaders;
    case "labTests":
      return labTestsHeaders;
    case "subContractors":
      return subContractorsHeaders;
    default:
      return labourHeaders;
  }
};
