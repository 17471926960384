import { useLocation } from "react-router-dom";

const modules = {
  pricing: {
    title: "Pricing",
    value: "pricing",
    path: "/pricing",
  },
  tracking: {
    title: "Tracking",
    value: "tracking",
    path: "/tracking",
  },
};

const useModules = () => {
  const { pathname } = useLocation();
  const currentModule = pathname.includes("tracking")
    ? modules.tracking
    : modules.pricing;

  return {
    currentModule,
    modules,
  };
};

export default useModules;
