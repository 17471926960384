import React, { useContext, useEffect } from "react";

import {
  BreadcrumbDispatchContext,
  ActiveTabDispatchContext,
  OpportunityContext,
} from "contexts/OpportunityProvider";
import Loader from "components/Loader/Loader";
import OpportunityPMMetricsSummary from "./OpportunityPMMetricsSummary";

export default function OpportunityPMMetrics() {
  const opportunity = useContext(OpportunityContext);
  const setBreadcrumbs = useContext(BreadcrumbDispatchContext);
  const setActiveTab = useContext(ActiveTabDispatchContext);

  const { id, title, currency } = opportunity;

  useEffect(() => {
    setBreadcrumbs([
      { name: "Dashboard", link: "/pricing" },
      { name: "Opportunities", active: "false" },
      { name: title, link: `/pricing/opportunities/${id}` },
      { name: "PM Metrics", active: false },
    ]);
    setActiveTab("PMMetrics");
  }, [setBreadcrumbs, setActiveTab, title, id]);

  if (Object.keys(opportunity).length === 0) return <Loader />;

  return (
    <OpportunityPMMetricsSummary
      opportunityId={id}
      pricingType={opportunity.pricingType?.title}
      currency={currency}
    />
  );
}
