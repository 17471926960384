import { Formik } from "formik";

import { Modal, Button, Form, Row, Col } from "react-bootstrap";

import InputFieldFormik from "../../Inputs/InputFieldFormik";
import DropdownFieldFormik from "../../Inputs/DropdownFieldFormik";
import { useCallGetDeApi } from "../../../hooks/useCallApi";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { post } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

import { get } from "utils/DeApi";
import * as yup from "yup";

const initialValues = {
  title: "",
  description: "",
  clientId: "",
  organizationId: "",
  countryId: "",
  contractTermId: "",
};

const NewOpportunity = () => {
  const subscribedPromises = useRef([]);
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [isImporting, setIsImporting] = useState(false);

  const schema = yup.object().shape({
    title: yup
      .string()
      .required("Title is a required field")
      .max(75, "Title must be less than 75 characters"),
    description: yup
      .string()
      .max(75, "Description must be less than 75 characters"),
    clientId: yup.string().required("Client is a required field"),
    countryId: yup.string().required("Country is a required field"),
    contractTermId: yup.string().required("Contract Term is a required field"),
    organizationId: yup.string().required("Organization is a required field"),
    currencyId: yup.string().required("Contract Currency is a required field"),
  });

  const sfSchema = yup.object().shape({
    sfNumber: yup.string().required().length(18),
  });

  const { data: clientsData } = useCallGetDeApi("/clients");

  const { data: organizationsData, isLoading: organizationsIsLoading } =
    useCallGetDeApi(`/organizations`);

  const { data: countries, isLoading: countriesIsLoading } =
    useCallGetDeApi(`/countries`);

  const { data: contractTermsData, isLoading: contractTermsLoading } =
    useCallGetDeApi("/contract-terms");
  const { data: currencyData } = useCallGetDeApi("/currencies");

  const createNewOpportunity = (values) => {
    setError(null);
    setIsLoading(true);
    const trimmedTitle = values.title.replace(/\s{2,}/g, " ").trim();
    const createUpdateOpportunityPromise = isImporting
      ? get(`/salesforce/${values?.sfNumber}`)
      : post("/opportunities", {
          ...values,
          title: trimmedTitle,
        });
    createUpdateOpportunityPromise.promise
      .then((response) => {
        setIsLoading(false);
        navigate(`/opportunities/${response.data.id}`);
      })
      .catch((error) => {
        let errObj = {
          data: {
            error: {
              message: error?.data?.message || "",
            },
          },
          statusText: error?.statusText,
          status: error?.status,
        };
        !error.isCanceled && setError(errObj);
        setIsLoading(false);
      });

    subscribedPromises.current.push(createUpdateOpportunityPromise);
  };

  const getOrganizations = (countryId) => {
    // setIsLoading(true)
    const getPromise = get("/organizations", {
      params: { countryId: countryId },
    });
    getPromise.promise.then((response) => {
      setOrganizations(response.data);
    });

    subscribedPromises.current.push(getPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  return (
    <>
      <Button
        variant={"primary"}
        onClick={() => setShow(true)}
        className="text-nowrap"
        size="sm"
      >
        {/* <span className="material-icons-outlined">add</span> */}
        Add Opportunity
      </Button>
      <Modal
        show={show}
        onHide={() => {
          setIsImporting(false);
          setError(null);
          setShow(false);
        }}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>New Opportunity</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={isImporting ? sfSchema : schema}
          onSubmit={(values) => {
            createNewOpportunity(values);
          }}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            setValues,
            values,
            touched,
            isValid,
            errors,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="text-gray">General Information</h5>
                    <div>
                      <Form.Check
                        type="switch"
                        id="custom-switch-line-2"
                        className="ms-3"
                        label={
                          <small className="text-uppercase text-muted fw-semibold">
                            Import from Salesforce
                          </small>
                        }
                        checked={isImporting}
                        onChange={(e) => {
                          setIsImporting(e.target.checked);
                          setValues({ ...initialValues });
                        }}
                      />
                    </div>
                  </div>
                  <hr />
                  {!isImporting ? (
                    <>
                      <Row>
                        <Col>
                          <InputFieldFormik
                            name="title"
                            label={"Proposal Title"}
                            error={errors.title}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            placeholder="Enter title"
                            touched={touched.title}
                            value={values.title}
                          />
                        </Col>
                        <Col>
                          <DropdownFieldFormik
                            data={contractTermsData}
                            textKey="title"
                            valueKey="id"
                            name="contractTermId"
                            label={"Contract Term"}
                            error={errors.contractTermId}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            placeholder={"Select Contract Term"}
                            touched={touched.contractTermId}
                            value={values.contractTermId}
                            isLoading={contractTermsLoading}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={5}>
                          <DropdownFieldFormik
                            data={countries}
                            textKey="name"
                            valueKey="id"
                            name="countryId"
                            label={"Owning Organization"}
                            error={errors.countryId}
                            handleBlur={handleBlur}
                            handleChange={(e) => {
                              getOrganizations(e.target.value);
                              handleChange(e);
                            }}
                            placeholder={"Select country"}
                            touched={touched.countryId}
                            value={values.countryId}
                            isLoading={organizationsIsLoading}
                          />
                        </Col>
                        <Col xs={5}>
                          <DropdownFieldFormik
                            data={organizations}
                            textKey="title"
                            valueKey="id"
                            name="organizationId"
                            label={""}
                            error={errors.organizationId}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            placeholder={"Select organization"}
                            touched={touched.organizationId}
                            value={values.organizationId}
                            isLoading={organizationsIsLoading}
                            className={"mt-1"}
                          />
                        </Col>
                        <Col xs={2}>
                          <InputFieldFormik
                            className={"mt-1"}
                            label={""}
                            placeholder={""}
                            disabled={true}
                            value={
                              organizations?.find(
                                (val) => val.id === values.organizationId,
                              )?.serialNo || ""
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <DropdownFieldFormik
                            data={clientsData}
                            textKey="name"
                            valueKey="id"
                            name="clientId"
                            label={"Client"}
                            error={errors.clientId}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            placeholder={"Select client's name"}
                            touched={touched.clientId}
                            value={values.clientId}
                          />
                        </Col>
                        <Col>
                          <DropdownFieldFormik
                            data={
                              currencyData?.filter(
                                (item) => item.title === "USA",
                              ) || []
                            }
                            textKey="code"
                            valueKey="id"
                            name="currencyId"
                            label={"Contract Currency"}
                            error={errors.currencyId}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            placeholder={"Select currency"}
                            touched={touched.currencyId}
                            value={values.currencyId}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <InputFieldFormik
                            name="description"
                            label={"Description"}
                            error={errors.description}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            placeholder={"Enter description"}
                            touched={touched.description}
                            value={values.description}
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Row>
                      <Col>
                        <InputFieldFormik
                          name="sfNumber"
                          label={"Salesforce Number"}
                          error={errors.sfNumber}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          placeholder="Enter salesforce Number"
                          touched={touched.sfNumber}
                          value={values.sfNumber}
                        />
                      </Col>
                    </Row>
                  )}

                  {error && <ErrorHandler error={error} />}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    size="sm"
                    variant="link"
                    className="bg-secondary bg-opacity-25 text-secondary border-0 fw-medium"
                    onClick={() => {
                      setValues({ ...initialValues });
                      setIsImporting(false);
                      setError(null);
                      setShow(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    disabled={
                      Object.keys(errors).length > 0 || isLoading || !isValid
                    }
                  >
                    Add Opportunity
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default NewOpportunity;
