import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";

import {
  get as deGet,
  post as dePost,
  destroy as deDelete,
  put as dePut,
} from "utils/DeApi";

import NoItems from "./NoItems/NoItems";
import CreateItem from "./CreateItem/CreateItem";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import EditText from "./EditText/EditText";
import { UserContext } from "contexts/UserProvider";
import { ActionItemsContext } from "contexts/ActionItemsProvider";

import "./ActionItems.scss";

const statuses = {
  pending: "pending",
  success: "success",
  error: "error",
};

const ActionItems = ({
  title,
  actionableType,
  actionableId,
  modalShow,
  toggleModal,
}) => {
  // context
  const { user } = useContext(UserContext);
  const { refetchActionItems } = useContext(ActionItemsContext);

  // modal level state
  const [modalStatus, setModalStatus] = useState(statuses.pending);
  const [modalError, setModalError] = useState("");
  const [subscribers, setSubscribers] = useState([]);
  const [filteredActionItems, setFilteredActionItems] = useState([]);

  // create item state
  const [createItemText, setCreateItemText] = useState("");
  const [isCreatingItem, setIsCreatingItem] = useState(false);
  const [createItemError, setCreateItemError] = useState(null);

  // acactivity  state
  const [isActivity, setIsActivity] = useState(false);

  // current edit item state
  const [currentEditItem, setCurrentEditItem] = useState(null);

  const location = useLocation();

  const getSubscribersUrl = "/project-intel/subscribers";
  const actionItemsUrl = "/project-intel/action-items";

  const getItemsAndSubscribers = async () => {
    const getSubscribers = deGet(getSubscribersUrl);
    const getAllActionItems = deGet(actionItemsUrl);
    try {
      setModalStatus(statuses.pending);
      const [subscribers, allActionItems] = await Promise.all([
        getSubscribers.promise,
        getAllActionItems.promise,
      ]);

      // filter the data based on same project ID and project type
      const filteredData = allActionItems?.data.filter(
        (item) =>
          item.actionableType === actionableType &&
          item.actionableId === actionableId,
      );

      // sorting subscribers in ascending order
      subscribers?.data?.sort((a, b) => {
        const fullName = `${a.firstName} ${a.lastName}`;
        return fullName.localeCompare(`${b.firstName} ${b.lastName}`);
      });

      setSubscribers(subscribers.data);
      setFilteredActionItems(filteredData);
      setModalStatus(statuses.success);
    } catch (error) {
      setModalStatus(statuses.error);
      setModalError(error);
    }
  };

  const createActionItem = async () => {
    const payload = {
      actionableType,
      actionableId,
      title: createItemText,
      isRead: 0,
      url: location.pathname,
    };

    try {
      setIsCreatingItem(true);
      const data = await dePost(actionItemsUrl, payload).promise;
      const updatedData = [data.data, ...filteredActionItems];
      setFilteredActionItems(updatedData);
      setIsCreatingItem(false);
      setCreateItemText("");
      refetchActionItems();
      toast.success("Item created successfully");
    } catch (error) {
      setCreateItemError(error);
      setIsCreatingItem(false);
      toast.error(error?.message);
    }
  };

  const markDoneItem = async (item) => {
    const { id } = item;

    const updateItemUrl = `/project-intel/action-items/${id}`;
    const payload = {
      doneBy: item?.doneBy ? null : user.userId,
    };

    const notifyToastText = item?.doneBy
      ? "Item undo successfully"
      : "Item mark done successfully";

    setIsActivity(true);
    try {
      const data = await dePut(updateItemUrl, payload).promise;
      const updatedData = filteredActionItems?.map((item) => {
        if (item.id === data.data.id) {
          return {
            ...data.data,
          };
        }

        return item;
      });
      setFilteredActionItems(updatedData);
      refetchActionItems();
      setIsActivity(false);
      toast.success(notifyToastText);
    } catch (error) {
      setIsActivity(false);
      toast.error(error?.message);
    }
  };

  const reset = async (item) => {
    const { id } = item;

    const updateItemUrl = `/project-intel/action-items/${id}`;
    const payload = {
      isRead: 0,
      doneBy: null,
    };
    setIsActivity(true);
    try {
      const data = await dePut(updateItemUrl, payload).promise;
      const updatedData = filteredActionItems?.map((item) => {
        if (item.id === data.data.id) {
          return {
            ...data.data,
          };
        }

        return item;
      });
      setFilteredActionItems(updatedData);
      refetchActionItems();
      setIsActivity(false);
      toast.success("Item has been reset successfully");
    } catch (error) {
      setIsActivity(false);
      toast.error(error?.message);
    }
  };

  const assignTo = async (sub, item) => {
    const { id } = item;

    const updateItemUrl = `/project-intel/action-items/${id}`;
    const payload = {
      assignedTo: sub.id,
      assignedBy: user.userId,
    };
    setIsActivity(true);
    try {
      const data = await dePut(updateItemUrl, payload).promise;
      const updatedData = filteredActionItems?.map((item) => {
        if (item.id === data.data.id) {
          return {
            ...data.data,
          };
        }

        return item;
      });
      setFilteredActionItems(updatedData);
      refetchActionItems();
      setIsActivity(false);
      toast.success("Item has been assigned successsfully");
    } catch (error) {
      setIsActivity(false);
      toast.error(error?.message);
    }
  };

  const updateTitle = async () => {
    const { id, title } = currentEditItem;

    const updateItemUrl = `/project-intel/action-items/${id}`;
    const payload = {
      title,
    };
    setIsActivity(true);
    try {
      const data = await dePut(updateItemUrl, payload).promise;
      const updatedData = filteredActionItems?.map((item) => {
        if (item.id === data.data.id) {
          return {
            ...data.data,
          };
        }

        return item;
      });
      setFilteredActionItems(updatedData);
      refetchActionItems();
      setCurrentEditItem(null);
      setIsActivity(false);
      toast.success("Item's title been updated successsfully");
    } catch (error) {
      setIsActivity(false);
      toast.error(error?.message);
    }
  };

  const deleteItem = async (item) => {
    const { id } = item;

    const deleteItemUrl = `/project-intel/action-items/${id}`;

    const foundIndex = filteredActionItems?.findIndex((item) => item.id === id);

    try {
      setIsActivity(true);
      await deDelete(deleteItemUrl).promise;

      // removing item from array
      const deepClonedFilteredActionItems =
        structuredClone(filteredActionItems);
      deepClonedFilteredActionItems.splice(foundIndex, 1);

      // setting up state
      setFilteredActionItems(deepClonedFilteredActionItems);
      setIsActivity(false);
      refetchActionItems();
      toast.error("Item deleted successfully");
    } catch (error) {
      setIsActivity(false);
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    getItemsAndSubscribers();
  }, []);

  const handleCreateItemText = (event) => {
    const value = event.target.value;
    setCreateItemError(null);
    setCreateItemText(value);
  };

  const handleEditItem = (item) => {
    setCurrentEditItem(item);
  };

  const handleEditItemChange = (event) => {
    const value = event?.target.value;
    setCurrentEditItem({ ...currentEditItem, title: value });
  };

  // utility functions

  const canEdit = (item) => {
    if (item?.createdBy?.id === user?.userId) return true;
    return false;
  };

  const canDelete = (item) => {
    if (item?.createdBy?.id === user?.userId) return true;
    return false;
  };

  const canMarkDone = (item) => {
    if (item?.assignedTo?.id === user?.userId) return true;

    return false;
  };

  const canReset = (item) => {
    if (item?.createdBy.id === user?.userId && item?.doneBy !== null)
      return true;
    return false;
  };

  const canAssign = (item) => {
    if (item?.createdBy?.id === user?.userId) return true;
    return false;
  };

  const renderModalBody = () => {
    if (modalStatus === statuses.pending) return <Loader />;

    if (modalStatus === statuses.error)
      return <ErrorHandler error={modalError} />;

    if (modalStatus === statuses.success) {
      return (
        <>
          <h5>Create New Action Item</h5>
          <CreateItem
            onClick={createActionItem}
            onChange={handleCreateItemText}
            value={createItemText}
            isCreatingItem={isCreatingItem}
            createItemError={createItemError}
          />
          {filteredActionItems.length === 0 && <NoItems />}

          {filteredActionItems.length > 0 && (
            <h5 className="mt-3">All Action Items</h5>
          )}
          <div
            className={
              filteredActionItems.length > 0 ? "all-action-items-list" : ""
            }
          >
            {filteredActionItems?.map((item) => {
              const isCurrentEditItem = item?.id === currentEditItem?.id;
              return (
                <>
                  {isCurrentEditItem ? (
                    <EditText
                      isActivity={isActivity}
                      updateTitle={updateTitle}
                      handleEditItem={handleEditItem}
                      currentEditItem={currentEditItem}
                      handleEditItemChange={handleEditItemChange}
                    />
                  ) : (
                    <InputGroup key={item.id} className="mt-2">
                      <Form.Control
                        aria-label="Text input with buttons"
                        type="text"
                        value={item.title}
                        disabled={item?.doneBy !== null}
                        title={item.title}
                      />

                      <InputGroup.Text>
                        {item.assignedTo ? (
                          <span
                            title={`${item?.assignedTo.firstName} ${item?.assignedTo?.lastName}`}
                          >{`${item?.assignedTo.firstName} ${item?.assignedTo?.lastName}`}</span>
                        ) : (
                          <span className="text-danger">Not Assigned</span>
                        )}
                      </InputGroup.Text>

                      {canMarkDone(item) && (
                        <Button
                          variant={item.doneBy ? "primary" : "outline-primary"}
                          size="sm"
                          onClick={() => markDoneItem(item)}
                          disabled={isActivity}
                          title={item?.doneBy ? "Undo" : "Mark done"}
                        >
                          <span className="material-icons-outlined">
                            check_circle
                          </span>
                        </Button>
                      )}

                      {canReset(item) && (
                        <Button
                          variant="outline-dark"
                          size="sm"
                          onClick={() => reset(item)}
                          disabled={isActivity}
                          title="Reset"
                        >
                          <span className="material-icons-outlined">
                            restart_alt
                          </span>
                        </Button>
                      )}

                      {canAssign(item) && (
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="outline-success"
                            id="dropdown-basic"
                            disabled={item?.doneBy !== null || isActivity}
                            title="Assign"
                          ></Dropdown.Toggle>

                          <Dropdown.Menu id="assignment-dropdown-menu" flip>
                            {subscribers?.map((sub) => {
                              return (
                                <Dropdown.Item
                                  key={sub.id}
                                  onClick={() => assignTo(sub, item)}
                                  active={sub.id === item?.assignedTo?.id}
                                >{`${sub.firstName} ${sub.lastName}`}</Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}

                      {canEdit(item) && (
                        <Button
                          variant="outline-warning"
                          size="sm"
                          onClick={() => handleEditItem(item)}
                          disabled={isActivity || item.doneBy !== null}
                          title="Edit"
                        >
                          <span className="material-icons-outlined">edit</span>
                        </Button>
                      )}

                      {canDelete(item) && (
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={() => deleteItem(item)}
                          disabled={isActivity || item?.doneBy !== null}
                          title="Delete"
                        >
                          <span className="material-icons-outlined">
                            delete
                          </span>
                        </Button>
                      )}
                    </InputGroup>
                  )}
                </>
              );
            })}
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      show={modalShow}
      centered
    >
      <Modal.Header closeButton onHide={toggleModal}>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-success"
        >
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderModalBody()}</Modal.Body>
      <Modal.Footer>
        <Button onClick={toggleModal}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ActionItems;
