import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { Button, Col, Form, Row, Alert } from "react-bootstrap";
import InputFieldFormik from "components/Inputs/InputFieldFormik";
import { post } from "utils/DeApi";
import { put } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import * as yup from "yup";

import { useRefreshPhasesData } from "helpers/PhasesHelpers";

const ValueFactor = ({
  handleNextPrevClick,
  opportunityId,
  valueFactor,
  setValueFactor,
}) => {
  const [message, setMessage] = useState("");
  const [messageStyle, setMessageStyle] = useState("");
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const { callGetPhasesAPI } = useRefreshPhasesData();

  const schema = yup.object().shape({
    valueFactor: yup
      .number()
      .min(0)
      .max(100)
      .required("This is a required field"),
  });

  const subscribedPromises = useRef([]);

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  useEffect(
    function determineMessage() {
      if (valueFactor?.valueFactorScore < 0) {
        setMessage("Low Value Opportunity; Consider a No Go decision");
        setMessageStyle("bg-danger bg-opacity-10 text-danger px-2");
      } else if (
        valueFactor?.valueFactorScore >= 1 &&
        valueFactor?.valueFactorScore <= 4
      ) {
        setMessage(
          "Discount likely required to win a competitive bid, need strong value propositions, may need 0% - 10% discount",
        );
        setMessageStyle("bg-warning bg-opacity-10 text-warning px-2");
      } else if (
        valueFactor?.valueFactorScore >= 5 &&
        valueFactor?.valueFactorScore <= 12
      ) {
        setMessage("Consider increasing your price by 5-20%");
        setMessageStyle("bg-success bg-opacity-10 text-success px-2");
      } else if (valueFactor?.valueFactorScore >= 13) {
        setMessage(
          "Consider increasing your price by greater than 20%; engage with Value Pricing Team",
        );
        setMessageStyle("bg-success bg-opacity-10 text-success px-2");
      } else {
        setMessage("");
        setMessageStyle("");
      }
    },
    [valueFactor?.valueFactorScore],
  );

  const addValueFactor = (values) => {
    setError("");
    setIsLoading(true);
    setSuccess(false);
    let payload = {
      value_factor_percentage: values?.valueFactor,
      value_amplifiers: JSON.stringify(valueFactor?.valueAmplifiers),
      value_diminishers: JSON.stringify(valueFactor?.valueDiminishers),
    };
    const valueFactorPromise = valueFactor?.id
      ? put(
          `/opportunities/${opportunityId}/value-factors/${valueFactor?.id}`,
          payload,
        )
      : post(`/opportunities/${opportunityId}/value-factors`, payload);

    valueFactorPromise.promise
      .then((response) => {
        setIsLoading(false);
        setValueFactor({
          ...(response?.data || {}),
          valueAmplifiers: JSON.parse(response?.data?.valueAmplifiers || `[]`),
          valueDiminishers: JSON.parse(
            response?.data?.valueDiminishers || `[]`,
          ),
        });

        callGetPhasesAPI();
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
      });

    subscribedPromises.current.push(valueFactorPromise);
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        valueFactor: valueFactor?.valueFactorPercentage || 0,
      }}
      onSubmit={(values) => addValueFactor(values)}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isValid,
      }) => (
        <Form>
          {error && <ErrorHandler error={error} />}
          <Col>
            <span className="text-gray fw-bold">Value Factor (%)</span>
            <InputFieldFormik
              name="valueFactor"
              placeholder="Enter percentage value factor"
              type="number"
              error={errors.valueFactor}
              handleBlur={handleBlur}
              handleChange={handleChange}
              touched={touched.valueFactor}
              value={values.valueFactor}
            />
          </Col>

          <br />
          <Row>
            <Col>
              <span className="text-gray fw-bold">Starting Price</span>
              <p>{valueFactor?.StartingPrice?.toFixed(2) || 0}</p>
            </Col>
            <Col>
              <div className="d-flex gap-2">
                <span className="text-gray fw-bold">Value Factor Score:</span>
                <p>{valueFactor?.valueFactorScore || 0}</p>
              </div>
              <span className={`p-2 ${messageStyle}`}>{message}</span>
            </Col>
          </Row>

          <br />
          <Col>
            <span className="text-gray fw-bold">Value Factored Price:</span>
            <p>{valueFactor?.valueFactorPrice?.toFixed(2) || 0}</p>
          </Col>
          {isSuccess && (
            <Alert variant="success" className={`text-success`}>
              <p className="m-0 font-bold">
                <b>The submission of your data is complete.</b>
              </p>
            </Alert>
          )}
          <div className="d-flex gap-2 float-end">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Button
                  size="m"
                  variant="link"
                  className="bg-secondary bg-opacity-25 text-secondary border-0 fw-medium"
                  onClick={() => handleNextPrevClick(2, null, true)}
                >
                  Back
                </Button>
                <Button
                  className="float-end"
                  size="m"
                  variant="primary"
                  type={"submit"}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ValueFactor;
