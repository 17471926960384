import { useState, useEffect } from "react";

export default function useEquipmentData(phases) {
  const [equipments, setEquipments] = useState([]);

  useEffect(
    function extractGridEquipmentDataFromPhases() {
      let initialEquipments = [];
      phases.forEach((phase) => {
        phase.activities.forEach((activity) => {
          activity.equipment.forEach((equipment, equipmentIndex) => {
            const isEquipmentPresentIndex = initialEquipments?.findIndex(
              (element) => {
                return element.id === equipment.id;
                // eslint-disable-next-line prettier/prettier
            },
            );

            if (isEquipmentPresentIndex === -1) {
              initialEquipments.push({
                ...equipment,
                localCurrency: "USD",
                equipmentCategory: equipment.equipmentCategory?.id || "",
              });
            } else {
              initialEquipments[isEquipmentPresentIndex].total +=
                equipment.total;
            }
          });
        });
      });

      let finalEquipments = [];
      initialEquipments.forEach((element) => {
        let singleItem = {
          ...element,
          phases: Array(phases.length).fill({}),
        };

        singleItem.total = Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(singleItem.total);

        phases.forEach((phase, phaseIndex) => {
          let sumOfTotalHours = 0;
          let sumOfTotalActivityContingency = 0;

          singleItem.phases[phaseIndex] = {
            id: phase.id,
            title: phase.title,
            totalHours: 0,
            activities: Array(phase.activities.length).fill({}),
          };
          phase.activities.forEach((activity, activityIndex) => {
            const equipmentFound = activity.equipment.find((labour) => {
              return element.id === labour.id;
            });
            if (equipmentFound) {
              sumOfTotalHours += equipmentFound.qty;
              sumOfTotalActivityContingency += Number.parseFloat(
                equipmentFound.activityContingency,
              );
              singleItem.phases[phaseIndex].activities[activityIndex] = {
                id: activity.id,
                title: activity.title,
                total: equipmentFound.total,
                totalHours: equipmentFound.qty,
                activityContingency: Number.parseFloat(
                  equipmentFound.activityContingency,
                ),
              };
            } else {
              singleItem.phases[phaseIndex].activities[activityIndex] = {
                id: activity.id,
                title: activity.title,
                total: 0,
                totalHours: 0,
                activityContingency: 0,
              };
            }
          });
          singleItem.phases[phaseIndex].totalHours = sumOfTotalHours;
          singleItem.phases[phaseIndex].activityContingency =
            sumOfTotalActivityContingency;
        });
        finalEquipments.push(singleItem);
      });

      setEquipments(finalEquipments);
    },
    [phases],
  );

  return [equipments, setEquipments];
}
