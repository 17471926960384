import { useEffect, useState } from "react";

export default function useLabTestData(phases) {
  const [labTests, setLabTests] = useState([]);

  useEffect(
    function extractGridDigitalEquipmentDataFromPhases() {
      let initialLabTests = [];
      phases.forEach((phase) => {
        phase.activities.forEach((activity) => {
          activity.labTests.forEach((labTest, labIndex) => {
            const isLabPresentIndex = initialLabTests.findIndex((element) => {
              return element.id === labTest.id;
              // eslint-disable-next-line prettier/prettier
            });

            if (isLabPresentIndex === -1) {
              initialLabTests.push({
                ...labTest,
                localCurrency: "USD",
                lab: labTest.lab?.id || "",
              });
            } else {
              initialLabTests[isLabPresentIndex].total += labTest.total;
            }
          });
        });
      });

      let finalLabTests = [];
      initialLabTests.forEach((element) => {
        let singleItem = {
          ...element,
          phases: Array(phases.length).fill({}),
        };

        singleItem.total = Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(singleItem.total);

        phases.forEach((phase, phaseIndex) => {
          let sumOfTotalHours = 0;
          let sumOfTotalActivityContingency = 0;

          singleItem.phases[phaseIndex] = {
            id: phase.id,
            title: phase.title,
            totalHours: 0,
            activities: Array(phase.activities.length).fill({}),
          };
          phase.activities.forEach((activity, activityIndex) => {
            const labTestFound = activity.labTests.find((labour) => {
              return element.id === labour.id;
            });
            if (labTestFound) {
              sumOfTotalHours += Number.parseInt(labTestFound.qty);
              sumOfTotalActivityContingency += Number.parseFloat(
                labTestFound.activityContingency,
              );
              singleItem.phases[phaseIndex].activities[activityIndex] = {
                id: activity.id,
                title: activity.title,
                total: labTestFound.total,
                totalHours: Number.parseInt(labTestFound.qty),
                activityContingency: Number.parseFloat(
                  labTestFound.activityContingency,
                ),
              };
            } else {
              singleItem.phases[phaseIndex].activities[activityIndex] = {
                id: activity.id,
                title: activity.title,
                total: 0,
                totalHours: 0,
                activityContingency: 0,
              };
            }
          });
          singleItem.phases[phaseIndex].totalHours = sumOfTotalHours;
          singleItem.phases[phaseIndex].activityContingency =
            sumOfTotalActivityContingency;
        });
        finalLabTests.push(singleItem);
      });

      setLabTests(finalLabTests);
    },
    [phases],
  );

  return [labTests, setLabTests];
}
