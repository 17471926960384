import { useContext, useEffect } from "react";

import { OpportunityContext } from "contexts/OpportunityProvider";
import { BreadcrumbDispatchContext } from "contexts/OpportunityProvider";
import { ActiveTabDispatchContext } from "contexts/OpportunityProvider";
import { Card } from "react-bootstrap";
import StepperControl from "./StepperControl";

const OpportunityValueFactor = () => {
  const opportunity = useContext(OpportunityContext);
  const setBreadcrumbs = useContext(BreadcrumbDispatchContext);
  const setActiveTab = useContext(ActiveTabDispatchContext);

  useEffect(() => {
    setBreadcrumbs([
      { name: "Dashboard", link: "/pricing" },
      { name: "Opportunities", active: false },
      {
        name: opportunity.title,
        link: `/pricing/opportunities/${opportunity.id}`,
      },
      { name: "Value Factor", active: false },
    ]);
    setActiveTab("valueFactor");
  }, [setActiveTab, setBreadcrumbs, opportunity]);

  return (
    <Card className="p-4">
      <StepperControl opportunityId={opportunity?.id} />
    </Card>
  );
};

export default OpportunityValueFactor;
