export function ConvertNumber(number, decimal = 3) {
  let convertedNumber;
  convertedNumber = number?.toLocaleString(undefined, {
    minimumFractionDigits: decimal,
    maximumFractionDigits: 3,
  });
  return convertedNumber;
}

export function isNumeric(value) {
  return /^[-]?(\d+\.\d*|\.\d+|\d+\.?)?$/.test(value);
}

export const convertToCurrency = (
  number,
  { currency = "USD", locale = "en-US", fractionDigits = 0 } = {},
) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(number);
};
