import { useEffect, useState } from "react";

export default function useDigitalEquipmentData(phases) {
  const [digitalEquipments, setDigitalEquipments] = useState([]);

  useEffect(
    function extractGridDigitalEquipmentDataFromPhases() {
      let initialDigitalEquipments = [];
      phases.forEach((phase) => {
        phase.activities.forEach((activity) => {
          activity.digitalEquipment.forEach((equipment, equipmentIndex) => {
            const isDigitalEquipmentPresentIndex =
              initialDigitalEquipments?.findIndex((element) => {
                return element.id === equipment.id;
                // eslint-disable-next-line prettier/prettier
              });

            if (isDigitalEquipmentPresentIndex === -1) {
              initialDigitalEquipments.push({
                ...equipment,
                localCurrency: "USD",
                equipmentCategory: equipment.equipmentCategory?.id || "",
              });
            } else {
              initialDigitalEquipments[isDigitalEquipmentPresentIndex].total +=
                equipment.total;
            }
          });
        });
      });

      let finalDigitalEquipments = [];
      initialDigitalEquipments.forEach((element) => {
        let singleItem = {
          ...element,
          phases: Array(phases.length).fill({}),
        };

        singleItem.total = Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(singleItem.total);

        phases.forEach((phase, phaseIndex) => {
          let sumOfTotalHours = 0;
          let sumOfTotalActivityContingency = 0;

          singleItem.phases[phaseIndex] = {
            id: phase.id,
            title: phase.title,
            totalHours: 0,
            activities: Array(phase.activities.length).fill({}),
          };
          phase.activities.forEach((activity, activityIndex) => {
            const digitalEquipmentFound = activity.digitalEquipment.find(
              (digitalEquipment) => {
                return element.id === digitalEquipment.id;
              },
            );
            if (digitalEquipmentFound) {
              sumOfTotalHours += Number.parseInt(digitalEquipmentFound.qty);
              sumOfTotalActivityContingency += Number.parseFloat(
                digitalEquipmentFound.activityContingency,
              );
              singleItem.phases[phaseIndex].activities[activityIndex] = {
                id: activity.id,
                title: activity.title,
                total: digitalEquipmentFound.total,
                totalHours: digitalEquipmentFound.qty,
                activityContingency: Number.parseFloat(
                  digitalEquipmentFound.activityContingency,
                ),
              };
            } else {
              singleItem.phases[phaseIndex].activities[activityIndex] = {
                id: activity.id,
                title: activity.title,
                total: 0,
                totalHours: 0,
                activityContingency: 0,
              };
            }
          });
          singleItem.phases[phaseIndex].totalHours = sumOfTotalHours;
          singleItem.phases[phaseIndex].activityContingency =
            sumOfTotalActivityContingency;
        });
        finalDigitalEquipments.push(singleItem);
      });

      setDigitalEquipments(finalDigitalEquipments);
    },
    [phases],
  );

  return [digitalEquipments, setDigitalEquipments];
}
