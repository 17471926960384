import { useQuery } from "@tanstack/react-query";
import { get } from "utils/DeApi";

export default function useActionItems(fetchActionItems = false) {
  const {
    data: actionItems,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["actionItems"],
    queryFn: () =>
      get("/project-intel/my-action-items").promise.then((res) => res.data),
    enabled: fetchActionItems,
    refetchOnWindowFocus: true,
    refetchInterval: 10_000, // every 10 seconds
  });

  return {
    actionItems: actionItems || [],
    isActionItemsLoading: isLoading,
    refetchActionItems: refetch,
  };
}
