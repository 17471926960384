import { UserContext } from "contexts/UserProvider";
import { useContext } from "react";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

import "./LandingPage.scss";

function LandingPage() {
  const { user, modules } = useContext(UserContext);
  const navigate = useNavigate();

  //   The BEE call it categories, on FEE it's modules
  //   check if both modules/categories are not assigned
  if (user?.categories.length === 0 || user?.categories === null)
    return (
      <>
        <Alert variant="warning">
          <Alert.Heading>
            A module has not yet been assigned to you.
          </Alert.Heading>
          <p>
            Please reach out to the administrator to request assignment of any
            modules.
          </p>
        </Alert>
      </>
    );

  return (
    <Row>
      <Col>
        <Card className="card-box-height">
          <Card.Header as="h4">Project Pricing</Card.Header>
          <Card.Body>
            <Card.Text>
              <p>
                Project Pricing is a web-based solution created to facilitate
                and reduce risks in the pricing process for ERM opportunities.
              </p>
              {modules?.pricing?.isAllowed === null && (
                <p className="text-danger">
                  A module has not yet been assigned to you. <br />
                  Please reach out to the administrator to request of this
                  module.
                </p>
              )}
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Button
              variant="primary"
              disabled={modules?.pricing?.isAllowed === null}
              onClick={() => navigate("/pricing")}
            >
              Visit Now
            </Button>
          </Card.Footer>
        </Card>
      </Col>
      <Col>
        <Card className="card-box-height">
          <Card.Header as="h4">Project Tracking</Card.Header>
          <Card.Body>
            <Card.Text>
              <p>
                Project Tracking is an extension of project pricing developed to
                conduct project controls and create an efficient management
                system.
              </p>
              {modules?.tracking?.isAllowed === null && (
                <p className="text-danger">
                  A module has not yet been assigned to you. <br />
                  Please reach out to the administrator to request of this
                  module.
                </p>
              )}
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Button
              variant="primary"
              disabled={modules?.tracking?.isAllowed === null}
              onClick={() => navigate("/tracking")}
            >
              Visit Now
            </Button>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
}

export default LandingPage;
