import * as echarts from "echarts";
import { useEffect, useRef } from "react";

const SummaryBarChart = ({ values }) => {
  const el = useRef(null);
  const colors = [
    "#82A78D",
    "#446D5D",
    "#00FFBC",
    "#858A7F",
    "#019A20",
    "#0A2B14",
  ];

  useEffect(() => {
    const data = [
      {
        value: values[5],
        name: values[5] > 1 ? "Subcontractors" : "Subcontractor",
      },
      { value: values[4], name: values[4] > 1 ? "Labs" : "Lab" },
      {
        value: values[3],
        name: values[3] > 1 ? "Digital Products" : "Digital Product",
      },
      {
        value: values[2],
        name: values[2] > 1 ? "Equipment & Supplies" : "Equipment & Supply",
      },
      { value: values[1], name: values[1] > 1 ? "Expenses" : "Expense" },
      { value: values[0], name: values[0] > 1 ? "Labours" : "Labour" },
    ];
    const option = {
      color: colors,
      legends: {
        show: false,
      },
      grid: {
        left: "25%",
        right: "15%",
        top: "3%",
        bottom: "3%",
      },
      tooltip: { trigger: "item", formatter: "{b}<br/> ${c}" },
      toolbox: {
        feature: {
          saveAsImage: { name: `Cost breakdown in a phase` },
        },
      },
      xAxis: {
        type: "value",
        show: false,
      },
      yAxis: {
        type: "category",
        data: data.map((item) => item.name),
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          interval: 0,
          rotate: 0,
        },
      },
      series: [
        {
          name: "Project Costing",
          data: data.map((item) => ({
            ...item,
            name: item.name,
            label: { show: item.value !== 0, formatter: "${c}" },
            stillShowZeroSum: false,
          })),
          type: "bar",
          label: {
            show: true,
            position: "right",
          },
          itemStyle: {
            color: (params) => colors[params.dataIndex % colors.length],
          },
        },
      ],
    };

    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: 200,
    });

    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: 500,
        height: 200,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, values]);

  return (
    <div className="chart-container">
      <div className="w-100 py-2" ref={el} />
    </div>
  );
};
export default SummaryBarChart;
