import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import { Formik } from "formik";
import { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router";
import { destroy } from "utils/DeApi";
import * as yup from "yup";

const DeleteOpportunity = ({ opportunityId, title, onOpportunityDeleted }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const schema = yup.object().shape({
    name: yup
      .string()
      .oneOf([title?.replace(/\s+/g, " ")], "Must match opportunity name")
      .required("Must match opportunity name"),
  });

  const handleDelete = () => {
    setIsLoading(true);
    const clientPromise = destroy(`/opportunities/${opportunityId}`);

    clientPromise.promise
      .then((response) => {
        onOpportunityDeleted && onOpportunityDeleted(opportunityId);
        setShow(false);
        navigate("/");
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Button
        variant="outline-danger"
        className="ms-1"
        size="sm"
        onClick={() => {
          setShow(true);
        }}
      >
        <span className="material-icons-outlined">delete</span>
      </Button>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Opportunity - {title}</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          initialValues={{ name: "" }}
          onSubmit={(_) => handleDelete()}
          validateOnMount
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form>
              <Modal.Body>
                <p>Are you sure you want to delete this opportunity?</p>

                <Form.Group controlId="name" className="mt-3 mb-3">
                  <Form.Label>
                    Please type the name of the opportunity to confirm.
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    placeholder="Enter opportunity's name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.name === title}
                    isInvalid={errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                {isLoading && <Loader />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="link"
                  type="button"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  onClick={handleSubmit}
                  variant="danger"
                  type="button"
                  disabled={!isValid}
                >
                  Yes, I want to delete the opportunity
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
        {error && <ErrorHandler error={error} />}
      </Modal>
    </>
  );
};

export default DeleteOpportunity;
