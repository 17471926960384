import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";

const NoTasks = () => {
  return (
    <Card className="mt-3" style={{ height: "680px" }}>
      <Card.Body>
        <h5 className="text-gray fw-bold">Project Tasks</h5>
        <hr />
        <Alert variant="info" className={`my-3 d-flex flex-row`}>
          <div className="me-3">
            <span className="material-icons md-18 text-primary text-opacity-75">
              info
            </span>
          </div>
          <div>
            <h5 className="mb-1">
              <small>No Tasks</small>
            </h5>
            <p className="mb-1">
              <small>There are currently no tasks to show.</small>
            </p>
          </div>
        </Alert>
      </Card.Body>
    </Card>
  );
};

export default NoTasks;
