import React from "react";

import {
  cellStyle,
  headingStyle,
  getReadOnlyInputStyles,
  invalidInputStyles,
} from "components/CostingGrid/EditableGrid/shared/utils";
import { get } from "utils/DeApi";
import {
  OverlayTrigger,
  Tooltip,
  Dropdown,
  Row,
  Form,
  Col,
} from "react-bootstrap";

import * as yup from "yup";
import CostingHeaderCell from "components/CostingGrid/EditableGrid/shared/CostingHeaderCell";
import {
  CustomDropdownMenu,
  CustomToggle,
} from "components/CostingGrid/EditableGrid/shared/CustomDropdowns";

import { AccordionButtonCell } from "components/CostingGrid/EditableGrid/shared/PhaseTitle";

import {
  getBagdeVariant,
  GovernanceExceptionApprovalPopover,
} from "components/OpportunityGovernance/shared";

const getLabourHeaders = (
  isMSAContract,
  labourExceptions,
  updateExceptionStatus,
) => {
  const headers = [
    {
      id: "colTag",
      header: (props) => <td className={headingStyle + " text-center"}>A </td>,
      accessorKey: "colTag",
      cell: (props) => (
        <td className={cellStyle + " text-center"}>
          {props.getValue()}
          <span className="float-end cursor-pointer">
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
              <Dropdown.Menu as={CustomDropdownMenu} align={"right"}>
                <Dropdown.Item
                  onClick={() =>
                    props.table.options.meta?.handleAddNewRow(
                      props.row.index + 1,
                    )
                  }
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Add labour</Tooltip>}
                  >
                    <span className="btn btn-sm p-0" role="button">
                      <span className="material-icons-outlined md-18">add</span>
                    </span>
                  </OverlayTrigger>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    props.table.options.meta?.handleDeleteRow(
                      props.row.original.id,
                    )
                  }
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Delete labour</Tooltip>}
                  >
                    <span className="btn btn-sm p-0" role="button">
                      <span className="material-icons-outlined md-18">
                        delete
                      </span>
                    </span>
                  </OverlayTrigger>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </td>
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "totalHours",
      header: (props) => (
        <AccordionButtonCell
          className={headingStyle.replace(/bg-[a-zA-Z]+/g, "bg-tertiary")}
          xs={6}
          eventKey={props.column.id}
        >
          Total Hours
          <span className="material-icons-outlined">expand_more</span>
        </AccordionButtonCell>
      ),
      cell: CostingHeaderCell,
      accessorKey: "totalHours", // accessor is the "key" in the data
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          disabled: true,
        },
        cellClasses: cellStyle.replace(/bg-[a-zA-Z]+/g, "bg-tertiary"),
      },
      columns: [
        {
          id: "totalRegularHours",
          header: () => (
            <td
              className={
                headingStyle.replace(/bg-[a-zA-Z]+/g, "bg-white") + " ps-4"
              }
              xs={6}
            >
              Regular Hours
            </td>
          ),
          cell: CostingHeaderCell,
          accessorKey: "totalRegularHours", // accessor is the "key" in the data
          footer: (props) => props.column.id,
          meta: {
            cellInputProps: {
              type: "number",
              disabled: true,
            },
            cellClasses: cellStyle.replace(/bg-[a-zA-Z]+/g, "bg-white"),
          },
        },
        {
          id: "totalOvertimeHours",
          header: () => (
            <td
              className={
                headingStyle.replace(/bg-[a-zA-Z]+/g, "bg-white") + " ps-4"
              }
              xs={6}
            >
              Overtime Hours
            </td>
          ),
          cell: CostingHeaderCell,
          accessorKey: "totalOvertimeHours", // accessor is the "key" in the data
          footer: (props) => props.column.id,
          meta: {
            cellInputProps: {
              type: "number",
              disabled: true,
            },
            cellClasses: cellStyle.replace(/bg-[a-zA-Z]+/g, "bg-white"),
          },
        },
      ],
    },

    {
      id: "total",
      header: (props) => (
        <AccordionButtonCell
          className={headingStyle.replace(/bg-[a-zA-Z]+/g, "bg-tertiary")}
          xs={6}
          eventKey={props.column.id}
        >
          Total Cost
          <span className="material-icons-outlined">expand_more</span>
        </AccordionButtonCell>
      ),
      cell: CostingHeaderCell,
      accessorKey: "total", // accessor is the "key" in the data
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          disabled: true,
        },
        cellClasses: cellStyle.replace(/bg-[a-zA-Z]+/g, "bg-tertiary"),
      },
      columns: [
        {
          id: "totalRegular",
          header: () => (
            <td
              className={
                headingStyle.replace(/bg-[a-zA-Z]+/g, "bg-white") + " ps-4"
              }
              xs={6}
            >
              Regular Cost
            </td>
          ),
          cell: CostingHeaderCell,
          accessorKey: "totalRegular", // accessor is the "key" in the data
          footer: (props) => props.column.id,
          meta: {
            cellInputProps: {
              type: "number",
              disabled: true,
            },
            cellClasses: cellStyle.replace(/bg-[a-zA-Z]+/g, "bg-white"),
          },
        },
        {
          id: "totalOvertime",
          header: () => (
            <td
              className={
                headingStyle.replace(/bg-[a-zA-Z]+/g, "bg-white") + " ps-4"
              }
              xs={6}
            >
              Overtime cost
            </td>
          ),
          cell: CostingHeaderCell,
          accessorKey: "totalOvertime", // accessor is the "key" in the data
          footer: (props) => props.column.id,
          meta: {
            cellInputProps: {
              type: "number",
              disabled: true,
            },
            cellClasses: cellStyle.replace(/bg-[a-zA-Z]+/g, "bg-white"),
          },
        },
      ],
    },
    {
      id: "employeeRoleId",
      header: () => (
        <td className={headingStyle} xs={6}>
          Project Role
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "employeeRoleId", // accessor is the "key" in the data
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "select",
          queryKey: "getProjectRoles",
          getOptionsData: () => get(`/employee-roles`).promise,
          labelKey: "name",
          valueKey: "id",
        },
      },
    },
    {
      id: "workingOrg",
      header: () => (
        <td className={headingStyle} xs={6}>
          Working Org
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "workingOrg",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "select",
          queryKey: "getCountries",
          getOptionsData: () => get(`/countries`).promise,
          labelKey: "name",
          valueKey: "id",
          isDbSyncRequiredOnBlur: false,
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Local Currency
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "localCurrency",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "text",
          disabled: true,
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Staff Name
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "id",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "select",
          dependsOnCols: ["workingOrg"],
          getOptionsData: (row) =>
            get(`/employees`, {
              params: {
                countryId: row.getValue("workingOrg"),
              },
            }).promise,
          labelKey: "name",
          valueKey: "id",
          isDbSyncRequiredOnChange: true,
          validationFn: (value, fieldName, row, table) => {
            const otherRows = table
              .getRowModel()
              .rows.filter(
                (rowElem) => rowElem.original.id !== row.original.id,
              );
            const isExistingRow = otherRows.some(
              (rowElem) => rowElem.original.id === value,
            );
            return isExistingRow ? "Staff already exists" : "";
          },
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Staff Role
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "staffRole",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "text",
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Employee Pay Uplift <br />
          ($ amount in Local Currency)
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "employeePayUplift",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          validationFn: (value) =>
            yup.number().min(0).isValidSync(value)
              ? ""
              : "Value needs to be a positive value",
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          CareerLevel
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "careerLevel", // accessor is the "key" in the data
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "text",
          disabled: true,
          dependsOnCols: ["id"],
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Local Rate
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "localCost",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          dependsOnCols: ["id"],
          apiFieldFn: () => "localCost", // maintain localCost as the API field key
          dependentOptionKey: isMSAContract ? "discountedCost" : "localCost", // but use discountedCost for MSA contracts from selected employee data
          validationFn: (value) =>
            yup.number().min(0).isValidSync(value)
              ? ""
              : "Value needs to be a positive value",
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          At Currency Exchange Rate
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "atCurrencyExchangeRate",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          disabled: true,
          dependsOnCols: ["id"],
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          % Labour Adjustments (+/-)
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "labourAdjustmentPercentage",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "custom",
          dependsOnCols: ["id"],
          apiFieldKey: "labourAdjustmentPercentage",
          validationFn: (value, _) =>
            !yup.number().min(-100).max(100).isValidSync(value)
              ? "Value needs to be a % (-100 to 100)"
              : "",
          component: ({
            row,
            column,
            table,
            cell,
            value,
            setValue,
            validationError,
            handleOnBlur,
            handleOnInput,
          }) => (
            <Row>
              <Col xs={4} className="pe-0">
                <OverlayTrigger
                  overlay={
                    <Tooltip>{validationError || "Editable cell"}</Tooltip>
                  }
                  trigger={["hover", "focus"]}
                >
                  <input
                    type="text"
                    className={
                      "border w-100 h-100 text-end pe-1" +
                      invalidInputStyles(validationError)
                    }
                    ref={(el) => table.options.meta?.cellRefs.set(cell.id, el)}
                    value={value}
                    onChange={(e) => {
                      setValue(e.target.value);
                    }}
                    onBlur={handleOnBlur}
                    onKeyDown={(e) =>
                      table.options.meta?.handleKeyDown(e, row, column, table)
                    }
                    onKeyUp={(e) => {
                      if (e.key === "Enter") e.target.blur();
                    }}
                    onInput={handleOnInput}
                  />
                </OverlayTrigger>
              </Col>
              <Col xs={8}>
                <OverlayTrigger
                  overlay={<Tooltip>Read only</Tooltip>}
                  trigger={["hover", "focus"]}
                >
                  <input
                    type="text"
                    className={
                      "border w-100 h-100 text-end pe-1" +
                      getReadOnlyInputStyles(true)
                    }
                    value={row.original.labourAdjustmentValue}
                    onBlur={handleOnBlur}
                    readOnly
                  />
                </OverlayTrigger>
              </Col>
            </Row>
          ),
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Local Rate (adjusted)
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "localRateAdjusted",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          disabled: true,
          dependsOnCols: ["id"],
        },
      },
    },
    {
      id: "contingencyAmtPercentage",
      header: (props) => (
        <td className={headingStyle} xs={6}>
          Individual Contingency
        </td>
      ),
      cell: CostingHeaderCell,
      accessorFn: (rowData) =>
        Number.parseInt(rowData.contingencyType) === 1
          ? rowData.contingencyAmount
          : rowData.contingencyPercentage,

      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "custom",
          mainFieldType: "number",
          apiFieldFn: (row) =>
            Number.parseInt(row.original.contingencyType) === 1
              ? "contingencyAmount"
              : "contingencyPercentage",
          validationFn: (value, fieldName, _) => {
            if (fieldName === "contingencyPercentage") {
              return yup.number().min(0).max(100).isValidSync(value)
                ? ""
                : "Value needs to be a % (0 to 100)";
            } else {
              return yup.number().min(0).isValidSync(value)
                ? ""
                : "Value needs to be a positive number";
            }
          },
          component: ({
            row,
            column,
            table,
            cell,
            value,
            setValue,
            validationError,
            handleOnBlur,
            handleOnInput,
          }) => {
            const isDiscountApplied =
              row.original.discountAmount > 0 ||
              row.original.discountPercentage > 0;
            return (
              <Row>
                <Col xs={4}>
                  <Form.Select
                    aria-label="Default select example"
                    className="p-0 border-0 fw-bold"
                    disabled={isDiscountApplied}
                    value={row.original.contingencyType}
                    onChange={(e) => {
                      setValue(
                        Number.parseInt(e.target.value) === 1
                          ? row.original.contingencyAmount
                          : row.original.contingencyPercentage,
                      );
                      const updatedRow = {
                        ...row.original,
                        contingencyType: e.target.value,
                      };
                      table.options.meta?.updateData(
                        row.original.id,
                        "contingencyType",
                        "contingencyType",
                        e.target.value,
                        updatedRow,
                        false,
                        false,
                      );
                    }}
                  >
                    <option value={1}>$</option>
                    <option value={2}>%</option>
                  </Form.Select>
                </Col>
                <Col>
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        {validationError ||
                          (isDiscountApplied
                            ? "Discount is applied"
                            : "Editable cell")}
                      </Tooltip>
                    }
                    trigger={["hover", "focus"]}
                  >
                    <input
                      type="text"
                      readOnly={isDiscountApplied}
                      className={
                        `${
                          validationError ? "border" : "border-0"
                        } w-100 h-100 text-end pe-1` +
                        invalidInputStyles(validationError) +
                        getReadOnlyInputStyles(isDiscountApplied)
                      }
                      ref={(el) =>
                        table.options.meta?.cellRefs.set(cell.id, el)
                      }
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      onBlur={handleOnBlur}
                      onKeyDown={(e) =>
                        table.options.meta?.handleKeyDown(e, row, column, table)
                      }
                      onKeyUp={(e) => {
                        if (e.key === "Enter") e.target.blur();
                      }}
                      onInput={handleOnInput}
                    />
                  </OverlayTrigger>
                </Col>
              </Row>
            );
          },
        },
      },
    },
    {
      id: "discountAmtPercentage",
      header: () => (
        <td className={headingStyle} xs={6}>
          Discount (
          <span className="small text-muted">
            Adding discount will require BUMP approval
          </span>
          )
        </td>
      ),
      cell: CostingHeaderCell,
      accessorFn: (rowData) =>
        Number.parseInt(rowData.discountType) === 1
          ? rowData.discountAmount
          : rowData.discountPercentage,
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "custom",
          mainFieldType: "number",
          apiFieldFn: (row) =>
            Number.parseInt(row.original.discountType) === 1
              ? "discountAmount"
              : "discountPercentage",
          validationFn: (value, fieldName, row) => {
            if (fieldName === "discountPercentage") {
              return yup.number().min(0).max(100).isValidSync(value)
                ? ""
                : "Value needs to be a % (0 to 100)";
            } else if (fieldName === "discountAmount") {
              try {
                yup.number().min(0).validateSync(value);
              } catch (error) {
                return "Value needs to be a positive number";
              }
              return Number.parseFloat(value) > row.original.localCost
                ? "Discount cannot be more than local cost"
                : "";
            }
            return "";
          },
          component: ({
            row,
            column,
            table,
            cell,
            value,
            setValue,
            validationError,
            handleOnBlur,
            handleOnInput,
          }) => {
            const isContigencyApplied =
              row.original.contingencyAmount > 0 ||
              row.original.contingencyPercentage > 0;

            const isDiscountApplied = value > 0;
            const isPercentageDiscountType = row.original.discountType === 2;

            const discountGovernanceException = labourExceptions?.find(
              (item) =>
                item.rule?.name?.toLowerCase() === "discount" &&
                item.uniqueId === row.original.id,
            );

            const isDiscountRuleViolated =
              isDiscountApplied &&
              isPercentageDiscountType &&
              discountGovernanceException;

            let discountAppliedWarning = "Editable cell";
            if (isDiscountRuleViolated) {
              discountAppliedWarning =
                discountGovernanceException?.rule?.description;
            }

            const ValdationPopover = ({ ...props }) => (
              <GovernanceExceptionApprovalPopover
                {...props}
                exception={discountGovernanceException}
                updateExceptionStatus={updateExceptionStatus}
              />
            );

            return (
              <Row>
                <Col xs={4}>
                  <Form.Select
                    aria-label="Default select example"
                    className="p-0 border-0 fw-bold"
                    value={row.original.discountType}
                    disabled={isContigencyApplied}
                    onChange={(e) => {
                      setValue(
                        Number.parseInt(e.target.value) === 1
                          ? row.original.discountAmount
                          : row.original.discountPercentage,
                      );
                      const updateRow = {
                        ...row.original,
                        discountType: e.target.value,
                      };
                      table.options.meta?.updateData(
                        row.original.id,
                        "discountType",
                        "discountType",
                        e.target.value,
                        updateRow,
                        false,
                        false,
                      );
                    }}
                  >
                    <option value={1}>$</option>
                    <option value={2}>%</option>
                  </Form.Select>
                </Col>
                <Col>
                  <span className="position-relative p-0">
                    {isDiscountRuleViolated ? (
                      <span className="position-absolute top-0 left-0">
                        <OverlayTrigger
                          overlay={ValdationPopover}
                          trigger={"click"}
                        >
                          <span
                            className="btn btn-xs p-0 m-0"
                            data-title="Click to view the exception details"
                          >
                            <span className="material-icons-outlined md-18 text-danger align-baseline fw-bold">
                              priority_high
                            </span>
                          </span>
                        </OverlayTrigger>
                      </span>
                    ) : null}
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          {validationError ||
                            (isContigencyApplied
                              ? "Contingency is applied"
                              : discountAppliedWarning)}
                        </Tooltip>
                      }
                      trigger={["hover", "focus"]}
                    >
                      <input
                        type="text"
                        readOnly={isContigencyApplied}
                        className={
                          `${
                            validationError || isDiscountApplied
                              ? "border"
                              : "border-0"
                          } w-100 h-100 text-end pe-1` +
                          invalidInputStyles(validationError) +
                          getReadOnlyInputStyles(isContigencyApplied) +
                          (isDiscountRuleViolated
                            ? ` border-tertiary bg-${getBagdeVariant(
                                discountGovernanceException.status,
                              )} bg-opacity-50`
                            : "")
                        }
                        ref={(el) =>
                          table.options.meta?.cellRefs.set(cell.id, el)
                        }
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        onBlur={handleOnBlur}
                        onKeyDown={(e) =>
                          table.options.meta?.handleKeyDown(
                            e,
                            row,
                            column,
                            table,
                          )
                        }
                        onKeyUp={(e) => {
                          if (e.key === "Enter") e.target.blur();
                        }}
                        onInput={handleOnInput}
                      />
                    </OverlayTrigger>
                  </span>
                </Col>
              </Row>
            );
          },
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Final Discounted Local Rate
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "finalDiscountedRate",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          disabled: true,
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Tax - GST/VAT/BEAT (%, if applicable)
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "taxVat",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          validationFn: (value, _) =>
            yup.number().min(0).max(100).isValidSync(value)
              ? ""
              : "Value needs to be a % (0 to 100)",
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Tax - WHT (%, if applicable)
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "taxWht",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          validationFn: (value, _) =>
            yup.number().min(0).max(100).isValidSync(value)
              ? ""
              : "Value needs to be a % (0 to 100)",
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Currency Rate (USD)
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "currencyCost",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          disabled: true,
        },
      },
    },
  ];

  return headers;
};

export default getLabourHeaders;
