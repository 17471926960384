import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import { Link, useParams } from "react-router-dom";

const customClass = "bg-success bg-opacity-10 text-success";

function TaskList({ data }) {
  const { projectId, phaseId, taskId } = useParams();

  const { activities: tasks } = data || {};

  const getLink = (id) =>
    `/tracking/projects/${projectId}/phases/${phaseId}/tasks/${id}`;

  const listItems = tasks?.map((currentTask) => {
    const link = getLink(currentTask.id);

    const conditionalClass = {
      ...(taskId === currentTask.id ? { className: customClass } : {}),
    };

    return (
      <ListGroup.Item key={currentTask.id} action {...conditionalClass}>
        <Link to={link} className="text-decoration-none">
          {currentTask.name}{" "}
          <Badge className="bg-tertiary text-dark">{currentTask.code}</Badge>
        </Link>
      </ListGroup.Item>
    );
  });

  return <ListGroup variant="flush">{listItems}</ListGroup>;
}

export default TaskList;
