import React from "react";
import { Form } from "react-bootstrap";

const RadioField = ({
  name,
  label,
  value,
  handleChange,
  handleBlur,
  options,
  error,
  touched,
}) => {
  return (
    <Form.Group controlId={name} className="mb-3">
      <Form.Label className="mb-1">{label}</Form.Label>
      <div className="d-flex flex-column">
        {options.map((option) => (
          <div key={option.id} className="mb-2">
            <Form.Check
              inline
              label={option.label}
              type="radio"
              id={`${name}-${option.value}`}
              name={name}
              value={option.id}
              checked={String(value) === String(option.id)}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        ))}
      </div>
      <Form.Control.Feedback type="invalid">
        {error && touched ? <small>{error}</small> : null}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default RadioField;
