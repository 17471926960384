import React from "react";
import { Link } from "react-router-dom";
import "./TextLogo.scss";

const TextLogo = () => (
  <div className="text-center Logo">
    <div className="pb-4">
      <Link to="/">
        <img
          className="img-responsive"
          src="/logo-green.png"
          width="320px"
          alt="Logo"
        />{" "}
      </Link>
    </div>
  </div>
);

export default TextLogo;
