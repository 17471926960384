import { Card, Col } from "react-bootstrap";

import OpportunitiesChart from "./OpportunitiesChart/OpportunitiesChart";
import Opportunities from "./Opportunities/Opportunities";
import { useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [oppSummary, setOppSummary] = useState([]);
  const [error, setError] = useState(false);
  const [isOpportunitiesUpdated, setIsOpportunitiesUpdated] = useState(false);

  const subscribedPromises = useRef([]);

  useEffect(() => {
    setIsLoading(true);
    setOppSummary(null);
    setError(null);

    const getPromise = get("/all-opportunities-summary");
    getPromise.promise
      .then((response) => {
        setOppSummary(response);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    const promises = subscribedPromises.current;
    promises.push(getPromise);
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [isOpportunitiesUpdated]);

  return (
    <>
      <Card className="py-2">
        <Card.Body className="d-flex gap-3">
          <Col>
            <div className="d-flex">
              <span className="rounded material-icons-outlined text-hunter-green bg-success bg-opacity-10 p-1">
                article
              </span>
              <h6 className="text-gray mb-0 ms-2">Total Opportunities</h6>
            </div>
            <span className="display-4 fw-medium">
              {oppSummary?.length || 0}
            </span>{" "}
            <span className="ms-2">
              {!!oppSummary?.length &&
                new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  notation: "compact",
                }).format(
                  oppSummary?.reduce((acc, curr) => {
                    return acc + curr?.totalOpportunitySum;
                  }, 0),
                )}
            </span>
          </Col>
          <Col className="border-start border-tertiary ps-2">
            <div className="d-flex">
              <span className="bg-info text-dark-blue bg-opacity-10 rounded material-icons-outlined p-1">
                payments
              </span>
              <h6 className="text-gray mb-0 ms-2">Total Costed</h6>
            </div>
            <span className="display-4 fw-medium">2</span>{" "}
            <span className="ms-2">$1.09M</span>
          </Col>

          <Col className="border-start border-tertiary ps-2">
            <div className="d-flex">
              <span className="text-deep-indigo bg-deep-indigo bg-opacity-10 rounded material-icons-outlined p-1">
                verified_user
              </span>
              <h6 className="text-gray mb-0 ms-2">Total Qualified</h6>
            </div>
            <span className="display-4 fw-medium">4</span>
            <span className="fs-6 ms-2">$3.19M</span>
          </Col>

          <Col className="border-start border-tertiary ps-2">
            <div className="d-flex">
              <span className="rounded material-icons-outlined text-success bg-success bg-opacity-10 p-1">
                fact_check
              </span>
              <h6 className="text-gray mb-0 ms-2">Total Approved</h6>
            </div>
            <span className="display-4 fw-medium">2</span>
            <span className="ms-2">$5.10M</span>
          </Col>
        </Card.Body>
      </Card>
      <Col xs={24} sm={24}>
        <Card className="mt-3 mb-3">
          <Card.Body>
            <h6>All Opportunities Summary</h6>

            <hr />
            {isLoading && <Loader />}
            {error && <ErrorHandler error={error} />}
            {!error && oppSummary && <OpportunitiesChart data={oppSummary} />}
          </Card.Body>
        </Card>
      </Col>

      <Opportunities
        setIsOpportunitiesUpdated={() =>
          setIsOpportunitiesUpdated((prev) => !prev)
        }
      />
    </>
  );
};

export default Dashboard;
