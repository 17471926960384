import React, { useEffect, useRef, useState, useContext } from "react";
import { Formik } from "formik";
import {
  Alert,
  Button,
  Card,
  Col,
  Row,
  Form,
  OverlayTrigger,
} from "react-bootstrap";
import * as yup from "yup";
import { useMutation, useQuery } from "@tanstack/react-query";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import DropdownFieldFormik from "components/Inputs/DropdownFieldFormik";
import InputFieldFormik from "components/Inputs/InputFieldFormik";
import RadioField from "components/Inputs/RadioField";
import { formatDate } from "components/Layouts/OpportunityLayout";
import Loader from "components/Loader/Loader";
import { GovernanceExceptionsDispatchContext } from "contexts/OpportunityProvider";

import { useCallGetDeApi } from "hooks/useCallApi";

import { get, put } from "utils/DeApi";
import { upload } from "utils/DeApi";

import {
  GovernanceExceptionApprovalPopover,
  getBagdeVariant,
} from "components/OpportunityGovernance/shared";

const GMSForm = ({
  opportunity,
  gmsException: exception,
  governanceExceptions = [],
}) => {
  const subscribedPromises = useRef([]);
  const contractFileInput = useRef();
  const taxExemptionFileInput = useRef();
  const invoiceFormatFileInput = useRef();

  const [isGetterLoading, setIsGetterLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showChinaFields, setShowChinaFields] = useState(false);
  const [showIndiaFields, setShowIndiaFields] = useState(false);
  const [invoiceDelete, setInvoiceDelete] = useState([]);
  const [gmsDetails, setGMSDetails] = useState({});

  const [attachments, setAttachments] = useState({});
  const [filesDelete, setFilesDelete] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const setGovernanceExceptions = useContext(
    GovernanceExceptionsDispatchContext,
  );

  const updateExceptionStatus = useMutation({
    mutationFn: ({ exceptionId, status }) =>
      put(
        `/opportunities/${opportunity.id}/governance-exceptions/${exceptionId}`,
        {
          status,
        },
      ).promise.then((res) => res.data),
    onSuccess: (data, variables, context) => {
      setGovernanceExceptions([
        ...governanceExceptions.filter((item) => item.id !== data.id),
        data,
      ]);
    },
    throwOnError: false,
  });

  const exceptionsResults = useQuery({
    queryKey: [`governanceExceptions`, opportunity.id],
    queryFn: () =>
      get(
        `/opportunities/${opportunity.id}/governance-exceptions`,
      ).promise.then((res) => {
        setGovernanceExceptions(res.data);
        return res.data;
      }),
  });

  const schema = yup.object().shape({
    Project_gms_number: yup
      .number()
      .min(0, "Project (GMS) Number must be greater than or equal to 0")
      .test({
        message: "Project (GMS) Number should have 16 digits only",
        test: (number) => {
          if (number) {
            return String(number).length <= 16;
          } else {
            return true;
          }
        },
      }),
    contract: yup.string().test({
      message: "Please provide a supported file type",
      test: (value, context) => {
        if (value) {
          const isValid = [
            "jpg",
            "jpeg",
            "png",
            "gif",
            "pdf",
            "docx",
            "csv",
          ].includes(value?.split(".")[1]);
          if (!isValid) context?.createError();
          return isValid;
        } else {
          return true;
        }
      },
    }),
    work_by_ermers_outside: yup.number(),
    client_status: yup.string(),
    Tax_exemption: yup.number(),
    tax_exemption_contract: yup
      .string()
      .when("Tax_exemption", {
        is: (val) => {
          return (
            val &&
            !attachments?.tax_exemption_contract?.length &&
            !gmsDetails?.tax_exemption_contract?.length
          );
        },
        then: () =>
          yup
            .string()
            .required(
              "The tax exemption contract field is required when tax exemption is accepted.",
            ),
        otherwise: () => yup.string().notRequired(),
      })
      .test({
        message: "Please provide a supported file type",
        test: (value, context) => {
          if (value) {
            const isValid = [
              "jpg",
              "jpeg",
              "png",
              "gif",
              "pdf",
              "docx",
              "csv",
            ].includes(value?.split(".")[1]);
            if (!isValid) context?.createError();
            return isValid;
          } else {
            return true;
          }
        },
      }),
    gstin: yup.string(),
    location: yup.string(),
    gms_payment_type: yup.string(),
    primary_client_billing_contact_name: yup.string(),
    primary_client_billing_address: yup.string(),
    client_billing_contact_name: yup.string(),
    client_billing_address: yup.string(),
    Payment_terms: yup.number().min(0, "Payment terms must be greater than 0"),
    invoicing_terms: yup.string(),
    invoice_format: yup.string().test({
      message: "Please provide a supported file type",
      test: (value, context) => {
        if (value) {
          const isValid = [
            "jpg",
            "jpeg",
            "png",
            "gif",
            "pdf",
            "docx",
            "csv",
          ].includes(value?.split(".")[1]);
          if (!isValid) context?.createError();
          return isValid;
        } else {
          return true;
        }
      },
    }),
    invoice_backup: yup.string(),
    invoices: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        name: yup.string().required("Name is a required field."),
        currency_id: yup.string().required("Currency is a required field."),
        expected_amount: yup
          .number()
          .required("Expected amount is a required field.")
          .min(0, "Expected amount must be greater than or equal to 0")
          .test({
            message: "Expected amount should have 16 digits only",
            test: (number) => {
              if (number) {
                return String(number).length <= 16;
              } else {
                return true;
              }
            },
          }),
        date_of_issue: yup
          .date()
          .nullable()
          .required("Date of issue is a required field.")
          .max(
            new Date(9999, 12, 31),
            "Please input the year using four digits",
          ),
      }),
    ),
  });

  const { data: pricingTypesData, isLoading: pricingTypesIsLoading } =
    useCallGetDeApi("/pricing-types/gms");

  const { data: locationsData, isLoading: locationIsLoading } =
    useCallGetDeApi("/gms-locations");

  const addGMSDetails = (values, cb) => {
    setError(null);
    setIsLoading(true);
    setIsSuccess(false);

    const formData = new FormData();

    for (const key in values) {
      if (values[key] !== null || values[key] !== "" || values[key]) {
        if (
          key === "contract" ||
          key === "tax_exemption_contract" ||
          key === "invoice_format"
        ) {
          if (attachments?.[key]?.length) {
            attachments?.[key]?.forEach((file, index) => {
              formData.append(`${key}_${index + 1}`, file);
            });
          }
        } else if (key === "gstin" || key === "location") {
          if (showIndiaFields) {
            formData.append(key, values[key]);
          } else {
            formData.append(key, "");
          }
        } else if (key === "invoices") {
          if (values[key]?.length) {
            formData.append("invoices", JSON.stringify(values[key]));
          }
        } else {
          formData.append(key, values[key]);
        }
      }
    }

    if (filesDelete.length) {
      formData.append("files_delete", JSON.stringify(filesDelete));
    }
    if (invoiceDelete.length) {
      formData.append("invoice_delete", JSON.stringify(invoiceDelete));
    }

    const addGMSPromise = upload(
      `/opportunities/${opportunity.id}/gms`,
      formData,
    );
    addGMSPromise.promise
      .then((response) => {
        setIsSuccess(true);
        setIsLoading(false);
        setGMSDetails(response?.data);
        if (
          response?.data?.tax_exemption !== null ||
          response?.data?.tax_exemption ||
          response?.data?.tax_exemption_contract?.length
        ) {
          setShowChinaFields(true);
        }
        if (response?.data?.gstin || response?.data?.location) {
          setShowIndiaFields(true);
        }
        setAttachments({});
        setFilesDelete([]);
        setInvoiceDelete([]);
        if (cb) {
          cb("contract", "");
          cb("tax_exemption_contract", "");
          cb("invoice_format", "");
          cb(
            "invoices",
            response?.data?.billing_information?.invoices?.map((invoice) => ({
              name: invoice?.name,
              currency_id: invoice?.currency?.id,
              expected_amount: invoice?.expected_amount,
              date_of_issue: invoice?.date_of_issue,
              id: invoice?.id,
            })) || [],
          );
        }
        contractFileInput.current.value = "";
        if (taxExemptionFileInput?.current) {
          taxExemptionFileInput.current.value = "";
        }
        invoiceFormatFileInput.current.value = "";
        setTimeout(() => {
          setIsSuccess(false);
        }, 5000);

        exceptionsResults.refetch();
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });
    subscribedPromises.current.push(addGMSPromise);
  };

  useEffect(() => {
    const getGMSDetails = () => {
      setIsGetterLoading(true);
      setError(false);
      const getGMSPromise = get(`/opportunities/${opportunity.id}/gms`);
      getGMSPromise.promise
        .then((response) => {
          setGMSDetails(response?.data);
          if (
            (response?.data?.tax_exemption !== undefined &&
              response?.data?.tax_exemption !== null &&
              String(response?.data?.tax_exemption)) ||
            response?.data?.tax_exemption_contract?.length
          ) {
            setShowChinaFields(true);
          }
          if (response?.data?.gstin || response?.data?.location) {
            setShowIndiaFields(true);
          }
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => {
          setTimeout(() => {
            setIsGetterLoading(false);
          }, 1500);
        });
      subscribedPromises.current.push(getGMSPromise);
    };

    getGMSDetails();

    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  return (
    <>
      {isGetterLoading && <Loader />}
      {isGetterLoading ? null : (
        <Formik
          validationSchema={schema}
          onSubmit={(values, { resetForm, setFieldValue }) => {
            addGMSDetails(values, setFieldValue);
          }}
          initialValues={{
            Project_gms_number: gmsDetails?.project_gms_number || "",
            work_by_ermers_outside:
              gmsDetails?.work_by_ermers_outside !== null
                ? gmsDetails?.work_by_ermers_outside
                : "",
            client_status: gmsDetails?.client_status || "",
            Tax_exemption:
              gmsDetails?.tax_exemption !== null
                ? gmsDetails?.tax_exemption
                : "",
            tax_exemption_contract: "",
            gstin: gmsDetails?.gstin || "",
            location: gmsDetails?.location?.id || "",
            gms_payment_type:
              gmsDetails?.billing_information?.gms_payment_type?.id || "",
            primary_client_billing_contact_name:
              gmsDetails?.billing_information
                ?.primary_client_billing_contact_name || "",
            primary_client_billing_address:
              gmsDetails?.billing_information?.primary_client_billing_address ||
              "",
            client_billing_contact_name:
              gmsDetails?.billing_information?.client_billing_contact_name ||
              "",
            client_billing_address:
              gmsDetails?.billing_information?.client_billing_address || "",
            Payment_terms: gmsDetails?.billing_information?.payment_terms || "",
            invoicing_terms:
              gmsDetails?.billing_information?.invoicing_terms || "",
            invoice_backup:
              gmsDetails?.billing_information?.invoice_backup || "",
            invoices:
              gmsDetails?.billing_information?.invoices?.map((invoice) => ({
                name: invoice?.name,
                currency_id: invoice?.currency?.id,
                expected_amount: invoice?.expected_amount,
                date_of_issue: invoice?.date_of_issue,
                id: invoice?.id,
              })) || [],
          }}
        >
          {({
            handleSubmit,
            values,
            isValid,
            errors,
            setFieldValue,
            setFieldTouched,
            handleBlur,
            handleChange,
            touched,
          }) => {
            return (
              <Card>
                <Card.Body>
                  <Form onSubmit={handleSubmit}>
                    <h4>GMS Setup Form </h4>
                    <hr />
                    <h5 className="text-gray">General Information</h5>
                    <hr />
                    <Row>
                      <Col>
                        <InputFieldFormik
                          label={"Client Name"}
                          value={opportunity?.client?.name}
                          disabled={true}
                          notShowValidSign={true}
                        />
                      </Col>
                      <Col>
                        <InputFieldFormik
                          label={"Project Name"}
                          value={`${opportunity?.organization?.serialNo} - ${
                            opportunity?.client?.name
                          }${
                            opportunity?.projectLocation
                              ? ` - ${opportunity?.projectLocation}`
                              : ""
                          }`}
                          disabled={true}
                          notShowValidSign={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputFieldFormik
                          name="sfNumber"
                          label={"Salesforce Number"}
                          value={opportunity?.sfNumber}
                          disabled={true}
                          notShowValidSign={true}
                        />
                      </Col>

                      <Col>
                        <InputFieldFormik
                          type="number"
                          name="Project_gms_number"
                          label={"Project (GMS) Number"}
                          error={errors.Project_gms_number}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          placeholder="Enter Project (GMS) Number"
                          touched={touched.Project_gms_number}
                          value={values.Project_gms_number}
                          notShowValidSign={false}
                          min={0}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputFieldFormik
                          name="inchargePartnerId"
                          label={"Partner in Charge"}
                          value={opportunity?.inchargePartner?.title}
                          disabled={true}
                          notShowValidSign={true}
                        />
                      </Col>
                      <Col>
                        <InputFieldFormik
                          name="projectManagerId"
                          label={"Project Manager"}
                          value={opportunity?.projectManager?.title}
                          disabled={true}
                          notShowValidSign={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-1">
                            Contract: Attach Signed Contract
                          </Form.Label>
                          <Form.Control
                            type="file"
                            ref={contractFileInput}
                            name="contract"
                            multiple
                            onChange={(event) => {
                              const _file = event.currentTarget.files;
                              setAttachments((prev) => ({
                                ...prev,
                                contract: [..._file],
                              }));
                              setFieldTouched("contract", true);
                              setFieldValue(
                                "contract",
                                event.currentTarget.value,
                              );
                            }}
                            isInvalid={errors?.contract && touched?.contract}
                            isValid={!errors?.contract && values?.contract}
                          />
                          <div className="d-flex gap-2 align-items-center flex-wrap mt-2">
                            {gmsDetails?.contract?.map((file, index) => (
                              <div
                                key={file.id}
                                className="d-flex justify-content-between align-items-center  border border-primary rounded p-1 gap-2 "
                              >
                                <a
                                  href={file?.url}
                                  rel="noopener, noreferrer"
                                  target="_blank"
                                  className="text-decoration-none"
                                >
                                  <span className="material-icons-outlined md-20">
                                    download
                                  </span>{" "}
                                  <small>{file.filename}</small>
                                </a>
                                <span
                                  className="material-icons-outlined md-20 cursor-pointer text-danger"
                                  role="button"
                                  onClick={() => {
                                    const contractFiles = { ...gmsDetails };
                                    contractFiles?.contract?.splice(index, 1);
                                    setFilesDelete((prev) => [
                                      ...prev,
                                      file.id,
                                    ]);
                                    setGMSDetails({ ...contractFiles });
                                  }}
                                >
                                  delete
                                </span>
                              </div>
                            ))}
                          </div>
                          <div className="valid-feedback d-block text-gray">
                            <small>
                              *File Format: jpg, jpeg, png, gif, pdf, docx and
                              csv
                            </small>
                          </div>
                          <Form.Control.Feedback type="invalid">
                            {errors?.contract && touched?.contract ? (
                              <small>{errors?.contract}</small>
                            ) : null}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <InputFieldFormik
                          label={"GMS Confidentiality Status"}
                          value={opportunity?.gmsConfidentialityStatus?.title}
                          disabled={true}
                          notShowValidSign={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <RadioField
                          name="work_by_ermers_outside"
                          label="Work by other ERMers outside your Country (or business entity)?"
                          value={values?.work_by_ermers_outside}
                          options={[
                            { label: "Yes", value: 1, id: 1 },
                            { label: "No", value: 0, id: 0 },
                          ]}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          error={errors.work_by_ermers_outside}
                          touched={touched.work_by_ermers_outside}
                        />
                      </Col>
                      <Col>
                        <InputFieldFormik
                          name="client_status"
                          label={
                            "First Time Client? Confirm assessment completed (with external reference, if applicable)"
                          }
                          error={errors.client_status}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          placeholder=""
                          touched={touched.client_status}
                          value={values.client_status}
                          notShowValidSign={false}
                        />
                      </Col>
                    </Row>
                    <h5
                      className="cursor-pointer d-inline-block bg-success bg-opacity-10 px-1"
                      onClick={() => {
                        setShowChinaFields((prev) => !prev);
                      }}
                    >
                      {showChinaFields ? (
                        <span className="material-icons-outlined">remove</span>
                      ) : (
                        <span className="material-icons-outlined">add</span>
                      )}{" "}
                      China Only
                    </h5>
                    {showChinaFields ? (
                      <Row>
                        <Col>
                          <RadioField
                            name="Tax_exemption"
                            label="Is all the work being done outside China and we can apply for a Tax Exemption?"
                            value={values?.Tax_exemption}
                            options={[
                              { label: "Yes", value: 1, id: 1 },
                              { label: "No", value: 0, id: 0 },
                            ]}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            error={errors.Tax_exemption}
                            touched={touched.Tax_exemption}
                          />
                        </Col>
                        <Col>
                          {/* <InputFieldFormik
                        name="Project_gms_number"
                        label={
                          "If Yes, attach Business Licence of Client, and Project Certification that the project is outside China."
                        }
                        error={errors.Project_gms_number}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder=""
                        touched={touched.Project_gms_number}
                        value={values.Project_gms_number}
                      /> */}
                          <Form.Group className="mb-3">
                            <Form.Label className="mb-1">
                              If Yes, attach Business Licence of Client, and
                              Project Certification that the project is outside
                              China.
                            </Form.Label>

                            <Form.Control
                              type="file"
                              name="tax_exemption_contract"
                              ref={taxExemptionFileInput}
                              multiple
                              onChange={(event) => {
                                const _file = event.currentTarget.files;
                                setAttachments((prev) => ({
                                  ...prev,
                                  tax_exemption_contract: [..._file],
                                }));
                                setFieldTouched("tax_exemption_contract", true);
                                setFieldValue(
                                  "tax_exemption_contract",
                                  event.currentTarget.value,
                                );
                              }}
                              isInvalid={
                                errors?.tax_exemption_contract &&
                                touched?.tax_exemption_contract
                              }
                              isValid={
                                !errors?.tax_exemption_contract &&
                                values?.tax_exemption_contract
                              }
                            />
                            <div className="d-flex gap-2 align-items-center flex-wrap mt-2">
                              {gmsDetails?.tax_exemption_contract?.map(
                                (file, index) => (
                                  <div
                                    key={file.id}
                                    className="d-flex justify-content-between align-items-center  border border-primary rounded p-1 gap-2 "
                                  >
                                    <a
                                      href={file?.url}
                                      rel="noopener, noreferrer"
                                      target="_blank"
                                      className="text-decoration-none"
                                    >
                                      <span className="material-icons-outlined md-20">
                                        download
                                      </span>{" "}
                                      <small>{file.filename}</small>
                                    </a>
                                    <span
                                      className="material-icons-outlined md-20 cursor-pointer text-danger"
                                      role="button"
                                      onClick={() => {
                                        const taxExemptionFileInput = {
                                          ...gmsDetails,
                                        };
                                        taxExemptionFileInput?.tax_exemption_contract?.splice(
                                          index,
                                          1,
                                        );
                                        setFilesDelete((prev) => [
                                          ...prev,
                                          file.id,
                                        ]);
                                        setGMSDetails({
                                          ...taxExemptionFileInput,
                                        });
                                      }}
                                    >
                                      delete
                                    </span>
                                  </div>
                                ),
                              )}
                            </div>
                            <div className="valid-feedback d-block text-gray">
                              <small>
                                *File Format: jpg, jpeg, png, gif, pdf, docx and
                                csv
                              </small>
                            </div>
                            <Form.Control.Feedback type="invalid">
                              {errors?.tax_exemption_contract &&
                              touched?.tax_exemption_contract ? (
                                <small>{errors?.tax_exemption_contract}</small>
                              ) : null}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : null}
                    <hr />
                    <br />
                    <h5
                      className="cursor-pointer d-inline-block bg-success bg-opacity-10 px-1"
                      onClick={() => {
                        setShowIndiaFields((prev) => !prev);
                        if (showIndiaFields) {
                          setFieldValue("gstin", "");
                          setFieldValue("location", "");
                        }
                      }}
                    >
                      {showIndiaFields ? (
                        <span className="material-icons-outlined">remove</span>
                      ) : (
                        <span className="material-icons-outlined">add</span>
                      )}{" "}
                      India Only
                    </h5>
                    {showIndiaFields ? (
                      <Row>
                        <Col>
                          <InputFieldFormik
                            name="gstin"
                            label={"GSTIN"}
                            error={errors.gstin}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            placeholder="Enter GSTIN"
                            touched={touched.gstin}
                            value={values.gstin}
                            notShowValidSign={false}
                          />
                        </Col>
                        <Col>
                          <DropdownFieldFormik
                            data={locationsData || []}
                            textKey="name"
                            valueKey="id"
                            name="location"
                            label={"Location (State or Territory)"}
                            error={errors.location}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            placeholder={"Select Location"}
                            touched={touched.location}
                            value={values.location}
                            isLoading={locationIsLoading}
                          />
                        </Col>
                      </Row>
                    ) : null}
                    <hr />
                    <Row>
                      <Col>
                        <InputFieldFormik
                          label={"Expense Mark up Rate"}
                          value={Intl.NumberFormat("en-us", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 0,
                          }).format(opportunity?.expensesMarkup)}
                          disabled={true}
                          notShowValidSign={true}
                        />
                      </Col>
                      <Col>
                        <InputFieldFormik
                          label={"Subcontracts Mark up Rate"}
                          value={Intl.NumberFormat("en-us", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 0,
                          }).format(opportunity?.suppliersMarkup)}
                          disabled={true}
                          notShowValidSign={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputFieldFormik
                          label={"Communications Mark up Rate"}
                          value={Intl.NumberFormat("en-us", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 0,
                          }).format(opportunity?.commRecovery)}
                          disabled={true}
                          notShowValidSign={true}
                        />
                      </Col>
                      <Col>
                        <InputFieldFormik
                          label={"Associated Project Cost Rate"}
                          value={Intl.NumberFormat("en-us", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 0,
                          }).format(opportunity?.apc)}
                          disabled={true}
                          notShowValidSign={true}
                        />
                      </Col>
                      <Col>
                        <InputFieldFormik
                          label={"Market Segment (Sector)"}
                          value={""}
                          disabled={true}
                          notShowValidSign={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputFieldFormik
                          label={"Work Type (Service)"}
                          value={opportunity?.service?.title}
                          disabled={true}
                          notShowValidSign={true}
                        />
                      </Col>
                      <Col>
                        <InputFieldFormik
                          label={"Work Type (Sub-Service)"}
                          value={opportunity?.subService?.name}
                          disabled={true}
                          notShowValidSign={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputFieldFormik
                          type="date"
                          label={"Start Date"}
                          value={
                            opportunity?.projectStartDate
                              ? formatDate(opportunity.projectStartDate)
                              : ""
                          }
                          disabled={true}
                          notShowValidSign={true}
                        />
                      </Col>
                      <Col>
                        <InputFieldFormik
                          type="date"
                          label={"End Date"}
                          value={
                            opportunity?.projectEndDate
                              ? formatDate(opportunity.projectEndDate)
                              : ""
                          }
                          disabled={true}
                          notShowValidSign={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputFieldFormik
                          label={"Labor Budget"}
                          value={Intl.NumberFormat("en-us", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 0,
                          }).format(opportunity?.summary?.labor?.totalCost)}
                          disabled={true}
                          notShowValidSign={true}
                        />
                      </Col>
                      <Col>
                        <InputFieldFormik
                          label={"Subcontractor Budget"}
                          value={Intl.NumberFormat("en-us", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 0,
                          }).format(
                            (opportunity?.summary?.labTests?.totalCost || 0) +
                              (opportunity?.summary?.subcontractors
                                ?.totalCost || 0),
                          )}
                          disabled={true}
                          notShowValidSign={true}
                        />
                      </Col>
                      <Col>
                        <InputFieldFormik
                          label={"Expense Budget"}
                          value={Intl.NumberFormat("en-us", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 0,
                          }).format(
                            opportunity?.summary?.expenses?.expense || 0,
                          )}
                          disabled={true}
                          notShowValidSign={true}
                        />
                      </Col>
                    </Row>
                    <h5 className="text-gray mt-3">Billing Information</h5>
                    <hr />
                    <Row>
                      <Col>
                        <DropdownFieldFormik
                          data={pricingTypesData || []}
                          textKey="title"
                          valueKey="id"
                          name="gms_payment_type"
                          label={"GMS Payment Type"}
                          error={errors.gms_payment_type}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          placeholder={"Select GMS Payment Type"}
                          touched={touched.gms_payment_type}
                          value={values.gms_payment_type}
                          isLoading={pricingTypesIsLoading}
                        />
                      </Col>
                      <Col>
                        <InputFieldFormik
                          name="primary_client_billing_contact_name"
                          label={"Primary Client Billing Contact Name"}
                          error={errors.primary_client_billing_contact_name}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          placeholder="Enter Primary Client Billing Contact Name"
                          touched={touched.primary_client_billing_contact_name}
                          value={values.primary_client_billing_contact_name}
                          notShowValidSign={false}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputFieldFormik
                          name="primary_client_billing_address"
                          label={"Primary Client Billing Address (Full)"}
                          error={errors.primary_client_billing_address}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          placeholder="Enter Primary Client Billing Address (Full)"
                          touched={touched.primary_client_billing_address}
                          value={values.primary_client_billing_address}
                          notShowValidSign={false}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputFieldFormik
                          name="client_billing_contact_name"
                          label={"Client Billing Contact Name"}
                          error={errors.client_billing_contact_name}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          placeholder="Enter Client Billing Contact Name"
                          touched={touched.client_billing_contact_name}
                          value={values.client_billing_contact_name}
                          notShowValidSign={false}
                        />
                      </Col>
                      <Col>
                        <InputFieldFormik
                          name="client_billing_address"
                          label={"Client Billing Address (Full)"}
                          error={errors.client_billing_address}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          placeholder="Enter Client Billing Address (Full)"
                          touched={touched.client_billing_address}
                          value={values.client_billing_address}
                          notShowValidSign={false}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputFieldFormik
                          name="invoicing_terms"
                          label={"Invoicing terms (from Contract)"}
                          error={errors.invoicing_terms}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          placeholder="Enter Invoicing terms (from Contract)"
                          touched={touched.invoicing_terms}
                          value={values.invoicing_terms}
                          notShowValidSign={false}
                        />
                      </Col>
                      <Col>
                        {exception ? (
                          <InputFieldFormik
                            name="Payment_terms"
                            label={
                              <>
                                Payment terms (from Contract) (Number of days)
                                <OverlayTrigger
                                  overlay={({ ...props }) => (
                                    <GovernanceExceptionApprovalPopover
                                      {...props}
                                      exception={exception}
                                      updateExceptionStatus={
                                        updateExceptionStatus
                                      }
                                    />
                                  )}
                                  trigger={["click"]}
                                >
                                  <span className="position-relative">
                                    <span
                                      className="btn btn-small p-0"
                                      data-title="Click to view the exception details"
                                    >
                                      <span
                                        className={`material-icons-outlined text-${getBagdeVariant(
                                          exception.status,
                                        )}`}
                                      >
                                        error
                                      </span>
                                    </span>
                                  </span>
                                </OverlayTrigger>
                              </>
                            }
                            error={errors.Payment_terms}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            placeholder="Enter Payment terms (from Contract)"
                            touched={touched.Payment_terms}
                            value={values.Payment_terms}
                            notShowValidSign={false}
                            type="number"
                          >
                            <small
                              className={`text-${getBagdeVariant(
                                exception.status,
                              )} fw-bold mt-ne-2`}
                            >
                              {exception.rule?.description}
                            </small>
                          </InputFieldFormik>
                        ) : (
                          <InputFieldFormik
                            name="Payment_terms"
                            label={
                              "Payment terms (from Contract) (Number of days)"
                            }
                            error={errors.Payment_terms}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            placeholder="Enter Payment terms (from Contract)"
                            touched={touched.Payment_terms}
                            value={values.Payment_terms}
                            notShowValidSign={false}
                            type="number"
                          />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {/* <InputFieldFormik
                      name="invoice_format"
                      label={"Invoice format (attach if required)"}
                      error={errors.invoice_format}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      placeholder="Enter Invoice format (attach if required)"
                      touched={touched.invoice_format}
                      value={values.invoice_format}
                    /> */}
                        <Form.Group
                          controlId={"invoice_format"}
                          className="mb-3"
                        >
                          <Form.Label className="mb-1">
                            Invoice format (attach if required)
                          </Form.Label>

                          <Form.Control
                            type="file"
                            name="invoice_format"
                            ref={invoiceFormatFileInput}
                            multiple
                            onChange={(event) => {
                              const _file = event.currentTarget.files;
                              setAttachments((prev) => ({
                                ...prev,
                                invoice_format: [..._file],
                              }));
                              setFieldTouched("invoice_format", true);
                              setFieldValue(
                                "invoice_format",
                                event.currentTarget.value,
                              );
                            }}
                            isInvalid={
                              errors?.invoice_format && touched?.invoice_format
                            }
                            isValid={
                              !errors?.invoice_format && values?.invoice_format
                            }
                          />
                          <div className="d-flex gap-2 align-items-center flex-wrap mt-2">
                            {gmsDetails?.billing_information?.invoice_format?.map(
                              (file, index) => (
                                <div
                                  key={file.id}
                                  className="d-flex justify-content-between align-items-center  border border-primary rounded p-1 gap-2 "
                                >
                                  <a
                                    href={file?.url}
                                    rel="noopener, noreferrer"
                                    target="_blank"
                                    className="text-decoration-none"
                                  >
                                    <span className="material-icons-outlined md-20">
                                      download
                                    </span>{" "}
                                    <small>{file.filename}</small>
                                  </a>
                                  <span
                                    className="material-icons-outlined md-20 cursor-pointer text-danger"
                                    role="button"
                                    onClick={() => {
                                      const invoicesFormatFiles = {
                                        ...gmsDetails,
                                      };
                                      invoicesFormatFiles?.billing_information?.invoice_format?.splice(
                                        index,
                                        1,
                                      );
                                      setFilesDelete((prev) => [
                                        ...prev,
                                        file.id,
                                      ]);
                                      setGMSDetails({
                                        ...invoicesFormatFiles,
                                      });
                                    }}
                                  >
                                    delete
                                  </span>
                                </div>
                              ),
                            )}
                          </div>
                          <div className="valid-feedback d-block text-gray">
                            <small>
                              *File Format: jpg, jpeg, png, gif, pdf, docx and
                              csv
                            </small>
                          </div>
                          <Form.Control.Feedback type="invalid">
                            {errors?.invoice_format &&
                            touched?.invoice_format ? (
                              <small>{errors?.invoice_format}</small>
                            ) : null}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <InputFieldFormik
                          name="invoice_backup"
                          label={"Invoice backup (list requirements)"}
                          error={errors.invoice_backup}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          placeholder="Enter Invoice backup (list requirements)"
                          touched={touched.invoice_backup}
                          value={values.invoice_backup}
                          notShowValidSign={false}
                        />
                      </Col>
                    </Row>
                    <h5 className="text-gray mt-3">Invoices Information</h5>
                    <hr />
                    {values?.invoices?.map((val, index) => {
                      return (
                        <Row key={index}>
                          <Col>
                            <InputFieldFormik
                              name="name"
                              label={"Name"}
                              handleBlur={handleBlur}
                              handleChange={(e) => {
                                const value = e.target.value;
                                setFieldValue(`invoices[${index}].name`, value);
                              }}
                              placeholder="Enter Name"
                              value={values?.invoices?.[index]?.name}
                              notShowValidSign={false}
                              error={errors?.invoices?.[index]?.name}
                              touched={touched?.invoices?.[index]?.name}
                            />
                          </Col>
                          <Col>
                            <InputFieldFormik
                              label={"Currency"}
                              value={opportunity?.currency?.code}
                              notShowValidSign={true}
                              disabled
                            />
                          </Col>
                          <Col>
                            <InputFieldFormik
                              type="number"
                              name="expected_amount"
                              label={"Expected Amount"}
                              handleBlur={handleBlur}
                              handleChange={(e) => {
                                const value = e.target.valueAsNumber;
                                setFieldValue(
                                  `invoices[${index}].expected_amount`,
                                  !isNaN(value) ? value : "",
                                );
                              }}
                              placeholder="Enter Expected Amount"
                              value={values?.invoices?.[index]?.expected_amount}
                              notShowValidSign={false}
                              error={errors?.invoices?.[index]?.expected_amount}
                              touched={
                                touched?.invoices?.[index]?.expected_amount
                              }
                            />
                          </Col>
                          <Col>
                            <div className="d-flex align-items-start justify-content-between gap-2">
                              <InputFieldFormik
                                type="date"
                                name="date_of_issue"
                                label={"Date Of Issue"}
                                handleBlur={handleBlur}
                                handleChange={(e) => {
                                  const value = e.target.value;
                                  setFieldValue(
                                    `invoices[${index}].date_of_issue`,
                                    value,
                                  );
                                }}
                                value={
                                  values?.invoices?.[
                                    index
                                  ]?.date_of_issue?.split(" ")[0]
                                }
                                notShowValidSign={false}
                                className="w-100"
                                error={errors?.invoices?.[index]?.date_of_issue}
                                touched={
                                  touched?.invoices?.[index]?.date_of_issue
                                }
                              />
                              <Button
                                variant="outline-danger"
                                style={{ marginTop: 28 }}
                                onClick={() => {
                                  const fields = [...(values?.invoices || [])];
                                  fields.splice(index, 1);
                                  if (val.id) {
                                    setInvoiceDelete((prev) => [
                                      ...prev,
                                      val.id,
                                    ]);
                                  }
                                  setFieldValue(`invoices`, [...fields]);
                                }}
                              >
                                <span className="material-icons-outlined md-18">
                                  delete
                                </span>
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      );
                    }) || null}
                    <Row>
                      <Col>
                        <Button
                          onClick={() => {
                            const fields = [...(values?.invoices || [])];
                            fields.push({
                              name: "",
                              currency_id: opportunity?.currency?.id,
                              expected_amount: "",
                              date_of_issue: "",
                            });

                            setFieldValue(`invoices`, [...fields]);
                          }}
                          type="button"
                          size="sm"
                          disabled={isLoading}
                          className="mb-3"
                        >
                          Add Invoice
                        </Button>
                      </Col>
                    </Row>
                    {error && <ErrorHandler error={error} />}
                    {isSuccess && (
                      <Alert variant="success" className={`text-success`}>
                        <p className="m-0 font-bold">
                          <b>The submission of your data is complete.</b>
                        </p>
                      </Alert>
                    )}
                    <Card.Footer className="bg-white px-0">
                      <div className="float-end">
                        <Button
                          type="submit"
                          className="px-3 text-nowrap"
                          disabled={isLoading}
                        >
                          Submit
                        </Button>
                      </div>
                    </Card.Footer>
                  </Form>
                </Card.Body>
              </Card>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default GMSForm;
