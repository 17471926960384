import { useContext, useEffect } from "react";
import { OpportunityContext } from "contexts/OpportunityProvider";
import { BreadcrumbDispatchContext } from "contexts/OpportunityProvider";
import { ActiveTabDispatchContext } from "contexts/OpportunityProvider";
import Loader from "components/Loader/Loader";
import GMSSummaryTable from "./GMSSummaryTable";

const OpportunityGMSSummary = () => {
  const opportunity = useContext(OpportunityContext);
  const setBreadcrumbs = useContext(BreadcrumbDispatchContext);
  const setActiveTab = useContext(ActiveTabDispatchContext);

  const { id, title, currency } = opportunity;

  useEffect(() => {
    setBreadcrumbs([
      { name: "Dashboard", link: "/pricing" },
      { name: "Opportunities", active: false },
      { name: title, link: `/pricing/opportunities/${id}` },
      { name: "GMS Summary", active: false },
    ]);
    setActiveTab("gmsSummary");
  }, [setActiveTab, setBreadcrumbs, title, id]);

  if (Object.keys(opportunity).length === 0) return <Loader />;

  return <GMSSummaryTable opportunityId={id} currency={currency} />;
};

export default OpportunityGMSSummary;
