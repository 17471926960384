import { useCallback, useEffect, useState, useRef } from "react";
import { get, post } from "../utils/DeApi";

const useCallPostDeApi = (url, onSuccess) => {
  const [data, setData] = useState(null);
  const [body, setBody] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const callApi = () => {
    setIsLoading(true);
    setData(null);
    setError(null);

    const auditPromise = post(url, body);
    auditPromise.promise
      .then((response) => {
        setIsLoading(false);
        setData(response.data);
        onSuccess();
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });
  };

  return {
    setBody,
    data,
    isLoading,
    error,
    callApi,
  };
};

const useCallGetDeApi = (url, params) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [callAgain, setCallAgain] = useState(false);

  const subscribedPromises = useRef([]);

  const callApi = () => {
    setCallAgain(!callAgain);
  };

  const sendGetRequest = useCallback(() => {
    setIsLoading(true);
    setData(null);
    setError(null);

    const getPromise = get(url, params);
    getPromise.promise
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(getPromise);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, callAgain]);

  useEffect(() => {
    sendGetRequest();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [sendGetRequest]);

  return {
    data,
    isLoading,
    error,
    callApi,
  };
};

export { useCallPostDeApi, useCallGetDeApi };
