import React, { useState } from "react";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { Button, Col, Row } from "react-bootstrap";
import * as yup from "yup";
import DropdownFieldFormik from "components/Inputs/DropdownFieldFormik";

const ValueAmplifiers = ({ valueFactor, handleNextPrevClick }) => {
  const initialValues = {
    clientRelationship:
      valueFactor?.valueAmplifiers?.find((val) => val.id === 1)?.score || "",
    clientPerception:
      valueFactor?.valueAmplifiers?.find((val) => val.id === 2)?.score || "",
    urgencyNeed:
      valueFactor?.valueAmplifiers?.find((val) => val.id === 3)?.score || "",
    clientAwareness:
      valueFactor?.valueAmplifiers?.find((val) => val.id === 4)?.score || "",
    impact:
      valueFactor?.valueAmplifiers?.find((val) => val.id === 5)?.score || "",
    dealSize:
      valueFactor?.valueAmplifiers?.find((val) => val.id === 6)?.score || "",
    marketScarcity:
      valueFactor?.valueAmplifiers?.find((val) => val.id === 7)?.score || "",
  };

  const options = [1, 2, 3, 4, 5].map((n) => ({ id: n, value: n }));

  const schema = yup.object().shape({
    clientRelationship: yup.string().required("This is a required field"),
    clientPerception: yup.string().required("This is a required field"),
    urgencyNeed: yup.string().required("This is a required field"),
    clientAwareness: yup.string().required("This is a required field"),
    impact: yup.string().required("This is a required field"),
    dealSize: yup.string().required("This is a required field"),
    marketScarcity: yup.string().required("This is a required field"),
  });

  const calculateAmplifiers = (values, actions) => {
    actions.setSubmitting(false);
    let arr = [];
    Object.keys(values).map((val, index) => {
      arr.push({
        id: index + 1,
        score: values[val],
      });
    });
    handleNextPrevClick(3, arr);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values, actions) => {
        calculateAmplifiers(values, actions);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        touched,
        isValid,
        isSubmitting,
        errors,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="d-flex gap-4">
            <Col>
              <Row>
                <h5 className="text-gray">Client Relationship</h5>
                <DropdownFieldFormik
                  data={options}
                  textKey="id"
                  valueKey="value"
                  name="clientRelationship"
                  label="Higher score represents level of trust and confidence in the team [Strong Client relationship = 5; New Client = 1]. Consider the different areas of the Client's business and the level of those relationships. Are we dealing with the decision maker(s)?"
                  type="number"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  placeholder="Answer"
                  error={errors.clientRelationship}
                  touched={touched.clientRelationship}
                  value={values.clientRelationship}
                />
              </Row>

              <Row>
                <h5 className="text-gray">Client Perception of ERM Value</h5>
                <DropdownFieldFormik
                  data={options}
                  textKey="id"
                  valueKey="value"
                  name="clientPerception"
                  label="Higher score represents a very informed client that understands and respects ERM's services."
                  type="number"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  placeholder="Answer"
                  error={errors.clientPerception}
                  touched={touched.clientPerception}
                  value={values.clientPerception}
                />
              </Row>

              <Row>
                <h5 className="text-gray">Urgency of Need</h5>
                <DropdownFieldFormik
                  data={options}
                  textKey="id"
                  valueKey="value"
                  name="urgencyNeed"
                  label="How critical is the need to our Client? Increase score with need. Also consider Client's risk and degree of mitigation of that risk in regards to potential cost (monetary, reputational, etc.)"
                  type="number"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  placeholder="Answer"
                  error={errors.urgencyNeed}
                  touched={touched.urgencyNeed}
                  value={values.urgencyNeed}
                />
              </Row>
            </Col>

            <Col>
              <Row>
                <h5 className="text-gray">Client Awareness</h5>
                <DropdownFieldFormik
                  data={options}
                  textKey="id"
                  valueKey="value"
                  name="clientAwareness"
                  label="Rate the client's understanding of the technical challenges of the opportunity."
                  type="number"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  placeholder="Answer"
                  error={errors.clientAwareness}
                  touched={touched.clientAwareness}
                  value={values.clientAwareness}
                />
              </Row>

              <Row>
                <h5 className="text-gray">Impact to the Client</h5>
                <DropdownFieldFormik
                  data={options}
                  textKey="id"
                  valueKey="value"
                  name="impact"
                  label="Risk? Reputation? Cost avoidance, revenue increase potential?"
                  type="number"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  placeholder="Answer"
                  error={errors.impact}
                  touched={touched.impact}
                  value={values.impact}
                />
              </Row>

              <Row>
                <h5 className="text-gray">Size of Deal</h5>
                <DropdownFieldFormik
                  data={options}
                  textKey="id"
                  valueKey="value"
                  name="dealSize"
                  label="Material value to the clients organization?"
                  type="number"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  placeholder="Answer"
                  error={errors.dealSize}
                  touched={touched.dealSize}
                  value={values.dealSize}
                />
              </Row>
              <Row>
                <h5 className="text-gray">Market Scarcity</h5>
                <DropdownFieldFormik
                  data={options}
                  textKey="id"
                  valueKey="value"
                  name="marketScarcity"
                  label="Score should increase based on an objective assessment of the scarcity of experts or teams needed to meet client needs. Consider our value propositions, branding, experience and global reach in addition to expertise."
                  type="number"
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  placeholder="Answer"
                  error={errors.marketScarcity}
                  touched={touched.marketScarcity}
                  value={values.marketScarcity}
                />
              </Row>
            </Col>
          </div>

          <div className="d-flex gap-2 float-end">
            <Button
              size="m"
              variant="link"
              className="bg-secondary bg-opacity-25 text-secondary border-0 fw-medium"
              onClick={() => handleNextPrevClick(1)}
            >
              Back
            </Button>
            <Button
              type="submit"
              size="m"
              variant="primary"
              disabled={isSubmitting}
            >
              Next
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ValueAmplifiers;
