import React, { useEffect } from "react";
import * as echarts from "echarts/lib/echarts.js";

const LabourPyramidChart = ({ careerLevelSummary, totalLaborHours }) => {
  useEffect(() => {
    const filteredData = careerLevelSummary
      ?.filter((level) => level.hoursPercentage > 0) // Filter out data with 0 value
      .map((level) => {
        return {
          value: level.hoursPercentage?.toFixed(2),
          name: level.title,
          hours: level.totalHours,
        };
      });
    const chart = echarts.init(document.getElementById("pyramid-chart"));

    const option = {
      title: {
        show: true,
        textStyle: {
          color: !filteredData.length ? "grey" : "black",
          fontSize: !filteredData.length ? 20 : 16,
        },
        text: !filteredData.length ? "No data available" : "Hours %",
        left: "center",
        top: !filteredData.length ? "center" : "bottom",
      },
      label: {
        position: "outer",
        alignTo: "none",
        textStyle: {
          fontSize: 8,
        },
        formatter: function (params) {
          const hoursText = params.data.hours > 1 ? "Hours" : "Hour";
          return `${params.data.name} - ${params.data.hours} ${hoursText} (${params.data.value}%)`;
        },
      },
      tooltip: {
        trigger: "item",
        formatter: function (params) {
          const hoursText = params.data.hours > 1 ? "Hours" : "Hour";
          return `${params.data.name} - ${params.data.hours} ${hoursText} (${params.data.value}%)`;
        },
      },
      toolbox: {
        feature: {
          saveAsImage: { name: `Labor Hours(%) Pyramid` },
        },
      },
      legend: {
        data: filteredData?.map((val) => val?.name),
      },
      series: [
        ...(filteredData.length
          ? [
              {
                type: "funnel",
                left: "10%",
                width: "80%",
                maxSize: "50%",
                sort: "ascending",
                gap: 2,
                funnelAlign: "center",
                label: {
                  show: true,
                  position: "left",
                },
                labelLine: {
                  length: 10,
                  lineStyle: {
                    width: 1,
                    type: "solid",
                  },
                },
                itemStyle: {
                  borderColor: "#fff",
                  borderWidth: 1,
                },
                data: filteredData,
              },
            ]
          : []),
      ],
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [careerLevelSummary, totalLaborHours]);

  return (
    <div
      id="pyramid-chart"
      style={{ width: "100%", height: "500px", marginTop: 10 }}
    />
  );
};

export default LabourPyramidChart;
