import { createContext, useContext, useState } from "react";

const PhasesContext = createContext(undefined);
const PhasesDispatchContext = createContext(undefined);

function PhasesProvider({ children }) {
  const [phases, setPhases] = useState([]);

  return (
    <PhasesContext.Provider value={phases}>
      <PhasesDispatchContext.Provider value={setPhases}>
        {children}
      </PhasesDispatchContext.Provider>
    </PhasesContext.Provider>
  );
}

export function usePhases() {
  return useContext(PhasesContext);
}

export function usePhasesDispatch() {
  return useContext(PhasesDispatchContext);
}

export { PhasesProvider, PhasesContext, PhasesDispatchContext };
