import { Link } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import useModules from "hooks/useModules";
import { UserContext } from "contexts/UserProvider";
import { useContext } from "react";

const ModulesDropdown = () => {
  const { modules: allowedModules } = useContext(UserContext);
  const { currentModule, modules } = useModules();

  const renderDropdown = () => {
    if (allowedModules.pricing.isAllowed && allowedModules.tracking.isAllowed) {
      return (
        <NavDropdown title={currentModule.title}>
          <NavDropdown.Item
            as={Link}
            to={modules.pricing.path}
            active={currentModule.value === modules.pricing.value}
          >
            {modules.pricing.title}
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item
            as={Link}
            to={modules.tracking.path}
            active={currentModule.value === modules.tracking.value}
          >
            {modules.tracking.title}
          </NavDropdown.Item>
        </NavDropdown>
      );
    }

    if (allowedModules.pricing.isAllowed) {
      return <Navbar.Text>Pricing</Navbar.Text>;
    }

    if (allowedModules.tracking.isAllowed) {
      return <Navbar.Text>Tracking</Navbar.Text>;
    }

    return null;
  };

  return <>{renderDropdown()}</>;
};

export default ModulesDropdown;
