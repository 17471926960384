import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span
    as="button"
    className="btn btn-sm p-0"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    ref={ref}
  >
    {children}
    <OverlayTrigger overlay={<Tooltip>View actions</Tooltip>}>
      <span className="material-icons-outlined md-16">more_vert</span>
    </OverlayTrigger>
  </span>
));

export const CustomDropdownMenu = React.forwardRef(
  (
    { children, style, className, "aria-labelledby": labeledBy, onClick },
    ref,
  ) => (
    <div
      ref={ref}
      className={className}
      aria-labelledby={labeledBy}
      style={{
        ...style,
        minWidth: "2rem",
      }}
    >
      {children}
    </div>
  ),
);
