import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";

import { isNumeric } from "utils/NumbersUtil";
import * as yup from "yup";

export const cellStyle = "border bg-white cell-width align-middle";
export const headingStyle =
  "border bg-white cell-heading-width align-middle fw-bold";

export const costingTypesMap = {
  labour: "Labour",
  digitalEquipment: "Digital Product",
  equipmentAndSupplies: "Equipment and Supplies",
  labTests: "Lab",
  expenses: "Expense",
  subContractors: "Subcontractor",
};

export const costingAPIResourceMap = {
  labour: "employees",
  digitalEquipment: "digital-equipments",
  equipmentAndSupplies: "equipments",
  labTests: "lab-tests",
  expenses: "expenses",
  subContractors: "subcontractors",
};

export const costingAPIResourcePropMap = {
  labour: "labor",
  digitalEquipment: "digitalEquipment",
  equipmentAndSupplies: "equipment",
  labTests: "labTests",
  expenses: "expenses",
  subContractors: "subcontractors",
};

export const ActionTypes = {
  updateLabours: "labours",
  updateEquipments: "equipments",
  updateDigitalEquipments: "digitalEquipments",
  updateLabTests: "labTests",
  updateExpenses: "expenses",
  updateSubContractors: "subContractors",
};

export function AddGridColumn({ costingType, handleClick, message }) {
  return (
    <td className="border-0 px-0 align-middle">
      <OverlayTrigger
        overlay={
          <Tooltip>
            Add {costingType}{" "}
            {message !== undefined ? (
              <>
                <br /> {message}
              </>
            ) : (
              ""
            )}
          </Tooltip>
        }
      >
        <span className="py-0 cursor-pointer">
          <span className="material-icons-outlined" onClick={handleClick}>
            add
          </span>
        </span>
      </OverlayTrigger>
    </td>
  );
}

AddGridColumn.propTypes = {
  costingTypesMap: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export function handleOnInput(event) {
  const value = event.target.value;
  if (value === "" || isNumeric(value)) {
    event.target.oldValue = value;
    event.target.oldSelectionStart = event.target.selectionStart;
    event.target.oldSelectionEnd = event.target.selectionEnd;
  } else if (event.target.hasOwnProperty("oldValue")) {
    event.target.value = event.target.oldValue;
    event.target.setSelectionRange(
      event.target.oldSelectionStart,
      event.target.oldSelectionEnd,
    );
  } else {
    event.target.value = "";
  }
}

export const getUnSavedRowStyles = (row, column) => {
  let styles = "";
  if (row.original._id?.includes("new") && column.id === "totalHours") {
    styles += " border-top-warning border-left-warning border-right-warning";
  } else if (row.original._id?.includes("new") && column.id !== "totalHours") {
    styles += " border-left-warning border-right-warning";
  }

  return styles;
};

export const getReadOnlyInputStyles = (isReadOnly) =>
  isReadOnly ? " bg-gray bg-opacity-10" : "";

export const invalidInputStyles = (validationError) =>
  validationError ? " border-danger" : "";

export const getWarningInputStyles = (isWarning) =>
  isWarning ? " border-tertiary bg-warning bg-opacity-75" : "";

export const formatNumberValue = (value, precision = 2) => {
  if (value === "" || value === null || value === undefined) return value;
  return Number.isNaN(value)
    ? value
    : Intl.NumberFormat("en-us", {
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
      }).format(
        Number(
          typeof value === "string" ? value.replace(/[^0-9.-]+/g, "") : value,
        ),
      );
};

export function hanldeCellToCellNavigation(e, row, col, table) {
  // find index of current row and column
  const rows = table.getRowModel().rows;
  const columns = table.getAllFlatColumns();
  const rowIndex = rows.findIndex((r) => r.id === row.id);
  let colIndex = columns.findIndex((c) => c.id === col.id);

  let nextRowIndex = rowIndex;
  let nextColIndex = colIndex;

  const isCursorAtStart = e.target.selectionStart === 0;
  const isCursorAtEnd = e.target.selectionStart === e.target.value.length;

  switch (e.key) {
    // left and right arrows - navigates to next row
    case "ArrowLeft":
      if (isCursorAtStart) {
        nextRowIndex = Math.max(0, rowIndex - 1);
        e.preventDefault();
      }
      break;
    case "ArrowRight":
      if (isCursorAtEnd) {
        nextRowIndex = Math.min(rows.length - 1, rowIndex + 1);
        e.preventDefault();
      }
      break;
    // up and down arrows - navigates to next column
    case "ArrowUp":
      nextColIndex = Math.max(0, colIndex - 1);
      e.preventDefault();
      break;
    case "ArrowDown":
      nextColIndex = Math.min(columns.length - 1, colIndex + 1);
      e.preventDefault();
      break;
    default:
      return;
  }

  const nextCell = rows[nextRowIndex]
    .getVisibleCells()
    .find((c) => c.column.id === columns[nextColIndex].id);
  const nextCellRef = table.options.meta.cellRefs.get(nextCell?.id);

  nextCellRef?.focus();
}

export function isValidUnitNumber(value) {
  return yup.number().integer().isValidSync(value)
    ? ""
    : "Value needs to be a unit number";
}

export function updatePhasesData({
  oldPhasesData,
  newPhasesData,
  costingType,
  phasesDispatch,
}) {
  // Create a map for quick lookup of new phases by id
  const newPhasesMap = new Map(newPhasesData.map((phase) => [phase.id, phase]));

  // Map through oldPhasesData to create a new array
  const updatedPhasesData = oldPhasesData.map((phase) => {
    // If the phase exists in newPhasesData, update activities
    if (newPhasesMap.has(phase.id)) {
      const newPhase = newPhasesMap.get(phase.id);

      // Create a map for quick lookup of new activities by id
      const newActivitiesMap = new Map(
        newPhase.activities.map((activity) => [activity.id, activity]),
      );

      // Map through phase.activities to create a new array
      const updatedActivities = phase.activities.map((activity) => {
        if (newActivitiesMap.has(activity.id)) {
          // Found a matching activity, create a new updated activity object
          const newActivity = newActivitiesMap.get(activity.id);
          return {
            ...activity,
            [costingAPIResourcePropMap[costingType]]:
              newActivity[costingAPIResourcePropMap[costingType]],
            summary: newActivity.summary,
          };
        }
        return activity; // No matching activity, return the original
      });

      // Return a new phase object with updated activities
      return { ...phase, activities: updatedActivities };
    }
    return phase; // No matching phase, return the original
  });

  phasesDispatch(updatedPhasesData);
}
