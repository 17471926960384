import { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import ActionItems from "components/Tracking/shared/components/ActionItems/ActionItems";
import Loader from "components/Loader/Loader";
import TaskAccrualDetail from "./TaskAccrualDetail/TaskAccrualDetail";
import TaskList from "./TaskList/TaskList";
import { Link, useParams } from "react-router-dom";
import { useCallGetDeApi } from "hooks/useCallApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

import Button from "react-bootstrap/Button";

import { ACTIONABLE_TYPES } from "../shared/types/types";

import "./PhasesTaskAccrual.scss";

const PhaseTaskAccrual = () => {
  const [currentTaskName, setCurrentTaskName] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const { projectId, phaseId, taskId } = useParams();

  const url = `project-intel/projects/${projectId}/phases/${phaseId}/activities`;
  const { data, isLoading, error } = useCallGetDeApi(url);

  const toggleModal = () => setModalShow((prevState) => !prevState);

  const {
    projectName,
    phaseName,
    projectUpdatedAt,
    projectCode,
    projectStatus,
    activities: tasks,
  } = data || {};

  useEffect(() => {
    const currentTask = tasks?.find((task) => task.id === taskId);
    setCurrentTaskName(currentTask?.name);
  }, [taskId, data, tasks]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h3 className="d-flex align-items-center">
            <span>Project - </span>
            <small>
              <Link
                to={`/tracking/projects/${projectId}`}
                title="Visit Project Details Page"
                className={`${
                  projectStatus === "critical"
                    ? "bg-danger text-danger"
                    : "bg-success text-success"
                } bg-opacity-10 px-1 ms-2 text-decoration-none`}
              >
                {projectName}
              </Link>
            </small>
            <Badge className="bg-tertiary text-dark project-code ms-2">
              {projectCode}
            </Badge>
          </h3>
          <h4>
            Phase - <small className="text-gray">{phaseName}</small>
          </h4>
          <i className="me-3">
            <small>
              Last Updated At{" "}
              {new Date(projectUpdatedAt).toLocaleString([], {
                dateStyle: "short",
                timeStyle: "short",
              })}
            </small>
          </i>
        </div>
        <div className="d-flex justify-content-end mb-1">
          <Button size="sm" onClick={toggleModal}>
            Action Items
          </Button>
        </div>
      </div>
      <Row className="my-4">
        <Col md={3}>
          <Card className="tasks-list">
            <Card.Body>
              <h5 className="text-gray fw-bold">Tasks</h5>
              <hr />
              <TaskList data={data} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={9}>
          <Card className="accruals-detail">
            <Card.Body>
              <h5 className="text-gray">
                <span className="fw-bold">Task -</span> {currentTaskName}
              </h5>
              <hr />
              <TaskAccrualDetail />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {modalShow && (
        <ActionItems
          title={data?.phaseName}
          actionableType={ACTIONABLE_TYPES.piTask}
          actionableId={taskId}
          toggleModal={toggleModal}
          modalShow={modalShow}
        />
      )}
    </>
  );
};

export default PhaseTaskAccrual;
