import React, { createContext, useState } from "react";

const OpportunityContext = createContext(undefined);
const OpportunityDispatchContext = createContext(undefined);
const BreadcrumbContext = createContext(undefined);
const BreadcrumbDispatchContext = createContext(undefined);
const ActiveTabContext = createContext(undefined);
const ActiveTabDispatchContext = createContext(undefined);

const GovernanceExceptionsContext = createContext(undefined);
const GovernanceExceptionsDispatchContext = createContext(undefined);

function OpportunityProvider({ children }) {
  const [opportunity, setOpportunity] = useState();
  const [breadcrumb, setBreadcrumb] = useState();
  const [activeTab, setActiveTab] = useState();

  const [governanceExceptions, setGovernanceExceptions] = useState();

  return (
    <OpportunityContext.Provider value={opportunity}>
      <OpportunityDispatchContext.Provider value={setOpportunity}>
        <BreadcrumbContext.Provider value={breadcrumb}>
          <BreadcrumbDispatchContext.Provider value={setBreadcrumb}>
            <ActiveTabContext.Provider value={activeTab}>
              <ActiveTabDispatchContext.Provider value={setActiveTab}>
                <GovernanceExceptionsContext.Provider
                  value={governanceExceptions}
                >
                  <GovernanceExceptionsDispatchContext.Provider
                    value={setGovernanceExceptions}
                  >
                    {children}
                  </GovernanceExceptionsDispatchContext.Provider>
                </GovernanceExceptionsContext.Provider>
              </ActiveTabDispatchContext.Provider>
            </ActiveTabContext.Provider>
          </BreadcrumbDispatchContext.Provider>
        </BreadcrumbContext.Provider>
      </OpportunityDispatchContext.Provider>
    </OpportunityContext.Provider>
  );
}

export {
  OpportunityProvider,
  OpportunityContext,
  OpportunityDispatchContext,
  BreadcrumbContext,
  BreadcrumbDispatchContext,
  ActiveTabContext,
  ActiveTabDispatchContext,
  GovernanceExceptionsContext,
  GovernanceExceptionsDispatchContext,
};
