import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import "./ErrorHandler.scss";

const ErrorHandler = ({ error, message, className = "" }) => {
  const [status, setStatus] = useState("");
  const [statusText, setStatusText] = useState("Error:");
  const [errorMsg, setErrorMsg] = useState(
    "Something unexpected happened. Please try again.",
  );
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      if (error.status) {
        setStatus(error.status);
      }
      if (error.statusText) {
        setStatusText(error.statusText);
      }

      let allowedPaths = ["/login", "/two-factor-code"];

      if (
        error.status === 401 &&
        allowedPaths.indexOf(location.pathname) === -1
      ) {
        navigate("/logout");
      }
      if (error.data && error.data.error) {
        if ("message" in error.data.error) {
          setErrorMsg(error.data.error.message);
        } else {
          setErrorMsg(error.data.error[Object.keys(error.data.error)[0]]);
        }
      }
    }
    if (error.data.message) {
      setErrorMsg(error.data.message);
    }
  }, [error]);
  if (status === 403)
    return (
      <Alert variant="success" className={`bg-white mt-5 mb-3 ${className}`}>
        <h4>Unauthorized</h4>
        <p>Sorry, you do not have access rights!</p>
        <small>{status}</small>
      </Alert>
    );
  else if (status === 404) {
    return (
      <Alert variant="warning" className={`mt-5 mb-3 ${className}`}>
        <p>
          <strong>Oh Snap! You got an error!</strong>
        </p>
        <p>Something unexpected happened. Please try again.</p>
        <small>{status}</small>
      </Alert>
    );
  } else
    return (
      <Alert
        variant="danger"
        className={`mt-1 bg-white border-0 p-0 text-danger mb-3 ${className}`}
      >
        {/* <h4>{statusText}</h4> */}
        <p className="m-0 font-bold">
          <b>{message || errorMsg}</b>
        </p>
        {/* <small>{status}</small> */}
      </Alert>
    );
};

ErrorHandler.propTypes = {
  error: PropTypes.object.isRequired,
  message: PropTypes.string,
  className: PropTypes.string,
};

export default ErrorHandler;
