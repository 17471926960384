import { useState, useEffect } from "react";

export default function useLabourData(phases) {
  const [labours, setLabours] = useState([]);

  useEffect(
    function extractGridLabourDataFromPhases() {
      let initialLabourArray = [];
      phases.forEach((phase) => {
        phase.activities.forEach((activity) => {
          activity.labor.forEach((singleLabour, labourIndex) => {
            const isLabourPresentIndex = initialLabourArray?.findIndex(
              (element) => {
                return element.id === singleLabour.id;
                // eslint-disable-next-line prettier/prettier
            },
            );

            if (isLabourPresentIndex === -1) {
              initialLabourArray.push({
                ...singleLabour,
                total: singleLabour.total + singleLabour.totalOvertime,
                totalRegular: singleLabour.total,
                totalRegularHours: singleLabour.regularHours,
                totalOvertimeHours: singleLabour.overtimeHours,
                workingOrg: singleLabour.country?.id,
                employeeRoleId: singleLabour.employeeRole?.id || "",
                localCurrency: "USD",
                atCurrencyExchangeRate: singleLabour.localCost * 1,
                discountType: singleLabour.discountAmount ? 1 : 2, // 1 is amount, 2 is percentage
                contingencyType: singleLabour.contingencyAmount ? 1 : 2,
                appliedRateSchedule: 1,
                labourAdjustmentPercentage: Number.parseFloat(
                  singleLabour.labourAdjustmentPercentage || 0,
                ),
              });
            } else {
              initialLabourArray[isLabourPresentIndex].total +=
                singleLabour.total + singleLabour.totalOvertime;
              initialLabourArray[isLabourPresentIndex].totalRegular +=
                singleLabour.total;
              initialLabourArray[isLabourPresentIndex].totalOvertime +=
                singleLabour.totalOvertime;

              initialLabourArray[isLabourPresentIndex].totalHours +=
                singleLabour.totalHours;
              initialLabourArray[isLabourPresentIndex].totalRegularHours +=
                singleLabour.regularHours;
              initialLabourArray[isLabourPresentIndex].totalOvertimeHours +=
                singleLabour.overtimeHours;
            }
          });
        });
      });

      let finalLabourArray = [];
      initialLabourArray.forEach((element) => {
        let singleItem = {
          ...element,
          phases: Array(phases.length).fill({}),
        };

        singleItem.total = Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(singleItem.total);

        singleItem.totalOvertime = Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(singleItem.totalOvertime);

        singleItem.totalRegular = Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(singleItem.totalRegular);

        phases.forEach((phase, phaseIndex) => {
          let sumOfTotalHours = 0;
          let sumOfTotalRegularHours = 0;
          let sumOfTotalOvertimeHours = 0;
          let sumOfTotalActivityContingency = 0;

          singleItem.phases[phaseIndex] = {
            id: phase.id,
            title: phase.title,
            totalHours: 0,
            totalRegularHours: 0,
            totalOvertimeHours: 0,
            activities: Array(phase.activities.length).fill({}),
          };
          phase.activities.forEach((activity, activityIndex) => {
            const labourFound = activity.labor.find((labour) => {
              return element.id === labour.id;
            });
            if (labourFound) {
              sumOfTotalHours += labourFound.totalHours;
              sumOfTotalRegularHours += labourFound.totalRegularHours;
              sumOfTotalOvertimeHours += labourFound.totalOvertimeHours;
              sumOfTotalActivityContingency += labourFound.activityContingency;

              singleItem.phases[phaseIndex].activities[activityIndex] = {
                id: activity.id,
                title: activity.title,
                total: labourFound.total,
                totalOvertime: labourFound.totalOvertime,
                totalHours: labourFound.totalHours,
                regularHours: labourFound.regularHours,
                overtimeHours: labourFound.overtimeHours,
                activityContingency: Number.parseFloat(
                  labourFound.activityContingency,
                ),
              };
            } else {
              singleItem.phases[phaseIndex].activities[activityIndex] = {
                id: activity.id,
                title: activity.title,
                total: 0,
                totalOvertime: 0,
                totalHours: 0,
                regularHours: 0,
                overtimeHours: 0,
                activityContingency: 0,
              };
            }
          });
          singleItem.phases[phaseIndex].totalRegularHours =
            sumOfTotalRegularHours;
          singleItem.phases[phaseIndex].totalOvertimeHours =
            sumOfTotalOvertimeHours;
          singleItem.phases[phaseIndex].totalHours = sumOfTotalHours;
          singleItem.phases[phaseIndex].activityContingency =
            sumOfTotalActivityContingency;
        });
        finalLabourArray.push(singleItem);
      });

      setLabours(finalLabourArray);
    },
    [phases],
  );

  return [labours, setLabours];
}
