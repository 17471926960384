import { useEffect, useRef, useState } from "react";
import { Alert, Card, Col, Container, Table, Dropdown } from "react-bootstrap";
import { get } from "utils/DeApi";
import AddPhase from "./AddPhase";
import Loader from "components/Loader/Loader";
import { usePhasesDispatch, usePhases } from "contexts/PhasesProvider";
import SinglePhasesAndActivitiesCostingChart from "components/OpportunitySummary/PhasesAndActivitiesCostingChart/SinglePhasesAndActivitiesCostingChart";
import SummaryBarChart from "./SummaryBarChart";
import SummaryPieChart from "./SummaryPieChart";
import { useRefreshPhasesData } from "helpers/PhasesHelpers";
const Phases = ({ opportunityId }) => {
  const subscribedPromises = useRef([]);
  const [selectedPhase, setSelectedPhase] = useState(null);

  const [toggleConfig, setToggleConfig] = useState();
  const [configError, setConfigError] = useState("");

  const titleHeaderRef = useRef(null);
  const labourHeaderRef = useRef(null);
  const expensesHeaderRef = useRef(null);
  const equipmentHeaderRef = useRef(null);
  const digitalProductsHeaderRef = useRef(null);
  const labsHeaderRef = useRef(null);
  const subcontractorsHeaderRef = useRef(null);
  const activitiesHeaderRef = useRef(null);
  const actionHeaderRef = useRef(null);

  const handlePhaseSelect = (phase) => {
    setSelectedPhase(phase);
  };

  const phasesData = usePhases();
  const setPhasesData = usePhasesDispatch();
  const { callGetPhasesAPI, isLoading } = useRefreshPhasesData();

  useEffect(() => {
    callGetPhasesAPI();
  }, []);

  const updatePhasesData = (newData) => {
    setPhasesData([...phasesData, newData]);
  };

  const refreshPhasesData = () => {
    callGetPhasesAPI();
  };

  useEffect(() => {
    if (phasesData?.length) {
      if (phasesData[0].title !== selectedPhase?.title) {
        setSelectedPhase(phasesData[0]);
      }
    }
  }, [phasesData]);

  useEffect(() => {
    const getConfig = () => {
      const getOpportunityConfigPromise = get(
        `/opportunities/${opportunityId}/config`,
      );
      getOpportunityConfigPromise.promise
        .then((response) => {
          const toggleObj = response?.data?.filter((config) => {
            return config?.key === "laborOverTime";
          });
          setToggleConfig(toggleObj?.length ? toggleObj[0] : {});
        })
        .catch((error) => {
          !error.isCanceled && setConfigError(error);
        });
      subscribedPromises.current.push(getOpportunityConfigPromise);
    };
    getConfig();

    const promises = subscribedPromises.current;
    return () => promises.forEach((promise) => promise.cancel());
  }, [opportunityId]);

  let pieChartValues = [];
  let phaseTitles = [];
  if (phasesData.length > 0) {
    pieChartValues = phasesData.map((phase) => phase.total.toFixed(2) || 0);
    phaseTitles = phasesData.map((phase) =>
      phase.title.replace(/ +/g, " ").trim(),
    );
  }

  return (
    <>
      <Card>
        <Card.Body>
          <div className="d-flex float-end">
            <AddPhase
              opportunityId={opportunityId}
              updatePhasesData={updatePhasesData}
            />
          </div>
          <h4>Phases and Activities</h4>
          <hr />

          {isLoading ? (
            <Loader />
          ) : (
            <>
              {!phasesData ||
              (Array.isArray(phasesData) && phasesData.length === 0) ? (
                <Alert variant="info" className={`my-3 d-flex flex-row`}>
                  <div className="me-3">
                    <span className="material-icons md-18 text-primary text-opacity-75">
                      info
                    </span>
                  </div>
                  <div>
                    <h5 className="mb-1">
                      <small>No Phases</small>
                    </h5>
                    <p className="mb-1">
                      <small>
                        There are currently no phases or activities to show.
                        Please add a new phase using the Add Phase button
                      </small>
                    </p>
                  </div>
                </Alert>
              ) : (
                <Table responsive size="sm">
                  <thead>
                    <tr>
                      <th ref={titleHeaderRef}>Phase</th>
                      <th ref={labourHeaderRef}>Labour</th>
                      <th ref={expensesHeaderRef}>Expenses</th>
                      <th ref={equipmentHeaderRef}>Equipment & Supplies</th>
                      <th ref={digitalProductsHeaderRef}>Digital Products</th>
                      <th ref={labsHeaderRef}>Labs</th>
                      <th ref={subcontractorsHeaderRef}>Subcontractors</th>
                      <th ref={activitiesHeaderRef}>Activities</th>
                      <th ref={actionHeaderRef}>Action</th>
                    </tr>
                  </thead>
                  {phasesData?.map((phase, index) => {
                    const { id } = phase;

                    return (
                      <SinglePhasesAndActivitiesCostingChart
                        key={id}
                        phase={phase}
                        index={index}
                        refreshList={refreshPhasesData}
                        showOvertime={Number(toggleConfig?.value || "0")}
                        titleHeaderRef={titleHeaderRef}
                        labourHeaderRef={labourHeaderRef}
                        expensesHeaderRef={expensesHeaderRef}
                        equipmentHeaderRef={equipmentHeaderRef}
                        digitalProductsHeaderRef={digitalProductsHeaderRef}
                        labsHeaderRef={labsHeaderRef}
                        subcontractorsHeaderRef={subcontractorsHeaderRef}
                        activitiesHeaderRef={activitiesHeaderRef}
                        actionHeaderRef={actionHeaderRef}
                      />
                    );
                  })}
                </Table>
              )}
            </>
          )}
        </Card.Body>
      </Card>
      <Container className="d-flex border-bottom-0  px-0 justify-content-center gap-3 my-3">
        <Card className="w-50">
          <Card.Body className="pt-0">
            <Col>
              <div className="d-flex justify-content-between">
                <h6 className="mt-3">Cost breakdown in a phase</h6>
                <Dropdown className="mt-2">
                  <Dropdown.Toggle
                    variant="outline-tertiary"
                    className="text-dark"
                    id="dropdown-phases"
                    size="sm"
                  >
                    {selectedPhase ? selectedPhase.title : "Select Phase"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {phasesData?.map((phase) => (
                      <Dropdown.Item
                        key={phase.id}
                        onClick={() => handlePhaseSelect(phase)}
                      >
                        {phase.title}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <hr className="my-2" />
              {!isLoading && (
                <>
                  {selectedPhase && (
                    <SummaryBarChart
                      values={[
                        (
                          selectedPhase.summary?.labor?.totalCost +
                          (Number(toggleConfig?.value || "0")
                            ? selectedPhase.summary?.labor?.totalOvertimeCost
                            : 0)
                        ).toFixed(2) || 0,
                        selectedPhase.summary?.expenses?.totalCost?.toFixed(
                          2,
                        ) || 0,
                        selectedPhase.summary?.equipment?.totalCost?.toFixed(
                          2,
                        ) || 0,
                        selectedPhase.summary?.digitalEquipment?.totalCost?.toFixed(
                          2,
                        ) || 0,
                        selectedPhase.summary?.labTests?.totalCost?.toFixed(
                          2,
                        ) || 0,
                        selectedPhase.summary?.subcontractors?.totalCost?.toFixed(
                          2,
                        ) || 0,
                      ]}
                      phase={selectedPhase.name}
                    />
                  )}
                </>
              )}
            </Col>
          </Card.Body>
        </Card>
        <Card className="w-50">
          <Card.Body>
            <Col>
              <h6>Overall cost of phases</h6>
              <hr />
              <div>
                {!isLoading && (
                  <>
                    <SummaryPieChart
                      values={pieChartValues}
                      phaseTitles={phaseTitles}
                    />
                  </>
                )}
              </div>
            </Col>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default Phases;
