import React from "react";
import { Row, Col } from "react-bootstrap";

const ReimbursableCellComponent = (props) => {
  const { value, handleOnBlur, setValue } = props;

  const inputValue = React.useRef(value);

  const yes = value === 1;
  const no = value === 0;

  React.useEffect(() => {
    if (inputValue.current === undefined && value !== undefined) {
      inputValue.current = value;
    } else if (
      inputValue.current !== value &&
      inputValue.current !== undefined &&
      value !== undefined
    ) {
      inputValue.current = value;
      handleOnBlur();
    }
  }, [value]);

  return (
    <Row>
      <Col>
        <input
          type="radio"
          id="yes"
          checked={yes}
          onChange={(e) => setValue(1)}
        />
        <label for="yes" className="ms-2">
          Yes
        </label>
      </Col>
      <Col>
        <input
          type="radio"
          id="no"
          checked={no}
          onChange={(e) => setValue(0)}
        />
        <label for="no" className="ms-2">
          No
        </label>
      </Col>
    </Row>
  );
};

export default ReimbursableCellComponent;
