import React, { useEffect, useState, useContext, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { Alert, Card, Col, Row, Tab, Tabs, Badge, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { usePhases } from "contexts/PhasesProvider";
import { useRefreshPhasesData } from "helpers/PhasesHelpers";
import {
  useLabourData,
  useExpenseData,
  useEquipmentData,
  useDigitalEquipmentData,
  useLabTestData,
  useSubContractorData,
} from "components/CostingGrid/EditableGrid/hooks";

import OpportunityCostingOverview from "../Overview/OpportunityCostingOverview";

import LabourGrid from "components/CostingGrid/EditableGrid/LabourGrid";
import ExpenseGrid from "components/CostingGrid/EditableGrid/ExpenseGrid";
import EquipmentGrid from "components/CostingGrid/EditableGrid/EquipmentGrid";
import DigitalEquipmentGrid from "components/CostingGrid/EditableGrid/DigitalEquipmentGrid";
import LabTestGrid from "components/CostingGrid/EditableGrid/LabTestsGrid";
import SubContractorGrid from "components/CostingGrid/EditableGrid/SubContractorsGrid";

import useOpportunitySummary from "hooks/useOpportunitySummary";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

import {
  OpportunityContext,
  OpportunityDispatchContext,
  GovernanceExceptionsDispatchContext,
} from "contexts/OpportunityProvider";
import { get } from "utils/DeApi";
import { post } from "utils/DeApi";
import { put } from "utils/DeApi";

const OpportunityCostingLayout = () => {
  const subscribedPromises = useRef([]);

  const { opportunityId } = useParams();

  const [toggleConfig, setToggleConfig] = useState();
  const [configError, setConfigError] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const phases = usePhases();
  const [labourData] = useLabourData(phases);
  const [expenseData] = useExpenseData(phases);
  const [equipmentData] = useEquipmentData(phases);
  const [digitalEquipmentData] = useDigitalEquipmentData(phases);
  const [labTestsData] = useLabTestData(phases);
  const [subContractorsData] = useSubContractorData(phases);

  const opportunity = useContext(OpportunityContext);
  const opportunityDispatch = useContext(OpportunityDispatchContext);

  const setGovernanceExceptions = useContext(
    GovernanceExceptionsDispatchContext,
  );

  const { callGetPhasesAPI } = useRefreshPhasesData();

  const getConfig = () => {
    const getOpportunityConfigPromise = get(
      `/opportunities/${opportunity?.id}/config`,
    );
    getOpportunityConfigPromise.promise
      .then((response) => {
        const toggleObj = response?.data?.filter((config) => {
          return config?.key === "laborOverTime";
        });
        setToggleConfig(toggleObj?.length ? toggleObj[0] : {});
      })
      .catch((error) => {
        !error.isCanceled && setConfigError(error);
      });
    subscribedPromises.current.push(getOpportunityConfigPromise);
  };

  useEffect(() => {
    callGetPhasesAPI();
    getConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: opportunitySummary,
    isLoading,
    isError,
    error,
    refetch: refreshOpportunityCostingSummary,
  } = useOpportunitySummary(opportunityId);

  const exceptionsResults = useQuery({
    queryKey: [`governanceRules`, opportunityId],
    queryFn: () =>
      get(`/opportunities/${opportunityId}/governance-exceptions`).promise.then(
        (res) => {
          setGovernanceExceptions(res.data);
          return res.data;
        },
      ),
  });

  const refetch = () => {
    refreshOpportunityCostingSummary();
    exceptionsResults.refetch();
  };

  // type can be "equipmentAndSupplies", "labour",'digitalEquipment','labTests', 'expenses', 'subContractors'
  const [type, setType] = useState(searchParams.get("type") || "labour");

  useEffect(() => {
    setType(searchParams.get("type") || "labour");
  }, [searchParams]);

  useEffect(
    function updateOpportunitySummaryContext() {
      opportunityDispatch((oldOpp) => ({
        ...oldOpp, // copy old context
        summary: opportunitySummary?.summary, // update opportunitySummary
      }));
    },
    [opportunitySummary, opportunityDispatch],
  );

  const handleSetType = (type) => {
    setType(type);
    setSearchParams({ type });
  };

  if (isLoading) return <Loader />;
  if (isError && error) return <ErrorHandler error={error} />;

  const {
    summary: {
      careerLevelSummary,
      totalCost: totalOpportunitCost,
      equipment,
      digitalEquipment,
      labor,
      labTests,
      expenses,
      subcontractors,
    },
  } = opportunitySummary;

  // temp set labor,expenses,equipment,digitalEquipment,labs,subcons totalCount to length of costingData
  labor.totalCount = labourData.length;
  expenses.totalCount = expenseData.length;
  equipment.totalCount = equipmentData.length;
  digitalEquipment.totalCount = digitalEquipmentData.length;
  labTests.totalCount = labTestsData.length;
  subcontractors.totalCount = subContractorsData.length;

  const opportunityContractCurrency = opportunity?.currency?.code || "USD";

  const setOvertimeToggle = (event) => {
    const updateOpportunityConfigPromise = toggleConfig?.id
      ? put(`/opportunities/${opportunity?.id}/config/${toggleConfig?.id}`, {
          key: "laborOverTime",
          value: event.target.checked,
        })
      : post(`/opportunities/${opportunity?.id}/config`, {
          key: "laborOverTime",
          value: event.target.checked,
        });
    updateOpportunityConfigPromise.promise
      .then((response) => {
        if (toggleConfig?.id) {
          setToggleConfig(response?.data);
        } else {
          const toggleObj = response?.data?.filter((config) => {
            return config?.key === "laborOverTime";
          });
          setToggleConfig(toggleObj?.length ? toggleObj[0] : {});
        }
        refetch();
        callGetPhasesAPI();
      })
      .catch((error) => {
        !error.isCanceled && setConfigError(error);
      });
    subscribedPromises.current.push(updateOpportunityConfigPromise);
  };

  return (
    <Card>
      <Card.Body>
        <h4>Project Costing</h4>
        <hr />
        <Row className="border-0">
          <Card className="border-0">
            <Card.Body className="d-flex py-2 px-3">
              <Col>
                <h5>
                  {labor.totalCount}
                  <span className="fs-6 text-gray">
                    {" "}
                    Labour{labor.totalCount > 1 ? "s" : ""}
                  </span>
                </h5>
                <span className="fs-5">
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: opportunityContractCurrency,
                  }).format(labor.totalCost)}{" "}
                </span>
              </Col>
              <Col>
                <h5>
                  {expenses.totalCount}
                  <span className="fs-6 text-gray">
                    {" "}
                    Expense{expenses.totalCount > 1 ? "s" : ""}
                  </span>
                </h5>
                <span className="fs-5">
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: opportunityContractCurrency,
                  }).format(expenses.expense)}{" "}
                </span>
              </Col>
              <Col>
                <h5>
                  {equipment.totalCount}
                  <span className="fs-6 text-gray">
                    {" "}
                    Equipment{equipment.totalCount > 1 ? "s" : ""}
                  </span>
                </h5>
                <span className="fs-5">
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: opportunityContractCurrency,
                  }).format(equipment.totalCost)}{" "}
                </span>
              </Col>
              <Col>
                <h5>
                  {digitalEquipment.totalCount}
                  <span className="fs-6 text-gray">
                    {" "}
                    Digital Product
                    {digitalEquipment.totalCount > 1 ? "s" : ""}
                  </span>
                </h5>
                <span className="fs-5">
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: opportunityContractCurrency,
                  }).format(digitalEquipment.totalCost)}{" "}
                </span>
              </Col>
              <Col className="ms-2">
                <h5>
                  {labTests.totalCount}
                  <span className="fs-6 text-gray">
                    {" "}
                    Lab
                    {labTests.totalCount > 1 ? "s" : ""}
                  </span>
                </h5>
                <span className="fs-5">
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: opportunityContractCurrency,
                  }).format(labTests.laboratory)}{" "}
                </span>
              </Col>
              <Col>
                <h5>
                  {subcontractors.totalCount}
                  <span className="fs-6 text-gray">
                    {" "}
                    Subcontractor
                    {subcontractors.totalCount > 1 ? "s" : ""}
                  </span>
                </h5>
                <span className="fs-5">
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: opportunityContractCurrency,
                  }).format(subcontractors.subcontractors)}{" "}
                </span>
              </Col>
              <Col className="bg-success bg-opacity-25 text-center">
                <h6 className="text-success pt-1">Total cost</h6>
                <span className="fs-5 text-success">
                  {Intl.NumberFormat("en-us", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: opportunityContractCurrency,
                  }).format(totalOpportunitCost)}{" "}
                </span>
              </Col>
              {opportunity?.pricingType?.title === "Lump Sum/Unit Price" ? (
                <Col className="bg-info bg-opacity-25 text-center">
                  <h6 className="text-success pt-1">Lump Sum Price</h6>
                  <span className="fs-5 text-success">
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(opportunity?.lumpSum)}{" "}
                  </span>
                </Col>
              ) : null}
            </Card.Body>
          </Card>
        </Row>
        <hr />
        <p>A breakdown of opportunity costs by costing types</p>
        <hr />

        <div className="d-flex flex-row items-center justify-content-between my-2">
          <div>
            <Badge className="p-2 bg-tertiary text-dark">
              Currency: {opportunityContractCurrency}
            </Badge>
            {type === "equipmentAndSupplies" && (
              <span className="h5 p-2">
                {equipment.totalCount}
                <span className="fs-5 text-gray ms-1">
                  Equipment
                  {equipment.totalCount > 1 ? "s" : ""}
                </span>
              </span>
            )}
            {type === "labour" && (
              <span className="h5 p-2">
                {labor.totalCount}
                <span className="fs-5 text-gray ms-1">
                  Labour{labor.totalCount > 1 ? "s" : ""}
                </span>
              </span>
            )}
            {type === "digitalEquipment" && (
              <span className="h5 p-2">
                {digitalEquipment.totalCount}
                <span className="fs-5 text-gray ms-1">
                  Digital Product
                  {digitalEquipment.totalCount > 1 ? "s" : ""}
                </span>
              </span>
            )}
            {type === "labTests" && (
              <span className="h5 p-2">
                {labTests.totalCount}{" "}
                <span className="fs-5 text-gray ms-1">
                  Lab
                  {labTests.totalCount > 1 ? "s" : ""}
                </span>
              </span>
            )}
            {type === "expenses" && (
              <span className="h5 p-2">
                {expenses.totalCount}{" "}
                <span className="fs-5 text-gray ms-1">
                  Expense
                  {expenses.totalCount > 1 ? "s" : ""}
                </span>
              </span>
            )}
            {type === "subContractors" && (
              <span className="h5 p-2">
                {subcontractors.totalCount}{" "}
                <span className="fs-5 text-gray ms-1">
                  Subcontractor
                  {subcontractors.totalCount > 1 ? "s" : ""}
                </span>
              </span>
            )}
            {Array.isArray(phases) && phases.length && type === "labour" ? (
              <>
                <div className="d-flex justify-content-start mt-3">
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Overtime Hours"
                    onChange={setOvertimeToggle}
                    checked={Number(toggleConfig?.value || "0")}
                  />
                </div>
                {configError && <ErrorHandler error={configError} />}
              </>
            ) : null}
          </div>
          <div className="text-end">
            {type === "equipmentAndSupplies" && (
              <>
                <span className="border ms-3 d-inline-block">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Contingency
                  </span>
                  <span
                    className={
                      [
                        equipment.totalContingency > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(equipment.totalContingency)}
                  </span>
                </span>
                <span className="border ms-3 d-inline-block">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Equipments
                  </span>
                  <span
                    className={
                      [
                        equipment.totalCost > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(equipment.totalCost)}
                  </span>
                </span>
              </>
            )}
            {type === "expenses" && (
              <>
                <span className="border ms-3 d-inline-block mt-2">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Contingency
                  </span>
                  <span
                    className={
                      [
                        expenses.totalContingency > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(expenses.totalContingency)}
                  </span>
                </span>
                <span className="border ms-3 d-inline-block mt-2">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Mark Up Original
                  </span>
                  <span
                    className={
                      [
                        expenses.totalMarkUpOriginal > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(expenses.totalMarkUpOriginal)}
                  </span>
                </span>
                <span className="border ms-3 d-inline-block mt-2">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Mark Up Adjusted
                  </span>
                  <span
                    className={
                      [
                        expenses.totalMarkUpAdjusted > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(expenses.totalMarkUpAdjusted)}
                  </span>
                </span>
                <br />
                <span className="border ms-3 d-inline-block mt-2">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Total
                  </span>
                  <span
                    className={
                      [
                        expenses.total > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(expenses.total)}
                  </span>
                </span>
                <span className="border ms-3 d-inline-block mt-2">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Expense
                  </span>
                  <span
                    className={
                      [
                        expenses.expense > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(expenses.expense)}
                  </span>
                </span>
              </>
            )}
            {type === "labour" && (
              <>
                <span className="border ms-3 d-inline-block">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Contingency
                  </span>
                  <span
                    className={
                      [
                        labor.totalContingency > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(labor.totalContingency)}
                  </span>
                </span>
                <span className="border ms-3 d-inline-block">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Activity hours
                  </span>
                  <span
                    className={
                      [
                        labor.totalCost > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }).format(labor.totalHours)}{" "}
                  </span>
                </span>
                <span className="border ms-3 d-inline-block">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Original
                  </span>
                  <span
                    className={
                      [
                        labor.totalCost > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25  p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(labor.original)}{" "}
                  </span>
                </span>
                <span className="border ms-3 d-inline-block">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Adjusted
                  </span>
                  <span
                    className={
                      [
                        labor.totalCost > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25  p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(labor.totalCost)}{" "}
                  </span>
                </span>
                <br />
                <span className="border ms-3 d-inline-block mt-2">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Overtime Hours
                  </span>
                  <span
                    className={
                      [
                        labor.overtimeHours > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }).format(labor?.overtimeHours)}{" "}
                  </span>
                </span>
                <span className="border ms-3 d-inline-block mt-2">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Total Overtime Pricing
                  </span>
                  <span
                    className={
                      [
                        labor.totalOvertimeCost > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(labor?.totalOvertimeCost)}{" "}
                  </span>
                </span>
                <span className="border ms-3 d-inline-block mt-2">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Net Labour Tax on Adjusted
                  </span>
                  <span
                    className={
                      [
                        labor.totalTaxAdjusted > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(labor.totalTaxAdjusted)}{" "}
                  </span>
                </span>
              </>
            )}
            {type === "digitalEquipment" && (
              <>
                <span className="border ms-3 d-inline-block">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Contingency
                  </span>
                  <span
                    className={
                      [
                        digitalEquipment.totalContingency > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(digitalEquipment.totalContingency)}
                  </span>
                </span>
                <span className="border ms-3 d-inline-block">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Digital Products
                  </span>
                  <span
                    className={
                      [
                        digitalEquipment.totalCost > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(digitalEquipment.totalCost)}
                  </span>
                </span>
              </>
            )}
            {type === "labTests" && (
              <>
                <span className="border ms-3 d-inline-block mt-2">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Contingency
                  </span>
                  <span
                    className={
                      [
                        labTests.totalContingency > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(labTests.totalContingency)}
                  </span>
                </span>
                <span className="border ms-3 d-inline-block mt-2">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Mark Up Original
                  </span>
                  <span
                    className={
                      [
                        labTests.totalMarkUpOriginal > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(labTests.totalMarkUpOriginal)}{" "}
                  </span>
                </span>
                <span className="border ms-3 d-inline-block mt-2">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Mark Up Adjusted
                  </span>
                  <span
                    className={
                      [
                        labTests.totalMarkUpAdjusted > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(labTests.totalMarkUpAdjusted)}{" "}
                  </span>
                </span>
                <br />
                <span className="border ms-3 d-inline-block mt-2">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Total
                  </span>
                  <span
                    className={
                      [
                        labTests.total > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(labTests.total)}
                  </span>
                </span>
                <span className="border ms-3 d-inline-block mt-2">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Labs
                  </span>
                  <span
                    className={
                      [
                        labTests.laboratory > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(labTests.laboratory)}
                  </span>
                </span>
              </>
            )}
            {type === "subContractors" && (
              <>
                <span className="border ms-3 d-inline-block mt-2">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Contingency
                  </span>
                  <span
                    className={
                      [
                        subcontractors.totalContingency > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(subcontractors.totalContingency)}
                  </span>
                </span>
                <span className="border ms-3 d-inline-block mt-2">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Mark Up Original
                  </span>
                  <span
                    className={
                      [
                        subcontractors.totalMarkUpOriginal > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(subcontractors.totalMarkUpOriginal)}{" "}
                  </span>
                </span>
                <span className="border ms-3 d-inline-block mt-2">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Mark Up Adjusted
                  </span>
                  <span
                    className={
                      [
                        subcontractors.totalMarkUpAdjusted > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(subcontractors.totalMarkUpAdjusted)}{" "}
                  </span>
                </span>
                <br />
                <span className="border ms-3 d-inline-block mt-2">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Total
                  </span>
                  <span
                    className={
                      [
                        subcontractors.total > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(subcontractors.total)}
                  </span>
                </span>
                <span className="border ms-3 d-inline-block mt-2">
                  <span className="fs-6 text-gray ms-1 me-2 d-inline-block">
                    Subcontractors
                  </span>
                  <span
                    className={
                      [
                        subcontractors.subcontractors > 0
                          ? "bg-success text-success"
                          : "bg-danger text-danger",
                      ] + " h5 bg-opacity-25 p-1 ms-1 d-inline-block mb-0"
                    }
                  >
                    {Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: opportunityContractCurrency,
                    }).format(subcontractors.subcontractors)}
                  </span>
                </span>
              </>
            )}
          </div>
        </div>
        {Array.isArray(phases) && phases.length === 0 ? (
          <Alert variant="info" className={`my-3 d-flex flex-row`}>
            <div className="me-3">
              <span className="material-icons md-18 text-primary text-opacity-75">
                info
              </span>
            </div>
            <div>
              <h5 className="mb-1">
                <small>No Phases</small>
              </h5>
              <p className="mb-1">
                <small>
                  There are currently no phases or activities to show.
                </small>
              </p>
            </div>
          </Alert>
        ) : (
          <Tabs
            defaultActiveKey={type}
            onSelect={(k) => {
              handleSetType(k);
            }}
            className="mb-3"
            mountOnEnter
            unmountOnExit
          >
            <Tab eventKey="overview" title="Overview">
              <OpportunityCostingOverview
                labourSummary={labor}
                careerLevelSummary={careerLevelSummary}
              />
            </Tab>
            <Tab eventKey="labour" title="Labour">
              <LabourGrid
                refreshOpportunityCostingSummary={() => refetch()}
                showOverTimeHours={Number(toggleConfig?.value || "0")}
              />
            </Tab>
            <Tab eventKey="expenses" title="Expenses">
              <ExpenseGrid refreshOpportunityCostingSummary={() => refetch()} />
            </Tab>
            <Tab eventKey="equipmentAndSupplies" title="Equipment and Supplies">
              <EquipmentGrid
                refreshOpportunityCostingSummary={() => refetch()}
              />
            </Tab>
            <Tab eventKey="digitalEquipment" title="Digital Products">
              <DigitalEquipmentGrid
                refreshOpportunityCostingSummary={() => refetch()}
              />
            </Tab>
            <Tab eventKey="labTests" title="Labs">
              <LabTestGrid refreshOpportunityCostingSummary={() => refetch()} />
            </Tab>
            <Tab eventKey="subContractors" title="Subcontractors">
              <SubContractorGrid
                refreshOpportunityCostingSummary={() => refetch()}
              />
            </Tab>
          </Tabs>
        )}
      </Card.Body>
    </Card>
  );
};

export default OpportunityCostingLayout;
