import { useContext, useEffect } from "react";
import ProjectInformation from "./Information/ProjectInformation";
import {
  OpportunityContext,
  BreadcrumbDispatchContext,
  ActiveTabDispatchContext,
} from "contexts/OpportunityProvider";

import Loader from "components/Loader/Loader";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { get } from "utils/DeApi";

const Opportunity = () => {
  const opportunityId = useParams().opportunityId;

  const setBreadcrumbs = useContext(BreadcrumbDispatchContext);
  const setActiveTab = useContext(ActiveTabDispatchContext);
  const opportunityContext = useContext(OpportunityContext);

  const {
    data: opportunity,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: ["getOpportunity", opportunityId],
    queryFn: () =>
      get(`/opportunities/${opportunityId}`).promise.then((res) => res.data),
  });

  useEffect(() => {
    setBreadcrumbs([
      { name: "Dashboard", link: "/pricing" },
      { name: "Opportunities", active: "false" },
    ]);
    setActiveTab("dashboard");
  }, [setBreadcrumbs, setActiveTab]);

  useEffect(() => {
    refetch();
  }, [opportunityContext, refetch]);

  if (isLoading || isRefetching) return <Loader />;

  return <ProjectInformation opportunity={opportunity} />;
};

export default Opportunity;
