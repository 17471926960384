import React, { useContext, useRef, useState, useEffect } from "react";
import * as yup from "yup";
import {
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
  Form,
  Alert,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { Formik } from "formik";
import PropTypes from "prop-types";

import { OpportunityContext } from "contexts/OpportunityProvider";
import { put, destroy } from "utils/DeApi";
import DropdownFieldFormik from "components/Inputs/DropdownFieldFormik";
import InputFieldFormik from "components/Inputs/InputFieldFormik";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

import { useCallGetDeApi } from "hooks/useCallApi";
import { post } from "utils/DeApi";

export default function EditOpportunityGSLModal({
  opportunityGSLs: initialOpportunityGSLs,
  handleClose,
}) {
  const opportunity = useContext(OpportunityContext);

  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [opportunityGSLs, setOpportunityGSLs] = useState(
    initialOpportunityGSLs,
  );

  useEffect(() => {
    setOpportunityGSLs(initialOpportunityGSLs);
  }, [initialOpportunityGSLs]);

  const { data: gslData, isLoading: gslDataIsLoading } = useCallGetDeApi(
    "/global-service-lines",
  );

  const schema = yup.object().shape({
    globalServiceLines: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        percentage: yup
          .number()
          .min(0, "Percentage allocation must be between 0 and 100")
          .max(100, "Percentage allocation must be between 0 and 100")
          .test("is-percentage-100", (value, context) => {
            const totalPercentage =
              context?.from?.[1]?.value?.globalServiceLines?.reduce(
                (total, gsl) => total + Number.parseFloat(gsl.percentage),
                0,
              );
            const prevValue = opportunityGSLs?.find(
              (gsl) => gsl.id === context.parent.id,
            )?.percentage;
            if (totalPercentage > 100 && Number(prevValue) !== Number(value)) {
              return context.createError({
                message: `The sum of all percentages must equal 100; currently, it exceeds by ${(
                  totalPercentage - 100
                ).toFixed(2)}%.`,
              });
            } else {
              return true;
            }
          })
          .required("Percentage allocation is required"),
      }),
    ),
  });

  const addOpportunityGSL = (values, { setSubmitting, resetForm }) => {
    setError(null);
    setIsLoading(true);

    const addOpportunityGSLPromise = put(
      `/opportunities/${opportunity?.id}/global-service-lines/${values.gslId}`,
      {
        percentage: values.percentage,
      },
    );
    addOpportunityGSLPromise.promise
      .then((response) => {
        setOpportunityGSLs(response.data);
        resetForm();
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setSubmitting(false);
        setIsLoading(false);
      });
    subscribedPromises.current.push(addOpportunityGSLPromise);
  };

  const updateOpportunityGSLAllocation = (values, setSubmitting) => {
    setError(null);
    setIsLoading(true);

    const updateOpportunityGSLPromise = post(
      `/opportunities/${opportunity?.id}/global-service-lines`,
      { globalServiceLines: [...values?.globalServiceLines] },
    );
    updateOpportunityGSLPromise.promise
      .then((response) => {
        setOpportunityGSLs(response.data);
        handleClose();
        setShow(false);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
        setSubmitting(false);
      });
    subscribedPromises.current.push(updateOpportunityGSLPromise);
  };

  const deleteOpportunityGSL = (gslId) => {
    setError(null);
    setIsLoading(true);

    const deleteOpportunityGSLPromise = destroy(
      `/opportunities/${opportunity?.id}/global-service-lines/${gslId}`,
    );
    deleteOpportunityGSLPromise.promise
      .then((response) => {
        setOpportunityGSLs(response.data);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    subscribedPromises.current.push(deleteOpportunityGSLPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  return (
    <>
      <OverlayTrigger overlay={<Tooltip>Update GSL Allocations</Tooltip>}>
        <Button
          variant={"outline-primary"}
          onClick={() => setShow(true)}
          className="text-nowrap"
          size="sm"
        >
          <span className="material-icons-outlined">edit</span>
        </Button>
      </OverlayTrigger>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          setShow(false);
        }}
        backdrop="static"
        size="lg"
        centered
      >
        <Formik
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            updateOpportunityGSLAllocation(values, setSubmitting);
          }}
          initialValues={{
            globalServiceLines: [...(gslData || [])].map((gsl) => {
              return {
                id: gsl.id,
                percentage:
                  opportunityGSLs?.find((val) => val.id === gsl.id)
                    ?.percentage || 0,
              };
            }),
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            isSubmitting,
            setSubmitting,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title className="w-100">
                    <div className="d-flex justify-content-between align-items-center w-100 me-3 pe-3">
                      <div>Update GSL Allocations</div>
                      <h6 className="text-muted fw-bold m-0">
                        Total allocation:{" "}
                        {Number(
                          values?.globalServiceLines?.reduce(
                            (total, gsl) =>
                              total + Number.parseFloat(gsl.percentage),
                            0,
                          ) || 0,
                        )?.toFixed(2)}
                        %
                      </h6>
                    </div>
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div
                    style={{
                      maxHeight: "65vh",
                      overflowY: "auto",
                      paddingRight: 10,
                    }}
                  >
                    {values?.globalServiceLines?.map((gsl, index) => (
                      <div className="d-flex align-items-top justify-content-between gap-2">
                        <InputFieldFormik
                          label={index > 0 ? "" : "Global Service Line"}
                          notShowLabel={index > 0 ? true : false}
                          value={
                            gslData?.find(
                              (val) =>
                                val.id ===
                                values?.globalServiceLines?.[index]?.id,
                            )?.title || 0
                          }
                          disabled
                          notShowValidSign={true}
                          className="w-75"
                        />
                        <InputFieldFormik
                          name={`globalServiceLines[${index}].percentage`}
                          label={index > 0 ? "" : "Percentage Allocation"}
                          notShowLabel={index > 0 ? true : false}
                          error={errors.globalServiceLines?.[index]?.percentage}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          placeholder="% (0-100)"
                          touched={
                            touched.globalServiceLines?.[index]?.percentage
                          }
                          value={
                            values?.globalServiceLines?.[index]?.percentage
                          }
                          className="w-25"
                          notShowValidSign={true}
                        />
                      </div>
                    ))}

                    {error ? <ErrorHandler error={error} /> : null}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div>
                      {Number(
                        values?.globalServiceLines?.reduce(
                          (total, gsl) =>
                            total + Number.parseFloat(gsl.percentage),
                          0,
                        ),
                      ) >= 99.999 ? (
                        <Alert variant="info" className="m-0">
                          The entire allocated percentage has been utilized.
                        </Alert>
                      ) : null}
                    </div>
                    <div>
                      <Button
                        onClick={() => {
                          handleClose();
                          setShow(false);
                        }}
                        type="button"
                        variant="outline-secondary"
                      >
                        Close
                      </Button>
                      <Button type="submit" variant="primary ms-2">
                        Save
                      </Button>
                    </div>
                  </div>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}

EditOpportunityGSLModal.propTypes = {
  opportunityGSLs: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
};
