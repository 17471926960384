import { useContext, useEffect } from "react";
import { Badge, NavLink } from "react-bootstrap";
import { Link, useParams, Routes, Route } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { get } from "utils/DeApi";

import BreadCrumbs from "components/BreadCrumb/BreadCrumb";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import OpportunityWBS from "components/OpportunityWBS/OpportunityWBS";
import OpportunityCosting from "components/OpportunityCosting/OpportunityCosting";
import OpportunityValueFactor from "components/OpportunityValueFactor/OpportunityValueFactor";

import { BreadcrumbContext } from "contexts/OpportunityProvider";
import { ActiveTabContext } from "contexts/OpportunityProvider";
import {
  OpportunityDispatchContext,
  GovernanceExceptionsDispatchContext,
} from "contexts/OpportunityProvider";
import { OpportunityContext } from "contexts/OpportunityProvider";
import { usePhasesDispatch } from "contexts/PhasesProvider";
import { useCallGetDeApi } from "hooks/useCallApi";
import Opportunity from "components/Opportunity/Opportunity";
import DeleteOpportunity from "components/Opportunity/DeleteOpportunity/DeleteOpportunity";
import UpdateOpportunity from "components/Opportunity/EditOpportunityDetail/EditOpportunityModal";
import "./OpportunityLayout.scss";
import OpportunityPLAN from "components/OpportunityPLAN/PLAN";
import OpportunityGMSForm from "components/OpportunityGMSForm/OpportunityGMSForm";
import OpportunityGMSSummary from "components/OpportunityGMSSummary/OpportunityGMSSummary";
import OpportunityPMMetrics from "components/OpportunityPMMetrics/OpportunityPMMetrics";
import OpportunityGovernance from "components/OpportunityGovernance/OpportunityGovernance";

const OpportunityLayout = ({ children }) => {
  const opportunity = useContext(OpportunityContext);
  const setOpportunity = useContext(OpportunityDispatchContext);
  const breadcrumbs = useContext(BreadcrumbContext);
  const setPhases = usePhasesDispatch();

  const setGovernanceExceptions = useContext(
    GovernanceExceptionsDispatchContext,
  );
  /*
    This is used to outline which tab is selected in the navigation component below
    the values can be "projectSummary", "wbs", "costing", "dashboard"
  */
  const activeTab = useContext(ActiveTabContext);

  const getActiveClassName = (activeTab, tabName) => {
    return activeTab !== tabName ? "nav-link" : "active";
  };

  let { opportunityId } = useParams();

  const { data, isLoading, error } = useCallGetDeApi(
    `/opportunities/${opportunityId}`,
  );

  const {
    data: phasesData,
    isLoading: isPhasesDataLoading,
    error: getPhasesError,
  } = useCallGetDeApi(`/opportunities/${opportunityId}/phases`);

  const { data: exceptionsData, isLoading: exceptionsDataIsLoading } = useQuery(
    {
      queryKey: ["opportunityGovernanceExceptions", opportunityId],
      queryFn: () =>
        get(
          `/opportunities/${opportunityId}/governance-exceptions`,
        ).promise.then((res) => res.data),
    },
  );

  useEffect(() => {
    if (exceptionsData && !exceptionsDataIsLoading) {
      setGovernanceExceptions(exceptionsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exceptionsData, exceptionsDataIsLoading]);

  useEffect(() => {
    setOpportunity(data);
    setPhases(phasesData || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, phasesData]);

  if (isLoading || isPhasesDataLoading) return <Loader />;
  if (error || getPhasesError)
    return <ErrorHandler error={error || getPhasesError} />;
  if (opportunity) {
    const { updatedAt, title, description, organization } = opportunity;

    return (
      <>
        <BreadCrumbs breadcrumbs={breadcrumbs} />
        {/* NAVIGATION */}
        <h3>
          {title}{" "}
          {organization?.title && (
            <small className="bg-success bg-opacity-10 text-success px-1">
              {organization.title}
            </small>
          )}
          {activeTab === "dashboard" && (
            <span className="float-end ms-2">
              <UpdateOpportunity
                opportunity={opportunity}
                initialValues={{
                  sfNumber: opportunity.sfNumber,
                  title: opportunity.title,
                  description: opportunity.description,
                  clientId: opportunity.client?.id,
                  clientContact: opportunity.clientContact,
                  clientEntityCountry: opportunity.clientEntityCountry,
                  projectLocation: opportunity.projectLocation,
                  isMapping: opportunity.isMapping,
                  expensesMarkup: opportunity.expensesMarkup,
                  suppliersMarkup: opportunity.suppliersMarkup,
                  apc: opportunity.apc,
                  commRecovery: opportunity.commRecovery,
                  projectManagerId: opportunity.projectManager?.id || null,
                  inchargePartnerId: opportunity.inchargePartner?.id || null,
                  businessUnitManagerPartnerId:
                    opportunity.businessUnitManagerPartner?.id || null,
                  ispTechPartnerId: opportunity.ispTechPartner?.id || null,
                  currencyId: opportunity.currency?.id || null,
                  countryId: opportunity.organization?.countryId,
                  organizationId: opportunity.organization?.id,
                  sectorId: opportunity.sector?.id || null,
                  pricingTypeId: opportunity.pricingType?.id || null,
                  lumpSum: opportunity?.lumpSum || null,
                  gmsConfidentialityStatusId:
                    opportunity?.gmsConfidentialityStatus?.id || null,
                  serviceId: opportunity.service?.id || null,
                  subServiceId: opportunity.subService?.id || null,
                  contractTermId: opportunity.contractTerm?.id || null,
                  tenderCompetitionId:
                    opportunity.tenderCompetition?.id || null,
                  projectStartDate: opportunity?.projectStartDate
                    ? formatDate(opportunity.projectStartDate)
                    : null,
                  projectEndDate: opportunity?.projectEndDate
                    ? formatDate(opportunity.projectEndDate)
                    : null,
                }}
              />
              <DeleteOpportunity
                opportunityId={opportunityId}
                title={opportunity?.title}
              />
            </span>
          )}
        </h3>
        <h5>{description}</h5>
        <i className="me-3">
          <small>
            Last Updated At{" "}
            {new Date(updatedAt).toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </small>
        </i>
        <Badge className="bg-tertiary text-dark">Proposal</Badge>{" "}
        <Badge className="bg-tertiary text-dark">
          {opportunity?.currency?.code || "USD"}
        </Badge>
        <div className="d-flex mt-4 mb-2 gap-5 container">
          <NavLink
            className={getActiveClassName(activeTab, "dashboard")}
            as={Link}
            to={`/pricing/opportunities/${opportunityId}`}
          >
            Project Summary
          </NavLink>
          <NavLink
            className={getActiveClassName(activeTab, "wbs")}
            as={Link}
            to={`/pricing/opportunities/${opportunityId}/wbs`}
          >
            Work Breakdown
          </NavLink>

          <NavLink
            className={getActiveClassName(activeTab, "costing")}
            as={Link}
            to={`/pricing/opportunities/${opportunityId}/costing?type=overview`}
          >
            Costing
          </NavLink>

          <NavLink
            className={getActiveClassName(activeTab, "valueFactor")}
            as={Link}
            to={`/pricing/opportunities/${opportunityId}/value-factor`}
          >
            Value Factor
          </NavLink>
          <NavLink
            className={getActiveClassName(activeTab, "gms")}
            as={Link}
            to={`/pricing/opportunities/${opportunityId}/gms`}
          >
            GMS Setup Form
          </NavLink>
          <NavLink
            className={getActiveClassName(activeTab, "gmsSummary")}
            as={Link}
            to={`/pricing/opportunities/${opportunityId}/gms-summary`}
          >
            GMS Summary
          </NavLink>
          <NavLink
            className={getActiveClassName(activeTab, "PMMetrics")}
            as={Link}
            to={`/pricing/opportunities/${opportunityId}/pm-metrics`}
          >
            PM Metrics
          </NavLink>
          <NavLink
            className={getActiveClassName(activeTab, "plan")}
            as={Link}
            to={`/pricing/opportunities/${opportunityId}/plan`}
          >
            PLAN
          </NavLink>
          <NavLink
            className={getActiveClassName(activeTab, "governance")}
            as={Link}
            to={`/pricing/opportunities/${opportunityId}/governance`}
          >
            Governance
          </NavLink>
        </div>
        <Routes>
          <Route path="/" element={<Opportunity />} />
          <Route path="/wbs" element={<OpportunityWBS />} />
          <Route path="/costing" element={<OpportunityCosting />} />
          <Route path="/value-factor" element={<OpportunityValueFactor />} />
          <Route path="/gms" element={<OpportunityGMSForm />} />
          <Route path="/gms-summary" element={<OpportunityGMSSummary />} />
          <Route path="/pm-metrics" element={<OpportunityPMMetrics />} />
          <Route path="/plan" element={<OpportunityPLAN />} />
          <Route path="/governance" element={<OpportunityGovernance />} />
        </Routes>
      </>
    );
  }
};

export default OpportunityLayout;

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1), // Months are zero-based
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}
