import { Formik } from "formik";

import { Modal, Button, Form, Spinner } from "react-bootstrap";

import InputFieldFormik from "components/Inputs/InputFieldFormik";
import { useEffect, useRef, useState } from "react";
import { post } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import * as yup from "yup";

const AddActivity = ({ phaseId, updateActivitiesData }) => {
  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const initialValues = {
    title: "",
    description: "",
  };

  const schema = yup.object().shape({
    title: yup
      .string()
      .required()
      .max(75, "Title must be less than 75 characters"),
    description: yup
      .string()
      .max(75, "Description must be less than 75 characters"),
  });

  const createNewPhase = (values) => {
    setError(null);
    setIsLoading(true);
    const auditPromise = post("/activities", {
      phaseId: phaseId,
      title: values.title.replace(/ +/g, " ").trim(),
      description: values.description,
    });
    auditPromise.promise
      .then((response) => {
        setIsLoading(false);
        updateActivitiesData(response.data);
        setShow(false);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });

    subscribedPromises.current.push(auditPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  return (
    <>
      <Button
        onClick={() => setShow(true)}
        size="sm"
        variant="outline-success"
        className="py-0 px-3"
      >
        Add Activity
      </Button>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>New Activity</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            createNewPhase(values);
          }}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <InputFieldFormik
                    name="title"
                    label={"Title"}
                    error={errors.title}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    placeholder={"Activity Title"}
                    touched={touched.title}
                    value={values.title}
                  />
                  <InputFieldFormik
                    name="description"
                    label={"Description"}
                    error={errors.description}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    placeholder={"Description"}
                    touched={touched.description}
                    value={values.description}
                  />

                  {error && <ErrorHandler error={error} />}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    size="sm"
                    variant="link"
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="success"
                    size="sm"
                    disabled={
                      Object.keys(errors).length > 0 || isLoading || !isValid
                    }
                  >
                    {isLoading && (
                      <Spinner
                        className="me-2"
                        animation="border"
                        size="sm"
                        variant="light"
                      />
                    )}{" "}
                    Add Activity
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};
export default AddActivity;
