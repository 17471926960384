import DeleteOpportunity from "components/Opportunity/DeleteOpportunity/DeleteOpportunity";
import { Alert, Button, Table, Card, Form, Pagination } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useRef, useEffect } from "react";

const SingleOpportunity = ({
  opportunity,
  onDeleteOpportunity,
  checkboxHeaderRef,
  titleHeaderRef,
  inchargePartnerHeaderRef,
  projectManagerHeaderRef,
  revenueHeaderRef,
  lastUpdatedHeaderRef,
  actionsHeaderRef,
  toggleOpportunity,
  isSelected,
}) => {
  const navigate = useNavigate();
  const { title, updatedAt, inchargePartner, projectManager, summary, id } =
    opportunity;
  const checkboxRef = useRef(null);
  const titleRef = useRef(null);
  const inchargePartnerRef = useRef(null);
  const projectManagerRef = useRef(null);
  const revenueRef = useRef(null);
  const lastUpdatedRef = useRef(null);
  const actionsRef = useRef(null);

  useEffect(() => {
    const updateHeaderWidth = (ref, headerRef) => {
      if (ref.current && headerRef.current) {
        const cellWidth = ref.current.getBoundingClientRect().width;
        headerRef.current.style.width = cellWidth + "px";
        ref.current.style.width = cellWidth + "px";
      }
    };
    updateHeaderWidth(checkboxRef, checkboxHeaderRef);
    updateHeaderWidth(titleRef, titleHeaderRef);
    updateHeaderWidth(inchargePartnerRef, inchargePartnerHeaderRef);
    updateHeaderWidth(projectManagerRef, projectManagerHeaderRef);
    updateHeaderWidth(revenueRef, revenueHeaderRef);
    updateHeaderWidth(lastUpdatedRef, lastUpdatedHeaderRef);
    updateHeaderWidth(actionsRef, actionsHeaderRef);
  }, []);

  return (
    <tr className="align-middle">
      <td ref={checkboxRef} className="px-3">
        <Form.Check
          type="checkbox"
          onChange={() => toggleOpportunity(opportunity.id)}
          checked={isSelected}
        />
      </td>
      <td ref={titleRef} className="px-3 text-capitalize">
        <Link
          className="bg-success bg-opacity-10 text-success px-1 text-decoration-none"
          to={`opportunities/${id}`}
        >
          {title}
        </Link>
      </td>

      <td ref={inchargePartnerRef} className="px-3">
        {inchargePartner?.title ?? "N/A"}
      </td>
      <td ref={projectManagerRef} className="px-3">
        {projectManager?.title ?? "N/A"}
      </td>
      <td ref={revenueRef} className="px-3">
        {new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(summary?.totalCost)}
      </td>
      <td ref={lastUpdatedRef} className="px-3">
        <small>
          {new Date(updatedAt).toLocaleString([], {
            dateStyle: "short",
            timeStyle: "short",
          })}
        </small>
      </td>
      <td ref={actionsRef} className="px-3" style={{ width: "120px" }}>
        <Button
          size="sm"
          variant="outline-primary"
          as={Link}
          to={`opportunities/${id}`}
        >
          <span className="material-icons-outlined">rate_review</span>
        </Button>
        <DeleteOpportunity
          opportunityId={id}
          title={title}
          onOpportunityDeleted={() => onDeleteOpportunity(id)}
        />
      </td>
    </tr>
  );
};

const OpportunitiesTable = ({
  opportunities,
  onDeleteOpportunity,
  itemsPerPage,
  setItemsPerPage,
  setPage,
  page,
  meta,
  selectedOpportunities,
  toggleOpportunity,
  toggleSelectAll,
  selectAll,
}) => {
  const checkboxHeaderRef = useRef(null);
  const titleHeaderRef = useRef(null);
  const inchargePartnerHeaderRef = useRef(null);
  const projectManagerHeaderRef = useRef(null);
  const revenueHeaderRef = useRef(null);
  const lastUpdatedHeaderRef = useRef(null);
  const actionsHeaderRef = useRef(null);

  if (opportunities?.length === 0) {
    return (
      <Alert variant="info" className="my-3 d-flex flex-row">
        <div className="me-3">
          <span className="material-icons md-18 text-primary text-opacity-75">
            info
          </span>
        </div>
        <div>
          <h5 className="mb-1">
            <small>No Opportunites</small>
          </h5>
          <p className="mb-1">
            <small>
              There are currently no opportunities to show. Please add a new
              opportunity using the New Opportunity button
            </small>
          </p>
        </div>
      </Alert>
    );
  }

  return (
    <Card>
      <Card.Body>
        <div>
          <Table
            responsive
            size="sm"
            style={{
              paddingRight: Number(itemsPerPage) === 10 ? "0px" : "18px",
            }}
          >
            <thead className="border-0 sticky-top">
              <tr>
                <th ref={checkboxHeaderRef} className="px-3">
                  <Form.Check
                    type="checkbox"
                    onChange={toggleSelectAll}
                    checked={selectAll}
                  />
                </th>
                <th ref={titleHeaderRef} className="px-3">
                  Proposal Title
                </th>
                <th ref={inchargePartnerHeaderRef} className="px-3">
                  Partner in Charge
                </th>
                <th ref={projectManagerHeaderRef} className="px-3">
                  Project Manager
                </th>
                <th ref={revenueHeaderRef} className="px-3">
                  Revenue
                </th>
                <th ref={lastUpdatedHeaderRef} className="px-3">
                  Last Updated
                </th>
                <th ref={actionsHeaderRef} className="px-3">
                  Actions
                </th>
              </tr>
            </thead>
          </Table>
        </div>
        <div
          style={{
            maxHeight: Number(itemsPerPage) === 10 ? "unset" : "60vh",
            overflowY: Number(itemsPerPage) === 10 ? "auto" : "scroll",
          }}
        >
          <Table responsive size="sm">
            <tbody>
              {opportunities?.map((opportunity) => {
                const { id, title } = opportunity;
                return (
                  <SingleOpportunity
                    key={id}
                    onDeleteOpportunity={onDeleteOpportunity}
                    opportunity={opportunity}
                    checkboxHeaderRef={checkboxHeaderRef}
                    titleHeaderRef={titleHeaderRef}
                    inchargePartnerHeaderRef={inchargePartnerHeaderRef}
                    projectManagerHeaderRef={projectManagerHeaderRef}
                    revenueHeaderRef={revenueHeaderRef}
                    lastUpdatedHeaderRef={lastUpdatedHeaderRef}
                    actionsHeaderRef={actionsHeaderRef}
                    isSelected={selectedOpportunities.some(
                      (opportunity) =>
                        opportunity.id === id && opportunity.title === title,
                    )}
                    toggleOpportunity={toggleOpportunity}
                  />
                );
              })}
            </tbody>
          </Table>
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="d-flex flex-column">
          <div className="d-flex gap-2 align-items-end justify-content-between">
            <Pagination className="mb-1">
              {Array.isArray(meta?.links) &&
                meta?.links.map((link, index) => {
                  return (
                    <Pagination.Item
                      key={index}
                      active={parseInt(link?.label) === page}
                      disabled={!link?.url}
                      onClick={() => {
                        if (link?.url) {
                          const URLParams = new URLSearchParams(
                            link.url.split("?")[1],
                          );
                          setPage(parseInt(URLParams.get("page")));
                        }
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: link?.label,
                        }}
                      />
                    </Pagination.Item>
                  );
                })}
            </Pagination>
            <div className="d-flex flex-row mb-1">
              <div className="me-3 pt-1">
                <small>
                  Showing {meta?.from} - {meta?.to} of {meta?.total}
                </small>
              </div>
              <Form.Group className="mb-1">
                <Form.Select
                  size="sm"
                  onChange={(e) => {
                    setItemsPerPage(e.target.value);
                    setPage(1);
                  }}
                  value={itemsPerPage}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={meta?.total}>View All</option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default OpportunitiesTable;
