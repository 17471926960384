import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";
import { put } from "utils/DeApi";

const ProjectAtRisk = ({ status, setProjectDetails }) => {
  const { id } = useParams();
  const [error, setError] = useState();
  const [checked, setChecked] = useState(false);

  const changeStatus = (e) => {
    setChecked(e.target.checked);
    setError(null);

    const getPromise = put(`/project-intel/projects/${id}`, {
      status: e.target.checked ? "critical" : "normal",
    });
    getPromise.promise
      .then(({ data }) => {
        setProjectDetails(data);
      })
      .catch((error) => {
        setChecked(!e.target.checked);
        !error.isCanceled && setError(error);
      });
  };

  useEffect(() => {
    setChecked(status === "critical");
  }, [status]);

  return (
    <>
      <Form className="d-flex justify-content-end align-items-center">
        <Form.Check type="checkbox" id={`check-api-checkbox}`}>
          <Form.Check.Input
            type="checkbox"
            isInvalid
            checked={checked}
            onChange={changeStatus}
          />
          <Form.Check.Label className="fw-bold">
            Project is at risk
          </Form.Check.Label>
        </Form.Check>
      </Form>
      {error && <ErrorHandler error={error} />}
    </>
  );
};
export default ProjectAtRisk;
