import React, { useState, useCallback, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import {
  cellStyle,
  getUnSavedRowStyles,
  handleOnInput,
  getReadOnlyInputStyles,
  invalidInputStyles,
  formatNumberValue,
} from "components/CostingGrid/EditableGrid/shared/utils";

/**
 *
 * This component is used in the CostingGrid component. It is used to render the header cell of the grid.
 * It is re-used by all headers accross the different costing types.
 *  */

const CostingHeaderCell = ({ getValue, row, column, table, cell }) => {
  const cellInputProps = column.columnDef.meta?.cellInputProps;
  const CustomInputComponent = cellInputProps?.component;

  const validationFn = cellInputProps?.validationFn;
  const isDbSyncRequiredOnBlur =
    cellInputProps?.isDbSyncRequiredOnBlur === false ? false : true; // default true if not provided as false explicitly
  const isDbSyncRequiredOnChange =
    cellInputProps?.isDbSyncRequiredOnChange === true ? true : false; // default false if not provided as true explicitly

  const cellClasses = column.columnDef.meta?.cellClasses || cellStyle;

  const initialValue = getValue();
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(() =>
    !!initialValue || initialValue === 0 ? initialValue : "",
  );
  const [validationError, setValidationError] = useState();

  const getFieldKey = useCallback(() => {
    if (
      cellInputProps?.apiFieldFn &&
      cellInputProps?.apiFieldFn instanceof Function
    ) {
      return cellInputProps?.apiFieldFn(row);
    }
    return cellInputProps?.apiFieldKey || column.id;
  }, [cellInputProps, column.id, row]);

  const isResourceForActivity = false;

  const handleSaveToDB = (newValue, isSaveToDB, updatedRow) => {
    // update data if col.id is not id or colTag and row is not new
    if (
      column.id !== "id" &&
      column.id !== "colTag" &&
      !row.original.id?.includes("new")
    ) {
      table.options.meta?.updateData(
        row.original.id,
        column.id,
        getFieldKey(),
        newValue,
        updatedRow,
        isResourceForActivity,
        isSaveToDB,
        true,
      );
    } else if (
      column.id === "id" &&
      row.original.id?.includes("new") &&
      row.original._id?.includes("new")
    ) {
      // new row, create the resource
      table.options.meta?.updateData(
        row.original.id,
        column.id,
        getFieldKey(),
        newValue,
        updatedRow,
        isResourceForActivity,
        isSaveToDB,
        true,
      );
    } else if (
      column.id === "id" &&
      !row.original.id?.includes("new") &&
      !row.original._id?.includes("new")
    ) {
      // existing row, update the resource
      table.options.meta?.updateData(
        row.original.id,
        column.id,
        getFieldKey(),
        newValue,
        updatedRow,
        isResourceForActivity,
        isSaveToDB,
        true,
        true,
      );
    } else {
      table.options.meta?.updateData(
        row.original.id,
        column.id,
        getFieldKey(),
        newValue,
        updatedRow,
        isResourceForActivity,
        false,
        true,
        false,
      );
    }
  };

  // When the input is blurred, we'll call our table meta's updateData function
  const handleOnBlur = () => {
    // restore initial value if input is empty
    let valueToSave = value;
    if (
      value === "" &&
      (cellInputProps?.type === "number" ||
        cellInputProps?.mainFieldType === "number")
    ) {
      valueToSave = 0;
      setValue(valueToSave);
    }

    if (handleValidate(valueToSave) !== "") {
      return;
    }

    if (initialValue === valueToSave) {
      return;
    }

    const isSaveToDB = isDbSyncRequiredOnBlur && !isDbSyncRequiredOnChange;

    const updatedRow = {
      ...row.original,
      [getFieldKey()]: valueToSave,
    };

    handleSaveToDB(valueToSave, isSaveToDB, updatedRow);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue, row.original]);

  const handleValidate = useCallback(
    (value) => {
      let validationError = "";
      if (validationFn && validationFn instanceof Function) {
        validationError = validationFn(value, getFieldKey(), row, table);
        setValidationError(validationError);
      } else {
        setValidationError(validationError);
      }
      return validationError;
    },
    [row, table],
  );

  useEffect(
    function handleValidateNewValue() {
      handleValidate(value);
    },
    [handleValidate, value],
  );

  const isAllDependentColsFilled = () => {
    return cellInputProps?.dependsOnCols?.every((colId) =>
      row.getValue(colId) ? true : false,
    );
  };

  const {
    isLoading: getOptionsDataIsLoading,
    // error: getOptionsDataError,
    data: optionsData,
  } = useQuery({
    queryKey: [
      "getOptionsData",
      cellInputProps?.queryKey,
      ...(cellInputProps?.dependsOnCols?.length
        ? [
            column.id,
            ...cellInputProps?.dependsOnCols.map((colId) =>
              row.getValue(colId),
            ),
          ]
        : [column.id]),
    ],
    queryFn: () =>
      cellInputProps
        ?.getOptionsData(row)
        .then((res) => res.data)
        .catch((err) => console.log(err)),
    enabled: isAllDependentColsFilled(),
    staleTime: 60 * 1000 * 60,
  });

  if (cellInputProps?.type === "select") {
    return (
      <td
        key={row.id}
        className={cellClasses + getUnSavedRowStyles(row, column)}
      >
        <OverlayTrigger
          overlay={
            cellInputProps?.disabled ? (
              <Tooltip>Read only</Tooltip>
            ) : (
              <Tooltip>{validationError || "Editable Cell"}</Tooltip>
            )
          }
          trigger={["hover", "focus"]}
          show={!!validationError}
        >
          <select
            className={
              `${validationError ? "border" : "border-0"} h-100 w-100` +
              invalidInputStyles(validationError)
            }
            value={value}
            disabled={cellInputProps.disabled}
            ref={(el) => table.options.meta?.cellRefs.set(cell.id, el)}
            onChange={(e) => {
              setValue(e.target.value);

              if (handleValidate(e.target.value) === "") {
                const selectedOption = optionsData.find(
                  (data) => data.id === e.target.value,
                );
                // update cols with read only dependent on this col
                const dependentCols = table
                  .getAllColumns()
                  .filter((col) =>
                    col.columnDef.meta?.cellInputProps?.dependsOnCols?.includes(
                      column.id,
                    ),
                  );

                const updatedRow = {
                  ...row.original,
                };

                dependentCols.forEach((col) => {
                  updatedRow[
                    col.columnDef.meta?.cellInputProps?.apiFieldKey || col.id
                  ] =
                    selectedOption[
                      col.columnDef.meta?.cellInputProps?.dependentOptionKey ||
                        col.columnDef.meta?.cellInputProps?.apiFieldKey ||
                        col.id
                    ];
                });

                updatedRow[getFieldKey()] = e.target.value;

                if (isDbSyncRequiredOnChange) {
                  handleSaveToDB(e.target.value, true, updatedRow);
                }
              }
            }}
            onBlur={handleOnBlur}
            key={row.id + column.id}
          >
            <option
              value={
                value?.includes("new") ||
                !optionsData?.find(
                  (it) => it[cellInputProps.valueKey] === value,
                )
                  ? value
                  : ""
              }
              disabled
            >
              {getOptionsDataIsLoading ? "Loading.." : "Select"}
            </option>
            {optionsData?.map((option) => (
              <option
                value={option[cellInputProps.valueKey]}
                key={option[cellInputProps.valueKey]}
              >
                {option[cellInputProps.labelKey]}
              </option>
            ))}
          </select>
        </OverlayTrigger>
      </td>
    );
  } else if (cellInputProps?.type === "custom") {
    return (
      <td
        key={row.id}
        className={cellClasses + getUnSavedRowStyles(row, column)}
      >
        <CustomInputComponent
          row={row}
          column={column}
          table={table}
          cell={cell}
          value={value}
          setValue={setValue}
          validationError={validationError}
          handleOnBlur={handleOnBlur}
          handleOnInput={handleOnInput}
          isLabourForActivity={isResourceForActivity}
        />
      </td>
    );
  }

  return (
    <td
      className={
        cellClasses +
        (cellInputProps?.type === "number" ? " text-end pe-1" : "") +
        getUnSavedRowStyles(row, column)
      }
    >
      <OverlayTrigger
        overlay={
          cellInputProps?.disabled ? (
            <Tooltip>Read only</Tooltip>
          ) : (
            <Tooltip>{validationError || "Editable Cell"}</Tooltip>
          )
        }
        trigger={["hover", "focus"]}
      >
        <input
          type={"text"}
          ref={(el) => table.options.meta?.cellRefs.set(cell.id, el)}
          readOnly={cellInputProps?.disabled}
          className={
            `${validationError ? "border" : "border-0"} h-100 w-100` +
            (cellInputProps?.type === "number" ? " text-end pe-1" : "") +
            getReadOnlyInputStyles(cellInputProps?.disabled) +
            invalidInputStyles(validationError)
          }
          value={
            cellInputProps?.disabled && cellInputProps?.type === "number"
              ? formatNumberValue(value)
              : value
          }
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleOnBlur}
          onKeyDown={(e) =>
            table.options.meta?.handleKeyDown(e, row, column, table)
          }
          onKeyUp={(e) => {
            if (e.key === "Enter") e.target.blur();
          }}
          onInput={cellInputProps?.type === "number" ? handleOnInput : null}
        />
      </OverlayTrigger>
    </td>
  );
};

export default CostingHeaderCell;
