import React, { useContext } from "react";
import {
  Card,
  Container,
  Col,
  Row,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useQuery, useMutation } from "@tanstack/react-query";

import { get, put } from "utils/DeApi";

import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { formatNumberValue } from "components/CostingGrid/EditableGrid/shared/utils";
import LabourCharts from "./LabourCharts/LabourCharts";
import { GovernanceExceptionsDispatchContext } from "contexts/OpportunityProvider";

import {
  GovernanceExceptionApprovalPopover,
  getBagdeVariant,
} from "components/OpportunityGovernance/shared";
import { convertToCurrency } from "utils/NumbersUtil";

const missedOPPMetrics = [
  {
    metric: "Gross Revenue:",
    key: "grossRevenue",
    type: "currency",
  },
  {
    metric: "Net Revenue:",
    key: "netRevenue",
    type: "currency",
  },
  {
    metric: "Net/Gross Ratio:",
    key: "netGrossRatio",
    type: "number",
  },
  {
    metric: "Avg Direct Labor Billing Rate:",
    key: "avgDirectLaborBillingRate",
    type: "currency",
    rateUnit: "hour",
  },
  {
    metric: "Mark up on Subcontractors:",
    key: "markUpOnSubcontractors",
    type: "currency",
  },
  {
    metric: "Mark up on Expenses:",
    key: "markUpOnExpenses",
    type: "currency",
  },
  {
    metric: "Effective Multiplier (Approx.):",
    key: "effectiveMultiplier",
    type: "number",
  },
  {
    metric: "Direct Labor Multiplier (Approx.):",
    key: "directLaborMultiplier",
    type: "number",
  },
  {
    metric: "Gross Profit (dollars) (Approx.):",
    key: "grossProfit",
    type: "currency",
  },
  {
    metric: "Contribution (Approx.):",
    key: "contribution",
    type: "number",
  },
];

const OvertimeImpactMetrics = [
  {
    metric: "Gross Revenue:",
    key: "grossRevenue",
    type: "currency",
  },
  {
    metric: "Net Revenue:",
    key: "netRevenue",
    type: "currency",
  },
  {
    metric: "Net/Gross Ratio:",
    key: "netGrossRatio",
    type: "number",
  },
];

export default function OpportunityPMMetricsSummary({
  opportunityId,
  pricingType,
  currency,
}) {
  const { data, isLoading, isRefetching, error } = useQuery({
    queryKey: ["getOpportunityPMMetrics", opportunityId],
    queryFn: () =>
      get(`/opportunities/${opportunityId}/pm-matrics`).promise.then(
        (res) => res.data,
      ),
  });

  const setGovernanceExceptions = useContext(
    GovernanceExceptionsDispatchContext,
  );

  const updateExceptionStatus = useMutation({
    mutationFn: ({ exceptionId, status }) =>
      put(
        `/opportunities/${opportunityId}/governance-exceptions/${exceptionId}`,
        {
          status,
        },
      ).promise.then((res) => res.data),
    onSuccess: (data, variables, context) => {
      setGovernanceExceptions([
        ...governanceExceptions.filter((item) => item.id !== data.id),
        data,
      ]);
    },
    throwOnError: false,
  });

  const {
    data: governanceExceptions,
    isLoading: isGovernanceExceptionsLoading,
  } = useQuery({
    queryKey: [`governanceRules`, opportunityId],
    queryFn: () =>
      get(`/opportunities/${opportunityId}/governance-exceptions`).promise.then(
        (res) => {
          setGovernanceExceptions(res.data);
          return res.data;
        },
      ),
  });

  const isLumpSum =
    pricingType?.toLowerCase().includes("lump") &&
    pricingType?.toLowerCase().includes("sum");

  const formatCurrencyWithUnit = (value, unit = "") => {
    let formattedValue = convertToCurrency(value, {
      currency: currency?.code,
      fractionDigits: 2,
    });

    if (unit) formattedValue += ` / ${unit}`;

    return formattedValue;
  };

  if (error) return <ErrorHandler error={error} />;
  if (isLoading || isRefetching || isGovernanceExceptionsLoading)
    return <Loader />;
  if (!data) return null;

  const {
    summary,
    missedOpportunit,
    withERMStandardRate,
    withProposedBillingRate,
    costSummaryPmMatrics,
    overTimeImpact,
    lumpSum,
  } = data;

  const exception = governanceExceptions?.find(
    (item) => item.rule?.name?.toLowerCase() === "gross value",
  );

  return (
    <>
      <Card className="p-3">
        <Card.Body>
          <h5 className="text-gray">
            Project Summary / Metrics (in {currency?.code})
          </h5>
          {exception ? (
            <small
              className={`text-${getBagdeVariant(exception.status)} fw-bold`}
            >
              {exception.rule?.description}
            </small>
          ) : null}
          <hr />
          <Container>
            <Row className="my-2">
              <Col>
                <span className="text-gray fw-bold">Project Name</span>
                <h6> {summary?.projectName || <small>N/A</small>}</h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">
                  Partner In Charge(PIC)
                </span>
                <h6> {summary?.partnerIncharge || <small>N/A</small>}</h6>
              </Col>

              <Col>
                <span className="text-gray fw-bold">Project Manager(PM)</span>
                <h6>{summary?.projectManager || <small>N/A</small>}</h6>
              </Col>
            </Row>
            <Row className="my-2">
              <Col>
                <span className="text-gray fw-bold">
                  Gross Revenue ({currency?.code})
                  {exception ? (
                    <OverlayTrigger
                      overlay={({ ...props }) => (
                        <GovernanceExceptionApprovalPopover
                          {...props}
                          exception={exception}
                          updateExceptionStatus={updateExceptionStatus}
                        />
                      )}
                      trigger={["click"]}
                    >
                      <span className="position-relative">
                        <span
                          className="btn btn-small"
                          data-title="Click to view the exception details"
                        >
                          <span
                            className={`material-icons-outlined text-${getBagdeVariant(
                              exception.status,
                            )}`}
                          >
                            error
                          </span>
                        </span>
                      </span>
                    </OverlayTrigger>
                  ) : null}
                </span>
                <h6>
                  {formatCurrencyWithUnit(summary?.grossRevenue || 0) || (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">
                  Net Revenue ({currency?.code})
                </span>
                <h6>
                  {formatCurrencyWithUnit(summary?.netRevenue || 0) || (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">Net/Gross Ratio (%)</span>
                <h6>
                  {" "}
                  {formatNumberValue(summary?.netGrossRatio, 2) || (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
            </Row>
            <Row className="my-2">
              <Col>
                <span className="text-gray fw-bold">
                  Avg Direct Labor Billing Rate ({currency?.code})
                </span>
                <h6>
                  {formatCurrencyWithUnit(
                    summary?.avgDirectLaborBillingRate || 0,
                  )}
                  &nbsp;/&nbsp;hour
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">
                  Mark Up on Subcontractors ({currency?.code})
                </span>
                <h6>
                  {formatCurrencyWithUnit(
                    summary?.markUpOnSubcontractors || 0,
                  ) || <small>N/A</small>}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">
                  Mark Up on Expenses ({currency?.code})
                </span>
                <h6>
                  {formatCurrencyWithUnit(summary?.markUpOnExpenses || 0) || (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
            </Row>
            <Row className="my-2">
              <Col>
                <span className="text-gray fw-bold">
                  Effective NR Multiplier (Approx. %)
                </span>
                <h6>
                  {" "}
                  {formatNumberValue(summary?.effectiveNrMultiplier, 2) || (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">
                  Direct Labor Multiplier (Approx. %)
                </span>
                <h6>
                  {formatNumberValue(summary?.directLaborMultiplier, 2) || (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">
                  Location Multiplier (Approx. %):
                </span>
                <h6>
                  {formatNumberValue(summary?.locationMultiplier, 2) || (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
            </Row>
            <Row className="my-2">
              <Col>
                <span className="text-gray fw-bold">
                  Service Multiplier (Approx. %)
                </span>
                <h6>
                  {" "}
                  {formatNumberValue(summary?.serviceMultiplier, 2) || (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">
                  Client Multiplier (Approx. %)
                </span>
                <h6>
                  {formatNumberValue(summary?.clientMultiplier, 2) || (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">
                  Gross Profit (dollars) (Approx.) ({currency?.code})
                </span>
                <h6>
                  {formatCurrencyWithUnit(summary?.grossProfit || 0) || (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
            </Row>
            <Row className="my-2">
              <Col>
                <span className="text-gray fw-bold">
                  Contribution (Approx.)
                </span>
                <h6>
                  {formatNumberValue(summary?.contribution, 2) || (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">
                  Effective Average Billing Rate ({currency?.code}):
                </span>
                <h6>
                  {formatCurrencyWithUnit(
                    summary?.effectiveAverageBillingRate || 0,
                  )}
                  &nbsp;/&nbsp;hour
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">
                  Contingency on Net Rev (%)
                </span>
                <h6>
                  {formatNumberValue(summary?.contingencyOnNetRevenue, 2) || (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <Card className="p-3 my-3">
        <Card.Body>
          <h5 className="text-gray">Missed Opportunities Metrics</h5>
          <hr />
          <Container>
            <Table responsive striped>
              <thead>
                <tr>
                  <th className="border-end">Metric</th>
                  <th className="text-end">
                    With Proposed Billing Rate/ Assumptions
                  </th>
                  <th className="text-end">
                    With ERM Standard Rate/ Assumptions
                  </th>
                  <th className="text-end">Missed Opportunity</th>
                </tr>
              </thead>
              <tbody>
                {missedOPPMetrics.map((item) => (
                  <tr key={item.key}>
                    <td className="border-end">{item.metric}</td>
                    <td className="text-end">
                      {item.type === "currency"
                        ? formatCurrencyWithUnit(
                            withProposedBillingRate[item.key] || 0,
                            item.rateUnit,
                          )
                        : formatNumberValue(
                            withProposedBillingRate[item.key],
                            2,
                          ) || <small>N/A</small>}
                    </td>
                    <td className="text-end">
                      {item.type === "currency"
                        ? formatCurrencyWithUnit(
                            withERMStandardRate[item.key] || 0,
                            item.rateUnit,
                          )
                        : formatNumberValue(
                            withERMStandardRate[item.key],
                            2,
                          ) || <small>N/A</small>}
                    </td>
                    <td className="text-end">
                      {item.type === "currency"
                        ? formatCurrencyWithUnit(
                            missedOpportunit[item.key] || 0,
                            item.rateUnit,
                          )
                        : formatNumberValue(missedOpportunit[item.key], 2) || (
                            <small>N/A</small>
                          )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        </Card.Body>
      </Card>
      <Card className="p-3 my-3">
        <Card.Body>
          <h5 className="text-gray">Overtime Impact</h5>
          <hr />
          <Container>
            <Table responsive striped>
              <thead>
                <tr>
                  <th className="border-end"></th>
                  <th className="text-end">Standard Proposal</th>
                  <th className="text-end">Overtime impact</th>
                  <th className="text-end">Difference</th>
                </tr>
              </thead>
              <tbody>
                {OvertimeImpactMetrics.map((item) => (
                  <tr key={item.key}>
                    <td className="border-end">{item.metric}</td>
                    <td className="text-end">
                      {item.type === "currency"
                        ? formatCurrencyWithUnit(
                            overTimeImpact[item.key]["standardProposal"] || 0,
                          )
                        : formatNumberValue(
                            overTimeImpact[item.key]["standardProposal"],
                            2,
                          ) || <small>N/A</small>}
                    </td>
                    <td className="text-end">
                      {item.type === "currency"
                        ? formatCurrencyWithUnit(
                            overTimeImpact[item.key]["overtimeImpact"] || 0,
                          )
                        : formatNumberValue(
                            overTimeImpact[item.key]["overtimeImpact"],
                            2,
                          ) || <small>N/A</small>}
                    </td>
                    <td className="text-end">
                      {item.type === "currency"
                        ? formatCurrencyWithUnit(
                            overTimeImpact[item.key]["differential"] || 0,
                          )
                        : formatNumberValue(
                            overTimeImpact[item.key]["differential"],
                            2,
                          ) || <small>N/A</small>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        </Card.Body>
      </Card>
      <Card className="p-3 my-3">
        <Card.Body>
          {isLumpSum ? (
            <>
              <h5 className="text-gray">Lumpsum (Fixed Income)</h5>
              <hr />
              <Container>
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th className="border-end"></th>
                      <th className="text-end">Standard Proposal</th>
                      <th className="text-end">Fixed Price</th>
                      <th className="text-end">Difference</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OvertimeImpactMetrics.map((item) => (
                      <tr key={item.key}>
                        <td className="border-end">{item.metric}</td>
                        <td className="text-end">
                          {item.type === "currency"
                            ? formatCurrencyWithUnit(
                                lumpSum[item.key]["standardProposal"] || 0,
                              )
                            : formatNumberValue(
                                lumpSum[item.key]["standardProposal"],
                                2,
                              ) || <small>N/A</small>}
                        </td>
                        <td className="text-end">
                          {item.type === "currency"
                            ? Intl.NumberFormat("en-us", {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                                style: "currency",
                                currency: currency?.code,
                              }).format(lumpSum[item.key]["fixedPrice"] || 0)
                            : formatNumberValue(
                                lumpSum[item.key]["fixedPrice"],
                                2,
                              ) || <small>N/A</small>}
                        </td>
                        <td className="text-end">
                          {item.type === "currency"
                            ? formatCurrencyWithUnit(
                                lumpSum[item.key]["differential"] || 0,
                              )
                            : formatNumberValue(
                                lumpSum[item.key]["differential"],
                                2,
                              ) || <small>N/A</small>}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Container>
            </>
          ) : (
            <h5 className="text-gray">
              Lumpsum (Fixed Income){" "}
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    This section is only applicable to Lumpsum Pricing Type
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined text-danger">
                  error
                </span>
              </OverlayTrigger>
            </h5>
          )}
        </Card.Body>
      </Card>
      <Card className="p-3 my-3">
        <Card.Body>
          <h5 className="text-gray">Labor Delegation Model</h5>
          <hr />
          <Row className="gy-3">
            <Col md={6} sm={12}>
              <Card>
                <Card.Body>
                  <h6>Distribution of Labour in Hours %</h6>
                  <hr />
                  <LabourCharts
                    data={costSummaryPmMatrics}
                    dataKey="hoursPercentage"
                    countKey="totalHours"
                    title="Distribution of Labour in Hours %"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} sm={12} className="flex-grow-1">
              <Card className="h-100">
                <Card.Body>
                  <h6>Distribution of Labour in Revenue %</h6>
                  <hr />
                  <LabourCharts
                    data={costSummaryPmMatrics}
                    dataKey="costPercentage"
                    countKey="totalCost"
                    title="Distribution of Labour in Revenue %"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}
