import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";

const CreateItem = ({
  onClick,
  onChange,
  value,
  isCreatingItem,
  createItemError,
}) => {
  const isDisableButton = isCreatingItem || value === "";
  return (
    <>
      <InputGroup>
        <Form.Control
          placeholder="Create new action item"
          aria-label="Create new action item"
          aria-describedby="create-item"
          onChange={onChange}
          value={value}
          disabled={isCreatingItem}
          required
        />
        <Button id="create-item" onClick={onClick} disabled={isDisableButton}>
          Create
        </Button>
      </InputGroup>
      {createItemError && (
        <span className="text-danger">{createItemError.data.message}</span>
      )}
    </>
  );
};

export default CreateItem;
