import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { memo } from "react";

import { convertToCurrency } from "utils/NumbersUtil";
const AccrualResource = ({ onChange, resource, data, isLoading }) => {
  const { key, label } = resource || {};

  // get current resource by key
  const { budget, gmsActual, accrual } = data[key];

  return (
    <Card>
      <Card.Body>
        <h5 className="fw-bold">{label}</h5>
        <hr />
        <Row className="gy-2">
          <Col sm={12} md={12} lg={12}>
            <div className="d-flex justify-content-between align-items-center">
              <div>Budget</div>
              <div>{convertToCurrency(budget)}</div>
            </div>
          </Col>
          <Col sm={12} md={12} lg={12}>
            <div className="d-flex justify-content-between align-items-center">
              <div>GMS Actual</div>
              <div>{convertToCurrency(gmsActual)}</div>
            </div>
          </Col>
          <Col sm={12} md={12} lg={12}>
            <div className="d-flex justify-content-between align-items-center">
              <div>Accrual</div>
              <div>
                <Form.Control
                  size="sm"
                  type="number"
                  name={key}
                  value={accrual}
                  placeholder="Accrual value"
                  onChange={(e) => onChange(key, e.target.value)}
                  disabled={isLoading}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default memo(AccrualResource);
