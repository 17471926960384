import { useEffect, useState } from "react";

export default function useExpenseData(phases) {
  const [expenses, setExpenses] = useState([]);

  useEffect(
    function extractGridExpensesDataFromPhases() {
      let initialExpenses = [];
      phases.forEach((phase) => {
        phase.activities.forEach((activity) => {
          activity.expenses?.forEach((expense, expenseIndex) => {
            const isExpensePresentIndex = initialExpenses?.findIndex(
              (element) => {
                return element.id === expense.id;
                // eslint-disable-next-line prettier/prettier
              },
            );

            if (isExpensePresentIndex === -1) {
              initialExpenses.push({
                ...expense,
                localCurrency: "USD",
              });
            } else {
              initialExpenses[isExpensePresentIndex].total += expense.total;
            }
          });
        });
      });

      let finalExpenses = [];
      initialExpenses.forEach((element) => {
        let singleItem = {
          ...element,
          phases: Array(phases.length).fill({}),
        };

        singleItem.totalMarkUp = Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(singleItem.totalMarkUp);

        singleItem.markUp = Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(singleItem.markUp);

        singleItem.total = Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(singleItem.total);

        phases.forEach((phase, phaseIndex) => {
          let sumOfTotalHours = 0;
          let sumOfTotalActivityContingency = 0;

          singleItem.phases[phaseIndex] = {
            id: phase.id,
            title: phase.title,
            totalHours: 0,
            activities: Array(phase.activities.length).fill({}),
          };
          phase.activities.forEach((activity, activityIndex) => {
            const expenseFound = activity.expenses.find((expense) => {
              return element.id === expense.id;
            });
            if (expenseFound) {
              sumOfTotalHours += Number.parseInt(expenseFound.qty);
              sumOfTotalActivityContingency += Number.parseFloat(
                expenseFound.activityContingency,
              );
              singleItem.phases[phaseIndex].activities[activityIndex] = {
                id: activity.id,
                title: activity.title,
                total: expenseFound.total,
                totalHours: Number.parseInt(expenseFound.qty),
                activityContingency: Number.parseFloat(
                  expenseFound.activityContingency,
                ),
              };
            } else {
              singleItem.phases[phaseIndex].activities[activityIndex] = {
                id: activity.id,
                title: activity.title,
                total: 0,
                totalHours: 0,
                activityContingency: 0,
              };
            }
          });
          singleItem.phases[phaseIndex].totalHours = sumOfTotalHours;
          singleItem.phases[phaseIndex].activityContingency =
            sumOfTotalActivityContingency;
        });
        finalExpenses.push(singleItem);
      });

      setExpenses(finalExpenses);
    },
    [phases],
  );

  return [expenses, setExpenses];
}
