export function convertNegativeToZero(value) {
  if (value < 0) {
    return 0;
  }
  return value;
}

export const getDateDifference = (startDate, endDate) => {
  if (startDate && endDate) {
    let date1 = new Date(startDate);
    let date2 = new Date(endDate);

    let Difference_In_Time = date2.getTime() - date1.getTime();

    let Difference_In_Weeks = Math.round(
      Difference_In_Time / (1000 * 3600 * 24 * 7),
    );
    return convertNegativeToZero(Difference_In_Weeks) + " week(s)";
  }
};
