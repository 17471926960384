import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import { get } from "utils/DeApi";
import { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import { Link, useParams } from "react-router-dom";

function ProjectsList({ status }) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const subscribedPromise = useRef([]);

  useEffect(() => {
    const url = "project-intel/projects?all=true";
    const getPromise = get(url);
    setIsLoading(true);
    getPromise.promise
      .then((response) => {
        const clonedData = structuredClone(response?.data);
        const sortedData = clonedData.sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        setData(sortedData);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromise.current.push(getPromise);

    const promise = subscribedPromise.current;

    return () => {
      promise.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [status]);

  const customClass = (status) =>
    status === "critical"
      ? "bg-danger bg-opacity-10 text-danger"
      : "bg-success bg-opacity-10 text-success";
  const params = useParams();

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  return (
    <ListGroup variant="flush">
      {data?.map((project) => (
        <ListGroup.Item
          className={`${
            params?.id === project?.id ? customClass(project?.status) : ""
          }`}
          action
          key={project.id}
        >
          <Link
            to={`/tracking/projects/${project?.id}`}
            className={`${
              project?.status === "critical" ? "text-danger" : "text-success"
            } text-decoration-none`}
          >
            {project?.status === "critical" ? (
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-top" placement="top">
                    Project is at risk
                  </Tooltip>
                }
              >
                <span>{project?.name}</span>
              </OverlayTrigger>
            ) : (
              <>{project?.name}</>
            )}
          </Link>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

export default ProjectsList;
