import Container from "react-bootstrap/Container";

import NoAuthorizatrionHeader from "components/NoAuthorizatrionHeader/NoAuthorizatrionHeader";

function NoAuthorizationLayout({ children }) {
  return (
    <>
      <NoAuthorizatrionHeader />
      <Container className="my-4 border-bottom-0">{children}</Container>
    </>
  );
}

export default NoAuthorizationLayout;
