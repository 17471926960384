import React from "react";
import * as yup from "yup";

import {
  cellStyle,
  headingStyle,
} from "components/CostingGrid/EditableGrid/shared/utils";
import { get } from "utils/DeApi";
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";

import {
  CustomDropdownMenu,
  CustomToggle,
} from "components/CostingGrid/EditableGrid/shared/CustomDropdowns";
import CostingHeaderCell from "components/CostingGrid/EditableGrid/shared/CostingHeaderCell";
import ReimbursableCellComponent from "components/CostingGrid/EditableGrid/shared/ReimbursableCell";

const getEquipmentHeaders = () => {
  const headers = [
    {
      id: "colTag",
      header: (props) => <td className={cellStyle + " text-center"}>A </td>,
      accessorKey: "colTag",
      cell: (props) => (
        <td className={cellStyle + " text-center"}>
          {props.getValue()}
          <span className="float-end cursor-pointer">
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
              <Dropdown.Menu as={CustomDropdownMenu} align={"right"}>
                <Dropdown.Item
                  onClick={() =>
                    props.table.options.meta?.handleAddNewRow(
                      props.row.index + 1,
                    )
                  }
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Add Equipment</Tooltip>}
                  >
                    <span className="btn btn-sm p-0" role="button">
                      <span className="material-icons-outlined md-18">add</span>
                    </span>
                  </OverlayTrigger>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    props.table.options.meta?.handleDeleteRow(
                      props.row.original.id,
                    )
                  }
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Delete Equipment</Tooltip>}
                  >
                    <span className="btn btn-sm p-0" role="button">
                      <span className="material-icons-outlined md-18">
                        delete
                      </span>
                    </span>
                  </OverlayTrigger>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </td>
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "total",
      header: () => (
        <td
          className={headingStyle.replace(/bg-[a-zA-Z]+/g, "bg-tertiary")}
          xs={6}
        >
          Total - Equipments
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "total", // accessor is the "key" in the data
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          disabled: true,
        },
        cellClasses: cellStyle.replace(/bg-[a-zA-Z]+/g, "bg-tertiary"),
      },
    },
    {
      id: "equipmentCategory",
      header: () => (
        <td className={headingStyle} xs={6}>
          Equipment Category
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "equipmentCategory",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "select",
          queryKey: "getEquipmentCategories",
          getOptionsData: () => get(`/equipment-categories`).promise,
          labelKey: "title",
          valueKey: "id",
          isDbSyncRequiredOnBlur: false,
        },
      },
    },
    {
      id: "id",
      header: () => (
        <td className={headingStyle} xs={6}>
          Equipment Name
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "id",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "select",
          dependsOnCols: ["equipmentCategory"],
          getOptionsData: (row) =>
            get(
              `/equipment-categories/${row.getValue(
                "equipmentCategory",
              )}/equipment`,
            ).promise,
          labelKey: "title",
          valueKey: "id",
          isDbSyncRequiredOnChange: true,
          validationFn: (value, fieldName, row, table) => {
            const otherRows = table
              .getRowModel()
              .rows.filter(
                (rowElem) => rowElem.original.id !== row.original.id,
              );
            const isExistingRow = otherRows.some(
              (rowElem) => rowElem.original.id === value,
            );
            return isExistingRow ? "Equipment already exists" : "";
          },
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Currency
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "localCurrency",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "text",
          disabled: true,
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Local Cost
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "localCost",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          dependsOnCols: ["id"],
          validationFn: (value) =>
            yup.number().min(0).isValidSync(value)
              ? ""
              : "Value needs to be a positive value",
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Description
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "description",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "text",
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Contingency (%, if applicable)
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "contingencyPercentage",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          validationFn: (value, _) =>
            yup.number().min(0).max(100).isValidSync(value)
              ? ""
              : "Value needs to be a % (0 to 100)",
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Tax - GST/VAT (%, if applicable)
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "taxVat",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          validationFn: (value, _) =>
            yup.number().min(0).max(100).isValidSync(value)
              ? ""
              : "Value needs to be a % (0 to 100)",
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Tax - WHT (%, if applicable)
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "taxWht",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          validationFn: (value, _) =>
            yup.number().min(0).max(100).isValidSync(value)
              ? ""
              : "Value needs to be a % (0 to 100)",
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Reimbursable (Default is Yes)
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "reimbursable",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "custom",
          component: ReimbursableCellComponent,
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Currency Cost
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "currencyCost",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          disabled: true,
        },
      },
    },
  ];

  return headers;
};

export default getEquipmentHeaders;
