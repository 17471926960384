import { useContext, useEffect } from "react";
import OpportunityCostingLayout from "components/OpportunityCosting/Layout/OpportunityCostingLayout";
import { OpportunityContext } from "contexts/OpportunityProvider";
import { BreadcrumbDispatchContext } from "contexts/OpportunityProvider";
import { ActiveTabDispatchContext } from "contexts/OpportunityProvider";

const OpportunityCosting = () => {
  const opportunity = useContext(OpportunityContext);
  const setBreadcrumbs = useContext(BreadcrumbDispatchContext);
  const setActiveTab = useContext(ActiveTabDispatchContext);

  useEffect(() => {
    setBreadcrumbs([
      { name: "Dashboard", link: "/pricing" },
      { name: "Opportunities", active: false },
      {
        name: opportunity?.title,
        link: `/pricing
        /opportunities/${opportunity?.id}`,
      },
      { name: "Costing", active: false },
    ]);
    setActiveTab("costing");
  }, [opportunity.title, opportunity.id, setActiveTab, setBreadcrumbs]);

  return <OpportunityCostingLayout />;
};

export default OpportunityCosting;
