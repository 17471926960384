import React, { useContext, useEffect, useState, useRef } from "react";
import "./Header.scss";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Row,
  Col,
  Form,
  Alert,
  Badge,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useMutation } from "@tanstack/react-query";

import ModulesDropdown from "./ModulesDropdown/ModulesDropdown";

import Feedback from "../Feedback/Feedback";

import { UserContext } from "contexts/UserProvider";
import { ActionItemsContext } from "contexts/ActionItemsProvider";

import useModules from "hooks/useModules";
import Loader from "components/Loader/Loader";
import { put } from "utils/DeApi";

const Header = ({ title }) => {
  const { user } = useContext(UserContext);
  const { userActionItems, isActionItemsLoading, refetchActionItems } =
    useContext(ActionItemsContext);

  const { currentModule, modules } = useModules();

  const isTrackingModule = currentModule.value === modules.tracking.value;

  // setting logo link based on module route
  const logoLink =
    currentModule.value === modules.pricing.value ? "/pricing" : "/tracking";

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || ""}</title>
      </Helmet>
      <Navbar
        bg="white"
        variant="light"
        expand="lg"
        fixed="top"
        className="border-bottom MainHeader"
      >
        <Container fluid>
          <Navbar.Brand as={Link} to={logoLink}>
            <span className="pe-2">
              <img
                className="img-responsive"
                src="/logo-green.png"
                style={{ width: "170px" }}
                alt="Logo"
              />{" "}
            </span>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="ms-auto">
              {isTrackingModule ? (
                <ActionItemsDropdown
                  actionItems={userActionItems}
                  isActionItemsLoading={isActionItemsLoading}
                  refetchActionItems={refetchActionItems}
                />
              ) : null}
              <Feedback />
              <ModulesDropdown />
              <NavDropdown align="end" title={`Hi, ${user?.firstName}`}>
                <NavDropdown.Item disabled>{user?.email}</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={Link} to="/logout">
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

Header.propTypes = {
  title: PropTypes.string,
};

export default Header;

const ActionItemsDropdown = ({
  actionItems,
  isActionItemsLoading,
  refetchActionItems,
}) => {
  const [filteredActionItems, setFilteredActionItems] = useState(actionItems);
  const [filter, setFilter] = useState(1); // 1: All, 2: Unread, 3: Read
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    handleChangeFilter(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionItems]);

  const handleChangeFilter = (newFilterValue) => {
    switch (newFilterValue) {
      case "1": // All
        setFilteredActionItems(actionItems);
        setFilter(1);
        break;
      case "2": // Unread
        setFilteredActionItems([...actionItems.filter((item) => !item.isRead)]);
        setFilter(2);
        break;
      case "3": // Read
        setFilteredActionItems([...actionItems.filter((item) => item.isRead)]);
        setFilter(3);
        break;

      default:
        setFilteredActionItems(actionItems);
        setFilter(1);
        break;
    }
  };

  const handleNavDropdownClick = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <NavDropdown
      align="end"
      className="me-3"
      title={<Title actionItems={actionItems} />}
      onClick={() => handleNavDropdownClick()}
      show={showDropdown}
      ref={dropdownRef}
    >
      <div className="">
        <Row
          className="py-2 px-3 align-items-center"
          onClick={(e) => e.stopPropagation()}
        >
          <Col className="">
            <span className="fw-bold text-gray fs-5">Actions</span>
          </Col>
          <Col className="">
            <Form.Select
              className=""
              onChange={(e) => handleChangeFilter(e.target.value)}
              value={filter}
            >
              <option value="1">All</option>
              <option value="2">Unread</option>
              <option value="3">Read</option>
            </Form.Select>
          </Col>
        </Row>
        <NavDropdown.Divider className="m-0" />
        <ActionItemsDropdownItems
          actionItems={filteredActionItems}
          isActionItemsLoading={isActionItemsLoading}
          refetchActionItems={refetchActionItems}
        />
      </div>
    </NavDropdown>
  );
};

const Title = ({ actionItems }) => {
  const unreadActionItems = actionItems.filter((item) => !item.isRead);

  return (
    <span>
      My Action Items{" "}
      {unreadActionItems && unreadActionItems.length ? (
        <Badge bg="secondary">{unreadActionItems.length}</Badge>
      ) : null}
    </span>
  );
};

const ActionItemsDropdownItems = ({
  actionItems,
  isActionItemsLoading,
  refetchActionItems,
}) => {
  if (isActionItemsLoading) {
    return <Loader />;
  }
  return (
    <div className="actions-dropdown-container">
      {actionItems && actionItems.length > 0 ? (
        actionItems.map((item) => (
          <ActionItemDropdownItem
            key={item.id}
            item={item}
            refetchActionItems={refetchActionItems}
          />
        ))
      ) : (
        <Alert variant="info" className="mx-3 my-3">
          No action items right now{" "}
          <span className="material-icons md-18">thumb_up</span>
        </Alert>
      )}
    </div>
  );
};

const ActionItemDropdownItem = ({ item, refetchActionItems }) => {
  const navigate = useNavigate();

  const getDeltaTime = (utcDate) => {
    const date = new Date(utcDate);
    const now = new Date();

    const nowTimestamp = now.getTime() / 1000;
    const dateTimestamp = date.getTime() / 1000;
    const diff = nowTimestamp - dateTimestamp;

    if (diff < 60) {
      return "Just now";
    } else if (diff < 3600) {
      return `${Math.floor(diff / 60)}m ago`;
    } else if (diff < 86400) {
      return `${Math.floor(diff / 3600)}h ago`;
    }

    return Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }).format(date);
  };

  const updateItemMutation = useMutation({
    mutationFn: ({ itemId, payload }) =>
      put(`/project-intel/action-items/${itemId}`, payload).promise,
    onSuccess: (_) => {
      refetchActionItems();
    },
  });

  const setActionItemRead = (item) => {
    // set action item as read
    updateItemMutation.mutateAsync({ itemId: item.id, payload: { isRead: 1 } });
    navigate(item.url);
  };

  const isDone = !!item.doneBy;
  const itemType = item.actionableType === "pi_project" ? "Project" : "Task";
  const isProject = item.actionableType === "pi_project";
  const itemColor = isProject ? "deep-indigo" : "primary";

  return (
    <>
      <Row
        className={
          "px-3 py-2 action-item-row " +
          (!item.isRead ? "unread-action-bg" : "")
        }
        onClick={() => setActionItemRead(item)}
      >
        <Col sm="1">
          <span
            className={`material-icons md-18 text-${
              isDone ? "success" : "gray"
            } small`}
          >
            {isDone ? "done_all" : "pending_actions"}
          </span>
        </Col>
        <Col>
          <Row>
            <Col>
              <span className="text-gray fw-bold small">
                {item.actionableName}
                {" - "}
                <span
                  className={`bg-${itemColor} text-${itemColor} p-1 bg-opacity-10 rounded-2`}
                >
                  {itemType}
                </span>
              </span>
            </Col>
            <Col sm="3">
              {" "}
              <span className="float-end text-gray small">
                {getDeltaTime(item.assignedAt)}
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className={"text-primary fw-bold"}>{item.title}</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="text-gray small">
                Assigned by: {item.assignedBy.firstName}{" "}
                {item.assignedBy.lastName}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <NavDropdown.Divider className="m-0" />
    </>
  );
};
