import React, { useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import "./App.css";

import "react-toastify/dist/ReactToastify.css";

import Login from "./Login/Login";
import Logout from "./Logout/Logout";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import NoMatch from "./NoMatch/NoMatch";

import Dashboard from "components/Dashboard/Dashboard";
import TrackingDashboard from "components/Tracking/Dashboard/Dashboard";
import PhaseTaskAccrual from "components/Tracking/PhaseTaskAccrual/PhasesTaskAccrual";
import ProjectDetails from "components/Tracking/ProjectDetails/ProjectDetails";

import { UserContext } from "contexts/UserProvider";
import { OpportunityProvider } from "contexts/OpportunityProvider";
import Header from "./Header/Header";
import { Container } from "react-bootstrap";
import OpportunityLayout from "components/Layouts/OpportunityLayout";

import { PhasesProvider } from "contexts/PhasesProvider";
import { ActionItemsProvider } from "contexts/ActionItemsProvider";

import { ToastContainer } from "react-toastify";
import NoAuthorizationLayout from "components/Layouts/NoAuthorizationLayout/NoAuthorizationLayout";
import LandingPage from "components/LandingPage/LandingPage";
import NoAuthorizationTrackingPage from "components/NoAuthorizationTrackingPage/NoAuthorizationTrackingPage";
import NoAuthorizationPricingPage from "components/NoAuthorizationPricingPage/NoAuthorizationPricingPage";

/**
 * Handles application level routing.
 * It sets a middleware for private and public routes.
 */
const App = () => {
  const { modules } = useContext(UserContext);
  return (
    <div className="Main">
      <Router>
        <ActionItemsProvider>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<PrivateRoute />}>
              <Route element={<PrivateRouteLayout />}>
                <Route
                  index
                  element={
                    <NoAuthorizationLayout>
                      <LandingPage />
                    </NoAuthorizationLayout>
                  }
                />
                <Route
                  path="pricing"
                  element={
                    modules?.pricing?.isAllowed ? (
                      <Outlet />
                    ) : (
                      <NoAuthorizationLayout>
                        <NoAuthorizationPricingPage />
                      </NoAuthorizationLayout>
                    )
                  }
                >
                  {/* module pricing routes */}
                  <Route index element={<Dashboard />} />
                  <Route
                    path="opportunities/:opportunityId/*"
                    element={
                      <OpportunityProvider>
                        <PhasesProvider>
                          <OpportunityLayout />
                        </PhasesProvider>
                      </OpportunityProvider>
                    }
                  />
                </Route>
                {/* module tracking routes */}
                <Route
                  path="tracking"
                  element={
                    modules?.tracking?.isAllowed ? (
                      <Outlet />
                    ) : (
                      <NoAuthorizationLayout>
                        <NoAuthorizationTrackingPage />
                      </NoAuthorizationLayout>
                    )
                  }
                >
                  <Route index element={<TrackingDashboard />} />
                  <Route path="projects/:id" element={<ProjectDetails />} />
                  <Route
                    path="projects/:projectId/phases/:phaseId/tasks/:taskId"
                    element={<PhaseTaskAccrual />}
                  />
                </Route>
              </Route>
            </Route>

            <Route path="login" element={<Login />} />
            <Route path="logout" element={<Logout />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
          <ToastContainer />
        </ActionItemsProvider>
      </Router>
    </div>
  );
};

/** Middleware for private routes. */
const PrivateRoute = () => {
  const location = useLocation();
  const { user } = useContext(UserContext);
  return user ? <Outlet /> : <Navigate to="login" state={{ from: location }} />;
};

const PrivateRouteLayout = () => {
  return (
    <>
      <Header />
      <Container className="my-4">
        <Outlet />
      </Container>
    </>
  );
};

/** Scroll To Top on Navigation */
const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
};

export default App;
