import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import { convertToCurrency } from "utils/NumbersUtil";

import { get } from "utils/DeApi";

import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

import "./ProjectsTable.scss";
import {
  Button,
  Form,
  OverlayTrigger,
  Pagination,
  Tooltip,
} from "react-bootstrap";

const ProjectsTable = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [meta, setMeta] = useState();

  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const subscribedPromises = useRef([]);
  const getProjects = () => {
    setIsLoading(true);
    setData(null);
    setError(null);

    const getPromise = get("/project-intel/projects", {
      params: {
        limit: itemsPerPage,
        page,
        per_page: itemsPerPage,
      },
    });
    getPromise.promise
      .then(({ data, meta }) => {
        setData(data);
        setMeta(meta);
        setIsLoading(false);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(getPromise);
  };

  useEffect(() => {
    getProjects();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [page, itemsPerPage]);

  if (data?.length === 0) {
    return (
      <Alert variant="info" className={`my-3 d-flex flex-row`}>
        <div className="me-3">
          <span className="material-icons md-18 text-primary text-opacity-75">
            info
          </span>
        </div>
        <div>
          <h5 className="mb-1">
            <small>No Projects</small>
          </h5>
          <p className="mb-1">
            <small>There are currently no projects to show.</small>
          </p>
        </div>
      </Alert>
    );
  }

  return (
    <Card style={{ height: "550px" }}>
      <Card.Body>
        <Table
          responsive
          size="sm"
          style={{
            paddingRight: Number(itemsPerPage) === 10 ? "0px" : "18px",
          }}
        >
          <thead className="border-0 sticky-top">
            <tr>
              <th>Project</th>
              <th style={{ width: 150 }}>Budget</th>
              <th style={{ width: 150 }}>Budget Spent</th>
              <th style={{ width: 150 }}>Updated At</th>
              <th style={{ width: 120 }}>Actions</th>
            </tr>
          </thead>
        </Table>

        {isLoading && <Loader />}
        {error && <ErrorHandler error={error} />}
        {!isLoading && !error && (
          <div
            style={{
              height: "400px",
              overflowY: Number(itemsPerPage) === 10 ? "unset" : "scroll",
            }}
          >
            <Table responsive size="sm">
              <tbody>
                {data?.map((project) => {
                  const { id, budget, gmsBudgetWithAccrual } = project;
                  return (
                    <tr className="align-middle" key={id}>
                      <td>
                        <Link
                          to={`projects/${id}`}
                          className={`${
                            project?.status === "critical"
                              ? "bg-danger text-danger"
                              : "bg-success text-success"
                          } bg-opacity-10 px-1 text-decoration-none`}
                        >
                          {project?.status === "critical" ? (
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-top" placement="top">
                                  Project is at risk
                                </Tooltip>
                              }
                            >
                              <span>{project?.name}</span>
                            </OverlayTrigger>
                          ) : (
                            <>{project?.name}</>
                          )}
                        </Link>
                      </td>
                      <td style={{ width: 150 }}>
                        {convertToCurrency(budget)}
                      </td>
                      <td style={{ width: 150 }}>
                        {convertToCurrency(gmsBudgetWithAccrual)}
                      </td>
                      <td style={{ width: 150 }}>
                        {new Date(project?.updatedAt).toLocaleString([], {
                          dateStyle: "short",
                          timeStyle: "short",
                        })}
                      </td>
                      <td style={{ width: 120 }}>
                        <Button
                          size="sm"
                          variant="outline-primary"
                          as={Link}
                          to={`projects/${id}`}
                        >
                          <span className="material-icons-outlined">
                            visibility
                          </span>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
      </Card.Body>
      <Card.Footer>
        <div className="d-flex flex-column">
          <div className="d-flex gap-2 align-items-end justify-content-between">
            <Pagination className="mb-1">
              {Array.isArray(meta?.links) &&
                meta?.links.map((link, index) => {
                  return (
                    <Pagination.Item
                      key={index}
                      active={parseInt(link?.label) === page}
                      disabled={!link?.url}
                      onClick={() => {
                        if (link?.url) {
                          const URLParams = new URLSearchParams(
                            link.url.split("?")[1],
                          );
                          setPage(parseInt(URLParams.get("page")));
                        }
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: link?.label,
                        }}
                      />
                    </Pagination.Item>
                  );
                })}
            </Pagination>
            <div className="d-flex flex-row mb-1">
              <div className="me-3 pt-1">
                <small>
                  Showing {meta?.from} - {meta?.to} of {meta?.total}
                </small>
              </div>
              <Form.Group className="mb-1">
                <Form.Select
                  size="sm"
                  onChange={(e) => {
                    setItemsPerPage(e.target.value);
                    setPage(1);
                  }}
                  value={itemsPerPage}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={meta?.total}>View All</option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default ProjectsTable;
