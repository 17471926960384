import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ProjectStats from "./ProjectStats/ProjectStats";
import ProjectsBarchart from "./ProjectsBarchart.js/ProjectsBarchart";
import ProjectsProgress from "./ProjectsProgress/ProjectsProgress";
import ProjectsTable from "./ProjectsTable/ProjectsTable";

import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { useCallGetDeApi } from "hooks/useCallApi";

const Dashboard = () => {
  const { data, isLoading, error } = useCallGetDeApi(
    "/project-intel/project-tracking",
  );

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  return (
    <>
      <ProjectStats data={data} />
      <Col xs={12} sm={12}>
        <Card className="mt-3 mb-3">
          <Card.Body>
            <Row className="gy-3">
              <Col xs={12} md={6}>
                <Card>
                  <Card.Body>
                    <h6>Budget by Projects</h6>
                    <hr />
                    {data && <ProjectsBarchart data={data} />}
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6}>
                <Card>
                  <Card.Body>
                    <h6>Budget Spent by Projects</h6>
                    <hr />
                    {data && <ProjectsProgress data={data} />}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} sm={12}>
        <Card className="mt-3 mb-3">
          <Card.Body>
            <h5 className="text-gray fw-bold">All Projects</h5>
            <hr />
            {data && <ProjectsTable data={data} />}
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default Dashboard;
