import React from "react";
import * as yup from "yup";

import {
  cellStyle,
  headingStyle,
} from "components/CostingGrid/EditableGrid/shared/utils";
import { get } from "utils/DeApi";
import { OverlayTrigger, Tooltip, Dropdown, Row, Col } from "react-bootstrap";

import CostingHeaderCell from "components/CostingGrid/EditableGrid/shared/CostingHeaderCell";
import {
  CustomDropdownMenu,
  CustomToggle,
} from "components/CostingGrid/EditableGrid/shared/CustomDropdowns";
import ReimbursableCellComponent from "components/CostingGrid/EditableGrid/shared/ReimbursableCell";
import { isNumeric } from "utils/NumbersUtil";
import { invalidInputStyles } from "components/CostingGrid/EditableGrid/shared/utils";

import {
  getBagdeVariant,
  GovernanceExceptionApprovalPopover,
} from "components/OpportunityGovernance/shared";

const getExpensesHeaders = (
  opportunity,
  expenseExceptions,
  updateExceptionStatus,
) => {
  const headers = [
    {
      id: "colTag",
      header: (props) => <td className={cellStyle + " text-center"}>A </td>,
      accessorKey: "colTag",
      cell: (props) => (
        <td className={cellStyle + " text-center"}>
          {props.getValue()}
          <span className="float-end cursor-pointer">
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
              <Dropdown.Menu as={CustomDropdownMenu} align={"right"}>
                <Dropdown.Item
                  onClick={() =>
                    props.table.options.meta?.handleAddNewRow(
                      props.row.index + 1,
                    )
                  }
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Add expense</Tooltip>}
                  >
                    <span className="btn btn-sm p-0" role="button">
                      <span className="material-icons-outlined md-18">add</span>
                    </span>
                  </OverlayTrigger>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    props.table.options.meta?.handleDeleteRow(
                      props.row.original.id,
                    )
                  }
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Delete expense</Tooltip>}
                  >
                    <span className="btn btn-sm p-0" role="button">
                      <span className="material-icons-outlined md-18">
                        delete
                      </span>
                    </span>
                  </OverlayTrigger>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </td>
      ),
      footer: (props) => props.column.id,
    },
    {
      id: "total",
      header: () => (
        <td
          className={headingStyle.replace(/bg-[a-zA-Z]+/g, "bg-tertiary")}
          xs={6}
        >
          Total - Expenses
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "total", // accessor is the "key" in the data
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          disabled: true,
        },
        cellClasses: cellStyle.replace(/bg-[a-zA-Z]+/g, "bg-tertiary"),
      },
    },
    {
      id: "id",
      header: () => (
        <td className={headingStyle} xs={6}>
          Expense Item
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "id", // accessor is the "key" in the data
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "select",
          queryKey: "getExpensesData",
          getOptionsData: () => get(`/expenses`).promise,
          labelKey: "name",
          valueKey: "id",
          isDbSyncRequiredOnChange: true,
          validationFn: (value, fieldName, row, table) => {
            const otherRows = table
              .getRowModel()
              .rows.filter(
                (rowElem) => rowElem.original.id !== row.original.id,
              );
            const isExistingRow = otherRows.some(
              (rowElem) => rowElem.original.id === value,
            );
            return isExistingRow ? "Expense already exists" : "";
          },
        },
      },
    },
    {
      id: "description",
      header: () => (
        <td className={headingStyle} xs={6}>
          Description
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "description",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "text",
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Currency
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "localCurrency",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "text",
          disabled: true,
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Local Expense
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "localCost",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          validationFn: (value) =>
            yup.number().min(0).isValidSync(value)
              ? ""
              : "Value needs to be a positive value",
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Currency Expense
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "currencyExpense",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          disabled: true,
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Contingency (%, if applicable)
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "contingencyPercentage",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          validationFn: (value, _) =>
            yup.number().min(0).max(100).isValidSync(value)
              ? ""
              : "Value needs to be a % (0 to 100)",
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Mark Up (at{" "}
          {isNumeric(opportunity?.expensesMarkup)
            ? opportunity?.expensesMarkup
            : "n/a "}
          %)
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "markUp",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          disabled: true,
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Adjusted Mark Up % (+/-)
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "adjustedMarkUpPercentage",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "custom",
          validationFn: (value, _) =>
            yup.number().min(-100).max(100).isValidSync(value)
              ? ""
              : "Value needs to be a % (-100 to 100)",
          component: ({
            row,
            column,
            table,
            cell,
            value,
            setValue,
            validationError,
            handleOnBlur,
            handleOnInput,
          }) => {
            const discountGovernanceException = expenseExceptions?.find(
              (item) =>
                item.rule?.name?.toLowerCase().includes("discount") &&
                item.uniqueId === row.original.id,
            );

            const isDiscountRuleViolated = !!discountGovernanceException;

            let discountAppliedWarning = "Editable cell";
            if (isDiscountRuleViolated) {
              discountAppliedWarning =
                discountGovernanceException?.rule?.description;
            }

            const ValdationPopover = ({ ...props }) => (
              <GovernanceExceptionApprovalPopover
                {...props}
                exception={discountGovernanceException}
                updateExceptionStatus={updateExceptionStatus}
              />
            );

            return (
              <Row>
                <Col>
                  <span className="position-relative p-0">
                    {isDiscountRuleViolated ? (
                      <span className="position-absolute top-0 left-0">
                        <OverlayTrigger
                          overlay={ValdationPopover}
                          trigger={"click"}
                        >
                          <span
                            className="btn btn-xs p-0 m-0"
                            data-title="Click to view the exception details"
                          >
                            <span className="material-icons-outlined md-18 text-danger align-baseline fw-bold">
                              priority_high
                            </span>
                          </span>
                        </OverlayTrigger>
                      </span>
                    ) : null}
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          {validationError || discountAppliedWarning}
                        </Tooltip>
                      }
                      trigger={["hover", "focus"]}
                    >
                      <input
                        type="text"
                        className={
                          `${
                            validationError ? "border" : "border-0"
                          } w-100 h-100 text-end pe-1` +
                          invalidInputStyles(validationError) +
                          (isDiscountRuleViolated
                            ? ` border-tertiary bg-${getBagdeVariant(
                                discountGovernanceException.status,
                              )} bg-opacity-50`
                            : "")
                        }
                        ref={(el) =>
                          table.options.meta?.cellRefs.set(cell.id, el)
                        }
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        onBlur={handleOnBlur}
                        onKeyDown={(e) =>
                          table.options.meta?.handleKeyDown(
                            e,
                            row,
                            column,
                            table,
                          )
                        }
                        onKeyUp={(e) => {
                          if (e.key === "Enter") e.target.blur();
                        }}
                        onInput={handleOnInput}
                      />
                    </OverlayTrigger>
                  </span>
                </Col>
              </Row>
            );
          },
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Total Mark Up
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "totalMarkUp",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          disabled: true,
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Total Mark Up (Currency rate)
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "totalMarkUpCurrencyRate",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          disabled: true,
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Tax - GST/VAT (%, if applicable)
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "taxVat",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          validationFn: (value, _) =>
            yup.number().min(0).max(100).isValidSync(value)
              ? ""
              : "Value needs to be a % (0 to 100)",
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Tax - WHT (%, if applicable)
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "taxWht",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          validationFn: (value, _) =>
            yup.number().min(0).max(100).isValidSync(value)
              ? ""
              : "Value needs to be a % (0 to 100)",
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Reimbursable
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "reimbursable",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "custom",
          component: ReimbursableCellComponent,
        },
      },
    },
    {
      header: () => (
        <td className={headingStyle} xs={6}>
          Currency Expense (after tax)
        </td>
      ),
      cell: CostingHeaderCell,
      accessorKey: "currencyCost",
      footer: (props) => props.column.id,
      meta: {
        cellInputProps: {
          type: "number",
          disabled: true,
        },
      },
    },
  ];

  return headers;
};

export default getExpensesHeaders;
