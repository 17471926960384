import React from "react";
import {
  Popover,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
  Button,
} from "react-bootstrap";

export const getBagdeVariant = (status) => {
  switch (status) {
    case ExceptionStatusEnum.PENDING:
      return "warning";
    case ExceptionStatusEnum.APPROVED:
      return "success";
    case ExceptionStatusEnum.REJECTED:
      return "danger";
    case ExceptionStatusEnum.IGNORED:
      return "secondary";
    default:
      return "warning";
  }
};

export const getApprovalStatusIcon = (status) => {
  switch (status) {
    case ExceptionStatusEnum.PENDING:
      return "pending";
    case ExceptionStatusEnum.APPROVED:
      return "done";
    case ExceptionStatusEnum.REJECTED:
      return "cancel";
    case ExceptionStatusEnum.IGNORED:
      return "do_not_disturb_on";
    default:
      return "pending";
  }
};

export const ExceptionStatusEnum = {
  PENDING: "pending",
  APPROVED: "accepted",
  REJECTED: "rejected",
  IGNORED: "ignored",
};

export const ResourceTypeEnum = {
  activityLabor: {
    title: "Labour Costing",
    pathToComponent: "/costing?type=labour",
  },
  activityExpense: {
    title: "Expenses Costing",
    pathToComponent: "/costing?type=expenses",
  },
  activitySubcontractor: {
    title: "Subcontractor Costing",
    pathToComponent: "/costing?type=subContractors",
    nameKey: "uniqueId",
  },
  opportunity: {
    title: "Opportunity PM Metrics",
    pathToComponent: "/pm-metrics",
  },
  gms: {
    title: "GMS Setup Form",
    pathToComponent: "/gms",
  },
  expense: {
    title: "Expense Costing",
    pathToComponent: "/costing?type=expenses",
  },
};

export const ResourceTypeEnumProxy = new Proxy(ResourceTypeEnum, {
  get: function (target, prop) {
    return (
      target[prop] || {
        title: prop,
        pathToComponent: "/governance",
        nameKey: "",
      }
    );
  },
});

export const GovernanceExceptionApprovalPopover = React.forwardRef(
  ({ exception, updateExceptionStatus, ...props }, ref) => {
    return (
      <Popover id="approval-popover" {...props} ref={ref}>
        <Popover.Header
          as="h3"
          className={`bg-${getBagdeVariant(
            exception.status,
          )} bg-opacity-25 text-${getBagdeVariant(exception.status)} fw-bold`}
        >
          {updateExceptionStatus.isPending ? (
            <p class="card-text h-100 placeholder-glow">
              <span class="placeholder col-6 me-2"></span>
              <span class="placeholder col-4"></span>
            </p>
          ) : (
            <p className="py-0 my-0">
              Status:{" "}
              {exception.status.charAt(0).toUpperCase() +
                exception.status.slice(1)}
            </p>
          )}
        </Popover.Header>
        <Popover.Body>
          {exception.rule.description}
          <hr />
          <Row className="justify-content-between">
            <Col className="text-center">
              <OverlayTrigger overlay={<Tooltip>Reject</Tooltip>}>
                <Button
                  variant="danger"
                  className="me-2 btn-sm"
                  disabled={updateExceptionStatus.isPending}
                  onClick={() =>
                    updateExceptionStatus.mutateAsync({
                      exceptionId: exception.id,
                      status: ExceptionStatusEnum.REJECTED,
                      uniqueId: exception.uniqueId,
                    })
                  }
                >
                  <span className="material-icons-outlined md-18">cancel</span>
                </Button>
              </OverlayTrigger>
            </Col>
            <Col className="text-center">
              <OverlayTrigger overlay={<Tooltip>Ignore</Tooltip>}>
                <Button
                  variant="outline-warning"
                  className="me-2 btn-sm"
                  disabled={updateExceptionStatus.isPending}
                  onClick={() =>
                    updateExceptionStatus.mutateAsync({
                      exceptionId: exception.id,
                      status: ExceptionStatusEnum.IGNORED,
                      uniqueId: exception.uniqueId,
                    })
                  }
                >
                  <span className="material-icons-outlined md-18">
                    do_not_disturb_on
                  </span>
                </Button>
              </OverlayTrigger>
            </Col>
            <Col className="text-center">
              <OverlayTrigger overlay={<Tooltip>Approve</Tooltip>}>
                <Button
                  variant="success"
                  className="me-2 btn-sm"
                  disabled={updateExceptionStatus.isPending}
                  onClick={() =>
                    updateExceptionStatus.mutateAsync({
                      exceptionId: exception.id,
                      status: ExceptionStatusEnum.APPROVED,
                      uniqueId: exception.uniqueId,
                    })
                  }
                >
                  <span className="material-icons-outlined md-18">
                    check_circle
                  </span>
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        </Popover.Body>
      </Popover>
    );
  },
);
