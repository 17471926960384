import React, { useRef } from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from "@tanstack/react-table";
import {
  Table,
  Accordion,
  Badge,
  Tooltip,
  OverlayTrigger,
  Alert,
} from "react-bootstrap";
import PropTypes from "prop-types";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import useLabourData from "./hooks/useLabourData";
import { usePhases } from "contexts/PhasesProvider";
import "../CostingTable.scss";

import { put, post, destroy } from "utils/DeApi";

import {
  cellStyle,
  costingTypesMap,
  AddGridColumn,
  costingAPIResourceMap,
  costingAPIResourcePropMap,
  getUnSavedRowStyles,
  hanldeCellToCellNavigation,
  updatePhasesData,
} from "./shared/utils";

import { useCostingHeaders } from "hooks/costing/useCostingGridHeaders";
import { useRefreshPhasesData } from "helpers/PhasesHelpers";
import { usePhasesDispatch } from "contexts/PhasesProvider";
import useOrderCostingData from "hooks/costing/useOrderCostingData";

import PhaseTitle, { AccordionButtonCell } from "./shared/PhaseTitle";
import PhaseTotals from "./shared/PhaseTotals";
import CostingDataCell from "./shared/EditableCostingDataCell";

import { newCostingResourceMap } from "./shared/newCostingData";

const hoursStyle = [
  "bg-warning text-danger",
  "bg-primary text-primary",
  "bg-success text-success",
];

function LabourEditableGrid({
  refreshOpportunityCostingSummary,
  showOverTimeHours,
}) {
  const [searchParams] = useSearchParams();
  const costingType = searchParams.get("type");

  const { opportunityId } = useParams();

  const phasesDispatch = usePhasesDispatch();
  const phasesData = usePhases();

  const [data, setData] = useLabourData(phasesData);
  const orderedData = useOrderCostingData(opportunityId, data, "labour");

  console.debug("OrderedGridData", orderedData);

  const cellRefs = useRef(new Map()).current;

  const { callGetPhasesAPI } = useRefreshPhasesData();

  const rowMutation = useMutation({
    mutationFn: ({ row, col, payload, isUpdate }) => {
      // if discount type is amount, set percentage to 0 and vice versa
      if (Number.parseInt(payload.discountType) === 1) {
        payload.discountPercentage = 0;
      } else {
        payload.discountAmount = 0;
      }

      // if contingency type is amount, set percentage to 0 and vice versa
      if (Number.parseInt(payload.contingencyType) === 1) {
        payload.contingencyPercentage = 0;
      } else {
        payload.contingencyAmount = 0;
      }

      return isUpdate
        ? put(
            `/activities/${col.id}/${costingAPIResourceMap[costingType]}/${row.id}`,
            { ...payload },
          ).promise
        : post(`/activities/${col.id}/${costingAPIResourceMap[costingType]}`, {
            employeeId: row.id,
            ...payload,
          }).promise;
    },
    onSuccess: (data, variables, context) => {
      updatePhasesData({
        oldPhasesData: phasesData,
        newPhasesData: data.data,
        costingType,
        phasesDispatch,
      });
      refreshOpportunityCostingSummary();
    },
    onError: (error, variables, context) => {
      console.error("error", error, variables, context);
    },
  });

  const rowMutationUpdateEmployee = useMutation({
    mutationFn: ({ row, payload }) => {
      // if discount type is amount, set percentage to 0 and vice versa
      if ("discountPercentage" in payload || "discountAmount" in payload) {
        if (Number.parseInt(row.discountType) === 1) {
          payload.discountPercentage = 0;
        } else {
          payload.discountAmount = 0;
        }
      } else if (
        "contingencyPercentage" in payload ||
        "contingencyAmount" in payload
      ) {
        // if contingency type is amount, set percentage to 0 and vice versa
        if (Number.parseInt(row.contingencyType) === 1) {
          payload.contingencyPercentage = 0;
        } else {
          payload.contingencyAmount = 0;
        }
      }

      return put(
        `/opportunities/${opportunityId}/activity_employees/${row.id}`,
        { ...payload },
      ).promise;
    },
    onSuccess: (data, variables, context) => {
      updatePhasesData({
        oldPhasesData: phasesData,
        newPhasesData: data.data,
        costingType,
        phasesDispatch,
      });
      refreshOpportunityCostingSummary();
    },
    onError: (error, variables, context) => {
      console.error("error", error, variables, context);
    },
  });

  const rowDelete = useMutation({
    mutationFn: ({ row }) => {
      return destroy(
        `/opportunities/${opportunityId}/activity_employees/${row.id}`,
      ).promise;
    },
    onSuccess: (data, variables, context) => {
      callGetPhasesAPI();
      refreshOpportunityCostingSummary();
    },
    onError: (error, variables, context) => {
      console.error("error", error, variables, context);
    },
  });

  const costingCols = useCostingHeaders(costingType);

  const colsWithoutOvertime = costingCols?.map((cols) => {
    if (cols.accessorKey === "totalHours" || cols.accessorKey === "total") {
      if (!cols.colsWithOvertime) {
        cols.colsWithOvertime = cols.columns;
      }
      if (!cols.colsWithoutOvertime) {
        cols.colsWithoutOvertime = cols?.columns?.filter((innerCols) => {
          if (
            innerCols?.accessorKey === "totalOvertimeHours" ||
            innerCols?.accessorKey === "totalOvertime"
          ) {
            return false;
          } else {
            return true;
          }
        });
      }
      cols.columns = showOverTimeHours
        ? cols.colsWithOvertime
        : cols.colsWithoutOvertime;

      return cols;
    } else {
      return cols;
    }
  });

  const columns = colsWithoutOvertime
    .concat(
      phasesData.map((phase, phaseIndex) => ({
        id: phase.id,
        header: () => (
          <PhaseTitle
            eventKey={phase.id}
            phaseIndex={phaseIndex}
            title={phase.title}
          />
        ),
        columns: phase.activities.map((activity, activityIndex) => ({
          id: activity.id,
          header: () => (
            <PhaseTitle
              eventKey={activity.id}
              phaseIndex={activityIndex}
              isActivityTitle
              title={`${phaseIndex + 1}.${activityIndex + 1} ${activity.title}`}
            />
          ),
          footer: (props) => props.column.id,
          accessorFn: (row) =>
            row.phases?.[phaseIndex]?.activities[activityIndex].totalHours || 0,
          columns: [
            {
              id: activity.id + "-regular",
              header: "Regular hours",
              accessorFn: (row) =>
                row.phases?.[phaseIndex]?.activities[activityIndex]
                  .regularHours || 0,
              cell: CostingDataCell,
              meta: {
                celInputProps: {
                  type: "number",
                  apiFieldKey: "regularHours",
                  isChildColumn: true,
                },
                activity: activity,
              },
            },
            ...(showOverTimeHours
              ? [
                  {
                    id: activity.id + "-overtime",
                    header: "Overtime hours",
                    accessorFn: (row) =>
                      row.phases?.[phaseIndex]?.activities[activityIndex]
                        .overtimeHours || 0,
                    cell: CostingDataCell,
                    meta: {
                      celInputProps: {
                        type: "number",
                        apiFieldKey: "overtimeHours",
                        isChildColumn: true,
                      },
                      activity: activity,
                    },
                  },
                ]
              : []),
          ],
        })),
        accessorFn: (row) => row.phases[phaseIndex].totalHours,
        footer: (props) => props.column.id,
      })),
    )
    .concat([
      {
        id: "contingency_label",
        header: () => {
          return (
            <div className="border sticky-left cell-heading-width align-middle fw-bold fs-5 px-2 py-1 border-0">
              Contingency
            </div>
          );
        },
      },
    ])
    .concat(
      phasesData.map((phase, phaseIndex) => ({
        id: phase.id + "_" + phaseIndex,
        header: () => (
          <PhaseTitle
            eventKey={phase.id + "_" + phaseIndex}
            phaseIndex={phaseIndex}
            title={phase.title}
          />
        ),
        columns: phase.activities.map((activity, activityIndex) => ({
          id: activity.id + "_" + activityIndex + phaseIndex,
          header: `${phaseIndex + 1}.${activityIndex + 1} ${activity.title}`,
          footer: (props) => activity.id,
          accessorFn: (row) =>
            row.phases?.[phaseIndex]?.activities[activityIndex]
              .activityContingency || 0,
          cell: CostingDataCell,
          meta: {
            celInputProps: {
              type: "number",
              apiFieldKey: "activityContingency",
            },
            activity: activity,
          },
        })),
        accessorFn: (row) => row.phases[phaseIndex].activityContingency,
        footer: (props) => phase.id,
      })),
    );

  const table = useReactTable({
    data: orderedData,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      name: "Labour Grid",
      updateData: (
        rowId,
        columnId,
        key,
        value,
        updatedRow,
        isLabourForActivity,
        isSaveToDB = true,
        isResourceProperty = false,
        isEmployeeUpdate = false,
      ) => {
        if (key === "activityContingency") {
          columnId = columnId?.split("_")[0];
        }
        if (key === "regularHours") {
          updatedRow?.phases?.forEach((phase) => {
            phase?.activities?.forEach((activity) => {
              if (activity.id === columnId) {
                updatedRow["activityContingency"] =
                  activity?.activityContingency;
              }
            });
          });
        } else if (key === "overtimeHours") {
          updatedRow?.phases?.forEach((phase) => {
            phase?.activities?.forEach((activity) => {
              if (activity.id === columnId) {
                updatedRow["activityContingency"] =
                  activity?.activityContingency;
              }
            });
          });
        }
        setData((old) =>
          old.map((row) => {
            if (row.id === rowId) {
              if (isSaveToDB) {
                if (isResourceProperty) {
                  if (updatedRow._id?.includes("new")) {
                    rowMutation.mutateAsync({
                      row: updatedRow,
                      col: {
                        id: phasesData[0]?.activities[0]?.id, // 1st activitiy id of the 1st phase
                      },
                      payload: {
                        ...updatedRow,
                        totalHours:
                          updatedRow.phases[0]?.activities[0]?.totalHours || 0,
                      },
                      isUpdate: phasesData.some((ph) => {
                        return ph.activities.some((activity) =>
                          activity[costingAPIResourcePropMap[costingType]].find(
                            (lb) =>
                              lb.id === updatedRow.id &&
                              !lb._id?.includes("new"),
                          ),
                        );
                      }),
                    });
                  } else {
                    rowMutationUpdateEmployee.mutateAsync({
                      row: row,
                      payload: {
                        localCost: updatedRow.localCost,
                        [isEmployeeUpdate ? "newEmployeeId" : key]: value,
                      },
                    });
                    if (isEmployeeUpdate) {
                      // ensure the updated row maintains the same order as the previous row
                      updatedRow.id = value;
                      updatedRow.orderIndex = row.order;
                    }
                  }
                } else {
                  let totalRegularHours;
                  let totalOvertimeHours;
                  if (key === "regularHours") {
                    totalRegularHours = value;
                    updatedRow.phases?.forEach((ph) =>
                      ph.activities.forEach((ac) => {
                        if (ac.id === columnId) {
                          totalOvertimeHours = ac.overtimeHours;
                        }
                      }),
                    );
                  } else if (key === "overtimeHours") {
                    totalOvertimeHours = value;
                    updatedRow.phases?.forEach((ph) =>
                      ph.activities.forEach((ac) => {
                        if (ac.id === columnId) {
                          totalRegularHours = ac.regularHours;
                        }
                      }),
                    );
                  } else if (key === "activityContingency") {
                    updatedRow.phases?.forEach((ph) =>
                      ph.activities.forEach((ac) => {
                        if (ac.id === columnId) {
                          totalRegularHours = ac.regularHours;
                          totalOvertimeHours = ac.overtimeHours;
                        }
                      }),
                    );
                  }
                  rowMutation.mutateAsync({
                    row: updatedRow,
                    col: {
                      id: columnId,
                    },
                    payload: {
                      ...updatedRow,
                      overtimeHours: totalOvertimeHours,
                      regularHours: totalRegularHours,
                      [key]: value,
                    },
                    isUpdate: isLabourForActivity,
                  });
                }
              }
              return updatedRow;
            }
            return row;
          }),
        );
      },
      cellRefs,
      handleDeleteRow: (rowId) => {
        if (rowId?.includes("new")) {
          setData((old) => old.filter((row) => row.id !== rowId));
          const _phases = [...phasesData];
          _phases[0]?.activities[0]?.[
            costingAPIResourcePropMap[costingType]
          ]?.splice(
            _phases[0]?.activities[0]?.[
              costingAPIResourcePropMap[costingType]
            ]?.findIndex((lb) => lb.id === rowId),
            1,
          );
          phasesDispatch(_phases);
          return;
        }
        rowDelete.mutate({
          row: {
            id: rowId,
          },
        });
      },
      handleKeyDown: hanldeCellToCellNavigation,
      handleAddNewRow: (orderIndex) => {
        const newCostingResourceCopy = Object.assign(
          {},
          newCostingResourceMap[costingType],
        );
        newCostingResourceCopy.orderIndex = orderIndex;
        newCostingResourceCopy.id = `new-${new Date().getTime()}`;
        newCostingResourceCopy._id = newCostingResourceCopy.id;
        const _phases = [...phasesData];
        _phases[0]?.activities[0]?.[
          costingAPIResourcePropMap[costingType]
        ]?.push(newCostingResourceCopy);
        phasesDispatch(_phases);
      },
    },
  });

  return (
    <Table responsive id="costing-grid">
      <div className="p-0 position-relative">
        {table.getAllColumns().map((col) => {
          const colHeader = table
            .getFlatHeaders()
            .find((header) => header.column.id === col.id);
          const isPhaseHeader = !!phasesData.find((p) => p.id === col.id);
          const isContigencyHeader = !!phasesData.find(
            (p, i) => p.id + "_" + i === col.id,
          );
          if (isPhaseHeader || isContigencyHeader) {
            return (
              <Accordion flush key={col.id} defaultActiveKey={col.id}>
                <tr>
                  {flexRender(col.columnDef.header, colHeader.getContext())}
                  {table.getRowModel().rows.map((row) => {
                    const colCellValue = row.getValue(col.id);
                    return (
                      <React.Fragment key={row.id}>
                        <PhaseTotals eventKey={col.id} row={row} col={col}>
                          <span className="d-flex justify-content-end align-middle my-auto">
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-top" placement="top">
                                  Phase total{" "}
                                  {isContigencyHeader
                                    ? "contingencies"
                                    : "hours"}
                                </Tooltip>
                              }
                            >
                              <Badge
                                className={
                                  [
                                    hoursStyle[
                                      !colCellValue
                                        ? 0
                                        : colCellValue <= 25
                                        ? 1
                                        : 2
                                    ],
                                  ] + " rounded-0 py-1 px-2 bg-opacity-25"
                                }
                              >
                                <h6 className="mb-0 fw-bold">
                                  {colCellValue || 0}
                                </h6>
                              </Badge>
                            </OverlayTrigger>
                          </span>
                        </PhaseTotals>
                      </React.Fragment>
                    );
                  })}
                </tr>
                <Accordion.Collapse className="p-0" eventKey={col.id}>
                  <>
                    {col.columns.length === 0 ? (
                      <tr>
                        <Alert
                          variant="info"
                          className={`my-3 d-flex flex-row`}
                        >
                          <div className="me-3">
                            <span className="material-icons md-18 text-primary text-opacity-75">
                              info
                            </span>
                          </div>
                          <div>
                            <h5 className="mb-1">
                              <small>No activities in this phase</small>
                            </h5>
                            <p className="mb-1">
                              <small>
                                There are currently no activities in this phase,
                                please go to the{" "}
                                <Link
                                  to={`/pricing/opportunities/${opportunityId}/wbs`}
                                >
                                  <strong>WBS</strong>
                                </Link>{" "}
                                to add activities
                              </small>
                            </p>
                          </div>
                        </Alert>
                      </tr>
                    ) : null}
                    {col.columns.map((subCol) => {
                      const subColHeader = table
                        .getFlatHeaders()
                        .find((header) => header.column.id === subCol.id);
                      if (isContigencyHeader) {
                        return (
                          <tr key={subCol.id}>
                            <td
                              className={
                                "border bg-white cell-heading-width align-middle cell-padding"
                              }
                            >
                              {subCol.columnDef.header}
                            </td>
                            {table.getRowModel().rows.map((row) => {
                              const colCell = row
                                .getVisibleCells()
                                .find((cell) => cell.column.id === subCol.id);
                              return (
                                <React.Fragment key={row.id}>
                                  {colCell ? (
                                    flexRender(
                                      colCell.column.columnDef.cell,
                                      colCell.getContext(),
                                    )
                                  ) : (
                                    <td
                                      className={cellStyle}
                                      ref={(el) => cellRefs.set(colCell.id, el)}
                                      onKeyDown={(e) =>
                                        hanldeCellToCellNavigation(
                                          e,
                                          row,
                                          colCell.column,
                                          table,
                                        )
                                      }
                                    ></td>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </tr>
                        );
                      }
                      return (
                        <Accordion
                          flush
                          key={subCol.id}
                          defaultActiveKey={subCol.id}
                        >
                          <tr key={subCol.id}>
                            {flexRender(
                              subCol.columnDef.header,
                              subColHeader.getContext(),
                            )}
                            {table.getRowModel().rows.map((row) => {
                              const colCellValue = row.getValue(subCol.id);
                              return (
                                <React.Fragment key={row.id}>
                                  <PhaseTotals
                                    eventKey={subCol.id}
                                    row={row}
                                    col={subCol}
                                    isActivityTotal
                                  >
                                    <span className="d-flex justify-content-end align-middle my-auto">
                                      <OverlayTrigger
                                        overlay={
                                          <Tooltip
                                            id="tooltip-top"
                                            placement="top"
                                          >
                                            Activity total hours
                                          </Tooltip>
                                        }
                                      >
                                        <Badge
                                          className={
                                            [
                                              hoursStyle[
                                                !colCellValue
                                                  ? 0
                                                  : colCellValue <= 25
                                                  ? 1
                                                  : 2
                                              ],
                                            ] +
                                            " rounded-0 py-1 px-2 bg-opacity-25"
                                          }
                                        >
                                          <h6 className="mb-0">
                                            {colCellValue || 0}
                                          </h6>
                                        </Badge>
                                      </OverlayTrigger>
                                    </span>
                                  </PhaseTotals>
                                </React.Fragment>
                              );
                            })}
                          </tr>
                          <Accordion.Collapse
                            className="p-0"
                            eventKey={subCol.id}
                          >
                            <>
                              {subCol.columns.map((subSubCol) => (
                                <tr key={subSubCol.id}>
                                  <td
                                    className={
                                      "border bg-white cell-heading-width align-middle cell-padding ps-4"
                                    }
                                  >
                                    {subSubCol.columnDef.header}
                                  </td>
                                  {table.getRowModel().rows.map((row) => {
                                    const colCell = row
                                      .getVisibleCells()
                                      .find(
                                        (cell) =>
                                          cell.column.id === subSubCol.id,
                                      );
                                    return (
                                      <React.Fragment key={row.id}>
                                        {colCell ? (
                                          flexRender(
                                            colCell.column.columnDef.cell,
                                            colCell.getContext(),
                                          )
                                        ) : (
                                          <td
                                            className={cellStyle}
                                            ref={(el) =>
                                              cellRefs.set(colCell.id, el)
                                            }
                                            onKeyDown={(e) =>
                                              hanldeCellToCellNavigation(
                                                e,
                                                row,
                                                colCell.column,
                                                table,
                                              )
                                            }
                                          ></td>
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                </tr>
                              ))}
                            </>
                          </Accordion.Collapse>
                        </Accordion>
                      );
                    })}
                  </>
                </Accordion.Collapse>
              </Accordion>
            );
          }
          if (col?.columnDef?.id === "contingency_label") {
            return (
              <div key={col.id}>
                <hr
                  className="m-0 mt-3 mb-2 p-0"
                  style={{
                    height: "2px",
                    border: "none",
                    color: "#333",
                    backgroundColor: "#333",
                  }}
                />
                {flexRender(col.columnDef.header, colHeader.getContext())}
              </div>
            );
          }
          if (col.id === "total" || col.id === "totalHours") {
            return (
              <Accordion flush key={col.id} defaultActiveKey={col.id}>
                <tr>
                  {flexRender(col.columnDef.header, colHeader.getContext())}
                  {table.getRowModel().rows.map((row) => {
                    const colCellValue = row.getValue(col.id);
                    return (
                      <AccordionButtonCell
                        className={
                          "border bg-tertiary cell-width align-middle text-end pe-1" +
                          getUnSavedRowStyles(row, col)
                        }
                        key={row.id}
                        eventKey={col.id}
                      >
                        <span className="fw-bold px-1">
                          {colCellValue || 0}
                        </span>
                      </AccordionButtonCell>
                    );
                  })}
                </tr>
                <Accordion.Collapse className="p-0" eventKey={col.id}>
                  <>
                    {col.columns.map((subCol) => {
                      const subColHeader = table
                        .getFlatHeaders()
                        .find((header) => header.column.id === subCol.id);
                      return (
                        <tr key={subCol.id}>
                          {flexRender(
                            subCol.columnDef.header,
                            subColHeader.getContext(),
                          )}
                          {table.getRowModel().rows.map((row) => {
                            const colCell = row
                              .getVisibleCells()
                              .find((cell) => cell.column.id === subCol.id);
                            return (
                              <React.Fragment key={row.id}>
                                {colCell ? (
                                  flexRender(
                                    colCell.column.columnDef.cell,
                                    colCell.getContext(),
                                  )
                                ) : (
                                  <td
                                    className={cellStyle}
                                    ref={(el) => cellRefs.set(colCell.id, el)}
                                    onKeyDown={(e) =>
                                      hanldeCellToCellNavigation(
                                        e,
                                        row,
                                        colCell.column,
                                        table,
                                      )
                                    }
                                  ></td>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </>
                </Accordion.Collapse>
              </Accordion>
            );
          }
          return (
            <tr key={col.id}>
              {flexRender(col.columnDef.header, colHeader.getContext())}
              {table.getRowModel().rows.map((row) => {
                const colCell = row
                  .getVisibleCells()
                  .find((cell) => cell.column.id === col.id);
                return (
                  <React.Fragment key={row.id}>
                    {colCell ? (
                      flexRender(
                        colCell.column.columnDef.cell,
                        colCell.getContext(),
                      )
                    ) : (
                      <td className={cellStyle}></td>
                    )}
                  </React.Fragment>
                );
              })}
              {col.id === "colTag" && (
                <AddGridColumn
                  handleClick={() => {
                    table.options.meta?.handleAddNewRow(data.length);
                  }}
                  costingType={costingTypesMap[costingType]}
                  message={
                    phasesData?.reduce(
                      (acc, curr) => acc + curr?.activities?.length,
                      0,
                    )
                      ? ""
                      : "(No Activity Available)"
                  }
                />
              )}
            </tr>
          );
        })}
      </div>
    </Table>
  );
}

export default React.memo(LabourEditableGrid);
LabourEditableGrid.propTypes = {
  refreshOpportunityCostingSummary: PropTypes.func.isRequired,
  showOverTimeHours: PropTypes.bool.isRequired,
};
