import React, { useState } from "react";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { postIds } from "utils/DeApi";

const BulkDeleteOpportunity = ({
  selectedOpportunities,
  onOpportunityDeleted,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handleDelete = () => {
    setIsLoading(true);
    const selectedIds = selectedOpportunities.map(
      (opportunity) => opportunity.id,
    );

    const opportunities = {
      opportunities: selectedIds,
    };

    postIds("/opportunities/bulk-delete", opportunities)
      .then(() => {
        onOpportunityDeleted && onOpportunityDeleted(selectedIds);
        setShow(false);
        window.location.reload();
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Button
        variant="outline-danger"
        className="ms-1"
        size="sm"
        onClick={() => {
          setShow(true);
        }}
      >
        Bulk Delete
      </Button>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Bulk Delete Opportunities</Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleDelete();
          }}
        >
          <Modal.Body>
            <p>Are you sure you want to delete the following opportunities?</p>
            <ul>
              {selectedOpportunities.map((opportunity) => (
                <li className="mb-2" key={opportunity.id}>
                  <span className="bg-danger bg-opacity-10 text-danger px-2">
                    {opportunity.title}
                  </span>
                </li>
              ))}
            </ul>
            {isLoading && <Spinner animation="border" />}
            {console.log(selectedOpportunities)}
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="link"
              type="button"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </Button>
            <Button size="sm" variant="danger" type="submit">
              Yes, I want to delete the opportunities
            </Button>
          </Modal.Footer>
        </Form>
        {error && <ErrorHandler error={error} />}
      </Modal>
    </>
  );
};

export default BulkDeleteOpportunity;
