import React, { useContext, useRef, useState, useEffect } from "react";
import * as yup from "yup";
import {
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
  Form,
  Alert,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { Formik } from "formik";
import PropTypes from "prop-types";

import { OpportunityContext } from "contexts/OpportunityProvider";
import { put, destroy } from "utils/DeApi";
import DropdownFieldFormik from "components/Inputs/DropdownFieldFormik";
import InputFieldFormik from "components/Inputs/InputFieldFormik";

import { useCallGetDeApi } from "hooks/useCallApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { post } from "utils/DeApi";

export default function EditOpportunityTCsModal({
  opportunityTCs: initialOpportunityTCs,
  handleClose,
}) {
  const opportunity = useContext(OpportunityContext);

  const subscribedPromises = useRef([]);

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [opportunityTCs, setOpportunityTCs] = useState(initialOpportunityTCs);

  useEffect(() => {
    setOpportunityTCs(initialOpportunityTCs);
  }, [initialOpportunityTCs]);

  const { data: TCData, isLoading: TCDataIsLoading } = useCallGetDeApi(
    "/technical-communities",
  );

  const schema = yup.object().shape({
    technicalCommunities: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        percentage: yup
          .number()
          .min(0, "Percentage allocation must be between 0 and 100")
          .max(100, "Percentage allocation must be between 0 and 100")
          .test("is-percentage-100", (value, context) => {
            const totalPercentage =
              context?.from?.[1]?.value?.technicalCommunities?.reduce(
                (total, tc) => total + Number.parseFloat(tc.percentage),
                0,
              );
            const prevValue = opportunityTCs?.find(
              (tc) => tc.id === context.parent.id,
            )?.percentage;
            if (totalPercentage > 100 && Number(prevValue) !== Number(value)) {
              return context.createError({
                message: `The sum of all percentages must equal 100; currently, it exceeds by ${(
                  totalPercentage - 100
                ).toFixed(2)}%.`,
              });
            } else {
              return true;
            }
          })
          .required("Percentage allocation is required"),
      }),
    ),
  });

  const addOpportunityTechnicalCommunity = (
    values,
    { setSubmitting, resetForm },
  ) => {
    setError(null);
    setIsLoading(true);

    const addOpportunityTechnicalCommunityPromise = put(
      `/opportunities/${opportunity?.id}/technical-communities/${values.technicalCommunityId}`,
      {
        percentage: values.percentage,
      },
    );
    addOpportunityTechnicalCommunityPromise.promise
      .then((response) => {
        setOpportunityTCs(response.data);
        resetForm();
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
        setSubmitting(false);
      });
    subscribedPromises.current.push(addOpportunityTechnicalCommunityPromise);
  };

  const updateOpportunityTCAllocation = (values, setSubmitting) => {
    setError(null);
    setIsLoading(true);

    const updateOpportunityTCPromise = post(
      `/opportunities/${opportunity?.id}/technical-communities`,
      {
        technicalCommunities: [...values?.technicalCommunities],
      },
    );
    updateOpportunityTCPromise.promise
      .then((response) => {
        setOpportunityTCs(response.data);
        handleClose();
        setShow(false);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
        setSubmitting(false);
      });
    subscribedPromises.current.push(updateOpportunityTCPromise);
  };

  const deleteOpportunityTC = (technicalCommunityId) => {
    setError(null);
    setIsLoading(true);

    const deleteOpportunityTCPromise = destroy(
      `/opportunities/${opportunity?.id}/technical-communities/${technicalCommunityId}`,
    );
    deleteOpportunityTCPromise.promise
      .then((response) => {
        setOpportunityTCs(response.data);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    subscribedPromises.current.push(deleteOpportunityTCPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip>Update Technical Communities Allocations</Tooltip>}
      >
        <Button
          variant={"outline-primary"}
          onClick={() => setShow(true)}
          className="text-nowrap"
          size="sm"
        >
          <span className="material-icons-outlined">edit</span>
        </Button>
      </OverlayTrigger>
      <Modal
        show={show}
        onHide={() => {
          handleClose();
          setShow(false);
        }}
        backdrop="static"
        size="lg"
        centered
      >
        <Formik
          validationSchema={schema}
          onSubmit={(values, { setSubmitting }) => {
            updateOpportunityTCAllocation(values, setSubmitting);
          }}
          initialValues={{
            technicalCommunities: [...(TCData || [])].map((tc) => {
              return {
                id: tc.id,
                percentage:
                  opportunityTCs?.find((val) => val.id === tc.id)?.percentage ||
                  0,
              };
            }),
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            isSubmitting,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title className="w-100">
                    <div className="d-flex justify-content-between align-items-center w-100 me-3 pe-3">
                      <div>Update Technical Communities Allocations</div>
                      <h6 className="text-muted fw-bold m-0">
                        Total allocation:{" "}
                        {Number(
                          values?.technicalCommunities?.reduce(
                            (total, tc) =>
                              total + Number.parseFloat(tc.percentage),
                            0,
                          ) || 0,
                        )?.toFixed(2)}
                        %
                      </h6>
                    </div>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div
                    style={{
                      maxHeight: "65vh",
                      overflowY: "auto",
                      paddingRight: 10,
                    }}
                  >
                    {values?.technicalCommunities?.map((tc, index) => (
                      <div className="d-flex align-items-top justify-content-between gap-2">
                        <InputFieldFormik
                          label={index > 0 ? "" : "Technical Community"}
                          value={
                            TCData?.find(
                              (val) =>
                                val.id ===
                                values?.technicalCommunities?.[index]?.id,
                            )?.title || 0
                          }
                          disabled
                          notShowValidSign={true}
                          className="w-75"
                          notShowLabel={index > 0 ? true : false}
                        />
                        <InputFieldFormik
                          name={`technicalCommunities[${index}].percentage`}
                          label={index > 0 ? "" : "Percentage Allocation"}
                          error={
                            errors?.technicalCommunities?.[index]?.percentage
                          }
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          placeholder="% (0-100)"
                          touched={
                            touched?.technicalCommunities?.[index]?.percentage
                          }
                          value={
                            values?.technicalCommunities?.[index]?.percentage
                          }
                          className="w-25"
                          notShowValidSign={true}
                          notShowLabel={index > 0 ? true : false}
                        />
                      </div>
                    ))}
                    {error ? <ErrorHandler error={error} /> : null}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <div>
                      {Number(
                        values?.technicalCommunities?.reduce(
                          (total, tc) =>
                            total + Number.parseFloat(tc.percentage),
                          0,
                        ) || 0,
                      ) >= 99.999 ? (
                        <Alert variant="info">
                          The entire allocated percentage has been utilized.
                        </Alert>
                      ) : null}
                    </div>

                    <div>
                      <Button
                        onClick={() => {
                          handleClose();
                          setShow(false);
                        }}
                        type="button"
                        variant="outline-secondary"
                      >
                        Close
                      </Button>
                      <Button type="submit" variant="primary ms-2">
                        Save
                      </Button>
                    </div>
                  </div>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
}

EditOpportunityTCsModal.propTypes = {
  opportunityTCs: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
};
