import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import { Card, Form, Button } from "react-bootstrap";
import NewOpportunity from "components/Opportunity/NewOpportunity/NewOpportunityModal";
import OpportunitiesTable from "./OpportunitiesTable";
import { useEffect, useRef, useState } from "react";
import { get } from "utils/DeApi";
import BulkDeleteOpportunity from "components/Opportunity/DeleteOpportunity/BulkDeleteOpportunities";
import DeleteOpportunity from "components/Opportunity/DeleteOpportunity/DeleteOpportunity";

const Opportunities = ({ setIsOpportunitiesUpdated }) => {
  const [searchTxt, setSearchTxt] = useState();
  const [opportunities, setOpportunities] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [meta, setMeta] = useState({ links: {} });
  const [selectedOpportunities, setSelectedOpportunities] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const subscribedPromises = useRef([]);
  const getOpportunities = () => {
    setIsLoading(true);
    setOpportunities(null);
    setError(null);

    const getPromise = get("/opportunities", {
      params: {
        limit: itemsPerPage,
        query: searchTxt,
        page,
        per_page: itemsPerPage,
      },
    });
    getPromise.promise
      .then(({ data, meta }) => {
        setMeta(meta);
        setOpportunities(data);
        setIsLoading(false);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(getPromise);
  };

  const onDeleteOpportunity = (id) => {
    getOpportunities();
    setIsOpportunitiesUpdated();
  };

  useEffect(() => {
    getOpportunities();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [page, itemsPerPage]);

  useEffect(() => {
    const debounceFunction = setTimeout(() => {
      getOpportunities();
    }, 500);

    return () => clearTimeout(debounceFunction);
  }, [searchTxt]);

  const toggleSelectAll = () => {
    const newSelectedOpportunities = selectAll
      ? []
      : opportunities.map((opportunity) => ({
          id: opportunity.id,
          title: opportunity.title,
        }));
    setSelectedOpportunities(newSelectedOpportunities);
    setSelectAll(!selectAll);
  };
  const toggleOpportunity = (id) => {
    setSelectedOpportunities((prevSelected) => {
      if (prevSelected.some((opportunity) => opportunity.id === id)) {
        return prevSelected.filter(
          (selectedOpportunity) => selectedOpportunity.id !== id,
        );
      } else {
        const opportunity = opportunities.find(
          (opportunity) => opportunity.id === id,
        );
        return [
          ...prevSelected,
          { id: opportunity.id, title: opportunity.title },
        ];
      }
    });
  };
  const handleDeleteSelectedOpportunities = () => {
    selectedOpportunities.forEach(({ id }) => {
      onDeleteOpportunity(id);
    });
    setSelectedOpportunities([]);
    setSelectAll(false);
  };
  return (
    <Card className="mt-3 mb-3">
      <Card.Body>
        {selectedOpportunities.length > 0 ? (
          <div className="d-flex  gap-2 float-end">
            <Button
              className="mb-0 text-dark"
              variant="outline-tertiary"
              onClick={() => {
                setSelectedOpportunities([]);
                setSelectAll(false);
              }}
            >
              <span className="material-icons-outlined text">close</span>
              <span className="ps-2">
                {selectedOpportunities.length} selected
              </span>
            </Button>
            {selectedOpportunities.length === 1 ? (
              <DeleteOpportunity
                opportunityId={selectedOpportunities[0].id}
                title={selectedOpportunities[0].title}
                onOpportunityDeleted={() =>
                  onDeleteOpportunity(selectedOpportunities[0].id)
                }
              />
            ) : (
              <BulkDeleteOpportunity
                selectedOpportunities={selectedOpportunities}
                setSelectedOpportunities={setSelectedOpportunities}
                onOpportunityDeleted={handleDeleteSelectedOpportunities}
              />
            )}
          </div>
        ) : (
          <div className="d-flex float-end gap-2">
            <Form.Control
              type="search"
              className="px-3"
              size="sm"
              placeholder="Search Opportunities"
              value={searchTxt}
              onChange={(e) => setSearchTxt(e.target.value)}
            />
            <div>
              <NewOpportunity />
            </div>
          </div>
        )}
        <h3>Recent Opportunities</h3>
        <hr />
        {isLoading && !opportunities ? (
          <Loader />
        ) : error ? (
          <ErrorHandler error={error} />
        ) : (
          <OpportunitiesTable
            opportunities={opportunities}
            onDeleteOpportunity={onDeleteOpportunity}
            page={page}
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            setPage={setPage}
            meta={meta}
            selectedOpportunities={selectedOpportunities}
            selectAll={selectAll}
            toggleOpportunity={toggleOpportunity}
            toggleSelectAll={toggleSelectAll}
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default Opportunities;
