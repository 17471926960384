import React, { useEffect, useRef, useState, useContext } from "react";
import { Modal, Button, Alert, Navbar, Form } from "react-bootstrap";
import "./Feedback.scss";
import { post } from "utils/BeeApi";

import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import { UserContext } from "contexts/UserProvider";

const Feedback = () => {
  const { user } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [body, setBody] = useState("");
  const [bodyError, setBodyError] = useState("");
  const subscribedPromises = useRef([]);

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  function handleCloseModal() {
    setShow(false);
    setSuccess(false);
    setError("");
    setBody("");
    setBodyError("");
  }

  function handleShowModal() {
    setShow(true);
  }

  function handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    setBody(value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    setError("");
    setIsLoading(true);
    setBodyError("");

    if (!body) {
      setIsLoading(false);
      setBodyError(true);
      return;
    }

    let message = `${body} \r\n \r\n Page URL: ${window.location.href}`;
    const contactPromise = post("/forms", {
      name: user && user.firstName,
      email: user && user.email,
      subject: `Feedback - ${
        window.location.pathname.split("/")[1] || "dashboard"
      }`,
      body: message,
    });

    contactPromise.promise
      .then((data) => {
        setIsLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
      });

    subscribedPromises.current.push(contactPromise);
  }

  const form = (
    <form className="FeedbackForm" onSubmit={handleSubmit} autoComplete="off">
      <Modal.Header closeButton>
        <Modal.Title>Send us your feedback!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Do you have a question, comment or suggestion? Let us know.</p>
        <Form.Group>
          <Form.Control
            as="textarea"
            rows={5}
            name="body"
            onChange={handleInputChange}
            value={body}
            isInvalid={bodyError ? "error" : null}
            placeholder="Describe your experience here..."
            required
          />
        </Form.Group>
        {error && <ErrorHandler error={error} />}
        {isLoading && <Loader error={isLoading} />}
        {success && (
          <Alert variant="success" className="mt-2 mb-2">
            <h4>Thanks</h4>
            <p>
              We appreciate that you’ve taken the time to help us improve PSI.
            </p>
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        {success ? (
          <Button variant="primary" onClick={handleCloseModal}>
            Close
          </Button>
        ) : (
          <span>
            <Button variant="link" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Submit
            </Button>
          </span>
        )}
      </Modal.Footer>
    </form>
  );

  return (
    <>
      <Navbar.Text className="Feedback me-3" onClick={handleShowModal}>
        <span className="material-icons-outlined">comment</span> Feedback
      </Navbar.Text>
      <Modal show={show} onHide={handleCloseModal} backdrop="static">
        {form}
      </Modal>
    </>
  );
};

export default Feedback;
