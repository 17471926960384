import { useContext } from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import { UserContext } from "contexts/UserProvider";

function NoAuthorizatrionHeader() {
  const { user } = useContext(UserContext);
  const logoLink = "/";

  return (
    <Navbar
      bg="white"
      variant="light"
      expand="lg"
      fixed="top"
      className="border-bottom MainHeader"
    >
      <Container fluid>
        <Navbar.Brand as={Link} to={logoLink}>
          <span className="pe-2">
            <img
              className="img-responsive"
              src="/logo-green.png"
              style={{ width: "170px" }}
              alt="Logo"
            />{" "}
          </span>
        </Navbar.Brand>
        <Navbar.Collapse>
          <Nav className="ms-auto">
            <NavDropdown align="end" title={`Hi, ${user?.firstName}`}>
              <NavDropdown.Item disabled>{user?.email}</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/logout">
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NoAuthorizatrionHeader;
