import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Button,
  Form,
  InputGroup,
  Spinner,
  Card,
} from "react-bootstrap";

import "./Login.scss";
import { login } from "utils/BeeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

import { login as loginDE } from "utils/DeApi";
import TextLogo from "../TextLogo/TextLogo";
import RedirectIfAuthenticated from "../RedirectIfAuthenticated/RedirectIfAuthenticated";
import { UserDispatchContext } from "contexts/UserProvider";
import { get } from "utils/BeeApi";
import { getRoutePath, checkAllowedModules } from "utils/RouteUtils";

/**
 * Handles logging in to the application.
 * Handles single sign on.
 */
const Login = () => {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [error, setError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { setUser, setModules } = useContext(UserDispatchContext);

  const navigate = useNavigate();

  function handleEmailChange(event) {
    let _email = event.target.value;
    setEmail(_email);
    setEmailValid(!!_email);
    setFormSubmitted(false);
  }

  function handlePasswordChange(event) {
    let password = event.target.value;
    setPassword(password);
    setPasswordValid(!!password.length);
    setFormSubmitted(false);
  }

  function toggleVisiblePassword() {
    setShowPassword(!showPassword);
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (emailValid && passwordValid) {
      setError("");
      setIsLoading(true);
      login({ email: email, password: password })
        .then((response) => {
          return loginDE(response.data);
        })
        .then((response) => {
          return get("user/anon").promise;
        })
        .then((response) => {
          setError(null);
          setIsLoading(false);
          setUser(response.data);
          const allowedModules = checkAllowedModules(
            response?.data?.categories,
          );
          setModules(allowedModules);
          const path = getRoutePath(response?.data?.categories);
          navigate(path);
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });
    }
  }

  function renderLoginForm() {
    return (
      <Form onSubmit={handleSubmit}>
        <div className="text-center">
          <h2 className="pre-title">Login to continue</h2>
        </div>
        <hr />
        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={handleEmailChange}
            isInvalid={!emailValid && formSubmitted}
            placeholder="Enter email"
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            This should be a valid email address
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              isInvalid={!passwordValid && formSubmitted}
              placeholder="Password"
            />
            <Button
              variant="light"
              className="border-top border-end border-start pt-0 border-bottom"
              onClick={toggleVisiblePassword}
            >
              {showPassword ? (
                <span className="material-icons-outlined">visibility_off</span>
              ) : (
                <span className="material-icons-outlined">visibility</span>
              )}
            </Button>
          </InputGroup>
          <Form.Control.Feedback type="invalid">
            This is required
          </Form.Control.Feedback>
        </Form.Group>

        {error && <ErrorHandler error={error} />}
        <div className="d-grid gap-2 mb-3">
          <Button type="submit" color="primary" disabled={isLoading}>
            {isLoading && (
              <Spinner
                className="me-2"
                animation="border"
                size="sm"
                variant="light"
              />
            )}
            Sign in
          </Button>
        </div>
        <p className="text-muted text-center mt-1 mb-1">
          <small>
            By continuing, you agree to our{" "}
            <a target="_blank" href="#/" rel="noreferrer">
              Terms of Service
            </a>{" "}
            and have read and acknowledge our{" "}
            <a target="_blank" href="#/" rel="noreferrer">
              Privacy Policy
            </a>
            .
          </small>
        </p>
        <hr />
        <p className="text-center">
          <Link to="/forgot-password">Forgot Password?</Link>
        </p>
      </Form>
    );
  }

  return (
    <Container className="Login mt-4 mb-4">
      <RedirectIfAuthenticated />
      <Row>
        <Col
          xl={{ span: 4, offset: 4 }}
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          sm={{ span: 8, offset: 2 }}
        >
          <TextLogo />
          <Card className="border-0 shadow-sm">
            <Card.Body>{renderLoginForm()}</Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
