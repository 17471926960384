import { useContext, useEffect } from "react";
import { OpportunityContext } from "contexts/OpportunityProvider";
import { BreadcrumbDispatchContext } from "contexts/OpportunityProvider";
import { ActiveTabDispatchContext } from "contexts/OpportunityProvider";
import Phases from "components/Phases/Phases";
import Loader from "components/Loader/Loader";
import PhasesAndActivitiesCostingChart from "components/OpportunitySummary/PhasesAndActivitiesCostingChart/PhasesAndActivitiesCostingChart";

const OpportunityWBS = () => {
  const opportunity = useContext(OpportunityContext);
  const setBreadcrumbs = useContext(BreadcrumbDispatchContext);
  const setActiveTab = useContext(ActiveTabDispatchContext);

  const { id, title } = opportunity;

  useEffect(() => {
    setBreadcrumbs([
      { name: "Dashboard", link: "/pricing" },
      { name: "Opportunities", active: false },
      { name: title, link: `/pricing/opportunities/${id}` },
      { name: "Work Breakdown", active: false },
    ]);
    setActiveTab("wbs");
  }, [setActiveTab, setBreadcrumbs, title, id]);

  if (Object.keys(opportunity).length === 0) return <Loader />;

  return (
    <>
      <Phases opportunityId={id} />
      {/* <PhasesAndActivitiesCostingChart /> */}
    </>
  );
};

export default OpportunityWBS;
