import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { convertToCurrency } from "utils/NumbersUtil";

import "./ProjectsProgress.scss";

const progressBarVariants = {
  success: "success",
  info: "info",
  warning: "warning",
  danger: "danger",
};

const ProjectsProgress = ({ data }) => {
  const getProgresBarVariant = (percentage = 0) => {
    if (percentage <= 25) {
      return progressBarVariants.success;
    } else if (percentage <= 50) {
      return progressBarVariants.info;
    } else if (percentage <= 75) {
      return progressBarVariants.warning;
    } else {
      return progressBarVariants.danger;
    }
  };

  const { projects } = data || {};

  const renderText = (budgetSpent = 0, percentage = 0) =>
    `${convertToCurrency(budgetSpent || 0)} (${percentage || 0}%)`;

  const renderProjectAtRisk = (project) => (
    <OverlayTrigger
      overlay={
        <Tooltip id="tooltip-top" placement="top">
          Project is at risk
        </Tooltip>
      }
    >
      <span className={`text-danger`}>{project?.name}</span>
    </OverlayTrigger>
  );

  return (
    <div className="project-progress-container">
      {projects?.map((project) => {
        return (
          <Row key={project.id} className="mb-4 px-2">
            <Col>
              <div className="d-flex justify-content-between mb-2">
                {project?.status === "critical"
                  ? renderProjectAtRisk(project)
                  : project?.name}
                <span>
                  {renderText(
                    project.budgetSpent,
                    project.budgetSpentPercentage,
                  )}
                </span>
              </div>
              <ProgressBar
                now={project.budgetSpentPercentage}
                variant={getProgresBarVariant(project.budgetSpentPercentage)}
              />
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default ProjectsProgress;
