import { useRef, useEffect } from "react";
import * as echarts from "echarts";

const LabourCharts = ({ data, title, dataKey, countKey }) => {
  const el = useRef(null);

  useEffect(() => {
    const seriedData = data
      ?.filter((level) => level?.[dataKey] > 0)
      ?.map((level) => {
        return {
          name: level.title,
          value: Math.round(level?.[dataKey] * 100) / 100,
          count: level?.[countKey],
        };
      });
    console.log(data);
    console.log(seriedData);
    const option = {
      title: {
        show: !seriedData.length,
        textStyle: {
          color: "grey",
          fontSize: 20,
        },
        text: "No data available",
        left: "center",
        top: "center",
      },
      tooltip: {
        trigger: "item",
        formatter: (params) => {
          return `${params?.data?.name} - ${
            countKey === "totalCost"
              ? Intl.NumberFormat("en-us", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "USD",
                }).format(params?.data?.count)
              : `${Number(params?.data?.count)} Hours`
          } (${Number(params?.data?.value)}%)`;
        },
      },
      toolbox: {
        feature: {
          saveAsImage: {
            name: title,
          },
        },
      },
      series: [
        {
          name: title,
          type: "pie",
          radius: "60%",
          data: seriedData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: el.current.offsetWidth,
    });

    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: el.current.offsetWidth / 1,
      });
    }
    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, data, title]);

  return <div ref={el}></div>;
};

export default LabourCharts;
