import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { get, put } from "utils/DeApi";

import TaskStats from "./TaskStats/TaskStats";
import AccrualResource from "./AccrualResource/AccrualResource";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const accrualResources = [
  { key: "labour", label: "Labour" },
  { key: "expense", label: "Expense" },
  { key: "equipment", label: "Equipment" },
  { key: "digitalProducts", label: "Digital Products" },
  { key: "labs", label: "Labs" },
  { key: "subContractors", label: "Subcontractors" },
];

const TaskAccrualDetail = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // update data states
  const [updteError, setUpdateError] = useState(null);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const subscribedPromises = useRef([]);

  const { projectId, phaseId, taskId } = useParams();

  const url = `/project-intel/projects/${projectId}/phases/${phaseId}/activities/${taskId}`;

  useEffect(() => {
    setIsLoading(true);
    const getPromise = get(url);
    getPromise.promise
      .then((response) => {
        setData({ ...response.data });
        setError(null);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    subscribedPromises.current.push(getPromise);
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [taskId, url]);

  const onChange = useCallback(
    (key, value) => {
      // dont allow to pass negative values
      if (value < 0) return;

      // updating state
      const clonedData = structuredClone(data);
      clonedData[key].accrual = value;
      setData(clonedData);
    },
    [data],
  );

  const updateAccruals = () => {
    const {
      labour,
      expense,
      equipment,
      labs,
      digitalProducts,
      subContractors,
    } = data;

    const payload = {
      expense_effort_accrual: Number(expense.accrual),
      labor_effort_accrual: Number(labour.accrual),
      subcontractors_effort_accrual: Number(subContractors.accrual),
      labs_efforts_accrual: Number(labs.accrual),
      digital_products_effort_accrual: Number(digitalProducts.accrual),
      equipment_effort_accrual: Number(equipment.accrual),
    };

    const getPromise = put(url, payload);
    setIsUpdateLoading(true);
    getPromise.promise
      .then((response) => {
        setData(response?.data);
        setError(null);
      })
      .catch((error) => {
        !error.isCanceled && setUpdateError(error);
      })
      .finally(() => {
        setIsUpdateLoading(false);
      });
  };

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  return (
    <Row className="gy-3">
      <TaskStats data={data} />
      <Col md={12} sm={12}>
        <Card>
          <Card.Body>
            <div className="d-flex justify-content-between">
              <span>Accrual</span>
              <span>Total: {data?.totalAccrual}</span>
            </div>
            <hr />
            {data && (
              <Row className="gy-3">
                {accrualResources?.map((resource) => {
                  return (
                    <Col sm={6} md={6} lg={6} key={resource.key}>
                      <AccrualResource
                        resource={resource}
                        data={data}
                        onChange={onChange}
                        isLoading={isUpdateLoading}
                      />
                    </Col>
                  );
                })}
              </Row>
            )}
          </Card.Body>
          <Card.Footer className="d-flex justify-content-end">
            {updteError && <ErrorHandler error={updteError} />}
            <Button onClick={updateAccruals} disabled={isUpdateLoading}>
              Update
            </Button>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
};

export default TaskAccrualDetail;
