import Alert from "react-bootstrap/Alert";

function NoAuthorizationTrackingPage() {
  return (
    <Alert variant="warning">
      This module is not currently assigned to you.
    </Alert>
  );
}

export default NoAuthorizationTrackingPage;
