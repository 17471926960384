export const getRoutePath = (categories) => {
  if (categories === undefined)
    throw new Error("Please provide categories arrays");

  if (categories.length === 0 || categories === null) return "/";

  const modules = categories?.reduce(
    (acc, curr) => {
      // if pricing assigned
      if (curr.categoryName === "Pricing") {
        acc.isPricingAllowed = true;
      }

      // if tracking assigned
      if (curr.categoryName === "Tracking") {
        acc.isTrackingAllowed = true;
      }

      return acc;
    },
    {
      isPricingAllowed: false,
      isTrackingAllowed: false,
    },
  );

  // if both modules are assigned, redirect to pricing
  if (modules.isPricingAllowed && modules.isTrackingAllowed) return "/pricing";

  // if pricing is assigned, redirect to pricing
  if (modules.isPricingAllowed) return "/pricing";

  // if tracking is assigned, redirect to tracking
  if (modules.isTrackingAllowed) return "/tracking";
};

export const checkAllowedModules = (categories) => {
  const modules = categories?.reduce(
    (acc, curr) => {
      // if pricing assigned
      if (curr.categoryName === "Pricing") {
        acc.pricing.isAllowed = true;
      }

      // if tracking assigned
      if (curr.categoryName === "Tracking") {
        acc.tracking.isAllowed = true;
      }

      return acc;
    },
    {
      pricing: { isAllowed: null },
      tracking: { isAllowed: null },
    },
  );

  return modules;
};
