import React, { useContext, useEffect } from "react";
import { useMutation, useQueries } from "@tanstack/react-query";
import {
  Card,
  OverlayTrigger,
  Tooltip,
  Accordion,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  Spinner,
  Row,
  Col,
  useAccordionButton,
} from "react-bootstrap";

import {
  BreadcrumbDispatchContext,
  ActiveTabDispatchContext,
  OpportunityContext,
  GovernanceExceptionsDispatchContext,
} from "contexts/OpportunityProvider";
import { get, put } from "utils/DeApi";

import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import "./OpportunityGovernance.scss";
import { Link } from "react-router-dom";

import {
  ResourceTypeEnumProxy,
  getBagdeVariant,
  ExceptionStatusEnum,
} from "./shared";

export default function OpportunityGovernance() {
  const opportunity = useContext(OpportunityContext);
  const setBreadcrumbs = useContext(BreadcrumbDispatchContext);
  const setActiveTab = useContext(ActiveTabDispatchContext);

  const { id, title } = opportunity;

  useEffect(() => {
    setBreadcrumbs([
      { name: "Dashboard", link: "/pricing" },
      { name: "Opportunities", active: "false" },
      { name: title, link: `/pricing/opportunities/${id}` },
      { name: "Governance", active: false },
    ]);
    setActiveTab("governance");
  }, [setBreadcrumbs, setActiveTab, title, id]);

  if (Object.keys(opportunity).length === 0) return <Loader />;
  return <OpportunityGovernanceSummary opportunityId={id} />;
}

const AccordionButtonCell = ({ eventKey, children, className, ...props }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    console.debug(eventKey);
  });

  return (
    <Col
      {...props}
      style={{
        cursor: "pointer",
      }}
      className={className}
      onClick={decoratedOnClick}
    >
      {children}
    </Col>
  );
};

const UnViolatedRuleItem = ({ rule }) => {
  return (
    <Row className="justify-content-between align-items-center py-3 px-2 my-2 border-bottom border-1 bg-gray text-gray bg-opacity-25">
      <Col className="fw-bold">{rule.name}</Col>
      <Col className="text-start fw-bold">{rule.description}</Col>
      <Col className="text-end text-nowrap">
        <OverlayTrigger overlay={<Tooltip>Rule is OK</Tooltip>}>
          <span
            className={`bg-success text-success bg-opacity-25 py-2 px-3 rounded-3 fw-bold`}
          >
            Rule OK
          </span>
        </OverlayTrigger>
      </Col>
    </Row>
  );
};

const ViolatedRuleItem = ({
  opportunityId,
  rule,
  exceptions,
  updateExceptionStatus,
}) => {
  const areAllExceptionsApproved = exceptions.every(
    (item) => item.status === ExceptionStatusEnum.APPROVED,
  );
  const colorStyle = areAllExceptionsApproved ? "success" : "danger";
  return (
    <Accordion flush defaultActiveKey={rule.id}>
      <Row className="justify-content-between align-items-center p-2 my-2 bg-gray text-gray bg-opacity-25">
        <AccordionButtonCell eventKey={rule.id} className="fw-bold">
          {rule.name}
          <span className="material-icons-outlined">expand_more</span>
        </AccordionButtonCell>
        <AccordionButtonCell eventKey={rule.id} className="text-start fw-bold">
          {rule.description}
        </AccordionButtonCell>
        <AccordionButtonCell
          eventKey={rule.id}
          className="text-end text-nowrap align-middle"
        >
          <OverlayTrigger
            overlay={
              <Tooltip>
                {areAllExceptionsApproved
                  ? "All exceptions are approved"
                  : "Rule violated, click to view exceptions"}
              </Tooltip>
            }
          >
            <span
              className={`bg-${colorStyle} text-${colorStyle} bg-opacity-25 py-2 px-3 rounded-3 fw-bold`}
            >
              {areAllExceptionsApproved
                ? "Exceptions Approved"
                : "Rule Violated"}
            </span>
          </OverlayTrigger>
        </AccordionButtonCell>
      </Row>
      <Accordion.Collapse eventKey={rule.id} key={rule.id} className="p-0">
        <>
          <Row className="justify-content-between p-2 my-2 border-bottom border-1">
            <Col className="text-start text-gray fw-bold">Name</Col>
            <Col className="text-start text-gray fw-bold">Link</Col>
            <Col className="text-start text-gray fw-bold">Approval Status</Col>
            <Col className="text-end text-gray fw-bold py-2 me-2">Actions</Col>
          </Row>
          {exceptions.map((exception) => (
            <Row
              key={exception.id}
              className="justify-content-between p-2 my-2 border-bottom border-1"
            >
              <Col className="text-start">
                {exception.resourceName || "n/a"}
              </Col>
              <Col className="text-start">
                <Link
                  to={`/opportunities/${opportunityId}${
                    ResourceTypeEnumProxy[exception.resourceType]
                      .pathToComponent
                  }`}
                  target="_blank"
                >
                  {ResourceTypeEnumProxy[exception.resourceType].title}
                  <span className="material-icons md-18">open_in_new</span>
                </Link>
              </Col>
              <Col className="text-start">
                {updateExceptionStatus.variables?.exceptionId ===
                  exception.id && updateExceptionStatus.isPending ? (
                  <Spinner size="sm" className="p-2" />
                ) : (
                  <span
                    className={`bg-${getBagdeVariant(
                      exception.status,
                    )} text-${getBagdeVariant(
                      exception.status,
                    )} me-2 bg-opacity-25 py-2 px-3 rounded-3 fw-bold`}
                  >
                    {exception.status.charAt(0).toUpperCase() +
                      exception.status.slice(1)}
                  </span>
                )}
              </Col>
              <Col className="text-end py-2 px-3">
                <DropdownButton
                  as={ButtonGroup}
                  title="Actions"
                  id="bg-nested-dropdown"
                  size="sm"
                  drop="down"
                >
                  <Dropdown.Item
                    eventKey="1"
                    disabled={
                      updateExceptionStatus.variables?.exceptionId ===
                        exception.id && updateExceptionStatus.isPending
                    }
                    onClick={() =>
                      updateExceptionStatus.mutateAsync({
                        exceptionId: exception.id,
                        status: ExceptionStatusEnum.APPROVED,
                      })
                    }
                  >
                    Approve
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="2"
                    disabled={
                      updateExceptionStatus.variables?.exceptionId ===
                        exception.id && updateExceptionStatus.isPending
                    }
                    onClick={() =>
                      updateExceptionStatus.mutateAsync({
                        exceptionId: exception.id,
                        status: ExceptionStatusEnum.IGNORED,
                      })
                    }
                  >
                    Ignore
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="3"
                    disabled={
                      updateExceptionStatus.variables?.exceptionId ===
                        exception.id && updateExceptionStatus.isPending
                    }
                    onClick={() =>
                      updateExceptionStatus.mutateAsync({
                        exceptionId: exception.id,
                        status: ExceptionStatusEnum.REJECTED,
                      })
                    }
                  >
                    Reject
                  </Dropdown.Item>
                </DropdownButton>
              </Col>
            </Row>
          ))}
        </>
      </Accordion.Collapse>
    </Accordion>
  );
};

function OpportunityGovernanceSummary({ opportunityId }) {
  const setGovernanceExceptions = useContext(
    GovernanceExceptionsDispatchContext,
  );

  const [rulesResults, exceptionsResults] = useQueries({
    queries: [
      {
        queryKey: [`governanceRules`, opportunityId],
        queryFn: () => get("/governance-rules").promise.then((res) => res.data),
      },
      {
        queryKey: [`governanceExceptions`, opportunityId],
        queryFn: () =>
          get(
            `/opportunities/${opportunityId}/governance-exceptions`,
          ).promise.then((res) => {
            setGovernanceExceptions(res.data);
            return res.data;
          }),
      },
    ],
  });

  const updateExceptionStatus = useMutation({
    mutationFn: ({ exceptionId, status }) =>
      put(
        `/opportunities/${opportunityId}/governance-exceptions/${exceptionId}`,
        {
          status,
        },
      ).promise,
    onSuccess: async (data, variables, context) => {
      await exceptionsResults.refetch();
    },
    throwOnError: false,
  });

  const governanceRules = rulesResults.data;
  const governanceExceptions = exceptionsResults.data;

  const isRuleInExceptions = (ruleId) => {
    return governanceExceptions?.some(
      (exception) => exception.rule?.id === ruleId,
    );
  };

  const isLoading =
    rulesResults.isLoading ||
    exceptionsResults.isLoading ||
    !rulesResults.data ||
    !exceptionsResults.data;
  const error = rulesResults.error || exceptionsResults.error;

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  return (
    <Card className="p-3">
      <Card.Body>
        <h5 className="text-gray">Governance Rules</h5>
        <hr />
        <div className="px-3 position-relative">
          <Row className="justify-content-between p-2 mb-2 border-bottom border-2">
            <Col className="fw-bold">Name</Col>
            <Col className="text-start fw-bold">Description</Col>
            <Col className="text-end fw-bold me-2">Status</Col>
          </Row>
          {governanceRules.map((rule) =>
            isRuleInExceptions(rule.id) ? (
              <ViolatedRuleItem
                opportunityId={opportunityId}
                rule={rule}
                key={rule.id}
                exceptions={
                  governanceExceptions.filter(
                    (exception) => exception.rule?.id === rule.id,
                  ) || []
                }
                updateExceptionStatus={updateExceptionStatus}
              />
            ) : (
              <UnViolatedRuleItem rule={rule} key={rule.id} />
            ),
          )}
        </div>
      </Card.Body>
    </Card>
  );
}
