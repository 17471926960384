import { useContext, useEffect } from "react";

import { OpportunityContext } from "contexts/OpportunityProvider";
import { BreadcrumbDispatchContext } from "contexts/OpportunityProvider";
import { ActiveTabDispatchContext } from "contexts/OpportunityProvider";
import { Button, Card } from "react-bootstrap";

const OpportunityPLAN = () => {
  const opportunity = useContext(OpportunityContext);
  const setBreadcrumbs = useContext(BreadcrumbDispatchContext);
  const setActiveTab = useContext(ActiveTabDispatchContext);

  useEffect(() => {
    setBreadcrumbs([
      { name: "Dashboard", link: "/pricing" },
      { name: "Opportunities", active: false },
      {
        name: opportunity.title,
        link: `/pricing/opportunities/${opportunity.id}`,
      },
      { name: "PLAN", active: false },
    ]);
    setActiveTab("plan");
  }, [setActiveTab, setBreadcrumbs, opportunity]);

  return (
    <Card>
      <Card.Body>
        <div className="d-flex float-end">
          <Button
            variant="outline-primary"
            // onClick={() => setShow(true)}
            className="px-3 text-nowrap"
            size="sm"
          >
            Create PLAN
          </Button>
        </div>
        <h4>Welcome to Project Liability ANalysis (or PLAN) ...</h4>
        <hr />
        <p>
          ERM's global program for identifying, reviewing, authorizing and
          controlling projects and opportunities presenting unusual types or
          levels of liability.
        </p>
        <p>
          If you are reading this page, it is most likely because you have been
          notified by your Regional PLAN Leader that your opportunity requires a
          PLAN review and you have been asked to retrieve this file from the
          PLAN page on Minerva. Here is a brief summary of the contents and the
          process for completing a PLAN review.
        </p>
        <p>
          The PLAN Process Flowchart depicts a summary of the process for
          completing a PLAN review as it directly affects the PIC/PM and BU MP,
          and guides you through the process of completing the review and
          obtaining appropriate approvals to proceed with a high liability
          opportunity or project.
        </p>
        <p>
          The PLAN Screening Page is where you will start your PLAN review by
          confirming that your opportunity or project meets one or more of the
          criteria that are used to identify higher liability projects and
          opportunities. Begin by checking the box for each applicable trigger.
          The General Information tab and Customized PLAN Questionnaire will
          both appear and be populated with information requests and questions
          relevant and appropriate to the criteria which you have selected on
          this page. If you are uncertain as to whether a trigger may be
          applicable, please select it.
        </p>
        <p>
          Next, fill out the General Information tab by addressing each item in
          the column entitled "Answer / Calculated Value" by either filling in
          the cell with the requested information or value, or selecting the
          most applicable answer from the drop down list (in the blue-shaded
          cells.) If the item includes a request for additional information,
          provide the requested information in the column entitled "Additional
          Info (if required)." Note that the bottom portion of this tab contains
          a series of auto-calculated values and does not require input from
          you.
        </p>
        <p>
          Finally, fill out the Customized PLAN Questionnaire by addressing each
          item in the column entitled "Question / Requested Information" by
          selecting the most applicable answer from the drop down list in the
          second column, entitled "Initial Risk Status". If the question
          includes a request for additional information, provide the requested
          information in the third column entitled "Partner/PM
          Explanations/Draft Mitigation Measures". [Please understand that,
          while some of the questions that automatically appear on the
          questionnaire may not seem directly relevant to your specific
          opportunity or project, the set of questions that are used in PLAN to
          solicit critical risk information have been fully vetted by the Global
          PLAN Team for all ERM services; they are there for good reason. Please
          answer all of them.]
        </p>
      </Card.Body>
    </Card>
  );
};

export default OpportunityPLAN;
