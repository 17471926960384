import { useEffect, useState } from "react";
import useLocalStorage from "hooks/useLocalStorage";
import { getColumnHeader } from "utils/StringUtils";

export default function useOrderCostingData(
  opportunityId,
  unorderedCostingData,
  costingType,
) {
  const [costingsColumnOrder, setCostingsColumnOrder] = useLocalStorage(
    opportunityId,
    {
      labour: [],
      equipments: [],
      digitalEquipment: [],
      labTests: [],
      expenses: [],
      subContractors: [],
    },
  );

  const [orderedCostingData, setOrderedCostingData] = useState([]);

  const isValidCostingType = (type) =>
    type === "labour" ||
    type === "equipments" ||
    type === "digitalEquipment" ||
    type === "labTests" ||
    type === "expenses" ||
    type === "subContractors";

  useEffect(
    function orderCostingData() {
      if (!isValidCostingType(costingType)) {
        throw new Error("Invalid costing type: " + costingType);
      }

      let costingDataOrder = costingsColumnOrder[costingType];

      const orderByKey = costingType === "subContractors" ? "name" : "id";

      const _unorderedCostingData = [...unorderedCostingData];
      const _orderedCostingData = _unorderedCostingData.map((item) => {
        item.order = costingDataOrder.findIndex(
          (key) => key === item[orderByKey],
        );
        if (item.order === -1) {
          // if order does not exist
          if (item.orderIndex > -1) {
            // and orderIndex exists, add the item at the orderIndex
            // move all costing items order after the orderIndex by 1
            _unorderedCostingData.forEach((costingItem) => {
              if (costingItem.order >= item.orderIndex) {
                costingItem.order = costingItem.order + 1;
              }
            });

            costingDataOrder.splice(item.orderIndex, 0, item[orderByKey]);
            item.order = item.orderIndex;
            return item;
          }
          // if orderIndex does not exist, add the item to the end
          costingDataOrder.push(item[orderByKey]);

          item.order = costingDataOrder?.findIndex(
            (key) => key === item[orderByKey],
          );
          return item;
        }
        item.order = costingDataOrder?.findIndex(
          (key) => key === item[orderByKey],
        ); // make sure the order is updated
        return item;
      });

      // make sure the order is updated, remove any keys that are not present in the final array
      costingDataOrder = costingDataOrder.filter((key) => {
        return (
          _orderedCostingData.findIndex((item) => item[orderByKey] === key) !==
          -1
        );
      });

      setCostingsColumnOrder((prev) => ({
        ...prev,
        [costingType]: costingDataOrder,
      }));

      // now sort the data based on the order
      _orderedCostingData
        .sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0))
        .forEach((item, index) => (item.colTag = getColumnHeader(index + 2)));

      setOrderedCostingData(_orderedCostingData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [costingType, unorderedCostingData],
  );

  return orderedCostingData;
}
