import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";
import { Formik } from "formik";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { put } from "utils/DeApi";
import * as yup from "yup";

const UpdateActivity = ({ activityId, refreshList, title }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const schema = yup.object().shape({
    name: yup.string().required("Activity name is required."),
  });

  const handleUpdate = ({ name }) => {
    setIsLoading(true);
    const updateActivityPromise = put(`/activities/${activityId}`, {
      title: name.replace(/ +/g, " ").trim(),
      description: "",
    });

    updateActivityPromise.promise
      .then((response) => {
        setShow(false);
        refreshList();
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Button
        size="sm"
        className="px-2 py-0 position-relative me-2"
        style={{ zIndex: 20 }}
        variant="outline-primary"
        onClick={(event) => {
          event.stopPropagation();
          setShow(true);
        }}
      >
        <span className="material-icons-outlined md-18">edit</span>
      </Button>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Activity</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          initialValues={{ name: title }}
          onSubmit={(values) => handleUpdate(values)}
          validateOnMount
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            values,
            isValid,
            errors,
            touched,
          }) => (
            <Form>
              <Modal.Body>
                <Form.Group controlId="name" className="mt-3 mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={values.name}
                    placeholder="Enter activity's name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={values.name && !errors.name}
                    isInvalid={errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                {isLoading && <Loader />}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  size="sm"
                  variant="link"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  onClick={handleSubmit}
                  variant="primary"
                  type="button"
                  disabled={!isValid || isLoading}
                >
                  Update
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
        {error && <ErrorHandler error={error} />}
      </Modal>
    </>
  );
};

export default UpdateActivity;
