import * as echarts from "echarts";
import { useEffect, useRef } from "react";

const SummaryPieChart = ({ values, phaseTitles }) => {
  const el = useRef(null);
  const colors = [
    "#0A2B14",
    "#019A20",
    "#00FFBC",
    "#446D5D",
    "#B7B2AA",
    "#82A78D",
  ];

  useEffect(() => {
    const data = values.map((value, index) => ({
      value: value,
      name: phaseTitles[index] || "Phase",
      label: { show: value !== 0 },
    }));
    const option = {
      color: colors,
      tooltip: {
        trigger: "item",
        formatter: "{b}<br/> {d}%",
      },
      toolbox: {
        feature: {
          saveAsImage: { name: `Overall cost of phases` },
        },
      },
      legend: {
        orient: "horizontal",
        left: "center",
      },
      grid: {
        left: "32%",
        right: "15%",
        top: "3%",
        bottom: "3%",
      },
      series: [
        {
          name: "Total cost per phase",
          type: "pie",
          radius: "50%",
          center: ["50%", "50%"],
          data: data,
          label: {
            show: true,
            formatter: "{b}  - ${c}",
          },
        },
      ],
    };

    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: 300,
    });
    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [values]);

  return <div className="chart-container" ref={el} />;
};
export default SummaryPieChart;
