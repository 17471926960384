import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

const DropdownFieldFormik = ({
  handleChange,
  value,
  placeholder,
  handleBlur,
  label,
  name,
  error,
  touched,
  data,
  textKey,
  isLoading,
  disabled,
  className,
  helpNote,
  notShowValidSign,
  notShowLabel,
}) => {
  const isValid = value && !error ? true : false;
  return (
    <Form.Group controlId={name} className={`mb-3 ${className}`}>
      {!notShowLabel ? <Form.Label className="mb-1">{label}</Form.Label> : null}
      <Form.Select
        name={name}
        placeholder={placeholder}
        value={value || ""}
        onChange={handleChange}
        onBlur={handleBlur}
        isInvalid={touched && error}
        disabled={disabled}
        {...(!notShowValidSign && { isValid })}
      >
        <option value={""} disabled>
          {isLoading ? "Loading" : placeholder}
        </option>
        {Array.isArray(data) &&
          data.map((item) => {
            let { id } = item;
            return (
              <option key={id} value={id}>
                {item[textKey]}
              </option>
            );
          })}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {error && touched ? <small>{error}</small> : null}
      </Form.Control.Feedback>
      <Form.Control.Feedback type="valid" className="text-danger">
        {helpNote && !error ? <small>{helpNote}</small> : null}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

DropdownFieldFormik.defaultProps = {
  value: "",
  error: "",
  touched: false,
  isLoading: false,
  disabled: false,
};

DropdownFieldFormik.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  touched: PropTypes.bool,
  data: PropTypes.array.isRequired,
  textKey: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DropdownFieldFormik;
