import AddActivity from "components/Phases/Activity/AddActivity";
import DeleteActivity from "components/Phases/Activity/DeleteActivity";
import UpdateActivity from "components/Phases/Activity/UpdateActivity";
import DeletePhase from "components/Phases/DeletePhase";
import UpdatePhase from "components/Phases/UpdatePhase";
import { useState, useEffect, useRef } from "react";
import { Accordion, Badge, useAccordionButton } from "react-bootstrap";

const SingleActivity = ({
  id,
  activity,
  refreshList,
  showOvertime,
  titleRef,
  labourRef,
  expensesRef,
  equipmentRef,
  digitalProductsRef,
  labsRef,
  subcontractorsRef,
  activitiesRef,
  actionRef,
}) => {
  let { title, summary } = activity;

  const {
    digitalEquipment,
    equipment,
    labor,
    expenses,
    labTests,
    subcontractors,
  } = summary;

  const titleActivityRef = useRef(null);
  const labourActivityRef = useRef(null);
  const expensesActivityRef = useRef(null);
  const equipmentActivityRef = useRef(null);
  const digitalProductsActivityRef = useRef(null);
  const labsActivityRef = useRef(null);
  const subcontractorsActivityRef = useRef(null);
  const activitiesActivityRef = useRef(null);
  const actionActivityRef = useRef(null);

  useEffect(() => {
    const updateHeaderWidth = (ref, headerRef) => {
      if (ref.current && headerRef.current) {
        const cellWidth = Math.max(
          ref.current.getBoundingClientRect().width,
          headerRef.current.getBoundingClientRect().width,
        );
        headerRef.current.style.width = cellWidth + "px";
        ref.current.style.width = cellWidth + "px";
      }
    };
    updateHeaderWidth(titleActivityRef, titleRef);
    updateHeaderWidth(labourActivityRef, labourRef);
    updateHeaderWidth(expensesActivityRef, expensesRef);
    updateHeaderWidth(equipmentActivityRef, equipmentRef);
    updateHeaderWidth(digitalProductsActivityRef, digitalProductsRef);
    updateHeaderWidth(labsActivityRef, labsRef);
    updateHeaderWidth(subcontractorsActivityRef, subcontractorsRef);
    updateHeaderWidth(activitiesActivityRef, activitiesRef);
    updateHeaderWidth(actionActivityRef, actionRef);
  }, []);
  return (
    <div className="mx-0 px-0 py-2 my-2 d-flex border-bottom border-1">
      <div ref={titleActivityRef}>
        <span className="d-flex justify-content-start align-items-center">
          <span className="ms-2">{title.replace(/ +/g, " ").trim()}</span>
        </span>
      </div>
      <div ref={labourActivityRef}>
        <span className="d-flex justify-content-start align-items-center my-auto">
          <Badge className="bg-white rounded-0 py-1 px-2 bg-opacity-10 text-dark">
            <h6 className="mb-0">
              {Intl.NumberFormat("en-us", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: "USD",
              }).format(
                labor.totalCost + (showOvertime ? labor.totalOvertimeCost : 0),
              )}
            </h6>
          </Badge>
        </span>
      </div>
      <div ref={expensesActivityRef}>
        <span className="d-flex justify-content-start align-items-center my-auto">
          <Badge className="bg-white rounded-0 py-1 px-2 bg-opacity-10 text-dark">
            <h6 className="mb-0">
              {Intl.NumberFormat("en-us", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: "USD",
              }).format(expenses.totalCost)}
            </h6>
          </Badge>
        </span>
      </div>
      <div ref={equipmentActivityRef}>
        <span className="d-flex justify-content-start align-items-center my-auto">
          <Badge className="bg-white rounded-0 py-1 px-2 bg-opacity-10 text-dark">
            <h6 className="mb-0">
              {Intl.NumberFormat("en-us", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: "USD",
              }).format(equipment.totalCost)}
            </h6>
          </Badge>
        </span>
      </div>
      <div ref={digitalProductsActivityRef}>
        <span className="d-flex justify-content-start align-items-center my-auto">
          <Badge className="bg-white rounded-0 py-1 px-2 bg-opacity-10 text-dark">
            <h6 className="mb-0">
              {Intl.NumberFormat("en-us", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: "USD",
              }).format(digitalEquipment.totalCost)}
            </h6>
          </Badge>
        </span>
      </div>
      <div ref={labsActivityRef}>
        <span className="d-flex justify-content-start align-items-center my-auto">
          <Badge className="bg-white rounded-0 py-1 px-2 bg-opacity-10 text-dark">
            <h6 className="mb-0">
              {Intl.NumberFormat("en-us", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: "USD",
              }).format(labTests.totalCost)}
            </h6>
          </Badge>
        </span>
      </div>
      <div ref={subcontractorsActivityRef}>
        <span className="d-flex justify-content-start align-items-center my-auto">
          <Badge className="bg-white rounded-0 py-1 px-2 bg-opacity-10 text-dark">
            <h6 className="mb-0">
              {Intl.NumberFormat("en-us", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: "USD",
              }).format(subcontractors.totalCost)}
            </h6>
          </Badge>
        </span>
      </div>
      <div ref={activitiesActivityRef}></div>
      <div ref={actionActivityRef}>
        <div className="d-flex flex-row justify-content-start align-items-center gap-2">
          <div className="d-flex">
            <UpdateActivity
              activityId={id}
              refreshList={refreshList}
              title={title.replace(/ +/g, " ").trim()}
            />
            <DeleteActivity
              activityId={id}
              refreshList={refreshList}
              title={title.replace(/ +/g, " ").trim()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const SinglePhasesAndActivitiesCostingChart = ({
  phase,
  index,
  refreshList,
  showOvertime,
  titleHeaderRef,
  labourHeaderRef,
  expensesHeaderRef,
  equipmentHeaderRef,
  digitalProductsHeaderRef,
  labsHeaderRef,
  subcontractorsHeaderRef,
  activitiesHeaderRef,
  actionHeaderRef,
}) => {
  let { id, activities, summary, title } = phase;

  const {
    digitalEquipment,
    equipment,
    labor,
    expenses,
    labTests,
    subcontractors,
  } = summary;
  const [isShow, setIsShow] = useState(false);
  const [isShowActivities, setIsShowActivities] = useState(false);

  const titleRef = useRef(null);
  const labourRef = useRef(null);
  const expensesRef = useRef(null);
  const equipmentRef = useRef(null);
  const digitalProductsRef = useRef(null);
  const labsRef = useRef(null);
  const subcontractorsRef = useRef(null);
  const activitiesRef = useRef(null);
  const actionRef = useRef(null);

  useEffect(() => {
    const updateHeaderWidth = (ref, headerRef) => {
      if (ref.current && headerRef.current) {
        const cellWidth = Math.max(
          ref.current.getBoundingClientRect().width,
          headerRef.current.getBoundingClientRect().width,
        );
        headerRef.current.style.width = cellWidth + "px";
        ref.current.style.width = cellWidth + "px";
      }
    };
    updateHeaderWidth(titleRef, titleHeaderRef);
    updateHeaderWidth(labourRef, labourHeaderRef);
    updateHeaderWidth(expensesRef, expensesHeaderRef);
    updateHeaderWidth(equipmentRef, equipmentHeaderRef);
    updateHeaderWidth(digitalProductsRef, digitalProductsHeaderRef);
    updateHeaderWidth(labsRef, labsHeaderRef);
    updateHeaderWidth(subcontractorsRef, subcontractorsHeaderRef);
    updateHeaderWidth(activitiesRef, activitiesHeaderRef);
    updateHeaderWidth(actionRef, actionHeaderRef);
  }, [
    titleHeaderRef,
    labourHeaderRef,
    expensesHeaderRef,
    equipmentHeaderRef,
    digitalProductsHeaderRef,
    labsHeaderRef,
    subcontractorsHeaderRef,
    activitiesHeaderRef,
    actionHeaderRef,
  ]);

  const toggleShowActivities = () => {
    setIsShowActivities((prevState) => !prevState);
  };

  const CustomToggle = ({ eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      setIsShow(!isShow);
    });

    return (
      <div
        className="d-flex justify-content-start align-items-center cursor-pointer"
        onClick={decoratedOnClick}
      >
        <span>{`${index + 1}`}.</span>
        <span className="ms-2 fs-6">
          {title.replace(/ +/g, " ").trim()}
        </span>{" "}
        <span className="material-icons-outlined">expand_more</span>
      </div>
    );
  };

  return (
    <tbody>
      <tr key={id} className="align-middle">
        <td ref={titleRef} onClick={toggleShowActivities}>
          <CustomToggle eventKey={id} />
        </td>
        <td ref={labourRef}>
          <span className="d-flex justify-content-start align-items-center my-auto">
            <Badge className="bg-success rounded-0 py-1 px-2 bg-opacity-10 text-primary">
              <h6 className="mb-0">
                {Intl.NumberFormat("en-us", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "USD",
                }).format(
                  labor.totalCost +
                    (showOvertime ? labor.totalOvertimeCost : 0),
                )}
              </h6>
            </Badge>
          </span>
        </td>
        <td ref={expensesRef}>
          <span className="d-flex justify-content-start align-items-center my-auto">
            <Badge className="bg-success rounded-0 py-1 px-2 bg-opacity-10 text-success">
              <h6 className="mb-0">
                {Intl.NumberFormat("en-us", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "USD",
                }).format(expenses.totalCost)}
              </h6>
            </Badge>
          </span>
        </td>
        <td ref={equipmentRef}>
          <span className="d-flex justify-content-start align-items-center my-auto">
            <Badge className="bg-success rounded-0 py-1 px-2 bg-opacity-10 text-success">
              <h6 className="mb-0">
                {Intl.NumberFormat("en-us", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "USD",
                }).format(equipment.totalCost)}
              </h6>
            </Badge>
          </span>
        </td>
        <td ref={digitalProductsRef}>
          <span className="d-flex justify-content-start align-items-center my-auto">
            <Badge className="bg-success rounded-0 py-1 px-2 bg-opacity-10 text-success">
              <h6 className="mb-0">
                {Intl.NumberFormat("en-us", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "USD",
                }).format(digitalEquipment.totalCost)}
              </h6>
            </Badge>
          </span>
        </td>
        <td ref={labsRef}>
          <span className="d-flex justify-content-start align-items-center my-auto">
            <Badge className="bg-success rounded-0 py-1 px-2 bg-opacity-10 text-success">
              <h6 className="mb-0">
                {Intl.NumberFormat("en-us", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "USD",
                }).format(labTests.totalCost)}
              </h6>
            </Badge>
          </span>
        </td>
        <td ref={subcontractorsRef}>
          <span className="d-flex justify-content-start align-items-center my-auto">
            <Badge className="bg-success rounded-0 py-1 px-2 bg-opacity-10 text-success">
              <h6 className="mb-0">
                {Intl.NumberFormat("en-us", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "USD",
                }).format(subcontractors.totalCost)}
              </h6>
            </Badge>
          </span>
        </td>
        <td ref={activitiesRef}>
          <span
            onClick={useAccordionButton(index)}
            className="cursor-pointer d-flex justify-content-start"
          >
            {activities.length > 1
              ? `${activities.length} Activities`
              : `${activities.length} Activity`}
          </span>
        </td>
        <td ref={actionRef}>
          <div className="d-flex flex-row justify-content-start align-items-center gap-2">
            <div className="d-flex">
              <UpdatePhase
                phaseId={id}
                refreshList={refreshList}
                title={title.replace(/ +/g, " ").trim()}
              />
              <DeletePhase
                phaseId={id}
                refreshList={refreshList}
                title={title.replace(/ +/g, " ").trim()}
              />
            </div>
          </div>
        </td>
      </tr>
      {isShowActivities && (
        <tr key={`${id}-activities`}>
          <td colSpan="9" className="p-0">
            <Accordion className="my-2 mx-0 p-0" flush>
              <>
                {activities.map((activity, activityIndex) => {
                  let { id } = activity;
                  return (
                    <SingleActivity
                      key={id}
                      id={id}
                      activity={activity}
                      parentIndex={index}
                      index={activityIndex}
                      refreshList={refreshList}
                      showOvertime={showOvertime}
                      titleRef={titleRef}
                      labourRef={labourRef}
                      expensesRef={expensesRef}
                      equipmentRef={equipmentRef}
                      digitalProductsRef={digitalProductsRef}
                      labsRef={labsRef}
                      subcontractorsRef={subcontractorsRef}
                      activitiesRef={activitiesRef}
                      actionRef={actionRef}
                    />
                  );
                })}
                <div className="text-end py-1 border-0 me-1">
                  <AddActivity
                    phaseId={id}
                    updateActivitiesData={refreshList}
                  />
                </div>
              </>
            </Accordion>
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default SinglePhasesAndActivitiesCostingChart;
