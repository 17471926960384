const newLabour = {
  id: "new",
  employeeRoleId: "",
  appliedRateSchedule: 1,
  careerLevel: "",
  discountType: 2,
  contingencyType: 1,
  contingencyAmount: 0,
  contingencyPercentage: 0,
  discountAmount: 0,
  discountPercentage: 0,
  workingOrg: "",
  country: { id: "", name: "", code: "" },
  employeeNumber: "",
  employeePayUplift: 0,
  labourAdjustmentPercentage: 0,
  labourAdjustmentValue: 0,
  finalRate: "",
  staffRole: "",
  _id: "new", // for logically detecting if a row has not been saved to DB
  localCost: 0,
  atCurrencyExchangeRate: "",
  localCurrency: "USD",
  name: "",
  total: 0,
  totalOvertime: 0,
  totalOvertimeHours: 0,
  overtimeHours: 0,
  regularHours: 0,
  totalHours: 0,
  workingOrgId: "",
  taxVat: 0,
  taxWht: 0,
};

const newExpense = {
  description: "",
  id: "new",
  _id: "new",
  localCost: 0,
  name: "",
  qty: 0,
  reimbursable: 1,
  markUp: 0,
  totalMarkUp: 0,
  taxVat: 0,
  taxWht: 0,
  total: 0,
};

const newEquipment = {
  description: "",
  equipmentCategory: { id: "", title: "" },
  id: "new",
  _id: "new",
  localCost: 0,
  qty: 0,
  reimbursable: 1,
  taxVat: 0,
  taxWht: 0,
  total: 0,
};

const newLabTest = {
  lab: { id: "", title: "" },
  id: "new",
  _id: "new",
  localCost: 0,
  markUp: 0,
  totalMarkUp: 0,
  localCurrency: "USD",
  qty: 0,
  taxVat: 0,
  taxWht: 0,
  total: 0,
};

const newSubContractor = {
  id: "new",
  _id: "new",
  name: "",
  localCost: 0,
  markUp: 0,
  totalMarkUp: 0,
  localCurrency: "USD",
  qty: 0,
  taxVat: 0,
  taxWht: 0,
  total: 0,
};

const newCostingResourceMap = {
  labour: newLabour,
  digitalEquipment: newEquipment,
  equipmentAndSupplies: newEquipment,
  labTests: newLabTest,
  expenses: newExpense,
  subContractors: newSubContractor,
};

export {
  newCostingResourceMap,
  newLabour,
  newExpense,
  newEquipment,
  newLabTest,
  newSubContractor,
};
