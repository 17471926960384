import { useRef, useEffect } from "react";
import * as echarts from "echarts";

const ProjectPieChart = ({ data }) => {
  const el = useRef(null);

  const { projectTotalBudget = 0, projectSpentBudget = 0 } = data || {};

  useEffect(() => {
    const option = {
      tooltip: {
        trigger: "item",
      },
      // legend: {
      //   orient: "horizontal",
      //   left: "center",
      // },
      toolbox: {
        feature: {
          saveAsImage: {
            name: `Distribution by Budget`,
          },
        },
      },
      series: [
        {
          name: "Total and Spent Budget",
          type: "pie",
          radius: "60%",
          data: [
            { value: projectTotalBudget, name: "Project Total Budget" },
            { value: projectSpentBudget, name: "Project Spent Budget" },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };

    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: el.current.offsetWidth,
    });

    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: el.current.offsetWidth / 1,
      });
    }
    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, projectTotalBudget, projectSpentBudget]);

  return <div ref={el}></div>;
};

export default ProjectPieChart;
