import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Alert from "react-bootstrap/Alert";

import { useState, useRef, useEffect } from "react";

const PhasesDropdown = ({
  setPage,
  selectedPhase,
  setSelectedPhase,
  phases,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [filteredPhases, setFilteredPhases] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const dropdownRef = useRef();
  const inputRef = useRef();

  const handleAwayClick = (e) => {
    if (
      dropdownRef?.current?.contains(e?.target) ||
      inputRef?.current?.contains(e?.target)
    ) {
      return;
    }

    setIsVisible(false);
  };

  const handleFocus = (event) => {
    const searchText = event.target.value.toLowerCase();
    const filtered = phases?.filter((phase) =>
      phase?.name?.toLowerCase().includes(searchText),
    );
    filtered?.sort((a, b) => a?.name?.localeCompare(b?.name));
    setFilteredPhases(filtered);
    setIsVisible(true);
  };

  const handleChange = (event) => {
    const searchText = event.target.value;
    setSearchValue(searchText);
  };

  const handleDropdownItemClick = (singlePhase) => {
    setSelectedPhase(singlePhase);
    setSearchValue(singlePhase?.name);
    setIsVisible(false);
  };

  const resetSelection = () => {
    setPage(1);
    setSelectedPhase("");
    setSearchValue("");
  };

  useEffect(() => {
    const lowerCaseSearch = searchValue.toLowerCase();
    const filtered = phases?.filter((phase) =>
      phase?.name?.toLowerCase().includes(lowerCaseSearch),
    );
    filtered?.sort((a, b) => a?.name?.localeCompare(b?.name));
    setFilteredPhases(filtered);
  }, [searchValue, phases]);

  useEffect(() => {
    const clonedPhases = structuredClone(phases);
    const sortedPhases = clonedPhases?.sort((a, b) =>
      a?.name?.localeCompare(b?.name),
    );

    setFilteredPhases(sortedPhases);
  }, [phases]);

  useEffect(() => {
    document.addEventListener("mousedown", handleAwayClick, false);
    return () =>
      document.removeEventListener("mousedown", handleAwayClick, false);
  }, []);

  return (
    <>
      <div className="d-flex align-items-center">
        <Form
          className="d-block"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Form.Group>
            <InputGroup style={{ width: 400 }} ref={inputRef}>
              <FormControl
                type="text"
                placeholder="Filter by Phases"
                onChange={handleChange}
                onFocus={handleFocus}
                value={searchValue}
              />
              <InputGroup.Text>
                <span className="material-icons-outlined">
                  keyboard_arrow_down
                </span>
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Form>
        <Button
          className="ms-2"
          onClick={resetSelection}
          disabled={selectedPhase === ""}
        >
          Reset Selection
        </Button>
      </div>
      {isVisible && (
        <div
          style={{
            position: "absolute",
            zIndex: 9998,
          }}
          ref={dropdownRef}
        >
          <Dropdown show={true} ref={dropdownRef} className="mt-2">
            <Dropdown.Menu
              style={{
                width: 400,
                maxHeight: 400,
                overflowY: "auto",
              }}
            >
              {filteredPhases.length === 0 && (
                <Alert className="mx-2" variant="warning">
                  No data found
                </Alert>
              )}
              {filteredPhases?.map((phase) => (
                <Dropdown.Item
                  onClick={() => handleDropdownItemClick(phase)}
                  key={phase?.id}
                >
                  {phase.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </>
  );
};

export default PhasesDropdown;
