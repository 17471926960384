import React, { createContext } from "react";
import useActionItems from "hooks/useActionItems";
import useModules from "hooks/useModules";

const ActionItemsContext = createContext(undefined);

function ActionItemsProvider({ children }) {
  const { currentModule, modules } = useModules();

  const isTrackingModule = currentModule.value === modules.tracking.value;
  const {
    actionItems: userActionItems,
    isActionItemsLoading,
    refetchActionItems,
  } = useActionItems(isTrackingModule);

  return (
    <ActionItemsContext.Provider
      value={{ userActionItems, isActionItemsLoading, refetchActionItems }}
    >
      {children}
    </ActionItemsContext.Provider>
  );
}

export { ActionItemsProvider, ActionItemsContext };
