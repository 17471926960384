import Button from "react-bootstrap/Button";

import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const EditText = ({
  isActivity,
  updateTitle,
  handleEditItem,
  currentEditItem,
  handleEditItemChange,
}) => {
  return (
    <InputGroup className="mt-2">
      <Form.Control
        aria-label="Edit text input with buttons"
        type="text"
        value={currentEditItem?.title}
        onChange={handleEditItemChange}
      />

      <Button
        variant="outline-primary"
        size="sm"
        onClick={updateTitle}
        disabled={currentEditItem?.title === "" || isActivity}
      >
        <span className="material-icons-outlined">done</span>
      </Button>
      <Button
        variant="outline-danger"
        size="sm"
        onClick={() => handleEditItem(null)}
        disabled={isActivity}
      >
        <span className="material-icons-outlined">close</span>
      </Button>
    </InputGroup>
  );
};

export default EditText;
