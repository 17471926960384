import { useState } from "react";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Table from "react-bootstrap/Table";

import { convertToCurrency } from "utils/NumbersUtil";

const taskStatuses = {
  normal: {
    value: "Normal",
    className: "text-secondary",
  },
  critical: {
    value: "Critical",
    className: "text-danger",
  },
};

const TaskStats = ({ data }) => {
  const [showPopover, setShowPopover] = useState(false);

  const handleClick = () => {
    setShowPopover((prevState) => !prevState);
  };

  const {
    totalBudget,
    gmsBudgetWithAccrual,
    totalAccrual,
    gmsBudget,
    completedPercentage,
    status,
  } = data || {};

  const currentStatus = taskStatuses[status];

  const breakdownPopover = (
    <Popover id="popover-basic" style={{ maxWidth: "400px" }}>
      <Popover.Header as="h4" className="d-flex justify-content-between">
        <span>GMS Budget Breakdown</span>
        <span
          className="ms-4 cursor-pointer material-icons-outlined"
          title="Close"
          onClick={handleClick}
        >
          close
        </span>
      </Popover.Header>
      <Popover.Body>
        <Table borderless size="sm">
          <thead>
            <tr>
              <th>GMS Budget</th>
              <th></th>
              <th>Accruals</th>
              <th></th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{convertToCurrency(gmsBudget)}</td>
              <td>+</td>
              <td>{convertToCurrency(totalAccrual)}</td>
              <td>=</td>
              <td>{convertToCurrency(gmsBudgetWithAccrual)}</td>
            </tr>
          </tbody>
        </Table>
      </Popover.Body>
    </Popover>
  );

  return (
    <Col md={12} sm={12}>
      <Card className="border-0x">
        <Card.Body>
          Overview
          <hr className="my-2" />
          <div className="d-flex justify-content-around gap-3 py-3">
            <Col>
              <div className="d-flex">
                <span className="rounded material-icons-outlined text-hunter-green bg-success bg-opacity-10 p-1">
                  article
                </span>
                <h6 className="text-gray mb-0 ms-2">Total Budget</h6>
              </div>
              <span className="display-4 fw-medium fs-5">
                {convertToCurrency(totalBudget)}
              </span>{" "}
            </Col>
            <Col className="border-start border-tertiary ps-2">
              <div className="d-flex">
                <span className="bg-info text-dark-blue bg-opacity-10 rounded material-icons-outlined p-1">
                  account_balance_wallet
                </span>
                <h6 className="text-gray mb-0 ms-2">GMS Budget</h6>
              </div>
              <div className="d-flex align-items-center">
                <span className="display-4 fw-medium fs-5">
                  {convertToCurrency(gmsBudgetWithAccrual)}
                </span>
                <OverlayTrigger
                  placement="bottom"
                  show={showPopover}
                  overlay={breakdownPopover}
                >
                  <span
                    className="material-icons-outlined fs-6 text-warning cursor-pointer"
                    onClick={handleClick}
                  >
                    info
                  </span>
                </OverlayTrigger>
              </div>
            </Col>
            <Col className="border-start border-tertiary ps-2">
              <div className="d-flex">
                <span className="text-deep-indigo bg-deep-indigo bg-opacity-10 rounded material-icons-outlined p-1">
                  done
                </span>
                <h6 className="text-gray mb-0 ms-2">Completed %</h6>
              </div>
              <span className="display-4 fw-medium fs-5">
                {completedPercentage > 100 ? (
                  <span className="fs-6 text-danger">
                    Budget Spent is exceeding the Total Budget
                  </span>
                ) : (
                  `${completedPercentage}%`
                )}
              </span>
            </Col>
            <Col className="border-start border-tertiary ps-2">
              <div className="d-flex">
                <span className="bg-danger text-danger bg-opacity-10 rounded material-icons-outlined p-1">
                  safety_check
                </span>
                <h6 className="text-gray mb-0 ms-2">Risk Status</h6>
              </div>
              <span
                className={`display-4 fw-medium fs-5 ${currentStatus?.className}`}
              >
                {currentStatus?.value}
              </span>
            </Col>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default TaskStats;
