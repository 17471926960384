import { useEffect, useRef } from "react";
import * as echarts from "echarts";

import "./ProjectsBarchart.scss";

const ProjectsBarchart = ({ data }) => {
  const el = useRef(null);

  const { projects } = data || {};

  useEffect(() => {
    const chartData = projects?.reduce(
      (acc, curr) => {
        acc?.xAxisData?.push(curr.name);
        acc?.series?.push(curr.budget);
        return acc;
      },
      {
        xAxisData: [],
        series: [],
      },
    );

    const option = {
      tooltip: {
        trigger: "axis",
        formatter: "{b} ${c}",
        axisPointer: {
          type: "shadow",
        },
        textStyle: {
          fontSize: 12,
        },
      },
      grid: {
        top: "4%",
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: chartData?.xAxisData,
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            rotate: 45,
            textStyle: {
              fontSize: 12,
            },
            formatter: function (value) {
              const maxLength = 12; // Maximum length of label
              return value.length > maxLength
                ? value.substring(0, maxLength - 3) + "..."
                : value;
            },
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            formatter: "${value}",
          },
        },
      ],
      series: [
        {
          type: "bar",
          barWidth: "45%",
          data: chartData?.series,
        },
      ],
    };
    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: el.current.offsetWidth / 2,
    });

    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: el.current.offsetWidth / 3,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, data, projects]);

  return <div ref={el} className="project-barchart-container"></div>;
};

export default ProjectsBarchart;
