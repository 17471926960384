import { useQuery } from "@tanstack/react-query";
import { get } from "utils/DeApi";

export default function useOpportunitySummary(opportunityId) {
  const result = useQuery({
    queryKey: ["getOpportunitySummary", opportunityId],
    queryFn: () =>
      get(`/opportunities/${opportunityId}/summary`).promise.then(
        (res) => res.data,
      ),
  });
  return {
    ...result,
  };
}
