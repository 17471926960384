import { Form } from "react-bootstrap";

const InputFieldFormik = ({
  handleChange,
  value,
  placeholder,
  handleBlur,
  label,
  type,
  name,
  error,
  touched,
  disabled,
  className,
  notShowValidSign,
  notShowLabel,
  children,
}) => {
  const isValid = value && !error ? true : false;
  return (
    <Form.Group controlId={name} className={`mb-3 ${className}`}>
      {!notShowLabel ? <Form.Label className="mb-1">{label}</Form.Label> : null}
      <Form.Control
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        isInvalid={error && touched}
        disabled={disabled}
        {...(!notShowValidSign && { isValid })}
      />
      <Form.Control.Feedback type="invalid">
        {error && touched ? <small>{error}</small> : null}
      </Form.Control.Feedback>
      {children}
    </Form.Group>
  );
};

export default InputFieldFormik;
