import React, { useState } from "react";
import { Formik } from "formik";
import { Button, Col, Form } from "react-bootstrap";
import * as yup from "yup";
import { required } from "yup";
import DropdownFieldFormik from "components/Inputs/DropdownFieldFormik";

const ValueDiminishers = ({ valueFactor, handleNextPrevClick }) => {
  const initialValues = {
    commoditization:
      valueFactor?.valueDiminishers?.find((val) => val.id === 1)?.score || "",
    competition:
      valueFactor?.valueDiminishers?.find((val) => val.id === 2)?.score || "",
    importance:
      valueFactor?.valueDiminishers?.find((val) => val.id === 3)?.score || "",
  };

  const options = [1, 2, 3, 4, 5].map((n) => ({ id: n, value: n }));

  const schema = yup.object().shape({
    commoditization: yup.string().required("This is a required field"),
    competition: yup.string().required("This is a required field"),
    importance: yup.string().required("This is a required field"),
  });

  const calculateDiminishers = (values, actions) => {
    actions.setSubmitting(false);
    let arr = [];
    Object.keys(values).map((val, index) => {
      arr.push({
        id: index + 1,
        score: values[val],
      });
    });
    handleNextPrevClick(2, arr);
  };

  const calculateSum = (values) => {
    const { commoditization, competition, importance } = values;

    const result =
      Number.parseInt(commoditization) +
      Number.parseInt(competition) +
      Number.parseInt(importance);

    return result;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values, actions) => {
        calculateDiminishers(values, actions);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        isSubmitting,
        touched,
        isValid,
        errors,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Col>
            <Col>
              <h5 className="text-gray">
                How commoditized is this opportunity?
              </h5>
              <DropdownFieldFormik
                data={options}
                textKey="id"
                valueKey="value"
                name="commoditization"
                label="[Highly commoditized = 5; Strategic/Advisory = 1]"
                error={errors.commoditization}
                touched={touched.commoditization}
                value={values.commoditization}
                handleBlur={handleBlur}
                handleChange={handleChange}
                placeholder="Answer"
              />
            </Col>
            <Col>
              <h5 className="text-gray">
                How competitive is this opportunity?
              </h5>
              <DropdownFieldFormik
                data={options}
                textKey="id"
                valueKey="value"
                name="competition"
                label="[5 or more bidders = 5; Sole source = 1]. Consider strength/role of Procurement in decision making."
                error={errors.competition}
                touched={touched.competition}
                value={values.competition}
                handleBlur={handleBlur}
                handleChange={handleChange}
                placeholder="Answer"
              />
            </Col>
            <Col>
              <h5 className="text-gray">
                How important is this opportunity to ERM?
              </h5>
              <DropdownFieldFormik
                data={options}
                textKey="id"
                valueKey="value"
                name="importance"
                label="[Willing to drop prices to win = 5; Nice to have = 1, allows us to keep our multipliers]"
                error={errors.importance}
                touched={touched.importance}
                value={values.importance}
                handleBlur={handleBlur}
                handleChange={handleChange}
                placeholder="Answer"
              />
            </Col>
            <Button
              type="submit"
              className="float-end"
              size="m"
              disabled={!isValid}
              variant="primary"
            >
              Next
            </Button>
          </Col>
        </Form>
      )}
    </Formik>
  );
};

export default ValueDiminishers;
