import { useCallGetDeApi } from "hooks/useCallApi";
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";

import { get } from "utils/DeApi";
import { getDateDifference } from "utils/helper";
import PieChart, { TCRevenuePieChart } from "./GSLChart";
import EditOpportunityGSLModal from "../EditOpportunityDetail/EditOpportunityGSLModal";
import EditOpportunityTCsModal from "../EditOpportunityDetail/EditOpportunityTCsModal";
import Loader from "components/Loader/Loader";
import { isNumeric } from "utils/NumbersUtil";
import { formatNumberValue } from "components/CostingGrid/EditableGrid/shared/utils";

const ProjectInformation = ({ opportunity }) => {
  const {
    title,
    sfNumber,
    inchargePartner,
    description,
    projectManager,
    businessUnitManagerPartner,
    ispTechPartner,
    apc,
    isMapping,
    pricingType,
    subService,
    projectLocation,
    projectStartDate,
    contractTerm,
    currency,
    tenderCompetition,
    projectEndDate,
    client,
    organization,
    gmsConfidentialityStatus,
    suppliersMarkup,
    expensesMarkup,
    commRecovery,
    service,
    sector,
    lumpSum,
    totalGrossClientProjectRevenue,
    totalNetRevenue,
    effectiveNetRevenueMultiplier,
  } = opportunity;

  const { data: countriesData } = useCallGetDeApi("/countries");

  const {
    data: gslsData,
    isLoading: isGSLDataLoading,
    isRefetching: isGSLDataRefetching,
    refetch: refreshGSLData,
  } = useQuery({
    queryKey: ["getOpportunityGlobalServiceLines", opportunity.id],
    queryFn: () =>
      get(`/opportunities/${opportunity.id}/global-service-lines`).promise.then(
        (res) => res.data,
      ),
  });

  const {
    data: techinicalCommunitiesData,
    isLoading: isTCDataLoading,
    isRefetching: isTCDataRefetching,
    refetch: refreshTCData,
  } = useQuery({
    queryKey: ["getOpportunityTechnicalCommunities", opportunity.id],
    queryFn: () =>
      get(
        `/opportunities/${opportunity.id}/technical-communities`,
      ).promise.then((res) => res.data),
  });

  return (
    <>
      <div className="d-flex gap-3 my-3">
        <div className="d-flex flex-column flex-fill">
          <Card className="flex-fill">
            <Card.Body>
              <h5 className="text-gray">General Information</h5>
              <hr />

              <Container>
                <Row>
                  <Col>
                    <span className="text-gray fw-bold">Proposal Title</span>
                    <h6> {title}</h6>
                  </Col>
                  <Col>
                    <span className="text-gray fw-bold">Salesforce Number</span>
                    <h6> {sfNumber}</h6>
                  </Col>
                  <Col>
                    <span className="text-gray fw-bold">Description</span>
                    <h6> {description || <small>N/A</small>}</h6>
                  </Col>

                  <Col>
                    <span className="text-gray fw-bold">Client</span>
                    <h6> {client?.name || <small>N/A</small>}</h6>
                  </Col>
                  <Col>
                    <span className="text-gray fw-bold">
                      Client Contract Entity Country
                    </span>
                    <h6> {client?.address || <small>N/A</small>}</h6>
                  </Col>
                  <Col>
                    <span className="text-gray fw-bold">Client Contact</span>
                    <h6> {client?.contact || <small>N/A</small>}</h6>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
          <Card className="mt-3">
            <Card.Body>
              <h5 className="text-gray">Project Information</h5>
              <hr />
              <Container>
                <Row>
                  <Col xs={2}>
                    <span className="text-gray fw-bold">Project Location</span>
                    <h6> {projectLocation || <small>N/A</small>}</h6>
                  </Col>
                  <Col xs={4}>
                    <span className="text-gray fw-bold">
                      Owning Organization
                    </span>
                    <h6>
                      {countriesData?.find(
                        (country) => country.id === organization?.countryId,
                      ) ? (
                        countriesData?.find(
                          (country) => country.id === organization?.countryId,
                        )?.name +
                        " | " +
                        organization?.title +
                        " | " +
                        organization?.serialNo
                      ) : (
                        <small>N/A</small>
                      )}
                    </h6>
                  </Col>
                  <Col xs={2}>
                    <span className="text-gray fw-bold">Sector</span>
                    <h6> {sector?.title || <small>N/A</small>}</h6>
                  </Col>
                  <Col xs={2}>
                    <span className="text-gray fw-bold">Services</span>
                    <h6> {service?.title || <small>N/A</small>}</h6>
                  </Col>
                  <Col xs={2}>
                    <span className="text-gray fw-bold">Sub services</span>
                    <h6> {subService?.name || <small>N/A</small>}</h6>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </div>
        <Card className="flex-grow-1">
          <Card.Body className="h-100">
            <h5 className="text-gray">Project Team</h5>
            <hr />
            <Container>
              <Col className="pb-4">
                <span className="text-gray fw-bold">Partner in Charge</span>
                <h6> {inchargePartner?.title || <small>N/A</small>}</h6>
              </Col>
              <Col className="pb-4">
                <span className="text-gray fw-bold">Project Manager</span>
                <h6> {projectManager?.title || <small>N/A</small>}</h6>
              </Col>
              <Col className="pb-4">
                <span className="text-gray fw-bold">Business Unit Manager</span>
                <h6>
                  {businessUnitManagerPartner?.title || <small>N/A</small>}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">
                  ISP Tech Partner (ESG Digital use)
                </span>
                <h6> {ispTechPartner?.title || <small>N/A</small>}</h6>
              </Col>
            </Container>
          </Card.Body>
        </Card>
      </div>
      <Card className="mb-3">
        <Card.Body>
          <h5 className="text-gray">
            Global Service Lines Allocation
            <span className="float-end">
              <EditOpportunityGSLModal
                opportunityGSLs={gslsData || []}
                handleClose={refreshGSLData}
              />
            </span>
          </h5>
          <hr />
          <Container>
            {isGSLDataLoading || isGSLDataRefetching ? (
              <Loader />
            ) : (
              <PieChart
                gslsData={gslsData
                  ?.filter((gsl) => {
                    return Number(gsl?.percentage) > 0;
                  })
                  ?.map((gsl) => ({
                    name: gsl.title,
                    value: gsl.percentage,
                  }))}
              />
            )}
          </Container>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Body>
          <h5 className="text-gray">
            Technical Communities Allocation & Revenue Distribution
            <span className="float-end">
              <EditOpportunityTCsModal
                opportunityTCs={techinicalCommunitiesData}
                handleClose={refreshTCData}
              />
            </span>
          </h5>
          <hr />
          <Container>
            <Row className="mt-3">
              <Col>
                {isTCDataLoading || isTCDataRefetching ? (
                  <Loader />
                ) : (
                  <TCRevenuePieChart
                    dataType="TC."
                    gslsData={
                      techinicalCommunitiesData
                        ?.filter((tc) => {
                          return Number(tc?.percentage) > 0;
                        })
                        ?.map((tc) => ({
                          name: tc.title,
                          percent: tc.percentage,
                          value: opportunity?.summary?.totalCost
                            ? (
                                (opportunity?.summary?.totalCost *
                                  tc.percentage) /
                                100
                              ).toFixed(2)
                            : 0,
                        })) || []
                    }
                    totalCost={opportunity?.summary?.totalCost}
                    colors={[
                      "#188fa7",
                      "#ffcab1",
                      "#78bc61",
                      "#0d1317",
                      "#5e503f",
                      "#134611",
                      "#f4ac32",
                      "#861657",
                      "#222e50",
                      "#cdd1c4",
                    ]}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <Card className="my-3">
        <Card.Body>
          <h5 className="text-gray">Financial Details</h5>
          <hr />
          <Container>
            <Row className="my-2">
              <Col>
                <span className="text-gray fw-bold">Contract Currency</span>
                <h6> {currency?.code || <small>N/A</small>}</h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">Pricing Type</span>
                <h6> {pricingType?.title || <small>N/A</small>}</h6>
              </Col>
              {pricingType?.title === "Lump Sum/Unit Price" ? (
                <Col>
                  <span className="text-gray fw-bold">Lump Sum/Unit Price</span>
                  <h6>
                    {lumpSum ? (
                      Intl.NumberFormat("en-us", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: currency?.code,
                      }).format(lumpSum)
                    ) : (
                      <small>N/A</small>
                    )}
                  </h6>
                </Col>
              ) : null}
              <Col>
                <span className="text-gray fw-bold">
                  Will GIS / Spatial Systems / mapping be involved?
                </span>
                <h6>{isMapping ? "Yes" : "No"}</h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">
                  GMS Confidentiality Status
                </span>
                <h6>{gmsConfidentialityStatus?.title || <small>N/A</small>}</h6>
              </Col>
            </Row>
            <Row className="my-2">
              <Col>
                <span className="text-gray fw-bold">
                  Mark Up on Subs/Suppliers&nbsp;(%)
                </span>
                <h6> {suppliersMarkup || <small>N/A</small>}</h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">
                  Mark Up on Expense&nbsp;(%)
                </span>
                <h6> {expensesMarkup || <small>N/A</small>}</h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">Comm Recovery (%)</span>
                <h6> {commRecovery || <small>N/A</small>}</h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">APC (%)</span>
                <h6> {apc ? Number(apc)?.toFixed(2) : <small>0.00</small>}</h6>
              </Col>
            </Row>
          </Container>
        </Card.Body>
        <Card.Body>
          <h5 className="text-gray">Total Gross (Client) Project Revenue</h5>
          <hr />
          <Container>
            <Row className="my-2">
              <Col>
                <span className="text-gray fw-bold">Original</span>
                <h6>
                  {isNumeric(totalGrossClientProjectRevenue.original) ? (
                    Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: currency?.code,
                    }).format(totalGrossClientProjectRevenue.original)
                  ) : (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">Adjusted</span>
                <h6>
                  {isNumeric(totalGrossClientProjectRevenue.adjusted) ? (
                    Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: currency?.code,
                    }).format(totalGrossClientProjectRevenue.adjusted)
                  ) : (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">Adjustment (%)</span>
                <h6>
                  {isNumeric(totalGrossClientProjectRevenue.adjustment) ? (
                    formatNumberValue(
                      totalGrossClientProjectRevenue.adjustment,
                      2,
                    )
                  ) : (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">Contingency&nbsp;(%)</span>
                <h6>
                  {isNumeric(
                    totalGrossClientProjectRevenue.contingencyPercentage,
                  ) ? (
                    formatNumberValue(
                      totalGrossClientProjectRevenue.contingencyPercentage,
                      2,
                    )
                  ) : (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
            </Row>
          </Container>
        </Card.Body>
        <Card.Body>
          <h5 className="text-gray">
            Total Net Revenue (Labor + Mark Up - NonReim.)
          </h5>
          <hr />
          <Container>
            <Row className="my-2">
              <Col>
                <span className="text-gray fw-bold">Original</span>
                <h6>
                  {isNumeric(totalNetRevenue.original) ? (
                    Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: currency?.code,
                    }).format(totalNetRevenue.original)
                  ) : (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">Adjusted</span>
                <h6>
                  {isNumeric(totalNetRevenue.adjusted) ? (
                    Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: currency?.code,
                    }).format(totalNetRevenue.adjusted)
                  ) : (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">Value Factor&nbsp;%</span>
                <h6>
                  {isNumeric(totalNetRevenue.valueFactor) ? (
                    formatNumberValue(totalNetRevenue.valueFactor, 2)
                  ) : (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
            </Row>
            <Row className="my-2">
              <Col>
                <span className="text-gray fw-bold">Adjustment (%)</span>
                <h6>
                  {isNumeric(totalNetRevenue.adjustment) ? (
                    formatNumberValue(totalNetRevenue.adjustment, 2)
                  ) : (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">
                  Contingency&nbsp;({currency?.code})
                </span>
                <h6>
                  {isNumeric(totalNetRevenue.contingency) ? (
                    Intl.NumberFormat("en-us", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: currency?.code,
                    }).format(totalNetRevenue.contingency)
                  ) : (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">Contingency&nbsp;(%)</span>
                <h6>
                  {isNumeric(totalNetRevenue.contingencyPercentage) ? (
                    formatNumberValue(totalNetRevenue.contingencyPercentage, 2)
                  ) : (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
            </Row>
          </Container>
        </Card.Body>
        <Card.Body>
          <h5 className="text-gray">Effective Net Revenue Multiplier (M)</h5>
          <hr />
          <Container>
            <Row className="my-2">
              <Col>
                <span className="text-gray fw-bold">Project</span>
                <h6>
                  {isNumeric(effectiveNetRevenueMultiplier.project) ? (
                    formatNumberValue(effectiveNetRevenueMultiplier.project, 2)
                  ) : (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">Location</span>
                <h6>
                  {isNumeric(effectiveNetRevenueMultiplier.location) ? (
                    formatNumberValue(effectiveNetRevenueMultiplier.location, 2)
                  ) : (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">Service</span>
                <h6>
                  {isNumeric(effectiveNetRevenueMultiplier.service) ? (
                    formatNumberValue(effectiveNetRevenueMultiplier.service, 2)
                  ) : (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">Key Client</span>
                <h6>
                  {isNumeric(effectiveNetRevenueMultiplier.keyClient) ? (
                    formatNumberValue(
                      effectiveNetRevenueMultiplier.keyClient,
                      2,
                    )
                  ) : (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
              <Col>
                <span className="text-gray fw-bold">HGHV</span>
                <h6>
                  {isNumeric(effectiveNetRevenueMultiplier.hghv) ? (
                    formatNumberValue(effectiveNetRevenueMultiplier.hghv, 2)
                  ) : (
                    <small>N/A</small>
                  )}
                </h6>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <div className="d-flex gap-3 my-3">
        <Card className="flex-fill">
          <Card.Body>
            <h5 className="text-gray">Contract & Tender</h5>
            <hr />
            <Container>
              <Row>
                <Col className="pb-4">
                  <span className="text-gray fw-bold">Contract Term</span>
                  <h6> {contractTerm?.title || <small>N/A</small>}</h6>
                </Col>
                <Col className="pb-4">
                  <span className="text-gray fw-bold">
                    Tender/Bid Competitions
                  </span>
                  <h6> {tenderCompetition?.title || <small>N/A</small>}</h6>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
        <Card className="flex-fill">
          <Card.Body>
            <h5 className="text-gray">Project Timelines</h5>
            <hr />
            <Container>
              <Row>
                <Col className="pb-4">
                  <span className="text-gray fw-bold">Project Start Date</span>
                  <h6>
                    {projectStartDate ? (
                      new Date(projectStartDate).toLocaleString(["en-us"], {
                        dateStyle: "long",
                      })
                    ) : (
                      <small>N/A</small>
                    )}
                  </h6>
                </Col>
                <Col className="pb-4">
                  <span className="text-gray fw-bold">Project Start End</span>
                  <h6>
                    {projectEndDate ? (
                      new Date(projectEndDate).toLocaleString(["en-us"], {
                        dateStyle: "long",
                      })
                    ) : (
                      <small>N/A</small>
                    )}
                  </h6>
                </Col>
                <Col className="pb-4">
                  <span className="text-gray fw-bold">Total Weeks</span>
                  <h6>
                    {projectStartDate && projectEndDate ? (
                      getDateDifference(projectStartDate, projectEndDate)
                    ) : (
                      <small>N/A</small>
                    )}
                  </h6>
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default ProjectInformation;
