import React from "react";
import { Card, Container } from "react-bootstrap";
import LabourPyramidChart from "../Charts/LabourPyramidChart";
import LabourRevenueChart from "../Charts/LabourRevenueChart";
import PropTypes from "prop-types";

function OpportunityCostingOverview({ labourSummary, careerLevelSummary }) {
  return (
    <Card className="my-2 border-0 p-0">
      <div className="m-3">
        <div className="d-flex text-start justify-content-between">
          <span className="h5">Staff Allocation Pyramid</span>
        </div>
      </div>

      <Container className="d-flex border-bottom-0 justify-content-center gap-3 ">
        <Card className="w-50">
          <Card.Body>
            <h6>Distribution by Career Level (CL) in Hours %</h6>
            <hr />
            <LabourPyramidChart
              totalLaborHours={labourSummary.totalHours}
              careerLevelSummary={careerLevelSummary}
            />
          </Card.Body>
        </Card>
        <Card className="w-50">
          <Card.Body>
            <h6>Distribution by Career Level (CL) in Revenue %</h6>
            <hr />
            <LabourRevenueChart
              totalLaborCost={labourSummary.totalCost}
              careerLevelSummary={careerLevelSummary}
            />
          </Card.Body>
        </Card>
      </Container>
    </Card>
  );
}

OpportunityCostingOverview.propTypes = {
  labourSummary: PropTypes.object.isRequired,
  careerLevelSummary: PropTypes.array.isRequired,
};

export default OpportunityCostingOverview;
