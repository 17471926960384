import { useState } from "react";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Table from "react-bootstrap/Table";

import { convertToCurrency } from "utils/NumbersUtil";

const ProjectStats = ({ data }) => {
  const [showPopover, setShowPopover] = useState(false);

  const handleClick = () => {
    setShowPopover((prevState) => !prevState);
  };

  const {
    projectTotalBudget = 0, // need correct data from backend, right now getting project name
    projectSpentBudgetWithAccrual = 0,
    budgetSpentPercentage = 0,
    completedPercentage = 0,
    projectSpentBudget = 0,
    projectTotalAccrual = 0,
  } = data || {};

  const breakdownPopover = (
    <Popover id="popover-basic" style={{ maxWidth: "400px" }}>
      <Popover.Header as="h4" className="d-flex justify-content-between">
        <span>Project Spent Budget Breakdown</span>
        <span
          className="ms-4 cursor-pointer material-icons-outlined"
          title="Close"
          onClick={handleClick}
        >
          close
        </span>
      </Popover.Header>
      <Popover.Body>
        <Table borderless size="sm">
          <thead>
            <tr>
              <th>Spent Budget</th>
              <th></th>
              <th>Accruals</th>
              <th></th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{convertToCurrency(projectSpentBudget)}</td>
              <td>+</td>
              <td>{convertToCurrency(projectTotalAccrual)}</td>
              <td>=</td>
              <td>{convertToCurrency(projectSpentBudgetWithAccrual)}</td>
            </tr>
          </tbody>
        </Table>
      </Popover.Body>
    </Popover>
  );

  return (
    <Card className="py-2 mt-3">
      <Card.Body className="d-flex gap-3">
        <Col>
          <div className="d-flex">
            <span className="rounded material-icons-outlined text-hunter-green bg-success bg-opacity-10 p-1">
              article
            </span>
            <h6 className="text-gray mb-0 ms-2">Project Total Budget</h6>
          </div>
          <span className="display-4 fw-medium fs-3">
            {convertToCurrency(projectTotalBudget)}
          </span>{" "}
        </Col>
        <Col className="border-start border-tertiary ps-2">
          <div className="d-flex">
            <span className="bg-info text-dark-blue bg-opacity-10 rounded material-icons-outlined p-1">
              account_balance_wallet
            </span>
            <h6 className="text-gray mb-0 ms-2">Project Spent Budget </h6>
          </div>
          <div className="d-flex align-items-center">
            <span className="display-4 fw-medium fs-3">
              {convertToCurrency(projectSpentBudgetWithAccrual)}
            </span>
            <OverlayTrigger
              placement="right"
              show={showPopover}
              overlay={breakdownPopover}
            >
              <span
                className="material-icons-outlined fs-5 text-warning cursor-pointer"
                onClick={handleClick}
              >
                info
              </span>
            </OverlayTrigger>
          </div>
        </Col>

        <Col className="border-start border-tertiary ps-2">
          <div className="d-flex">
            <span className="text-deep-indigo bg-deep-indigo bg-opacity-10 rounded material-icons-outlined p-1">
              payments
            </span>
            <h6 className="text-gray mb-0 ms-2">Budget Spent</h6>
          </div>
          <span className="display-4 fw-medium fs-3">
            {`${budgetSpentPercentage}%`}
          </span>
        </Col>

        <Col className="border-start border-tertiary ps-2">
          <div className="d-flex">
            <span className="text-deep-indigo bg-deep-indigo bg-opacity-10 rounded material-icons-outlined p-1">
              done
            </span>
            <h6 className="text-gray mb-0 ms-2">Completed</h6>
          </div>
          <span className="display-4 fw-medium fs-3">
            {completedPercentage > 100 ? (
              <span className="fs-6 text-danger">
                Budget Spent is exceeding the Total Budget
              </span>
            ) : (
              `${completedPercentage}%`
            )}
          </span>
        </Col>
      </Card.Body>
    </Card>
  );
};

export default ProjectStats;
