import React from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

import { getUnSavedRowStyles } from "components/CostingGrid/EditableGrid/shared/utils";

const PhaseTotals = ({
  eventKey,
  children,
  row,
  col,
  ref,
  isActivityTotal = false,
}) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    console.debug(eventKey);
  });

  return (
    <td
      className={
        `border cell-width ${
          isActivityTotal ? "" : "bg-tertiary"
        } align-middle cursor-pointer` + getUnSavedRowStyles(row, col)
      }
      onClick={decoratedOnClick}
      ref={ref}
    >
      {children}
    </td>
  );
};

export default PhaseTotals;
