import { useRef, useEffect } from "react";
import * as echarts from "echarts";

const ProjectPhasesPieChart = ({ data }) => {
  const el = useRef(null);

  useEffect(() => {
    if (data) {
      const seriedData = data.map((phase) => ({
        name: phase.name,
        value: phase.total,
      }));

      const option = {
        tooltip: {
          trigger: "item",
        },
        toolbox: {
          feature: {
            saveAsImage: {
              name: `Distribution by Phases`,
            },
          },
        },
        series: [
          {
            name: "Total and Spent Budget",
            type: "pie",
            radius: "60%",
            data: seriedData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      const chart = echarts.init(el.current, null, {
        width: el.current.offsetWidth,
        height: el.current.offsetWidth,
      });

      chart.setOption(option);

      function handleResize() {
        chart.resize({
          width: el.current.offsetWidth,
          height: el.current.offsetWidth / 1,
        });
      }
      return () => {
        window.removeEventListener("resize", handleResize);
        chart.dispose();
      };
    }
  }, [el, data]);

  return <div ref={el}></div>;
};

export default ProjectPhasesPieChart;
