import { useContext, useEffect } from "react";
import { OpportunityContext } from "contexts/OpportunityProvider";
import { BreadcrumbDispatchContext } from "contexts/OpportunityProvider";
import {
  ActiveTabDispatchContext,
  GovernanceExceptionsContext,
} from "contexts/OpportunityProvider";
import Loader from "components/Loader/Loader";
import GMSForm from "./GSMForm";

const OpportunityGMSForm = () => {
  const opportunity = useContext(OpportunityContext);
  const setBreadcrumbs = useContext(BreadcrumbDispatchContext);
  const setActiveTab = useContext(ActiveTabDispatchContext);

  const governanceExceptions = useContext(GovernanceExceptionsContext);

  const { id, title } = opportunity;

  useEffect(() => {
    setBreadcrumbs([
      { name: "Dashboard", link: "/" },
      { name: "Opportunities", active: false },
      { name: title, link: `/opportunities/${id}` },
      { name: "GMS Setup Form", active: false },
    ]);
    setActiveTab("gms");
  }, [setActiveTab, setBreadcrumbs, title, id]);

  if (Object.keys(opportunity).length === 0) return <Loader />;

  return (
    <>
      {/* <Phases opportunityId={id} /> */}
      <GMSForm
        opportunity={opportunity}
        gmsException={governanceExceptions?.find(
          (item) => item.rule?.name?.toLowerCase() === "payment terms",
        )}
        governanceExceptions={governanceExceptions}
      />
      {/* <PhasesAndActivitiesCostingChart /> */}
    </>
  );
};

export default OpportunityGMSForm;
