import React from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

const PhaseTitle = ({
  eventKey,
  phaseIndex,
  title,
  isActivityTitle = false,
}) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    console.debug(eventKey);
  });

  return (
    <td
      className={`border ${
        isActivityTitle ? "" : "bg-tertiary"
      } cell-heading-width align-middle p-2`}
      onClick={decoratedOnClick}
    >
      {isActivityTitle ? (
        <span className="ps-1"> {`${title}`}</span>
      ) : (
        <span>{`${phaseIndex + 1}. ${title}`}</span>
      )}

      <span className="material-icons-outlined">expand_more</span>
    </td>
  );
};

export const AccordionButtonCell = ({
  eventKey,
  children,
  className,
  ...props
}) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    console.debug(eventKey);
  });

  return (
    <td {...props} className={className} onClick={decoratedOnClick}>
      {children}
    </td>
  );
};

export default PhaseTitle;
