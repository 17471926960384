import { useEffect, useRef } from "react";
import { Card, Table, Badge } from "react-bootstrap";
import Loader from "components/Loader/Loader";
import { useCallGetDeApi } from "hooks/useCallApi";

const GMSSummaryTable = ({ opportunityId, currency }) => {
  const titleHeaderRef = useRef(null);

  const { data, isLoading } = useCallGetDeApi(
    `/opportunities/${opportunityId}/gms-summary`,
  );

  return (
    <Card>
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="mb-0 ">GMS Summary</h4>
          <h6 className="mb-0 fw-bold">
            Expense Budget:
            <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary ms-2">
              <h6 className="mb-0 fw-bold">
                {Intl.NumberFormat("en-us", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: currency?.code,
                }).format(
                  (data?.summary?.finalTotalWithOutVat || 0) -
                    (data?.summary?.labor?.totalCost || 0),
                )}
              </h6>
            </Badge>
          </h6>
        </div>
        <hr />
        {isLoading ? (
          <Loader />
        ) : (
          <Table responsive size="sm">
            <thead>
              <tr>
                <th
                  ref={titleHeaderRef}
                  className="px-2 align-middle"
                  style={{
                    minWidth: "250px",
                    position: "sticky",
                    zIndex: 12,
                    left: 0,
                    borderRight: "1px solid #dee2e6",
                  }}
                >
                  Phase
                </th>
                <th className="px-2 align-middle" style={{ minWidth: "150px" }}>
                  Labour
                </th>
                <th className="px-2 align-middle" style={{ minWidth: "150px" }}>
                  Internal Labor Tax
                </th>
                <th className="px-2 align-middle" style={{ minWidth: "150px" }}>
                  Expenses
                </th>
                <th className="px-2 align-middle" style={{ minWidth: "150px" }}>
                  Labs
                </th>
                <th className="px-2 align-middle" style={{ minWidth: "150px" }}>
                  Subcontractors
                </th>
                <th className="px-2 align-middle" style={{ minWidth: "200px" }}>
                  MARK UP VALUE on Expenses, Lab and Subs
                </th>
                <th className="px-2 align-middle" style={{ minWidth: "150px" }}>
                  Communic Recovery
                </th>
                <th className="px-2 align-middle" style={{ minWidth: "150px" }}>
                  APC
                </th>
                <th className="px-2 align-middle" style={{ minWidth: "150px" }}>
                  Equipment & Supplies
                </th>
                <th className="px-2 align-middle" style={{ minWidth: "150px" }}>
                  Digital Products
                </th>
                <th className="px-2 align-middle" style={{ minWidth: "150px" }}>
                  Total (VAT Tax exclusive)"
                </th>
                <th className="px-2 align-middle" style={{ minWidth: "150px" }}>
                  Value Added Tax (VAT)
                </th>
                <th className="px-2 align-middle" style={{ minWidth: "150px" }}>
                  Total (VAT Tax inclusive)
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.phases?.map((phase, index) => {
                return (
                  <SingleRow
                    index={index}
                    phase={phase}
                    key={index + phase?.id}
                    titleHeaderRef={titleHeaderRef}
                  />
                );
              })}
              {data?.contingencySummary?.finalTotalWithOutVat && !isLoading ? (
                <SingleRow
                  index={"contingency"}
                  phase={{ summary: data?.contingencySummary }}
                  titleHeaderRef={titleHeaderRef}
                />
              ) : (
                <></>
              )}
            </tbody>
            {!isLoading && (
              <tfoot>
                <tr>
                  <td
                    ref={titleHeaderRef}
                    className="px-2 fw-bold fs-6"
                    style={{
                      minWidth: "250px",
                      position: "sticky",
                      zIndex: 12,
                      left: 0,
                      borderRight: "1px solid #dee2e6",
                    }}
                  >
                    Total
                  </td>
                  <td
                    className="px-2 align-middle"
                    style={{ minWidth: "150px" }}
                  >
                    <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
                      <h6 className="mb-0 fw-bold">
                        {Intl.NumberFormat("en-us", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: currency?.code,
                        }).format(data?.summary?.labor?.totalCost || 0)}
                      </h6>
                    </Badge>
                  </td>
                  <td
                    className="px-2 align-middle"
                    style={{ minWidth: "150px" }}
                  >
                    <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
                      <h6 className="mb-0 fw-bold">
                        {Intl.NumberFormat("en-us", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: currency?.code,
                        }).format(data?.summary?.labor?.totalTaxAdjusted || 0)}
                      </h6>
                    </Badge>
                  </td>
                  <td
                    className="px-2 align-middle"
                    style={{ minWidth: "150px" }}
                  >
                    <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
                      <h6 className="mb-0 fw-bold">
                        {Intl.NumberFormat("en-us", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: currency?.code,
                        }).format(data?.summary?.expenses?.totalCost || 0)}
                      </h6>
                    </Badge>
                  </td>
                  <td
                    className="px-2 align-middle"
                    style={{ minWidth: "150px" }}
                  >
                    <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
                      <h6 className="mb-0 fw-bold">
                        {Intl.NumberFormat("en-us", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: currency?.code,
                        }).format(data?.summary?.labTests?.totalCost || 0)}
                      </h6>
                    </Badge>
                  </td>
                  <td
                    className="px-2 align-middle"
                    style={{ minWidth: "150px" }}
                  >
                    <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
                      <h6 className="mb-0 fw-bold">
                        {Intl.NumberFormat("en-us", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: currency?.code,
                        }).format(
                          data?.summary?.subcontractors?.totalCost || 0,
                        )}
                      </h6>
                    </Badge>
                  </td>
                  <td
                    className="px-2 align-middle"
                    style={{ minWidth: "150px" }}
                  >
                    <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
                      <h6 className="mb-0 fw-bold">
                        {Intl.NumberFormat("en-us", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: currency?.code,
                        }).format(data?.summary?.allMarkUpValuesTotal || 0)}
                      </h6>
                    </Badge>
                  </td>
                  <td
                    className="px-2 align-middle"
                    style={{ minWidth: "150px" }}
                  >
                    <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
                      <h6 className="mb-0 fw-bold">
                        {Intl.NumberFormat("en-us", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: currency?.code,
                        }).format(data?.summary?.commRecoveryTotal || 0)}
                      </h6>
                    </Badge>
                  </td>
                  <td
                    className="px-2 align-middle"
                    style={{ minWidth: "150px" }}
                  >
                    <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
                      <h6 className="mb-0 fw-bold">
                        {Intl.NumberFormat("en-us", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: currency?.code,
                        }).format(data?.summary?.apcTotal || 0)}
                      </h6>
                    </Badge>
                  </td>
                  <td
                    className="px-2 align-middle"
                    style={{ minWidth: "150px" }}
                  >
                    <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
                      <h6 className="mb-0 fw-bold">
                        {Intl.NumberFormat("en-us", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: currency?.code,
                        }).format(data?.summary?.equipment?.totalCost || 0)}
                      </h6>
                    </Badge>
                  </td>
                  <td
                    className="px-2 align-middle"
                    style={{ minWidth: "150px" }}
                  >
                    <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
                      <h6 className="mb-0 fw-bold">
                        {Intl.NumberFormat("en-us", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: currency?.code,
                        }).format(
                          data?.summary?.digitalEquipment?.totalCost || 0,
                        )}
                      </h6>
                    </Badge>
                  </td>
                  <td
                    className="px-2 align-middle"
                    style={{ minWidth: "150px" }}
                  >
                    <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
                      <h6 className="mb-0 fw-bold">
                        {Intl.NumberFormat("en-us", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: currency?.code,
                        }).format(data?.summary?.finalTotalWithOutVat || 0)}
                      </h6>
                    </Badge>
                  </td>
                  <td
                    className="px-2 align-middle"
                    style={{ minWidth: "150px" }}
                  >
                    <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
                      <h6 className="mb-0 fw-bold">
                        {Intl.NumberFormat("en-us", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: currency?.code,
                        }).format(data?.summary?.ValueAddedTax || 0)}
                      </h6>
                    </Badge>
                  </td>
                  <td
                    className="px-2 align-middle"
                    style={{ minWidth: "150px" }}
                  >
                    <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
                      <h6 className="mb-0 fw-bold">
                        {Intl.NumberFormat("en-us", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: currency?.code,
                        }).format(data?.summary?.finalTotalWithVat || 0)}
                      </h6>
                    </Badge>
                  </td>
                </tr>
              </tfoot>
            )}
          </Table>
        )}
      </Card.Body>
    </Card>
  );
};

const SingleRow = ({ phase, index, titleHeaderRef }) => {
  const titleRef = useRef(null);

  useEffect(() => {
    const updateHeaderWidth = (ref, headerRef) => {
      if (ref.current && headerRef.current) {
        const cellWidth = Math.max(
          ref.current.getBoundingClientRect().width,
          headerRef.current.getBoundingClientRect().width,
        );
        console.log(cellWidth);
        headerRef.current.style.width = cellWidth + "px";
        ref.current.style.width = cellWidth + "px";
      }
    };
    updateHeaderWidth(titleRef, titleHeaderRef);
  }, [titleHeaderRef]);
  return (
    <tr>
      <td
        className="px-2 fs-6"
        ref={titleRef}
        style={{
          position: "sticky",
          minWidth: "250px",
          zIndex: 12,
          left: 0,
          borderRight: "1px solid #dee2e6",
        }}
      >
        {index !== "contingency" ? `${index + 1}. ` : ""}
        {phase?.title || "Contingency"}
      </td>
      <td className="px-2 align-middle" style={{ minWidth: "150px" }}>
        <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
          <h6 className="mb-0">
            {Intl.NumberFormat("en-us", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(phase?.summary?.labor?.totalCost)}
          </h6>
        </Badge>
      </td>
      <td className="px-2 align-middle" style={{ minWidth: "150px" }}>
        <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
          <h6 className="mb-0">
            {Intl.NumberFormat("en-us", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(phase?.summary?.labor?.totalTaxAdjusted || 0)}
          </h6>
        </Badge>
      </td>
      <td className="px-2 align-middle" style={{ minWidth: "150px" }}>
        <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
          <h6 className="mb-0">
            {Intl.NumberFormat("en-us", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(phase?.summary?.expenses?.totalCost)}
          </h6>
        </Badge>
      </td>
      <td className="px-2 align-middle" style={{ minWidth: "150px" }}>
        <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
          <h6 className="mb-0">
            {Intl.NumberFormat("en-us", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(phase?.summary?.labTests?.totalCost)}
          </h6>
        </Badge>
      </td>
      <td className="px-2 align-middle" style={{ minWidth: "150px" }}>
        <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
          <h6 className="mb-0">
            {Intl.NumberFormat("en-us", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(phase?.summary?.subcontractors?.totalCost)}
          </h6>
        </Badge>
      </td>
      <td className="px-2 align-middle" style={{ minWidth: "150px" }}>
        <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
          <h6 className="mb-0">
            {Intl.NumberFormat("en-us", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(
              phase?.summary?.allMarkUpValues ||
                phase?.summary?.allMarkUpValuesTotal ||
                0,
            )}
          </h6>
        </Badge>
      </td>
      <td className="px-2 align-middle" style={{ minWidth: "150px" }}>
        <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
          <h6 className="mb-0">
            {Intl.NumberFormat("en-us", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(
              phase?.summary?.commRecovery ||
                phase?.summary?.commRecoveryTotal ||
                0,
            )}
          </h6>
        </Badge>
      </td>
      <td className="px-2 align-middle" style={{ minWidth: "150px" }}>
        <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
          <h6 className="mb-0">
            {Intl.NumberFormat("en-us", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(phase?.summary?.apc || phase?.summary?.apcTotal || 0)}
          </h6>
        </Badge>
      </td>
      <td className="px-2 align-middle" style={{ minWidth: "150px" }}>
        <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
          <h6 className="mb-0">
            {Intl.NumberFormat("en-us", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(phase?.summary?.equipment?.totalCost)}
          </h6>
        </Badge>
      </td>
      <td className="px-2 align-middle" style={{ minWidth: "150px" }}>
        <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
          <h6 className="mb-0">
            {Intl.NumberFormat("en-us", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(phase?.summary?.digitalEquipment?.totalCost || 0)}
          </h6>
        </Badge>
      </td>
      <td className="px-2 align-middle" style={{ minWidth: "150px" }}>
        <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
          <h6 className="mb-0">
            {Intl.NumberFormat("en-us", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(phase?.summary?.finalTotalWithOutVat)}
          </h6>
        </Badge>
      </td>
      <td className="px-2 align-middle" style={{ minWidth: "150px" }}>
        <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
          <h6 className="mb-0">
            {Intl.NumberFormat("en-us", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(phase?.summary?.ValueAddedTax)}
          </h6>
        </Badge>
      </td>
      <td className="px-2 align-middle" style={{ minWidth: "150px" }}>
        <Badge className="bg-primary rounded-0 py-1 px-2 bg-opacity-10 text-primary">
          <h6 className="mb-0">
            {Intl.NumberFormat("en-us", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(phase?.summary?.finalTotalWithVat)}
          </h6>
        </Badge>
      </td>
    </tr>
  );
};

export default GMSSummaryTable;
