import { useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import ProjectAtRisk from "./ProjectAtRisk/ProjectAtRisk";
import ActionItems from "components/Tracking/shared/components/ActionItems/ActionItems";
import ProjectStats from "./ProjectStats/ProjectStats";
import ProjectsList from "./ProjectsList/ProjectsList";
import ProjectPieChart from "./ProjectPieChart/ProjectPieChart";
import ProjectPhasesPieChart from "./ProjectPhasesPieChart/ProjectPhasesPieChart";
import PhasesTable from "./PhasesTable/PhasesTable";
import { get } from "utils/DeApi";
import { useEffect, useRef, useState } from "react";
import { useCallGetDeApi } from "hooks/useCallApi";

import { ACTIONABLE_TYPES } from "components/Tracking/shared/types/types";

const ProjectDetails = () => {
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();

  const [modalShow, setModalShow] = useState(false);

  const subscribedPromises = useRef([]);

  const toggleModal = () => setModalShow((prevState) => !prevState);

  const {
    data: pieChartData,
    isLoading: chartLoading,
    error: chartError,
  } = useCallGetDeApi(`/project-intel/projects/${id}/phases?pie_chart=true`);

  useEffect(() => {
    const getProjectDetails = () => {
      setIsLoading(true);
      setData(null);
      setError(null);

      const getPromise = get(`/project-intel/projects/${id}`);
      getPromise.promise
        .then(({ data, meta }) => {
          setData(data);
          setIsLoading(false);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });

      subscribedPromises.current.push(getPromise);
    };
    getProjectDetails();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [id]);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h3>
            Project -{" "}
            <small
              className={`${
                data?.status === "critical"
                  ? "bg-danger text-danger"
                  : "bg-success text-success"
              } bg-opacity-10 px-1`}
            >
              {data?.name}
            </small>
          </h3>
          <i className="me-3">
            <small>
              Last Updated At{" "}
              {new Date(data?.updatedAt).toLocaleString([], {
                dateStyle: "short",
                timeStyle: "short",
              })}
            </small>
          </i>
        </div>
        <div>
          <div className="d-flex justify-content-end mb-1">
            <Button size="sm" onClick={toggleModal}>
              Action Items
            </Button>
          </div>
          <ProjectAtRisk status={data?.status} setProjectDetails={setData} />
        </div>
      </div>
      <ProjectStats data={data} />
      <Row className="mt-3 mb-3">
        <Col md={3}>
          <Card style={{ height: "1292px", overflowY: "auto" }}>
            <Card.Body>
              <h5 className="text-gray fw-bold">Projects</h5>
              <hr />
              <ProjectsList status={data?.status} />
            </Card.Body>
          </Card>
        </Col>
        <Col md={9}>
          <Card>
            <Card.Body>
              <h5 className="text-gray fw-bold">Project Charts</h5>
              <hr />
              <Row className="gy-3">
                <Col md={6} sm={12}>
                  <Card>
                    <Card.Body>
                      <h6>Distribution by Budget</h6>
                      <hr />
                      <ProjectPieChart data={data} />
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} sm={12} className="flex-grow-1">
                  <Card className="h-100">
                    <Card.Body>
                      <h6>Distribution by Phases</h6>
                      <hr />
                      {chartLoading && <Loader />}
                      {chartError && <ErrorHandler error={chartError} />}
                      {!chartLoading && !chartError && (
                        <ProjectPhasesPieChart data={pieChartData} />
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <PhasesTable projectId={id} phases={pieChartData} />
        </Col>
      </Row>
      {modalShow && (
        <ActionItems
          title={data?.name}
          actionableType={ACTIONABLE_TYPES.piProject}
          actionableId={data?.id}
          toggleModal={toggleModal}
          modalShow={modalShow}
        />
      )}
    </>
  );
};

export default ProjectDetails;
