import React, { useEffect, useRef } from "react";
import "./OpportunitiesChart.scss";
import * as echarts from "echarts";
import { convertNegativeToZero } from "utils/helper";

const OpportunitiesChart = ({ data }) => {
  const el = useRef(null);

  useEffect(() => {
    const _data = data.reduce(
      (acc, { summary, title }) => {
        return {
          labor: [
            ...acc.labor,
            convertNegativeToZero(summary?.labor?.sum)?.toFixed(),
          ],
          digitalEquipment: [
            ...acc.digitalEquipment,
            convertNegativeToZero(summary?.digitalEquipment?.sum)?.toFixed(),
          ],
          equipment: [
            ...acc.equipment,
            convertNegativeToZero(summary?.equipment?.sum)?.toFixed(),
          ],
          expenses: [
            ...acc.expenses,
            convertNegativeToZero(summary?.expenses?.sum)?.toFixed(),
          ],
          labTests: [
            ...acc.labTests,
            convertNegativeToZero(summary?.labTests?.sum)?.toFixed(),
          ],
          subcontractors: [
            ...acc.subcontractors,
            convertNegativeToZero(summary?.subcontractors?.sum)?.toFixed(),
          ],
          oppTitle: [...acc.oppTitle, title],
        };
      },
      {
        labor: [],
        digitalEquipment: [],
        equipment: [],
        expenses: [],
        labTests: [],
        subcontractors: [],
        oppTitle: [],
      },
    );

    const colors = [
      "#208604",
      "#00421b",
      "#a93737",
      "#143976",
      "#008563",
      "#5546b6",
    ];
    const option = {
      color: colors,
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: [
          "Labour",
          "Expenses",
          "Equipment & Supplies",
          "Digital Products",
          "Labs",
          "Subcontractors",
        ],
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      toolbox: {
        feature: {
          saveAsImage: { name: `All Opportunities Summary` },
        },
      },
      xAxis: {
        type: "category",
        // boundaryGap: false,
        show: false,
        data: _data.oppTitle,
      },
      yAxis: {
        type: "value",
      },
      axisPointer: {
        lineStyle: {
          width: 0,
        },
      },
      series: [
        {
          name: "Labour",
          type: "bar",
          stack: "total",
          barWidth: "60%",
          color: "#002060",
          data: _data.labor,
        },
        {
          name: "Expenses",
          type: "bar",
          stack: "total",
          barWidth: "60%",
          color: "#00b050",
          data: _data.expenses,
        },
        {
          name: "Equipment & Supplies",
          type: "bar",
          stack: "total",
          barWidth: "60%",
          color: "#ffc000",
          data: _data.equipment,
        },
        {
          name: "Digital Products",
          type: "bar",
          stack: "total",
          barWidth: "60%",
          color: "#c00000",
          data: _data.digitalEquipment,
        },

        {
          name: "Labs",
          type: "bar",
          stack: "total",
          barWidth: "60%",
          color: "#DB7430",
          data: _data.labTests,
        },
        {
          name: "Subcontractors",
          type: "bar",
          stack: "total",
          barWidth: "60%",
          color: "#9B59B6",
          data: _data.subcontractors,
        },
      ],
    };

    const chart = echarts.init(el.current, null, {
      width: el.current.offsetWidth,
      height: el.current.offsetWidth / 3,
    });

    chart.setOption(option);

    function handleResize() {
      chart.resize({
        width: el.current.offsetWidth,
        height: el.current.offsetWidth / 3,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      chart.dispose();
    };
  }, [el, data]);

  return (
    <div className="OpportunitiesChart">
      <div className="approved-opps-chart-container me-auto" ref={el} />
    </div>
  );
};

export default OpportunitiesChart;
