import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

import PropTypes from "prop-types";

const GSLPieChart = ({
  gslsData,
  dataType = "GSL",
  colors = [
    "#76885B",
    "#430A5D",
    "#D20062",
    "#5546b6",
    "#208604",
    "#00421b",
    "#a93737",
    "#143976",
    "#008563",
    "#87A922",
  ],
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);

    // Sample data for the pie chart
    const data = gslsData;
    const totalPercentage = data.reduce(
      (acc, curr) => acc + Number.parseFloat(curr.value),
      0,
    );
    if (totalPercentage < 99.999) {
      data.push({
        name: "Unallocated",
        value: 100 - totalPercentage,
      });
    }

    const option = {
      color: colors,
      tooltip: {
        trigger: "item",
        formatter: "{b} - {d}%",
      },
      grid: {
        top: "0%",
        bottom: "0%",
        left: "0%",
        right: "0%",
      },
      series: [
        {
          name: `${dataType} Data`,
          type: "pie",
          radius: "75%",
          center: ["50%", "50%"],
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
      label: {
        position: "outer",
        alignTo: "none",
        textStyle: {
          fontSize: 8,
        },
        formatter: "{b} - {d}%",
      },
    };

    chartInstance.setOption(option);

    // Clean up the chart instance
    return () => {
      chartInstance.dispose();
    };
  }, []);

  return <div ref={chartRef} style={{ width: "100%", height: "400px" }}></div>;
};

GSLPieChart.propTypes = {
  gslsData: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  colors: PropTypes.array,
};

export const TCRevenuePieChart = ({
  gslsData,
  totalCost,
  dataType = "TC",
  colors = [
    "#76885B",
    "#430A5D",
    "#D20062",
    "#5546b6",
    "#208604",
    "#00421b",
    "#a93737",
    "#143976",
    "#008563",
    "#87A922",
  ],
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current);

    // Sample data for the pie chart
    const data = gslsData;
    const totalPercentage = data.reduce(
      (acc, curr) => acc + Number.parseFloat(curr.percent),
      0,
    );
    if (totalPercentage < 100) {
      data.push({
        name: "Unallocated",
        percent: 100 - totalPercentage,
        value: totalCost
          ? ((totalCost * (100 - totalPercentage)) / 100).toFixed(2)
          : 0,
      });
    }

    const option = {
      color: colors,
      grid: {
        top: "0%",
        bottom: "0%",
        left: "0%",
        right: "0%",
      },
      tooltip: {
        trigger: "item",
        formatter: (params) => {
          return `${params?.data?.name} - ${Intl.NumberFormat("en-us", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: "currency",
            currency: "USD",
          }).format(params?.data?.value)} (${Number(params?.data?.percent)}%)`;
        },
      },
      series: [
        {
          name: `${dataType} Data`,
          type: "pie",
          radius: "75%",
          center: ["50%", "50%"],
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
      label: {
        position: "outer",
        alignTo: "right",
        textStyle: {
          fontSize: 8,
        },
        formatter: (params) =>
          `${params.name} - ${Intl.NumberFormat("en-us", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: "currency",
            currency: "USD",
          }).format(params.value)} (${Number(params?.data?.percent)}%)`,
      },
    };

    chartInstance.setOption(option);

    // Clean up the chart instance
    return () => {
      chartInstance.dispose();
    };
  }, []);

  return <div ref={chartRef} style={{ width: "100%", height: "400px" }}></div>;
};

GSLPieChart.propTypes = {
  gslsData: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  colors: PropTypes.array,
};

export default GSLPieChart;
