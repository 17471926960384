import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";

import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import { convertToCurrency } from "utils/NumbersUtil";

import "./PhasesTable.scss";
import { get } from "utils/DeApi";
import { Form, OverlayTrigger, Pagination, Tooltip } from "react-bootstrap";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import NoTasks from "./NoTasks/NoTasks";
import PhasesDropdown from "./PhasesDropdown/PhasesDropdown";

const PhasesTable = ({ projectId, phases }) => {
  const taskHeaderRef = useRef(null);
  const phaseHeaderRef = useRef(null);
  const budgetHeaderRef = useRef(null);
  const budgetSpentHeaderRef = useRef(null);
  const updatedAtHeaderRef = useRef(null);

  const taskRef = useRef(null);
  const phaseRef = useRef(null);
  const budgetRef = useRef(null);
  const budgetSpentRef = useRef(null);
  const updatedAtRef = useRef(null);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [meta, setMeta] = useState();

  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [selectedPhase, setSelectedPhase] = useState("");

  const subscribedPromises = useRef([]);

  useEffect(() => {
    const getProjects = () => {
      setIsLoading(true);
      setData(null);
      setError(null);

      const getPromise = get(`/project-intel/projects/${projectId}/task`, {
        params: {
          limit: itemsPerPage,
          page,
          phase: selectedPhase?.id || "",
          per_page: itemsPerPage,
        },
      });
      getPromise.promise
        .then((response) => {
          setData(response?.data);
          setMeta(response);
          setIsLoading(false);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });

      subscribedPromises.current.push(getPromise);
    };

    getProjects();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [projectId, page, itemsPerPage, selectedPhase]);

  useEffect(() => {
    const updateHeaderWidth = (ref, headerRef) => {
      if (ref.current && headerRef.current) {
        const cellWidth = ref.current.getBoundingClientRect().width;
        headerRef.current.style.width = cellWidth + "px";
        ref.current.style.width = cellWidth + "px";
      }
    };

    updateHeaderWidth(taskHeaderRef, taskRef);
    updateHeaderWidth(phaseHeaderRef, phaseRef);
    updateHeaderWidth(budgetHeaderRef, budgetRef);
    updateHeaderWidth(budgetSpentHeaderRef, budgetSpentRef);
    updateHeaderWidth(updatedAtHeaderRef, updatedAtRef);
  }, [data]);

  if (data?.length === 0 && !isLoading) {
    return <NoTasks />;
  }

  return (
    <Card className="mt-3" style={{ height: "680px" }}>
      <Card.Body>
        <h5 className="text-gray fw-bold">Project Tasks</h5>
        <hr />
        <PhasesDropdown
          setPage={setPage}
          selectedPhase={selectedPhase}
          setSelectedPhase={setSelectedPhase}
          phases={phases}
        />
        <Table
          className="mt-3"
          responsive
          size="sm"
          style={{
            paddingRight: Number(itemsPerPage) === 10 ? "0px" : "18px",
          }}
        >
          <thead className="border-0 sticky-top">
            <tr>
              <th ref={taskHeaderRef}>Task</th>
              <th ref={phaseHeaderRef}>Phase</th>
              <th style={{ width: 150 }} ref={budgetHeaderRef}>
                Budget
              </th>
              <th style={{ width: 150 }} ref={budgetSpentHeaderRef}>
                Budget Spent
              </th>
              <th style={{ width: 150 }} ref={updatedAtHeaderRef}>
                Updated At
              </th>
            </tr>
          </thead>
        </Table>
        {isLoading && <Loader />}
        {error && <ErrorHandler error={error} />}
        {!isLoading && !error && (
          <div
            style={{
              height: "420px",
              overflowY: Number(itemsPerPage) === 10 ? "unset" : "scroll",
            }}
          >
            <Table responsive size="sm">
              <tbody>
                {data?.map((task) => {
                  const {
                    id,
                    name,
                    budget,
                    budgetSpentWithAccrual,
                    phaseName,
                    phaseId,
                    status,
                    updatedAt,
                  } = task;
                  return (
                    <tr key={id}>
                      <td className="py-2" ref={taskRef}>
                        <Link
                          to={`phases/${phaseId}/tasks/${id}`}
                          className={`${
                            status === "critical"
                              ? "bg-danger text-danger"
                              : "bg-success text-success"
                          } bg-opacity-10 px-1 text-decoration-none`}
                        >
                          {status === "critical" ? (
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-top" placement="top">
                                  Task is at risk
                                </Tooltip>
                              }
                            >
                              <span>{name}</span>
                            </OverlayTrigger>
                          ) : (
                            <>{name}</>
                          )}
                        </Link>
                      </td>
                      <td className="py-2" ref={phaseRef}>
                        <Link
                          to={`phases/${phaseId}/tasks/${id}`}
                          className="text-decoration-none"
                        >
                          {phaseName}
                        </Link>
                      </td>
                      <td className="py-2" ref={budgetRef}>
                        {convertToCurrency(budget)}
                      </td>
                      <td className="py-2" ref={budgetSpentRef}>
                        {convertToCurrency(budgetSpentWithAccrual)}
                      </td>
                      <td className="py-2" ref={updatedAtRef}>
                        {new Date(updatedAt).toLocaleString([], {
                          dateStyle: "short",
                          timeStyle: "short",
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
      </Card.Body>
      <Card.Footer>
        <div className="d-flex flex-column">
          <div className="d-flex gap-2 align-items-end justify-content-between">
            <Pagination className="mb-1">
              {Array.isArray(meta?.links) &&
                meta?.links.map((link, index) => {
                  return (
                    <Pagination.Item
                      key={index}
                      active={parseInt(link?.label) === page}
                      disabled={!link?.url}
                      onClick={() => {
                        if (link?.url) {
                          const URLParams = new URLSearchParams(
                            link.url.split("?")[1],
                          );
                          setPage(parseInt(URLParams.get("page")));
                        }
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: link?.label,
                        }}
                      />
                    </Pagination.Item>
                  );
                })}
            </Pagination>
            <div className="d-flex flex-row mb-1">
              <div className="me-3 pt-1">
                <small>
                  Showing {meta?.from} - {meta?.to} of {meta?.total}
                </small>
              </div>
              <Form.Group className="mb-1">
                <Form.Select
                  size="sm"
                  onChange={(e) => {
                    setItemsPerPage(e.target.value);
                    setPage(1);
                  }}
                  value={itemsPerPage}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={meta?.total}>View All</option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default PhasesTable;
