import { useState, useEffect } from "react";

export default function useSubContractorData(phases) {
  const [subContractors, setSubContractors] = useState([]);

  useEffect(
    function extractGridSubContractorDataFromPhases() {
      let initialSubContractors = [];
      phases.forEach((phase) => {
        phase.activities.forEach((activity) => {
          activity.subcontractors.forEach((subCon, subConIndex) => {
            const isSubConPresentIndex = initialSubContractors?.findIndex(
              (element) =>
                element.id === subCon.id || element.name === subCon.name,
            );

            if (isSubConPresentIndex === -1) {
              subCon.id = subCon.id.toString();
              initialSubContractors.push({
                ...subCon,
                localCurrency: "USD",
              });
            } else {
              initialSubContractors[isSubConPresentIndex].total += subCon.total;
            }
          });
        });
      });

      let finalSubContractors = [];
      initialSubContractors.forEach((element) => {
        let singleItem = {
          ...element,
          phases: Array(phases.length).fill({}),
        };

        singleItem.total = Intl.NumberFormat("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(singleItem.total);

        phases.forEach((phase, phaseIndex) => {
          let sumOfTotalHours = 0;
          let sumOfTotalActivityContingency = 0;

          singleItem.phases[phaseIndex] = {
            id: phase.id,
            title: phase.title,
            totalHours: 0,
            activities: Array(phase.activities.length).fill({}),
          };
          phase.activities.forEach((activity, activityIndex) => {
            const subContractor = activity.subcontractors.find((subCon) => {
              return element.id === subCon.id || element.name === subCon.name;
            });
            if (subContractor) {
              sumOfTotalHours += Number.parseInt(subContractor.qty);
              sumOfTotalActivityContingency += Number.parseFloat(
                subContractor.activityContingency,
              );
              singleItem.phases[phaseIndex].activities[activityIndex] = {
                id: activity.id,
                activitySubContractorId: subContractor.id,
                title: activity.title,
                total: subContractor.total,
                totalHours: Number.parseInt(subContractor.qty),
                activityContingency: Number.parseFloat(
                  subContractor.activityContingency,
                ),
              };
            } else {
              singleItem.phases[phaseIndex].activities[activityIndex] = {
                id: activity.id,
                activitySubContractorId: null,
                title: activity.title,
                total: 0,
                totalHours: 0,
                activityContingency: 0,
              };
            }
          });
          singleItem.phases[phaseIndex].totalHours = sumOfTotalHours;
          singleItem.phases[phaseIndex].activityContingency =
            sumOfTotalActivityContingency;
        });
        finalSubContractors.push(singleItem);
      });

      setSubContractors(finalSubContractors);
    },
    [phases],
  );

  return [subContractors, setSubContractors];
}
