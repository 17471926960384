import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";

import { Modal, Button, Form, Row, Col } from "react-bootstrap";

import InputFieldFormik from "../../Inputs/InputFieldFormik";
import DropdownFieldFormik from "../../Inputs/DropdownFieldFormik";
import RadioField from "components/Inputs/RadioField";
import { useCallGetDeApi } from "../../../hooks/useCallApi";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { put } from "utils/DeApi";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

import { OpportunityDispatchContext } from "contexts/OpportunityProvider";
import { get } from "utils/DeApi";
import { getDateDifference } from "utils/helper";
import * as yup from "yup";

const UpdateOpportunity = ({ opportunity, initialValues, isUpdate }) => {
  const subscribedPromises = useRef([]);
  const navigate = useNavigate();

  const { opportunityId } = useParams();

  const dispatchUpdatedOpportunity = React.useContext(
    OpportunityDispatchContext,
  );

  const [organizations, setOrganizations] = useState([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const { data: countriesData } = useCallGetDeApi("/countries");

  const { data: clientsData } = useCallGetDeApi("/clients");

  const { data: employeesData } = useCallGetDeApi("/employees-list");

  const {
    data: tenderCompetitionsData,
    isLoading: tenderCompetitionsIsLoading,
  } = useCallGetDeApi("/tender-competitions");

  const { data: pricingTypesData, isLoading: pricingTypesIsLoading } =
    useCallGetDeApi("/pricing-types");

  const { data: servicesData, isLoading: servicesIsLoading } =
    useCallGetDeApi("/services");

  const { data: sectorsData, isLoading: sectorsIsLoading } =
    useCallGetDeApi("/sectors");

  const { data: currencyData } = useCallGetDeApi("/currencies");

  const { data: ispTechPartnersData } = useCallGetDeApi("/isp-tech-partners");

  const { data: contractTermsData, isLoading: contractTermsLoading } =
    useCallGetDeApi("/contract-terms");

  const {
    data: gmsConfidentialityStatusesData,
    isLoading: gmsConfidentialityStatusesLoading,
  } = useCallGetDeApi("/gms-confidentiality-statuses");

  const schema = yup.object().shape({
    sfNumber: yup.string().nullable(),
    title: yup
      .string()
      .required("Title is a required field")
      .max(75, "Title must be less than 75 characters"),
    description: yup
      .string()
      .nullable()
      .max(75, "Description must be less than 75 characters"),
    currencyId: yup.string().required("Contract Currency is a required field"),
    countryId: yup.string().required("Country is a required field"),
    contractTermId: yup.string().required("Contract Term is a required field"),
    organizationId: yup.string().required("Organization is a required field"),
    projectManagerId: yup.string().required("PM is a required field"),
    inchargePartnerId: yup.string().required("PIC is a required field"),
    pricingTypeId: yup.string(),
    lumpSum: yup.number().when("pricingTypeId", {
      is: (value) =>
        value &&
        pricingTypesData?.filter(
          (val) => val.id === value && val.title === "Lump Sum/Unit Price",
        )?.length,
      then: (schema) =>
        schema
          .required("Lump Sum is a required field")
          .min(0)
          .max(999999999999),
      otherwise: (schema) => schema.nullable().optional(),
    }),
    projectStartDate: yup
      .date()
      .nullable()
      .max(
        yup.ref("projectEndDate"),
        "Start date must be earlier than end date",
      ),
    projectEndDate: yup
      .date()
      .nullable()
      .min(
        yup.ref("projectStartDate"),
        "End date must be later than start date",
      )
      .max(new Date(2080, 12, 31), "Project End Date cannot exceed 31-12-2080"),
    suppliersMarkup: yup
      .number()
      .nullable()
      .min(0, "Suppliers mark up needs to be a % (0-100)")
      .max(100, "Suppliers mark up needs to be a % (0-100)"),
    expensesMarkup: yup
      .number()
      .nullable()
      .min(0, "Expenses mark up needs to be a % (0-100)")
      .max(100, "Expenses mark up needs to be a % (0-100)"),
    commRecovery: yup
      .number()
      .nullable()
      .min(0, "Comm recovery needs to be a % (0-100)")
      .max(100, "Comm recovery needs to be a % (0-100)"),
    apc: yup
      .number()
      .nullable()
      .min(0, "APC needs to be a % (0-100)")
      .max(100, "APC needs to be a % (0-100)"),
  });

  const getOrganizations = (countryId) => {
    const getPromise = get("/organizations", {
      params: { countryId: countryId },
    });
    getPromise.promise
      .then((response) => {
        setOrganizations(response.data);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
      });

    subscribedPromises.current.push(getPromise);
  };

  useEffect(() => {
    getOrganizations(initialValues?.countryId);
  }, [initialValues?.countryId]);

  const updateOpportunity = (values) => {
    setError(null);
    setIsLoading(true);

    const payload = {};
    for (const key in values) {
      if (values[key] !== null) {
        if (key === "title") {
          payload[key] = values[key].replace(/\s{2,}/g, " ").trim();
        } else {
          payload[key] = values[key];
        }
      }
    }
    payload.lumpSum =
      payload?.pricingTypeId &&
      pricingTypesData?.filter(
        (val) =>
          val.id === payload?.pricingTypeId &&
          val.title === "Lump Sum/Unit Price",
      )?.length
        ? payload.lumpSum
        : "0.0000";

    const updateOpportunityPromise = put(`/opportunities/${opportunityId}`, {
      ...payload,
    });
    updateOpportunityPromise.promise
      .then((response) => {
        setIsLoading(false);
        dispatchUpdatedOpportunity(response.data);
        setShow(false);
      })
      .catch((error) => {
        !error.isCanceled && setError(error);
        setIsLoading(false);
      });
    subscribedPromises.current.push(updateOpportunityPromise);
  };

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  return (
    <>
      <Button
        variant={"outline-primary"}
        onClick={() => setShow(true)}
        className="text-nowrap"
        size="sm"
      >
        <span className="material-icons-outlined">edit</span>
      </Button>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Opportunity</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={(values) => {
            updateOpportunity(values);
          }}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Modal.Body>
                  <h5 className="text-gray">General Information</h5>
                  <hr />
                  <Row>
                    <Col>
                      <InputFieldFormik
                        name="title"
                        label={"Proposal Title"}
                        error={errors.title}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder="Enter title"
                        touched={touched.title}
                        value={values.title}
                      />
                    </Col>
                    <Col>
                      <InputFieldFormik
                        name="sfNumber"
                        label={"Salesforce Number"}
                        error={errors.sfNumber}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder=""
                        touched={touched.sfNumber}
                        value={values.sfNumber}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <InputFieldFormik
                        name="description"
                        label={"Description"}
                        error={errors.description}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={"Enter description"}
                        touched={touched.description}
                        value={values.description}
                      />
                    </Col>
                    <Col>
                      <DropdownFieldFormik
                        data={clientsData}
                        textKey="name"
                        valueKey="id"
                        name="clientId"
                        label={"Client"}
                        error={errors.clientId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={"Select client's name"}
                        touched={touched.clientId}
                        value={values.clientId}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <InputFieldFormik
                        type="text"
                        name="projectLocation"
                        label={"Project Location"}
                        error={errors.projectLocation}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={"Enter location"}
                        touched={touched.projectLocation}
                        value={values.projectLocation}
                      />
                    </Col>
                  </Row>
                  <br />

                  <h5 className="text-gray">Project Team</h5>
                  <hr />
                  <Row>
                    <Col>
                      <DropdownFieldFormik
                        data={employeesData}
                        textKey="name"
                        valueKey="id"
                        name="inchargePartnerId"
                        label={"Partner in Charge"}
                        error={errors.inchargePartnerId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={"Select PIC"}
                        touched={touched.inchargePartnerId}
                        value={values.inchargePartnerId}
                      />
                    </Col>
                    <Col>
                      <DropdownFieldFormik
                        data={employeesData}
                        textKey="name"
                        valueKey="id"
                        name="projectManagerId"
                        label={"Project Manager"}
                        error={errors.projectManagerId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={"Select PM"}
                        touched={touched.projectManagerId}
                        value={values.projectManagerId}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <DropdownFieldFormik
                        data={employeesData}
                        textKey="name"
                        valueKey="id"
                        name="businessUnitManagerPartnerId"
                        label={"Business Unit Manager Partner"}
                        error={errors.businessUnitManagerPartnerId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={"Select BUMP"}
                        touched={touched.businessUnitManagerPartnerId}
                        value={values.businessUnitManagerPartnerId}
                      />
                    </Col>
                    <Col>
                      <DropdownFieldFormik
                        data={ispTechPartnersData}
                        textKey="title"
                        valueKey="id"
                        name="ispTechPartnerId"
                        label={"ISP Tech Partner (ESG Digital use)"}
                        error={errors.ispTechPartnerId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={
                          "Select ISP Tech Partner (ESG Digital use)"
                        }
                        touched={touched.ispTechPartnerId}
                        value={values.ispTechPartnerId}
                      />
                    </Col>
                  </Row>
                  <br />

                  <h5 className="text-gray">Project Information</h5>
                  <hr />
                  <Row>
                    <Col xs={5}>
                      <DropdownFieldFormik
                        data={countriesData}
                        textKey="name"
                        valueKey="id"
                        name="countryId"
                        label={"Owning Organization"}
                        error={errors.countryId}
                        handleBlur={handleBlur}
                        handleChange={(e) => {
                          getOrganizations(e.target.value);
                          handleChange(e);
                          setFieldValue("organizationId", "", true);
                        }}
                        placeholder={"Select country"}
                        touched={touched.countryId}
                        value={values.countryId}
                      />
                    </Col>
                    <Col xs={5}>
                      <DropdownFieldFormik
                        data={organizations}
                        textKey="title"
                        valueKey="id"
                        name="organizationId"
                        error={errors.organizationId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={"Select organization"}
                        touched={touched.organizationId}
                        value={values.organizationId}
                        className={"mt-1"}
                      />
                    </Col>
                    <Col xs={2}>
                      <InputFieldFormik
                        className={"mt-1"}
                        disabled={true}
                        value={
                          organizations?.find(
                            (val) => val.id === values.organizationId,
                          )?.serialNo || ""
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DropdownFieldFormik
                        data={sectorsData}
                        textKey="name"
                        valueKey="id"
                        name="sectorId"
                        label={"Sector"}
                        error={errors.sectorId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={"Select sector"}
                        touched={touched.sectorId}
                        value={values.sectorId}
                        isLoading={sectorsIsLoading}
                      />
                    </Col>
                    <Col>
                      <DropdownFieldFormik
                        data={servicesData}
                        textKey="name"
                        valueKey="id"
                        name="serviceId"
                        label={"Services"}
                        error={errors.serviceId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={"Select service"}
                        touched={touched.serviceId}
                        value={values.serviceId}
                        isLoading={servicesIsLoading}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DropdownFieldFormik
                        data={
                          servicesData?.find(
                            (serv) => serv.id === values.serviceId,
                          )?.subServices
                        }
                        textKey="name"
                        valueKey="id"
                        name="subServiceId"
                        label={"Sub Services"}
                        error={errors.subServiceId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={"Select sub services"}
                        touched={touched.subServiceId}
                        value={values.subServiceId}
                        isLoading={servicesIsLoading}
                      />
                    </Col>
                  </Row>
                  <br />

                  <h5 className="text-gray">Financial Details</h5>
                  <hr />
                  <Row>
                    <Col>
                      <DropdownFieldFormik
                        data={
                          currencyData?.filter(
                            (item) => item.title === "USA",
                          ) || []
                        }
                        textKey="code"
                        valueKey="id"
                        name="currencyId"
                        label={"Contract Currency"}
                        error={errors.currencyId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={"Select currency"}
                        touched={touched.currencyId}
                        value={values.currencyId}
                      />
                    </Col>
                    <Col>
                      <DropdownFieldFormik
                        data={pricingTypesData}
                        textKey="title"
                        valueKey="id"
                        name="pricingTypeId"
                        label={"Pricing Type"}
                        error={errors.pricingTypeId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={"Select pricing type"}
                        touched={touched.pricingTypeId}
                        value={values.pricingTypeId}
                        isLoading={pricingTypesIsLoading}
                      />
                    </Col>
                    {values?.pricingTypeId &&
                    pricingTypesData?.filter(
                      (val) =>
                        val.id === values?.pricingTypeId &&
                        val.title === "Lump Sum/Unit Price",
                    )?.length ? (
                      <Col>
                        <InputFieldFormik
                          type="number"
                          name="lumpSum"
                          label={"Lump Sum/Unit Price"}
                          error={errors.lumpSum}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          placeholder={"Enter Lump Sum Price"}
                          touched={touched.lumpSum}
                          value={values.lumpSum}
                        />
                      </Col>
                    ) : null}
                  </Row>
                  <Row>
                    <Col>
                      <RadioField
                        name="isMapping"
                        label="Will GIS / Spatial Systems / mapping be involved?"
                        value={values.isMapping}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        options={[
                          { label: "Yes", id: 1 },
                          { label: "No", id: 0 },
                        ]}
                        error={errors.isMapping}
                        touched={touched.isMapping}
                      />
                    </Col>
                    <Col>
                      <DropdownFieldFormik
                        data={gmsConfidentialityStatusesData}
                        textKey="title"
                        valueKey="id"
                        name="gmsConfidentialityStatusId"
                        label={"GMS Confidentiality Status"}
                        error={errors.gmsConfidentialityStatusId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={"Select status"}
                        touched={touched.gmsConfidentialityStatusId}
                        value={values.gmsConfidentialityStatusId}
                        isLoading={gmsConfidentialityStatusesLoading}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <InputFieldFormik
                        name="suppliersMarkup"
                        label={"Mark Up on Subs/Suppliers (%)"}
                        type="number"
                        error={errors.suppliersMarkup}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={"0"}
                        touched={touched.suppliersMarkup}
                        value={values.suppliersMarkup}
                      />
                    </Col>

                    <Col>
                      <InputFieldFormik
                        name="expensesMarkup"
                        label={"Mark Up on Expense (%)"}
                        type="number"
                        error={errors.expensesMarkup}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={"0"}
                        touched={touched.expensesMarkup}
                        value={values.expensesMarkup}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <InputFieldFormik
                        name="commRecovery"
                        label={"Comm Recovery (%)"}
                        type="number"
                        error={errors.commRecovery}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={"0"}
                        touched={touched.commRecovery}
                        value={values.commRecovery}
                      />
                    </Col>
                    <Col>
                      <InputFieldFormik
                        name="apc"
                        label={"APC (%)"}
                        type="number"
                        error={errors.apc}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={"0"}
                        touched={touched.apc}
                        value={values.apc}
                      />
                    </Col>
                  </Row>
                  <br />

                  <h5 className="text-gray">Contract and Tender</h5>
                  <hr />
                  <Row>
                    <Col>
                      <DropdownFieldFormik
                        data={contractTermsData}
                        textKey="title"
                        valueKey="id"
                        name="contractTermId"
                        label={"Contract Term"}
                        error={errors.contractTermId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={"Select Contract Term"}
                        touched={touched.contractTermId}
                        value={values.contractTermId}
                        isLoading={contractTermsLoading}
                        helpNote={
                          "Modifying the contract term will alter the calculation."
                        }
                      />
                    </Col>
                    <Col>
                      <DropdownFieldFormik
                        data={tenderCompetitionsData}
                        textKey="title"
                        valueKey="id"
                        name="tenderCompetitionId"
                        label={"Tender/Bid Competitions"}
                        error={errors.tenderCompetitionId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        placeholder={"Select Tender/Bid Competitions"}
                        touched={touched.tenderCompetitionId}
                        value={values.tenderCompetitionId}
                        isLoading={tenderCompetitionsIsLoading}
                      />
                    </Col>
                  </Row>
                  <br />

                  <h5 className="text-gray">Project Timeline</h5>
                  <hr />
                  <Row>
                    <Col>
                      <InputFieldFormik
                        name="projectStartDate"
                        label={"Project Start Date"}
                        type="date"
                        error={errors.projectStartDate}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched.projectStartDate}
                        value={values.projectStartDate}
                      />
                    </Col>
                    <Col>
                      <InputFieldFormik
                        type="date"
                        name="projectEndDate"
                        label={"Project End Date"}
                        error={errors.projectEndDate}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        touched={touched.projectEndDate}
                        value={values.projectEndDate}
                      />
                    </Col>
                    <Col>
                      <InputFieldFormik
                        type="text"
                        label={"Total Weeks"}
                        disabled={true}
                        value={getDateDifference(
                          values?.projectStartDate,
                          values?.projectEndDate,
                        )}
                      />
                    </Col>
                  </Row>

                  {error && <ErrorHandler error={error} />}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    size="sm"
                    variant="link"
                    className="bg-secondary bg-opacity-25 text-secondary border-0 fw-medium"
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" size="sm" disabled={isLoading}>
                    Update Opportunity
                  </Button>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

UpdateOpportunity.defaultProps = {
  initialValues: {
    sfNumber: "",
    title: "",
    description: "",
    clientId: "",
    clientContact: "",
    clientEntityCountry: "",
    projectLocation: "",
    projectManagerId: "",
    inchargePartnerId: "",
    businessUnitManagerPartnerId: "",
    ispTechPartnerId: "",
    currencyId: "",
    organizationId: "",
    isMapping: "",
    expensesMarkUp: "",
    suppliersMarkup: "",
    apc: "",
    commRecovery: "",
    gmsConfidentialityStatusId: "",
    sectorId: "",
    pricingTypeId: "",
    serviceId: "",
    subServiceId: "",
    contractTermId: "",
    tenderCompetitionId: "",
  },
};

UpdateOpportunity.propTypes = {
  initialValues: PropTypes.object,
};

export default UpdateOpportunity;
