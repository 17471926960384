import React, { useEffect, useRef, useState } from "react";
import { Row, ProgressBar } from "react-bootstrap";
import ValueDiminishers from "./ValueDiminishers";
import ValueAmplifiers from "./ValueAmplifiers";
import ValueFactor from "./ValueFactor";
import "./Stepper.scss";
import { get } from "utils/DeApi";
import Loader from "components/Loader/Loader";
import ErrorHandler from "components/ErrorHandler/ErrorHandler";

const StepperControl = ({ opportunityId }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [valueFactor, setValueFactor] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const subscribedPromises = useRef([]);

  const handleNextPrevClick = (step, result, back) => {
    setActiveStep(step);
    if (!back) {
      if (step === 2) {
        setValueFactor((prev) => ({ ...prev, valueDiminishers: result }));
      } else if (step === 3) {
        setValueFactor((prev) => ({ ...prev, valueAmplifiers: result }));
      }
    }
  };
  const totalSteps = 3;

  useEffect(() => {
    function fetchValueFactor(id) {
      setIsLoading(true);
      setError("");

      const valueFactorPromise = get(`/opportunities/${id}/value-factors`);
      valueFactorPromise.promise
        .then((response) => {
          setValueFactor({
            ...(response?.data || {}),
            valueAmplifiers: JSON.parse(
              response?.data?.valueAmplifiers || `[]`,
            ),
            valueDiminishers: JSON.parse(
              response?.data?.valueDiminishers || `[]`,
            ),
          });
          if (response?.data?.id) {
            setActiveStep(3);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          !error.isCanceled && setError(error);
          setIsLoading(false);
        });

      subscribedPromises.current.push(valueFactorPromise);
    }

    fetchValueFactor(opportunityId);
  }, [opportunityId]);

  useEffect(() => {
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => promise.cancel());
    };
  }, []);

  return (
    <>
      <div className="justify-content-md-center">
        <div md={8}>
          <br />
          {!isLoading && (
            <>
              <ProgressBar now={(activeStep / totalSteps) * 100} />
              <div className="stepper">
                {[1, 2, 3].map((stepNumber) => (
                  <div
                    key={stepNumber}
                    className={`step ${
                      activeStep >= stepNumber ? "active" : ""
                    }`}
                  >
                    <div className="circle">{stepNumber}</div>
                    <div className="title">
                      {stepNumber === 1 && "Value Diminishers"}
                      {stepNumber === 2 && "Value Amplifiers"}
                      {stepNumber === 3 && "Value Factor"}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          <div>
            {isLoading && <Loader />}
            {error && <ErrorHandler error={error} />}
            {!isLoading && !error && valueFactor && (
              <Row className="mt-4" md={8}>
                {activeStep === 1 && (
                  <ValueDiminishers
                    valueFactor={valueFactor}
                    handleNextPrevClick={handleNextPrevClick}
                  />
                )}
                {activeStep === 2 && (
                  <ValueAmplifiers
                    valueFactor={valueFactor}
                    handleNextPrevClick={handleNextPrevClick}
                  />
                )}
                {activeStep === 3 && (
                  <ValueFactor
                    handleNextPrevClick={handleNextPrevClick}
                    opportunityId={opportunityId}
                    valueFactor={valueFactor}
                    setValueFactor={(data) => setValueFactor(data)}
                  />
                )}
              </Row>
            )}
          </div>
        </div>
      </div>

      <br />
    </>
  );
};

export default StepperControl;
