import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserContext } from "contexts/UserProvider";

const RedirectIfAuthenticated = ({ isAuthenticated = false }) => {
  const location = useLocation();
  const { user } = useContext(UserContext);

  return (
    (isAuthenticated || user) && (
      <Navigate to={{ pathname: "/", state: { from: location } }} />
    )
  );
};

export default RedirectIfAuthenticated;
